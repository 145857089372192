import validator from "validator";

export const changeUsFormatNumber = (value) => {
  let formattedValue = value.replace(/\D/g, ""); // Remove non-numeric characters

  if (formattedValue.length > 3 && formattedValue.length <= 6) {
    formattedValue = `(${formattedValue.slice(0, 3)}) ${formattedValue.slice(
      3
    )}`;
  } else if (formattedValue.length > 6) {
    formattedValue = `(${formattedValue.slice(0, 3)}) ${formattedValue.slice(
      3,
      6
    )}-${formattedValue.slice(6, 10)}`;
  } else if (formattedValue.length > 0) {
    formattedValue = `(${formattedValue.slice(0, 3)}`;
  }

  return formattedValue;
};

export const formatAmount = (amount) => {
  if (amount === null || amount === undefined || Number.isNaN(amount))
    return "$0.00";

  // Using Intl.NumberFormat to format the amount in US currency style
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return formatter.format(amount);
};

export const validate = (value) => {
  if (
    validator.isStrongPassword(value, {
      minLength: 10,
      minNumbers: 1,
      minLowercase: 1,
      minUppercase: 1,
      minSymbols: 1,
    })
  ) {
    return true;
  } else {
    return false;
  }
};

export const changeSocialSecurityFormat = (value) => {
  let formattedValue = value.replace(/\D/g, ""); // Remove non-numeric characters

  if (formattedValue.length > 3 && formattedValue.length <= 5) {
    // Format as XXX-XX
    formattedValue = `${formattedValue.slice(0, 3)}-${formattedValue.slice(3)}`;
  } else if (formattedValue.length > 5) {
    // Format as XXX-XX-XXXX
    formattedValue = `${formattedValue.slice(0, 3)}-${formattedValue.slice(
      3,
      5
    )}-${formattedValue.slice(5, 9)}`;
  }

  return formattedValue;
};
