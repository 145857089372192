import {
  faDownload,
  faMinus,
  faPlus,
  faTrash,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Input, Option, Select, Textarea } from "@mui/joy";
import { Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  CardFooter,
  CardHeader,
  Modal,
  ModalBody,
  ModalFooter,
} from "react-bootstrap";
import { toast } from "react-toastify";
import { Button, Card, CardBody, Form } from "reactstrap";
import { formatAmount } from "../../components/Common";
import SideBar from "../../components/sidebar/Sidebar";
import UserBase from "../../components/user/UserBase";
import "../../css/formPOPup.css";
import "../../css/myestare.css";
import {
  addJewelries,
  deleteJewelry,
  getJewelries,
} from "../../services/JewelryService";
import {
  deleteSingleProperty,
  downloadDocument1,
  fetchNetWorth,
  getBeneficiary,
  getOwnerByUserId,
  getSecondaryUser,
  getToken,
  getUser,
} from "../../services/user-service";
import { ConfirmationPopup } from "./ConfirmationPopup";
import Deletebutton from "./Deletebutton";
import UpdateButton from "./UpdateButton";

const initialJewelryData = {
  jewelry: {
    jewelryListName: "",
    owner: "",
    jewelryCaption: "",
    jewelryName: "",
    otherJewelryName: "",
    quantity: "",
    perQuantityValue: "",
    estimatedValue: "",
    caratValue: "",
    weight: "",
    notes: "",
  },
  sharedDetails: [
    {
      distributedType: "",
      distributedValue: "",
      distributedAmount: "",
      beneficiaryId: "",
    },
  ],
};

function JewelryList() {
  const [jewelries, setJewelries] = useState([initialJewelryData]);
  const [files, setFiles] = useState([]);
  const [images, setImages] = useState([]);

  const [modal, setModal] = useState(false);
  const [jewelryListNameValue, setJewelryListNameValue] = useState("");
  const handleChangeAddJewelryListName = (event) => {
    const value = event.target.value;
    setJewelryListNameValue(value);
  };
  const handleChangeConfirm = () => {
    if (jewelryListNameValue !== "") {
      setJewelries((prevJewelries) => {
        const updatedJewelries = [...prevJewelries];

        updatedJewelries[0].jewelry.jewelryListName = jewelryListNameValue;
        return updatedJewelries;
      });
      setForm1(!form1);
    }
    toggleJewelryList();
  };
  const toggleJewelryList = () => {
    setModal(false);
  };

  // for owner field
  const [ownerName, setOwnerName] = useState([]);
  const handleChange = (event, newValue, field, index, type = "jewelry") => {
    if (field === "caratValue" || field === "jewelryName") {
      setJewelries((prevJewelries) =>
        prevJewelries.map((item, i) =>
          i === index
            ? {
                ...item,
                [type]: {
                  ...item[type],
                  [field]: newValue,
                  otherJewelryName:
                    field === "jewelryName" && newValue !== "Other" ? "" : "",
                },
              }
            : item
        )
      );
    }

    if (field === "owner") {
      const ownerString = Array.isArray(newValue)
        ? newValue.join(", ")
        : newValue;

      setJewelries((prevJewelries) =>
        prevJewelries.map((item, i) =>
          i === index
            ? {
                ...item,
                [type]: {
                  ...item[type],
                  owner: ownerString,
                },
              }
            : item
        )
      );

      setOwnerName((prevOwnerNames) => {
        const updatedOwnerNames = [...prevOwnerNames];
        updatedOwnerNames[index] = newValue;
        return updatedOwnerNames;
      });
    }
  };

  // Define an array to store user names
  const [ownerNames, setOwnerNames] = useState([]);

  // Get primaryUser data
  const primaryUserDetails = getUser();
  const primaryUserName =
    primaryUserDetails.firstName + " " + primaryUserDetails.lastName;

  // Get secondaryUser data
  const secondaryUserDetails = getSecondaryUser();
  useEffect(() => {
    const fetchOwnerData = async () => {
      const userId = getUser().commonId;
      const token = "Bearer " + getToken();

      try {
        const resp = await getOwnerByUserId(token, userId);
        // Process data after fetching
        const names = [primaryUserName];

        // Check if secondary user exists
        if (secondaryUserDetails !== undefined) {
          const secondaryUserName = `${secondaryUserDetails.firstName} ${secondaryUserDetails.lastName}`;
          names.push(secondaryUserName);
        }
        resp.forEach((user) => {
          const fullName = `${user.firstName} ${user.lastName}`;
          names.push(fullName);
        });

        // Update ownerNames state
        setOwnerNames(names);
      } catch (error) {
        console.error("Failed to fetch owner data", error);
      }
    };

    fetchOwnerData();
  }, []);

  const handleChanges = (e, field, index, type = "jewelry") => {
    const { value } = e.target;

    setJewelries((prevJewelries) =>
      prevJewelries.map((jewelry, i) => {
        // If the field is "jewelryListName" and the length is greater than 0
        if (field === "jewelryListName" && prevJewelries.length > 0) {
          return {
            ...jewelry,
            [type]: {
              ...jewelry[type],
              jewelryListName: value, // Set jewelryListName to the new value for all items
            },
          };
        }

        // If the field is "perQuantityValue", calculate and update estimatedValue directly
        if (field === "perQuantityValue" && i === index) {
          return {
            ...jewelry,
            [type]: {
              ...jewelry[type],
              perQuantityValue: value, // Update perQuantityValue
              estimatedValue: jewelry[type].quantity * value, // Calculate estimatedValue
            },
          };
        }

        // If the field is "quantity", update the specific index and recalculate estimatedValue
        if (field === "quantity" && i === index) {
          return {
            ...jewelry,
            [type]: {
              ...jewelry[type],
              quantity: value, // Update quantity for the specific index
              estimatedValue: value * jewelry[type].perQuantityValue, // Recalculate estimatedValue
            },
          };
        }

        // Default behavior for other fields
        return i === index
          ? {
              ...jewelry,
              [type]: {
                ...jewelry[type],
                [field]: value,
              },
            }
          : jewelry;
      })
    );
  };

  // Handle image
  const handleImageChange = (event, indexValue) => {
    const index = indexValue;
    const selectedFiles = event.target.files;
    const allowedExtensions = ["pdf"];

    if (selectedFiles) {
      const selectedFilesArray = Array.from(selectedFiles);

      // Check each file's extension
      const invalidFiles = selectedFilesArray.filter((file) => {
        const fileNameParts = file.name.split(".");
        const fileExtension =
          fileNameParts[fileNameParts.length - 1].toLowerCase();
        return !allowedExtensions.includes(fileExtension);
      });

      if (invalidFiles.length === 0) {
        // Update the files state for the current index
        setFiles((prevFiles) => ({
          ...prevFiles,
          [index]: selectedFilesArray, // Store files for the current index
        }));
      } else {
        // Handle invalid file extensions
        const invalidExtensions = invalidFiles
          .map((file) => file.name.split(".").pop())
          .join(", ");
        toast.error(
          `Invalid file extensions: ${invalidExtensions}. Please select valid document formats.`,
          {
            position: toast.POSITION.BOTTOM_CENTER,
          }
        );
        event.target.value = ""; // Clear the input field
      }
    } else {
      // If no files selected, clear the files for the current index
      setFiles((prevFiles) => ({
        ...prevFiles,
        [index]: [], // Clear files for the current index
      }));
    }
  };
  const handleImageChange1 = (event, indexValue) => {
    const index = indexValue;
    const selectedFiles = event.target.files;
    const allowedExtensions = ["jpg", "jpeg", "png", "gif"];

    if (selectedFiles) {
      const selectedFilesArray = Array.from(selectedFiles);

      // Check each file's extension
      const invalidFiles = selectedFilesArray.filter((file) => {
        const fileNameParts = file.name.split(".");
        const fileExtension =
          fileNameParts[fileNameParts.length - 1].toLowerCase();
        return !allowedExtensions.includes(fileExtension);
      });

      if (invalidFiles.length === 0) {
        // Update the files state for the current index
        setImages((prevImages) => ({
          ...prevImages,
          [index]: selectedFilesArray, // Store files for the current index
        }));
      } else {
        // Handle invalid file extensions
        const invalidExtensions = invalidFiles
          .map((file) => file.name.split(".").pop())
          .join(", ");
        toast.error(
          `Invalid file extensions: ${invalidExtensions}. Please select valid image formats.`,
          {
            position: toast.POSITION.BOTTOM_CENTER,
          }
        );
        event.target.value = ""; // Clear the input field
      }
    } else {
      // If no files selected, clear the files for the current index
      setImages((prevImages) => ({
        ...prevImages,
        [index]: [], // Clear files for the current index
      }));
    }
  };

  const resetForm = () => {
    setFiles([]);
    setImages([]);
    setOwnerName([]);
    setJewelries([initialJewelryData]);
    setIsTextFieldClicked(false);
    setJewelryListNameValue("");
  };

  // Set the form
  const jewelryForm = (event) => {
    event.preventDefault();
    let token = "Bearer " + getToken();

    // create form data to send a file and remaining class data
    const formData = new FormData();

    formData.append("data", JSON.stringify(jewelries));

    Object.keys(files).forEach((index) => {
      files[index].forEach((file) => {
        formData.append(`files${index}`, file);
      });
    });

    Object.keys(images).forEach((index) => {
      images[index].forEach((image) => {
        formData.append(`images${index}`, image);
      });
    });

    let allHaveData = true;

    jewelries.forEach((jewelry) => {
      const hasData = jewelry.sharedDetails.every(
        (detail) => detail?.distributedType !== ""
      );

      if (!hasData) {
        allHaveData = false;
      }
    });

    if (!allHaveData) {
      toast.error("Select beneficiary before submitting the form. !!", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    }

    addJewelries(formData, token)
      .then((resp) => {
        AddCard();
        toast.success("Data Added !!", {
          position: toast.POSITION.BOTTOM_CENTER,
        });

        fetchNetWorth(primaryUserDetails.commonId);
        toggle(null, null, "close");
      })
      .catch((error) => {});
  };

  const [showConfirmationDelete, setShowConfirmationDelete] = useState(false);
  const [currentId, setCurrentId] = useState(null);
  const [currentIdType, setCurrentIdType] = useState("");
  const removeJewelry = (id, idType) => {
    setShowConfirmationDelete(true);
    setCurrentId(id);
    setCurrentIdType(idType);
  };

  const handleConfirmationDeleteClose = () => {
    setShowConfirmationDelete(false);
  };

  // Code by Purnendu
  const handleRemove = (params) => {
    setShowConfirmationDelete(false);
    const { Id, idType } = params;
    if (idType === "jewelryId") {
      deleteJewelry(Id)
        .then((res) => {
          toast.success("Deleted successfully...", {
            position: toast.POSITION.BOTTOM_CENTER,
          });
          fetchNetWorth(primaryUserDetails.commonId);
          AddCard();
          setShow1(false);
        })
        .catch((error) => {});
    } else {
      deleteSingleProperty(Id)
        .then((res) => {
          setBeneficiaryVisible(!beneficiaryVisible);
          setShow1(false);
          AddCard();
          toast.success("Deleted successfully...", {
            position: toast.POSITION.BOTTOM_CENTER,
          });
          fetchNetWorth(primaryUserDetails.commonId);
        })
        .catch((error) => {});
    }
  };

  const handleDownload = (id, fileName) => {
    let myarry = fileName.split(".");

    downloadDocument1(id)
      .then((response) => {
        const downloadUrl = URL.createObjectURL(response.data);

        const link = document.createElement("a");
        link.href = downloadUrl;
        link.download = `${myarry[0]}.${myarry[1]}`;
        link.click();
        URL.revokeObjectURL(downloadUrl);
      })
      .catch((error) => {});
  };

  // const [metalPrice, setMetalPrice] = useState(0);

  // useEffect(() => {
  //   // Fetch metal price from the API and store it in the state

  //   const fetchMetalPrice = () => {
  //     if (data.jewelry.jewelryName === "") {
  //       setMetalPrice(0);
  //       if (data.jewelry.caratValue === "") {
  //         setMetalPrice(0);
  //         return;
  //       }
  //       return;
  //     }
  //     // if (data.keratValue === "") {
  //     //   setMetalPrice(0);
  //     //   return;
  //     // }

  //     const headers = {
  //       "x-access-token": "goldapi-sw18arlkmh58d1-io",
  //       "Content-Type": "application/json",
  //     };
  //     const metalAPIEndpoint = `https://www.goldapi.io/api/${data.jewelry.jewelryName}/USD`;
  //     axios
  //       .get(metalAPIEndpoint, { headers: headers })
  //       .then((res) => {
  //         setMetalPrice(res.data[data.jewelry.caratValue]); // Assuming the API response contains the price for 24K gold per gram
  //       })
  //       .catch((error) => {});
  //   };
  // }, []);

  // ... (remaining existing functions)

  // useEffect(
  //   () => {
  //     const calculateEstimatedValue = () => {
  //       if (data.jewelry.jewelryName && data.jewelry.weight && metalPrice) {
  //         // Assuming the selected metal unit is 24K and using the metal price for 24K gold to calculate the estimated value
  //         const estimatedValue = data.jewelry.weight * metalPrice;

  //         setData((prevData) => ({
  //           ...prevData,
  //           jewelry: {
  //             ...prevData.jewelry,
  //             estimatedValue: estimatedValue.toFixed(3),
  //           },
  //         }));

  //         // setData((prevData) => ({
  //         //   ...prevData,
  //         //   estimatedValue: estimatedValue.toFixed(3),
  //         // }));
  //       } else {
  //         setData((prevData) => ({
  //           ...prevData,
  //           jewelry: {
  //             ...prevData.jewelry,
  //             estimatedValue: "",
  //           },
  //         }));
  //       }
  //     };

  //     // calculateEstimatedValue();
  //   },
  //   // [data.jewelry.jewelryName, data.jewelry.weight, metalPrice]
  //   []
  // );

  const getDisplayName = (metadataValue) => {
    switch (metadataValue) {
      case "XAU":
        return "Gold";
      case "XAG":
        return "Silver";
      case "XPT":
        return "Platinum";
      case "XPD":
        return "Palladium";
      default:
        return metadataValue;
    }
  };
  const getDisplayKeratValue = (keratValue) => {
    switch (keratValue) {
      case "price_gram_24k":
        return "24k";
      case "price_gram_22k":
        return "22k";
      case "price_gram_21k":
        return "21k";
      case "price_gram_20k":
        return "20k";
      case "price_gram_18k":
        return "18";
      case "price_gram_16k":
        return "16";
      case "price_gram_14k":
        return "14k";
      case "price_gram_10k":
        return "10k";
      default:
        return keratValue;
    }
  };

  // show notes popup
  const [popupVisible, setPopupVisible] = useState(false);
  const [popupVisibleImages, setPopupVisibleImages] = useState(false);
  const [selectedImages, setSelectedImages] = useState("");

  const [selectedNote, setSelectedNote] = useState("");

  const handleOpenPopup = (note) => {
    setSelectedNote(note);
    setPopupVisible(true);
  };

  // for multiple download
  const [popupVisibleDownlaod, setPopupVisibleDownlaod] = useState(false);
  const [selectedDownlaod, setSelectDownload] = useState("");

  const handleShowDownlaod = (showDetail) => {
    setPopupVisibleDownlaod(true);
    setSelectDownload(showDetail);
  };

  const handleShowImages = (showDetail) => {
    setPopupVisibleImages(true);
    setSelectedImages(showDetail);
  };

  // page opening  animation
  const [show, setShow] = useState(false);

  //  form show button
  let [form1, setForm1] = useState(false);

  const [existingJewelryListName, setExistingJewelryListName] = useState(null);
  const toggle = (event, value, closePopup) => {
    setExistingJewelryListName(value);
    resetForm();
    if (closePopup) {
      setForm1(false);
      setJewelryListNameValue("");
      return;
    }
    if (value) {
      setForm1(true);
    } else {
      if (jewelryListNameValue !== "") {
        setForm1(false);
      } else {
        setModal(true);
      }
    }
  };

  useEffect(() => {
    if (existingJewelryListName) {
      setJewelries((prevJewelries) =>
        prevJewelries.map((item) => ({
          ...item,
          jewelry: {
            ...item.jewelry,
            jewelryListName: existingJewelryListName, // Set the new jewelryListName
          },
        }))
      );
    }
  }, [existingJewelryListName]);
  // cards
  let [card, setCard] = useState([]); // card = [ {} , {} , {}] - include the form data going to use it for card
  let [showDetail, setShowDetail] = useState([]); // this is to display the card details
  let [show1, setShow1] = useState(false);

  // card creating
  const AddCard = () => {
    let userId = getUser().commonId;
    let token = "Bearer " + getToken(); // Added 'Bearer'
    getJewelries(token, userId)
      .then((res) => {
        setCard(res);
      })
      .catch((error) => {
        setCard([]);
      });
  };

  // showing the details of cards like popup
  const Showdetails = (obj) => {
    // const arrayFromObject = Object.keys(obj).map(key => obj[key]);
    setShowDetail(obj);
    setShow1(true);
  };

  // beneficiary addition in form
  const [beneficiary, setBenificiary] = useState([]);
  const getBenificiarydata = () => {
    let userId = getUser().commonId;

    let token = "Bearer " + getToken();
    getBeneficiary(token, userId)
      .then((res) => {
        setBenificiary(res);
      })
      .catch((err) => {});
  };
  useEffect(() => {
    setShow(true);
    AddCard();
    getBenificiarydata();
  }, []);

  // for add field pop
  let [showAdditionField, SetshowAdditionField] = useState(false);
  const [isTextFieldClicked, setIsTextFieldClicked] = useState(false);

  //
  const [benevisible, setbeneVisible] = useState(false);
  const [distributionType, setDistributionType] = useState("");
  const [selectedBeneficiaries, setSelectedBeneficiaries] = useState([]);
  const [beneficiaryDetails, setBeneficiaryDetails] = useState({});
  const [estimatedTotalAmount, setEstimatedTotalAmount] = useState(0);
  const [beneficiaryVisible, setBeneficiaryVisible] = useState(false);
  const [selectedBeneficiary, setSelectedBeneficiary] = useState("");
  const [currentBeneficiaryIndex, setCurrentBeneficiaryIndex] = useState(null);

  const handleShowBeneficiary = (event, indexValue) => {
    setCurrentBeneficiaryIndex(indexValue);
    setShow1(false);
    setbeneVisible(true);
  };

  const handleReset = () => {
    setbeneVisible(false);
    setDistributionType("");
    setSelectedBeneficiaries([]);
    setBeneficiaryDetails({});
  };

  const handleDistributionTypeChange = (event) => {
    const newType = event.target.value;
    const resetDetails = {};
    Object.keys(beneficiaryDetails).forEach((beneficiary) => {
      resetDetails[beneficiary] = { percentage: "", value: "" };
    });
    setDistributionType(newType);
    setBeneficiaryDetails(resetDetails);
  };

  const handleBeneficiarySelection = (event) => {
    const selectedBeneficiary = event.target.value;
    if (!selectedBeneficiaries.includes(selectedBeneficiary)) {
      setSelectedBeneficiaries([...selectedBeneficiaries, selectedBeneficiary]);
      setBeneficiaryDetails({
        ...beneficiaryDetails,
        [selectedBeneficiary]: { percentage: "", value: "" },
      });
    }
  };

  const handleBeneficiaryClose = (beneficiary) => {
    const updatedBeneficiaries = selectedBeneficiaries.filter(
      (b) => b !== beneficiary
    );
    setSelectedBeneficiaries(updatedBeneficiaries);
    const updatedDetails = { ...beneficiaryDetails };
    delete updatedDetails[beneficiary];
    setBeneficiaryDetails(updatedDetails);
  };

  const getBenificiaryName = (id) => {
    var foundBenificiary = null;
    if (id.beneficiary === undefined) {
      foundBenificiary = beneficiary.find((b) => b.id === parseInt(id));
    } else {
      foundBenificiary = beneficiary.find(
        (b) => b.id === parseInt(id.beneficiary)
      );
    }
    if (foundBenificiary) {
      return `${foundBenificiary.firstName} ${foundBenificiary.lastName}`;
    } else {
      return "Benificiary not found";
    }
  };

  const handleFieldChange = (beneficiary, field, value) => {
    const estimatedValue = parseFloat(
      jewelries[currentBeneficiaryIndex]?.jewelry?.estimatedValue
    );

    if (!estimatedValue || estimatedValue === 0) {
      toast.error(
        "Please provide a valid estimated value before adding percentages!"
      );
      return;
    }

    let updatedDetails = { ...beneficiaryDetails };
    if (/^\d*$/.test(value)) {
      updatedDetails = {
        ...updatedDetails,
        [beneficiary]: {
          ...updatedDetails[beneficiary],
          [field]: value,
        },
      };
    }

    let totalPercentage = 0;
    let totalValue = 0;
    Object.keys(updatedDetails).forEach((key) => {
      const beneficiaryPercentage = parseFloat(updatedDetails[key]?.percentage);
      const beneficiaryValue = parseFloat(updatedDetails[key]?.value);
      if (!isNaN(beneficiaryPercentage) && beneficiaryPercentage >= 0) {
        totalPercentage += beneficiaryPercentage;
      }
      if (!isNaN(beneficiaryValue) && beneficiaryValue >= 0) {
        totalValue += beneficiaryValue;
      }
    });

    if (totalPercentage > 100 || totalValue > estimatedValue) {
      updatedDetails[beneficiary][field] = "";
      toast.error(
        "Total percentage exceeds 100% or total value exceeds estimated value!"
      );
    } else {
      setBeneficiaryDetails(updatedDetails);
    }
  };

  const calculateDistributedAmount = (
    distributionType,
    balance,
    beneficiaryDetail
  ) => {
    if (distributionType === "percentage") {
      return (
        (parseFloat(balance) * parseFloat(beneficiaryDetail?.percentage || 0)) /
        100
      ).toFixed(2);
    } else if (distributionType === "dollar") {
      return parseFloat(beneficiaryDetail?.value || 0).toFixed(2);
    }
    return "0.00";
  };

  const handleSave = () => {
    toast.success("Saved successfully!");
    setbeneVisible(false);
    const jewelryIndex = currentBeneficiaryIndex;
    console.log("index: ", jewelryIndex);

    if (jewelryIndex === null || !jewelries[jewelryIndex]) {
      console.error("Invalid jewelry index");
      return;
    }

    let i = 0;
    Object.keys(beneficiaryDetails).forEach((key) => {
      const beneficiaryPercentage = parseFloat(
        beneficiaryDetails[key]?.percentage
      );
      const beneficiaryValue = parseFloat(beneficiaryDetails[key]?.value);

      if (!isNaN(beneficiaryPercentage) && beneficiaryPercentage >= 0) {
        const distributedAmount = calculateDistributedAmount(
          "percentage",
          jewelries[jewelryIndex]?.jewelry?.estimatedValue,
          beneficiaryDetails[key]
        );
        handleChanges2(
          "percentage",
          beneficiaryPercentage,
          distributedAmount,
          parseInt(key),
          i,
          jewelryIndex
        );
        i += 1;
      }

      if (!isNaN(beneficiaryValue) && beneficiaryValue >= 0) {
        const distributedAmount = calculateDistributedAmount(
          "dollar",
          jewelries[jewelryIndex]?.jewelry?.estimatedValue,
          beneficiaryDetails[key]
        );
        handleChanges2(
          "dollar",
          beneficiaryValue,
          distributedAmount,
          parseInt(key),
          i,
          jewelryIndex
        );
        i += 1;
      }
    });
    handleReset();
  };

  const handleChanges2 = (t, v, a, b, i, jewelryIndex) => {
    // Ensure jewelries and index are valid
    if (!Array.isArray(jewelries) || jewelries[jewelryIndex] === undefined) {
      console.error("Invalid jewelries array or index");
      return;
    }

    // Check if the index i is within the bounds of the array
    if (i >= 0) {
      // Create a new object to update the specific element at index i
      const updatedElement = {
        distributedType: t,
        distributedValue: v,
        distributedAmount: a,
        beneficiaryId: b,
      };

      // Use the functional form of setState to ensure the current state is used
      setJewelries((prevJewelries) => {
        const updatedSharedDetails = [
          ...prevJewelries[jewelryIndex].sharedDetails,
        ];
        updatedSharedDetails[i] = updatedElement;

        const updatedJewelries = [...prevJewelries];
        updatedJewelries[jewelryIndex] = {
          ...updatedJewelries[jewelryIndex],
          sharedDetails: updatedSharedDetails,
        };

        return updatedJewelries;
      });
    } else {
      console.error("Index out of bounds");
    }
  };

  const handleOpenBeneficiary = (showDetail) => {
    setSelectedBeneficiary(showDetail);
    setBeneficiaryVisible(true);
  };

  const uniqueJewelryTypes = [
    ...new Set(
      card
        .map((entity) => entity.jewelry.jewelryName)
        .filter((jewelryName) => jewelryName !== "Other")
    ),
  ];
  const uniqueOtherJewelryTypes = [
    ...new Set(
      card
        .map((entity) => entity.jewelry.otherJewelryName)
        .filter(
          (otherJewelryName) =>
            otherJewelryName !== null && otherJewelryName !== ""
        )
    ),
  ];

  const base64ToBlob = (base64Data) => {
    const byteCharacters = atob(base64Data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: "image/jpeg" }); // Adjust type if needed
  };

  const addJewelryRow = () => {
    // setJewelries([...jewelries, initialJewelryData]);

    setJewelries((prevJewelries) => [
      ...prevJewelries,
      {
        ...initialJewelryData,
        jewelry: {
          ...initialJewelryData.jewelry,
          jewelryListName: prevJewelries[0]?.jewelry.jewelryListName || "",
        },
      },
    ]);
  };

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const removeJewelryRow = (index) => {
    setShowConfirmation(true);
    setCurrentIndex(index);
  };

  const handleConfirmation = () => {
    setShowConfirmation(false);
    const index = currentIndex;
    setJewelries(jewelries.filter((_, i) => i !== index));

    setFiles((prevFiles) => {
      const updatedFiles = Object.keys(prevFiles).reduce((acc, key) => {
        const numericKey = parseInt(key, 10);
        if (numericKey !== index) {
          const newIndex = numericKey > index ? numericKey - 1 : numericKey;
          acc[newIndex] = prevFiles[key];
        }
        return acc;
      }, {});

      return updatedFiles;
    });

    setImages((prevImages) => {
      const updatedImages = Object.keys(prevImages).reduce((acc, key) => {
        const numericKey = parseInt(key, 10);
        if (numericKey !== index) {
          const newIndex = numericKey > index ? numericKey - 1 : numericKey;
          acc[newIndex] = prevImages[key];
        }
        return acc;
      }, {});

      return updatedImages;
    });

    // Update ownerName by shifting indices after removal
    setOwnerName((prevOwnerNames) => {
      const updatedOwnerNames = prevOwnerNames.filter((_, i) => i !== index);
      return updatedOwnerNames;
    });
  };

  const handleConfirmationClose = () => {
    setShowConfirmation(false);
  };

  // Group and consolidate data
  const consolidated = card.reduce((acc, item) => {
    const listName = item.jewelry.jewelryListName;

    if (!acc[listName]) {
      acc[listName] = {
        jewelryListName: listName,
        data: [],
      };
    }

    acc[listName].data.push({
      jewelry: item.jewelry,
      documents: item.documents,
      sharedDetails: item.sharedDetails,
      supportingImages: item.supportingImages,
    });

    return acc;
  }, {});

  // Convert consolidated object to an array
  const uniqueJewelryListResult = Object.values(consolidated);

  return (
    <div className={`your-component ${show ? "fade-in-element" : ""}`}>
      <UserBase>
        <div className="mt-5"></div>
        <SideBar>
          {primaryUserDetails.accountType === "secondary" &&
          secondaryUserDetails.accountType === "primary" &&
          secondaryUserDetails.isSecondaryUserEditable === "false" ? (
            ""
          ) : (
            <div className="addme">
              <div className="addme_inner">
                <button onClick={() => toggle()}>Add Jewelry List</button>
              </div>
            </div>
          )}

          <div className="propCard">
            <div class="propCard-card">
              {uniqueJewelryListResult.map((item, index) => (
                <div
                  className="propCard-card-body"
                  style={{ padding: "20px 20px 10px 20px" }}
                >
                  <div className="propCard_card_status">
                    <p
                      className={`propCard_card_status_text ${
                        item.data.every(
                          (dataItem) => dataItem.sharedDetails.length > 0
                        )
                          ? "completed"
                          : "incomplete"
                      }`}
                    >
                      {item.data.every(
                        (dataItem) => dataItem.sharedDetails.length > 0
                      )
                        ? "completed"
                        : "incomplete"}
                    </p>
                  </div>
                  <h5 className="propCard-card-title">
                    {item.jewelryListName}
                  </h5>
                  <div className="propCard-btn">
                    <p className="viewDetails">
                      <button
                        className="jewelry_add_more"
                        onClick={(event) => toggle(event, item.jewelryListName)}
                      >
                        Add More
                      </button>
                      <button
                        style={{ margin: "10px 0" }}
                        onClick={() => Showdetails(item.data)}
                      >
                        View Details
                      </button>
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {form1 && (
            <div
              className="overlay1"
              style={{ transition: "500ms", height: "" }}
            >
              <div>
                <Form onSubmit={jewelryForm} className="addform_body">
                  <container>
                    <div className="homepage jewelry_container">
                      <Card>
                        <CardHeader>
                          <div
                            style={{ width: "100%" }}
                            className="addform_heading"
                          >
                            <h3 className="addform_heading_h1">
                              {jewelryListNameValue && jewelryListNameValue}
                              {existingJewelryListName &&
                                existingJewelryListName}
                            </h3>
                            <div
                              className="addform_close"
                              onClick={(event) => toggle(event, "", "close")}
                            >
                              <FontAwesomeIcon icon={faXmark} />
                            </div>
                          </div>
                        </CardHeader>
                        <CardBody className="jewelry_card_body">
                          <div>
                            <table className="jewelry_table">
                              <thead>
                                <tr>
                                  <th>#</th>
                                  <th className="jewelry_100px">Owner*</th>
                                  <th className="jewelry_110px">Type*</th>
                                  <th className="jewelry_110px">Name*</th>
                                  <th className="jewelry_90px">Weight(gm)</th>
                                  <th className="jewelry_65px">Qty*</th>
                                  <th className="jewelry_90px">Qty/Value*</th>
                                  <th className="jewelry_100px">
                                    Appraised Val*
                                  </th>
                                  <th>Images</th>
                                  <th>Documents</th>
                                  <th>Beneficiary*</th>
                                  <th>Notes</th>
                                  <th></th>
                                </tr>
                              </thead>
                              <tbody>
                                {jewelries.map((row, index) => (
                                  <tr key={index}>
                                    <td>{index + 1}</td>

                                    <td>
                                      <Select
                                        className="jewelry_select jewelry_100px"
                                        placeholder="Owner"
                                        value={ownerName[index] || []}
                                        multiple
                                        onChange={(event, newValue) =>
                                          handleChange(
                                            event,
                                            newValue,
                                            "owner",
                                            index,
                                            "jewelry"
                                          )
                                        }
                                        required
                                      >
                                        {ownerNames.map((name) => (
                                          <Option key={name} value={name}>
                                            {name}
                                          </Option>
                                        ))}
                                      </Select>
                                    </td>

                                    <td>
                                      <div className="jewelry_select_and_input">
                                        <div>
                                          <Select
                                            className="jewelry_select jewelry_110px"
                                            value={
                                              jewelries[index]?.jewelry
                                                ?.jewelryName || ""
                                            }
                                            onChange={(event, newValue) =>
                                              handleChange(
                                                event,
                                                newValue,
                                                "jewelryName",
                                                index,
                                                "jewelry"
                                              )
                                            }
                                            required
                                          >
                                            <Option value="">
                                              Jewelry Type
                                            </Option>
                                            <Option value="Gold">Gold</Option>
                                            <Option value="Silver">
                                              Silver
                                            </Option>
                                            <Option value="Platinum">
                                              Platinum
                                            </Option>
                                            <Option value="Palladium">
                                              Palladium
                                            </Option>
                                            <Option value="Stone">Stone</Option>
                                            <Option value="Other">Other</Option>
                                          </Select>
                                        </div>
                                        {jewelries[index]?.jewelry
                                          ?.jewelryName === "Other" && (
                                          <div>
                                            <Input
                                              className="jewelry_input jewelry_110px"
                                              placeholder="Other Jewelry Type"
                                              onChange={(e) =>
                                                handleChanges(
                                                  e,
                                                  "otherJewelryName",
                                                  index,
                                                  "jewelry"
                                                )
                                              }
                                              value={
                                                jewelries[index]?.jewelry
                                                  ?.otherJewelryName || ""
                                              }
                                              required={
                                                jewelries[index]?.jewelry
                                                  ?.jewelryName === "Other"
                                              }
                                            />
                                          </div>
                                        )}
                                      </div>
                                    </td>

                                    <td>
                                      <Input
                                        className="jewelry_input jewelry_110px"
                                        placeholder="Jewelry Name"
                                        onChange={(e) =>
                                          handleChanges(
                                            e,
                                            "jewelryCaption",
                                            index,
                                            "jewelry"
                                          )
                                        }
                                        value={
                                          jewelries[index]?.jewelry
                                            ?.jewelryCaption || ""
                                        }
                                        required
                                      />
                                    </td>
                                    <td>
                                      <Input
                                        className="jewelry_90px"
                                        type="number"
                                        placeholder="Weight"
                                        onChange={(e) =>
                                          handleChanges(
                                            e,
                                            "weight",
                                            index,
                                            "jewelry"
                                          )
                                        }
                                        value={
                                          jewelries[index]?.jewelry?.weight ||
                                          ""
                                        }
                                      />
                                    </td>
                                    <td>
                                      <Input
                                        className="jewelry_65px"
                                        type="number"
                                        placeholder="Qty"
                                        onChange={(e) =>
                                          handleChanges(
                                            e,
                                            "quantity",
                                            index,
                                            "jewelry"
                                          )
                                        }
                                        value={
                                          jewelries[index]?.jewelry?.quantity ||
                                          ""
                                        }
                                        required
                                      />
                                      {/* <Select
                                          className="jewelry_select jewelry_90px"
                                          value={
                                            jewelries[index]?.jewelry
                                              ?.caratValue || ""
                                          }
                                          onChange={(event, newValue) =>
                                            handleChange(
                                              event,
                                              newValue,
                                              "caratValue",
                                              index,
                                              "jewelry"
                                            )
                                          }
                                        >
                                          <Option value="">Value</Option>
                                          <Option value="24k">24k</Option>
                                          <Option value="22k">22k</Option>
                                          <Option value="21k">21k</Option>
                                          <Option value="20k">20k</Option>
                                          <Option value="18k">18k</Option>
                                          <Option value="16k">16k</Option>
                                          <Option value="14k">14k</Option>
                                          <Option value="10k">10k</Option>
                                        </Select> */}
                                    </td>
                                    <td>
                                      <Input
                                        className="jewelry_90px"
                                        type="number"
                                        placeholder="value"
                                        onChange={(e) =>
                                          handleChanges(
                                            e,
                                            "perQuantityValue",
                                            index,
                                            "jewelry"
                                          )
                                        }
                                        value={
                                          jewelries[index]?.jewelry
                                            ?.perQuantityValue || ""
                                        }
                                        startDecorator={<div>$</div>}
                                        required
                                      />
                                    </td>

                                    <td>
                                      <Input
                                        disabled
                                        className="jewelry_input jewelry_130px"
                                        id={`estimatedValue${index}`}
                                        placeholder="Appraised Value"
                                        type="number"
                                        onChange={(e) =>
                                          handleChanges(
                                            e,
                                            "estimatedValue",
                                            index,
                                            "jewelry"
                                          )
                                        }
                                        value={
                                          jewelries[index]?.jewelry
                                            ?.estimatedValue || ""
                                        }
                                        onClick={() =>
                                          setIsTextFieldClicked(true)
                                        }
                                        startDecorator={<div>$</div>}
                                        required
                                      />
                                    </td>
                                    <td>
                                      <div className="jewelry_choose_images">
                                        <div>
                                          <label
                                            className="jewelry_choose_images_label"
                                            htmlFor={`images${index}`}
                                          >
                                            Images
                                          </label>
                                        </div>
                                        {images[index] && (
                                          <div>
                                            <label className="jewelry_choose_images_file">
                                              {images[index]?.length === 1
                                                ? `${
                                                    images[index][0].name
                                                      .length > 15
                                                      ? images[
                                                          index
                                                        ][0].name.substring(
                                                          0,
                                                          2
                                                        ) +
                                                        "..." +
                                                        images[
                                                          index
                                                        ][0].name.slice(-4)
                                                      : images[index][0].name
                                                  }`
                                                : `${images[index]?.length} images`}
                                            </label>
                                          </div>
                                        )}

                                        <input
                                          style={{
                                            display: "none",
                                          }}
                                          id={`images${index}`}
                                          type="file"
                                          name="images"
                                          onChange={(e) =>
                                            handleImageChange1(e, index, 5)
                                          }
                                          multiple
                                          accept="image/*"
                                        />
                                      </div>
                                    </td>

                                    <td>
                                      <div className="jewelry_choose_files">
                                        <div>
                                          <label
                                            className="jewelry_choose_file_label"
                                            htmlFor={`files${index}`}
                                          >
                                            Documents
                                          </label>
                                        </div>
                                        {files[index] && (
                                          <div>
                                            <label className="jewelry_choose_file_file">
                                              {files[index]?.length === 1
                                                ? `${
                                                    files[index][0].name
                                                      .length > 15
                                                      ? files[
                                                          index
                                                        ][0].name.substring(
                                                          0,
                                                          4
                                                        ) +
                                                        "..." +
                                                        files[
                                                          index
                                                        ][0].name.slice(-6)
                                                      : files[index][0].name
                                                  }`
                                                : `${files[index]?.length} files`}
                                            </label>
                                          </div>
                                        )}

                                        <input
                                          style={{
                                            display: "none",
                                          }}
                                          id={`files${index}`}
                                          type="file"
                                          name="myfile"
                                          onChange={(e) =>
                                            handleImageChange(e, index)
                                          }
                                          accept=".pdf"
                                          multiple
                                        />
                                      </div>
                                    </td>

                                    <td>
                                      <div
                                        className="bene-select beneficiary_btn"
                                        onClick={(e) =>
                                          handleShowBeneficiary(e, index)
                                        }
                                      >
                                        Beneficiary
                                      </div>
                                    </td>
                                    <td>
                                      <Textarea
                                        style={{
                                          fontSize: "14px",
                                        }}
                                        placeholder="Notes"
                                        id="notes"
                                        onChange={(e) =>
                                          handleChanges(
                                            e,
                                            "notes",
                                            index,
                                            "jewelry"
                                          )
                                        }
                                        value={
                                          jewelries[index]?.jewelry?.notes || ""
                                        }
                                      />
                                    </td>
                                    <td>
                                      <div className="jewelry_action">
                                        {jewelries.length > 1 && (
                                          <Button
                                            className="jewelry_remove_button"
                                            onClick={() =>
                                              removeJewelryRow(index)
                                            }
                                          >
                                            <FontAwesomeIcon icon={faMinus} />
                                          </Button>
                                        )}
                                      </div>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                            <div style={{ marginTop: "8px" }}>
                              <Button
                                className="jewelry_add_more_button"
                                type="button"
                                onClick={addJewelryRow}
                              >
                                <FontAwesomeIcon icon={faPlus} />
                              </Button>
                            </div>
                          </div>
                        </CardBody>
                        <CardFooter>
                          <div className="text-center">
                            <Button
                              className="my-estate-clear-btn"
                              onClick={resetForm}
                              type="reset"
                              outline
                            >
                              Clear
                            </Button>
                            <Button
                              outline
                              type=""
                              className="my-estate-add-btn"
                            >
                              Add
                            </Button>
                          </div>
                        </CardFooter>
                      </Card>
                    </div>
                  </container>
                </Form>
              </div>
            </div>
          )}
          {modal && (
            <div>
              <Modal
                show={modal}
                toggle={toggleJewelryList}
                style={{ paddingTop: "50px" }}
              >
                <ModalBody style={{ color: "black" }}>
                  Please create your jewelry list here:
                  <div>
                    <Input
                      type="text"
                      value={jewelryListNameValue}
                      placeholder="Enter your jewelry list name"
                      onChange={handleChangeAddJewelryListName}
                    />
                  </div>
                </ModalBody>
                <ModalFooter style={{ padding: "5px" }}>
                  <Button
                    className="confirm_delete_btn"
                    onClick={handleChangeConfirm}
                  >
                    Add
                  </Button>{" "}
                  <Button
                    className="confirm_cancel_btn"
                    onClick={toggleJewelryList}
                  >
                    Cancel
                  </Button>
                </ModalFooter>
              </Modal>
            </div>
          )}

          {showConfirmation && (
            <ConfirmationPopup
              handleConfirmation={handleConfirmation}
              handleConfirmationClose={handleConfirmationClose}
              anyText="Do you want to remove item from this list?"
            />
          )}

          {showConfirmationDelete && (
            <Deletebutton
              handleRemove={handleRemove}
              Id={currentId}
              idType={currentIdType}
              handleConfirmationClose={handleConfirmationDeleteClose}
            />
          )}

          {show1 && (
            <>
              <div className="popup">
                <div className="popup-content popup-content-download">
                  <div className="note_popup">
                    <div className="note_popup_heading">
                      <div style={{ textAlign: "center", width: "100%" }}>
                        <h2>{`${showDetail[0]?.jewelry?.jewelryListName} Details`}</h2>
                      </div>
                      <div>
                        <button
                          className="note_popup_heading_close_btn"
                          onClick={() => {
                            setShow1(false);
                          }}
                        >
                          <FontAwesomeIcon icon={faXmark} />
                        </button>
                      </div>
                    </div>
                    <div>
                      {showDetail.map((details, index) => (
                        <div
                          key={index}
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            margin: "5px",
                            padding: "10px",
                            border: "solid 1px lightgray",
                            borderRadius: "5px",
                            minWidth: "230px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            {/* Left side */}
                            <div>
                              <p
                                style={{
                                  fontSize: "17px",
                                  color: "black",
                                  fontWeight: "500",
                                }}
                              >
                                {`Item - ${index + 1}`}
                                {"  "}
                                <span
                                  className={`propCard_card_status_text ${
                                    details.sharedDetails.length > 0
                                      ? "completed"
                                      : "incomplete"
                                  }`}
                                >
                                  {details.sharedDetails.length > 0
                                    ? "completed"
                                    : "incomplete"}
                                </span>
                                {/* {getDisplayName(details.jewelry.jewelryName) ===
                                "Other" ? (
                                  <>
                                    {details.jewelry.otherJewelryName} -{" "}
                                    {index + 1}
                                  </>
                                ) : (
                                  <>
                                    {getDisplayName(
                                      details.jewelry.jewelryName
                                    )}{" "}
                                    - {index + 1}
                                  </>
                                )} */}
                              </p>
                            </div>
                            {/* Right side */}
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <div className="me-2">
                                {details.documents &&
                                  details.documents.length > 0 && (
                                    <Tooltip title="click to see multiple download files">
                                      <div
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                          handleShowDownlaod(details);
                                          setShow1(false);
                                        }}
                                      >
                                        <div className="myestate_download_button dwnbtn">
                                          <FontAwesomeIcon
                                            className="myestate_download_icon"
                                            icon={faDownload}
                                          />
                                          <span></span>
                                        </div>
                                      </div>
                                    </Tooltip>
                                  )}
                              </div>
                              {primaryUserDetails.accountType === "secondary" &&
                              secondaryUserDetails.accountType === "primary" &&
                              secondaryUserDetails.isSecondaryUserEditable ===
                                "false" ? (
                                ""
                              ) : (
                                <div className="me-2">
                                  <Tooltip title="Click Here To Edit">
                                    <div>
                                      <UpdateButton
                                        URL={"../my-estate/jewelry/"}
                                        id={details.jewelry.id}
                                      />
                                    </div>
                                  </Tooltip>
                                </div>
                              )}

                              {primaryUserDetails.accountType === "secondary" &&
                              secondaryUserDetails.accountType === "primary" &&
                              secondaryUserDetails.isSecondaryUserEditable ===
                                "false" ? (
                                ""
                              ) : (
                                <FontAwesomeIcon
                                  onClick={() =>
                                    removeJewelry(
                                      details.jewelry.id,
                                      "jewelryId"
                                    )
                                  }
                                  className="delete-button-comp"
                                  style={{ cursor: "pointer" }}
                                  icon={faTrash}
                                />
                              )}
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",

                              flexWrap: "wrap", // Allow wrapping to next line if needed
                            }}
                            className="mt-2"
                          >
                            {/* Left section */}
                            <div style={{ flex: "1", minWidth: "250px" }}>
                              {details.jewelry.owner && (
                                <p
                                  style={{ color: "black", fontWeight: "500" }}
                                >
                                  Owner : <span>{details.jewelry.owner}</span>
                                </p>
                              )}

                              <p style={{ color: "black", fontWeight: "500" }}>
                                Jewelry Type :{" "}
                                <span>
                                  {getDisplayName(
                                    details.jewelry.jewelryName
                                  ) === "Other"
                                    ? details.jewelry.otherJewelryName
                                    : getDisplayName(
                                        details.jewelry.jewelryName
                                      )}
                                </span>
                              </p>

                              <p style={{ color: "black", fontWeight: "500" }}>
                                Jewelry Name:{" "}
                                <span>{details.jewelry.jewelryCaption}</span>
                              </p>

                              <p style={{ color: "black", fontWeight: "500" }}>
                                Weight(gm):{" "}
                                <span>{details.jewelry.weight}</span>
                              </p>

                              {details.sharedDetails[0] && (
                                <p
                                  style={{ color: "black", fontWeight: "500" }}
                                  onClick={() => {
                                    handleOpenBeneficiary(details);
                                    setShow1(false);
                                  }}
                                >
                                  Beneficiary Details{" "}
                                  <span>
                                    <span className="readmore">Click Here</span>
                                  </span>
                                </p>
                              )}

                              {details.supportingImages &&
                                details.supportingImages.length > 0 && (
                                  <Tooltip title="click to see images">
                                    <p
                                      style={{
                                        color: "black",
                                        fontWeight: "500",
                                      }}
                                      onClick={() => {
                                        handleShowImages(details);
                                        setShow1(false);
                                      }}
                                    >
                                      Jewelry Images{" "}
                                      <span>
                                        <span className="readmore">
                                          Click Here
                                        </span>
                                      </span>
                                    </p>
                                  </Tooltip>
                                )}
                            </div>

                            {/* Right section */}
                            <div style={{ flex: "0.5", minWidth: "250px" }}>
                              <p style={{ color: "black", fontWeight: "500" }}>
                                Quantity:{" "}
                                <span>{details.jewelry.quantity}</span>
                              </p>
                              <p style={{ color: "black", fontWeight: "500" }}>
                                Value/Quantity:{" "}
                                <code
                                  style={{ color: "green", fontWeight: "bold" }}
                                >
                                  {formatAmount(
                                    details.jewelry.perQuantityValue
                                  )}
                                </code>
                              </p>
                              <p style={{ color: "black", fontWeight: "500" }}>
                                Appraised Value:{" "}
                                <code
                                  style={{ color: "green", fontWeight: "bold" }}
                                >
                                  {formatAmount(details.jewelry.estimatedValue)}
                                </code>
                              </p>

                              {details.jewelry.notes && (
                                <Tooltip title="Click To see Note">
                                  <p
                                    style={{
                                      color: "black",
                                      fontWeight: "500",
                                    }}
                                    onClick={() => {
                                      handleOpenPopup(details.jewelry.notes);
                                      setShow1(!show1);
                                    }}
                                  >
                                    Note:{" "}
                                    <span>
                                      {" "}
                                      {details && details.jewelry.notes
                                        ? details.jewelry.notes.slice(0, 5)
                                        : ""}
                                      ...
                                      <span className="readmore">
                                        Read More
                                      </span>
                                    </span>
                                  </p>
                                </Tooltip>
                              )}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}

          {popupVisible && (
            // Popup div
            <div className="popup">
              <div className="popup-content">
                <div className="note_popup">
                  <div className="note_popup_heading">
                    <div>
                      <h2>Notes</h2>
                    </div>
                    <div>
                      <button
                        className="note_popup_heading_close_btn"
                        onClick={() => {
                          setPopupVisible(false);
                          setShow1(!show1);
                        }}
                      >
                        <FontAwesomeIcon icon={faXmark} />
                      </button>
                    </div>
                  </div>
                  <div>
                    <p>{selectedNote}</p>
                  </div>
                </div>
              </div>
            </div>
          )}

          {popupVisibleImages && (
            <div className="popup">
              <div className="popup-content popup-content-download">
                <div className="note_popup">
                  <div className="note_popup_heading">
                    <div style={{ textAlign: "center", width: "100%" }}>
                      <h2>Jewelry Images</h2>
                    </div>
                    <div>
                      <button
                        className="note_popup_heading_close_btn"
                        onClick={() => {
                          setPopupVisibleImages(false);
                          setShow1(true);
                        }}
                      >
                        <FontAwesomeIcon icon={faXmark} />
                      </button>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      justifyContent: "center",
                    }}
                  >
                    {selectedImages.supportingImages &&
                      selectedImages.supportingImages.length > 0 &&
                      selectedImages.supportingImages.map((image, index) => (
                        <div
                          key={index}
                          style={{
                            marginBottom: "20px",
                            width: "30%",
                            padding: "0 10px",
                          }}
                        >
                          <Tooltip title={image.fileName}>
                            <div style={{ cursor: "pointer" }}>
                              <img
                                key={index}
                                src={URL.createObjectURL(
                                  base64ToBlob(image.file)
                                )}
                                alt={`jewelryImage ${index + 1}`}
                                className="image-item"
                                style={{ width: "100%", height: "auto" }} // Adjust width and height as needed
                              />
                            </div>
                          </Tooltip>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          )}

          {/*  download popup */}
          {popupVisibleDownlaod && (
            // Popup div

            <div className="popup">
              <div className="popup-content popup-content-download">
                <div className="note_popup">
                  <div className="note_popup_heading">
                    <div style={{ textAlign: "center", width: "100%" }}>
                      <h2>Download Files</h2>
                    </div>
                    <div>
                      <button
                        className="note_popup_heading_close_btn"
                        onClick={() => {
                          setPopupVisibleDownlaod(false);
                          setShow(true);
                        }}
                      >
                        <FontAwesomeIcon icon={faXmark} />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/*  download popup */}
          {popupVisibleDownlaod && (
            <div className="popup">
              <div className="popup-content popup-content-download">
                <div className="note_popup">
                  <div className="note_popup_heading">
                    <div style={{ textAlign: "center", width: "100%" }}>
                      <h2>Download Files</h2>
                    </div>
                    <div>
                      <button
                        className="note_popup_heading_close_btn"
                        onClick={() => {
                          setPopupVisibleDownlaod(false);
                          setShow1(true);
                        }}
                      >
                        <FontAwesomeIcon icon={faXmark} />
                      </button>
                    </div>
                  </div>

                  <div>
                    {selectedDownlaod.documents &&
                      selectedDownlaod.documents.length > 0 &&
                      selectedDownlaod.documents.map((file, index) => (
                        <div key={index} style={{ marginBottom: "20px" }}>
                          <Tooltip title={file.fileName}>
                            <div
                              style={{
                                cursor: "pointer",
                                display: "flex",
                                gap: "20px",
                              }}
                              onClick={() => {
                                handleDownload(file.id, file.fileName);
                              }}
                            >
                              Download - {index + 1}
                              <div className="myestate_download_button dwnbtn">
                                <FontAwesomeIcon
                                  className="myestate_download_icon"
                                  icon={faDownload}
                                />
                                <span>{file.fileName}</span>
                              </div>
                            </div>
                          </Tooltip>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          )}

          {benevisible && (
            <div className="popup">
              <div
                className="popup-content"
                style={{
                  minWidth: "350px",
                  width: "100%",
                  maxWidth: "700px",
                }}
              >
                <div className="note_popup">
                  <div className="note_popup_heading">
                    <div className="share_property_heading">
                      <h2>Share Property</h2>
                    </div>
                    <div>
                      <button
                        className="note_popup_heading_close_btn"
                        onClick={handleReset}
                      >
                        <FontAwesomeIcon icon={faXmark} />
                      </button>
                    </div>
                  </div>
                  <div className="share_property_est_value">
                    <p>
                      Estimated Value:{" "}
                      <code style={{ color: "green", fontWeight: "bold" }}>
                        $
                        {
                          jewelries[currentBeneficiaryIndex]?.jewelry
                            ?.estimatedValue
                        }
                      </code>
                    </p>
                  </div>
                  <div className="BeneficiarySelect">
                    <div className="BeneficiarySelectContainer">
                      <div className="BeneficiarySelectRow">
                        <div className="share_property_Type">
                          <p className="share_property_Type_paragraph">
                            Choose Distribution Type:{" "}
                          </p>
                          <select
                            value={distributionType}
                            onChange={handleDistributionTypeChange}
                            className="share_property_Type_select"
                          >
                            <option value="">Select Type</option>
                            <option value="percentage">Percentage</option>
                            <option value="dollar">Dollar</option>
                          </select>
                        </div>
                        <div className="SelectContainer">
                          <select
                            className="Property-inputfiled"
                            onChange={handleBeneficiarySelection}
                            value=""
                            disabled={!distributionType}
                          >
                            <option value="" disabled hidden>
                              {distributionType
                                ? "Select Your Beneficiary Username"
                                : "Select Type First"}
                            </option>
                            {beneficiary.map((benif) => (
                              <option
                                key={benif.id}
                                value={benif.id}
                                disabled={selectedBeneficiaries.includes(
                                  benif.id
                                )}
                              >
                                {`${benif.firstName} ${benif.lastName}`}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="share_beneficiary_main_card">
                        {selectedBeneficiaries.map((beneficiary) => (
                          <div
                            key={beneficiary}
                            className="share_beneficiary_card"
                          >
                            <div>
                              <p className="share_beneficiary_card_para">
                                Beneficiary:{" "}
                                {getBenificiaryName({ beneficiary })}
                              </p>
                              {distributionType === "percentage" && (
                                <input
                                  type="text"
                                  className="share_ben_percentage"
                                  placeholder="Percentage"
                                  value={
                                    beneficiaryDetails[beneficiary]
                                      ?.percentage || ""
                                  }
                                  onChange={(e) =>
                                    handleFieldChange(
                                      beneficiary,
                                      "percentage",
                                      e.target.value
                                    )
                                  }
                                />
                              )}
                              {distributionType === "dollar" && (
                                <input
                                  type="text"
                                  className="share_ben_percentage"
                                  placeholder="Dollar Value"
                                  value={
                                    beneficiaryDetails[beneficiary]?.value || ""
                                  }
                                  onChange={(e) =>
                                    handleFieldChange(
                                      beneficiary,
                                      "value",
                                      e.target.value
                                    )
                                  }
                                />
                              )}
                              {distributionType && (
                                <p className="share_beneficiary_card_para">
                                  Distributed Value: $
                                  {distributionType === "percentage"
                                    ? calculateDistributedAmount(
                                        "percentage",
                                        jewelries[currentBeneficiaryIndex]
                                          ?.jewelry?.estimatedValue,
                                        beneficiaryDetails[beneficiary]
                                      )
                                    : calculateDistributedAmount(
                                        "dollar",
                                        jewelries[currentBeneficiaryIndex]
                                          ?.jewelry?.estimatedValue,
                                        beneficiaryDetails[beneficiary]
                                      )}
                                </p>
                              )}
                            </div>
                            <div
                              className="share_beneficiary_card_close"
                              onClick={() =>
                                handleBeneficiaryClose(beneficiary)
                              }
                            >
                              <FontAwesomeIcon icon={faXmark} />
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
                {selectedBeneficiaries.length > 0 && (
                  <button onClick={handleSave}>Save</button>
                )}
              </div>
            </div>
          )}

          {beneficiaryVisible && (
            <div className="popup">
              <div className="popup-content popup-content-download">
                <div className="note_popup">
                  <div className="note_popup_heading">
                    <div style={{ textAlign: "center", width: "100%" }}>
                      <h2>share property</h2>
                    </div>
                    <div>
                      <button
                        className="note_popup_heading_close_btn"
                        onClick={() => {
                          setBeneficiaryVisible(false);
                          setShow1(true);
                        }}
                      >
                        <FontAwesomeIcon icon={faXmark} />
                      </button>
                    </div>
                  </div>
                  <div>
                    {selectedBeneficiary.sharedDetails &&
                      selectedBeneficiary.sharedDetails.map(
                        (details, index) => (
                          <div
                            key={index}
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              margin: "5px",
                              padding: "10px",
                              border: "solid 1px lightgray",
                              borderRadius: "5px",
                              minWidth: "230px",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <p
                                style={{
                                  fontSize: "17px",
                                  color: "black",
                                  fontWeight: "500",
                                }}
                              >
                                Share - {index + 1}
                              </p>
                              {primaryUserDetails.accountType === "secondary" &&
                              secondaryUserDetails.accountType === "primary" &&
                              secondaryUserDetails.isSecondaryUserEditable ===
                                "false" ? (
                                ""
                              ) : (
                                <FontAwesomeIcon
                                  onClick={() =>
                                    removeJewelry(details.id, "sharedDetailId")
                                  }
                                  className="delete-button-comp"
                                  style={{ cursor: "pointer" }}
                                  icon={faTrash}
                                />
                              )}
                            </div>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                marginLeft: "20px",
                              }}
                            >
                              <p
                                style={{
                                  fontSize: "15px",
                                  color: "black",
                                  fontWeight: "400",
                                  marginLeft: "20px",
                                }}
                              >
                                Beneficiary Name:
                                <span style={{ marginLeft: "10px" }}>
                                  {getBenificiaryName(details.beneficiaryId)}
                                </span>
                              </p>
                              <p
                                style={{
                                  fontSize: "15px",
                                  color: "black",
                                  fontWeight: "400",
                                  marginLeft: "20px",
                                }}
                              >
                                Distributed Type:
                                <span style={{ marginLeft: "10px" }}>
                                  {details.distributedType}
                                </span>
                              </p>
                              <p
                                style={{
                                  fontSize: "15px",
                                  color: "black",
                                  fontWeight: "400",
                                  marginLeft: "20px",
                                }}
                              >
                                Distributed Value:{" "}
                                <span style={{ marginLeft: "10px" }}>
                                  {details &&
                                    details.distributedType === "dollar" &&
                                    "$"}
                                  {details.distributedValue}
                                  {details.distributedType === "percentage" &&
                                    "%"}
                                </span>
                              </p>
                              <p
                                style={{
                                  fontSize: "15px",
                                  color: "black",
                                  fontWeight: "400",
                                  marginLeft: "20px",
                                }}
                              >
                                Distributed Amount:
                                <span style={{ marginLeft: "10px" }}>
                                  ${details.distributedAmount}
                                </span>
                              </p>
                            </div>
                          </div>
                        )
                      )}
                  </div>
                </div>
              </div>
            </div>
          )}

          {showAdditionField && (
            // Display the popup when a row is selected
            <div className="popup">
              <div className="popup-content">
                <div className="note_popup_heading">
                  <div>
                    <h2>All Additional Field</h2>
                  </div>
                  <div>
                    <button
                      className="note_popup_heading_close_btn"
                      onClick={() => {
                        SetshowAdditionField(false);
                        setShow1(!show1);
                      }}
                    >
                      <FontAwesomeIcon icon={faXmark} />
                    </button>
                  </div>
                </div>
                <div className="popup-body">
                  {showAdditionField.addfield1 !== 0 &&
                  showAdditionField.addfield1 !== "" ? (
                    <p
                      style={{
                        fontWeight: 450,
                        fontSize: "12px",
                        color: "black",
                      }}
                    >
                      New Field 1 : {showAdditionField.addfield1}
                    </p>
                  ) : (
                    <p></p>
                  )}
                  {showAdditionField.addfield2 !== 0 &&
                  showAdditionField.addfield2 !== "" ? (
                    <p
                      style={{
                        fontWeight: 450,
                        fontSize: "12px",
                        color: "black",
                      }}
                    >
                      New Field 2 : {showAdditionField.addfield2}
                    </p>
                  ) : (
                    <p></p>
                  )}
                  {showAdditionField.addfield3 !== 0 &&
                  showAdditionField.addfield3 !== "" ? (
                    <p
                      style={{
                        fontWeight: 450,
                        fontSize: "12px",
                        color: "black",
                      }}
                    >
                      New Field 3 : {showAdditionField.addfield3}
                    </p>
                  ) : (
                    <p></p>
                  )}
                  {showAdditionField.addfield4 !== 0 &&
                  showAdditionField.addfield4 !== "" ? (
                    <p
                      style={{
                        fontWeight: 450,
                        fontSize: "12px",
                        color: "black",
                      }}
                    >
                      New Field 4 : {showAdditionField.addfield4}
                    </p>
                  ) : (
                    <p></p>
                  )}
                  {showAdditionField.addfield5 !== 0 &&
                  showAdditionField.addfield5 !== "" ? (
                    <p
                      style={{
                        fontWeight: 450,
                        fontSize: "12px",
                        color: "black",
                      }}
                    >
                      New Field 5 : {showAdditionField.addfield5}
                    </p>
                  ) : (
                    <p></p>
                  )}
                </div>
              </div>
            </div>
          )}
        </SideBar>
      </UserBase>
    </div>
  );
}

export default JewelryList;
