import React, { useEffect } from "react";
import Form from "react-bootstrap/Form";
import { Button, Card, CardBody, CardHeader, Container } from "reactstrap";
import {
  getBeneficiary,
  getSecondaryUser,
  getToken,
  getUser,
} from "../../services/user-service";
//import { Accordion, data , AccordionContext } from "reactstrap";
import {
  FormLabel,
  Input,
  Option,
  Select,
  Textarea,
  Typography,
} from "@mui/joy";
import { FormControlLabel, Switch, Tooltip } from "@mui/material";
import axios from "axios";
import { useState } from "react";
import { toast } from "react-toastify";
import "../../css/myestare.css";
// import Deletebutton from "./Deletebutton";
// import UpdateButton from "./UpdateButton";
import { faMinus, faPlus, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Country } from "country-state-city";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { addInternationalAsset } from "../../services/InternationalAssetService";
import "./../../css/formPOPup.css";
import { getInternationalBank } from "../../services/bank-service";
import UserBase from "../../components/user/UserBase";
import SideBar from "../../components/sidebar/Sidebar";

export function InternationalAssetRealEstate() {
  const navigate = useNavigate();

  // new update
  let [form1, setForm1] = useState(true);
  const toggle = () => {
    setForm1(!form1);
    navigate("/user/my-estate/International_assets");
  };

  const [data, setData] = useState({
    realEstate: {
      owner: "",

      propertyCaption: "",
      propertyType: "",
      otherPropertyType: "",
      aptNumber: "",
      streetAddress: "",
      estPropertyVal: "",
      zipCode: "",
      country: "",
      propertyTax: "",
      city: "",
      state: "",
      notes: "",
    },
    mortgages: [
      {
        mortgage: "",

        mortgageNumber: "",
      },
    ],
    sharedDetails: [
      {
        distributedType: "",
        distributedValue: "",
        distributedAmount: "",
        beneficiaryId: "",
      },
    ],
  });

  const [data1, setData1] = useState({
    assetCaption: "",
    assetType: "realEstate",
    countryName: "",
  });

  const handleChangesData = (e, field) => {
    const newValue = e.target.value;
    setData1((prevData) => ({
      ...prevData,
      [field]: newValue,
    }));
  };

  const handleChangesCountry = (event, newValue) => {
    setData1((prevData) => ({
      ...prevData,
      countryName: newValue,
    }));
  };

  const [ownerName, setOwnerName] = useState([]);

  const handleChange = (event, newValue, stringValue) => {
    if (stringValue === "propertyType") {
      setData((prevData) => ({
        ...prevData,
        realEstate: {
          ...prevData.realEstate,
          propertyType: newValue,
        },
      }));
    }

    if (stringValue === "owner") {
      // Convert ownerName array to a single string

      const comingValue =
        typeof newValue === "string" ? newValue.split(",") : newValue;
      const ownerString = comingValue.join(", ");

      setData((prevData) => ({
        ...prevData,
        realEstate: {
          ...prevData.realEstate,
          owner: ownerString,
        },
      }));

      // Update the ownerName state afterwards
      setOwnerName(newValue);
    }
  };

  // Define an array to store user names
  const ownerNames = [];

  // Get primaryUser data
  const primaryUserDetails = getUser();
  const primaryUserName =
    primaryUserDetails.firstName + " " + primaryUserDetails.lastName;

  // Get secondaryUser data
  const secondaryUserDetails = getSecondaryUser();
  let secondaryUserName = "";

  // Check if secondary user exists
  if (secondaryUserDetails !== undefined) {
    secondaryUserName =
      secondaryUserDetails.firstName + " " + secondaryUserDetails.lastName;

    // Push both user names into the array
    ownerNames.push(primaryUserName, secondaryUserName);
    ownerNames.push("Other");
  } else {
    // If secondary user doesn't exist, only push primary user name
    ownerNames.push(primaryUserName);
    ownerNames.push("Other");
  }

  // use state to set the selected images
  const [selectedImage, setSelectedImage] = useState(null);

  // const [error] = useState({
  //   errors: {},
  //   isError: false,
  // });

  const handleChanges = (e, field) => {
    const newValue = e.target.value;
    setData((prevData) => ({
      ...prevData,
      realEstate: {
        ...prevData.realEstate,
        [field]: newValue,
      },
    }));
    const totalBalance = data.realEstate.estPropertyVal;
    setEstimatedTotalAmount(totalBalance);
  };
  const handleImageChange = (event) => {
    const selectedFiles = event.target.files;
    const allowedExtensions = ["pdf"];

    if (selectedFiles) {
      const selectedFilesArray = Array.from(selectedFiles);

      // Check each file's extension
      const invalidFiles = selectedFilesArray.filter((file) => {
        const fileNameParts = file.name.split(".");
        const fileExtension =
          fileNameParts[fileNameParts.length - 1].toLowerCase();
        return !allowedExtensions.includes(fileExtension);
      });

      if (invalidFiles.length === 0) {
        const formData = new FormData();

        selectedFilesArray.forEach((file, index) => {
          formData.append(`file${index + 1}`, file);
        });
        setSelectedImage(selectedFilesArray);
      } else {
        // Handle invalid file extensions
        const invalidExtensions = invalidFiles
          .map((file) => file.name.split(".").pop())
          .join(", ");
        toast.error(
          `Invalid file extensions: ${invalidExtensions}. Please select valid document formats.`,
          {
            position: toast.POSITION.BOTTOM_CENTER,
          }
        );
        event.target.value = ""; // Clear the input field
      }
    }
  };

  const resetForm = () => {
    setIsTextFieldClicked(false);
    setIsTextFieldClicked2(false);
    setIsTextFieldClicked3(false);
    setVisibleColumnIndex(0);

    setData({
      realEstate: {
        owner: "",
        propertyCaption: "",
        propertyType: "",
        otherPropertyType: "",
        aptNumber: "",
        streetAddress: "",
        estPropertyVal: "",
        zipCode: "",
        country: "",
        propertyTax: "",
        city: "",
        state: "",
        notes: "",
      },
      mortgages: [
        {
          mortgage: "",

          mortgageNumber: "",
        },
      ],
      sharedDetails: [
        {
          distributedType: "",
          distributedValue: "",
          distributedAmount: "",
          beneficiaryId: "",
        },
      ],
    });
    setOwnerName([]);
  };

  // Set the form
  const AddForm = (event) => {
    event.preventDefault();
    let token = "Bearer " + getToken();
    if (
      data.streetAddress === "" ||
      data.zipCode === "" ||
      data.city === "" ||
      data.state === "" ||
      data.country === ""
      // data.rentalIncome === ""
    ) {
      // toast.error("Form data is invalid !! " , { position: toast.POSITION.BOTTOM_CENTER });
      toast.error("Please fill all menu and After Add.");
      return;
    }

    //create form data to send a file and remaining class data
    const formData = new FormData();
    if (null != selectedImage) {
      for (let i = 0; i < selectedImage.length; i++) {
        formData.append(`files`, selectedImage[i]);
      }
    }

    formData.append("asset", JSON.stringify(data));
    formData.append("data", JSON.stringify(data1));

    addInternationalAsset(formData, token)
      .then((resp) => {
        toast.success("Data Added !!", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
        toggle();
      })
      .catch((error) => {
        toast.error(
          "File is too large please choose file size under 400 KB",
          error
        );
      });
  };

  // const columns = [
  //   {
  //     id: "streetAddress",
  //     label: "Address",
  //     style: {
  //       minWidth: 100,
  //       fontWeight: "bold",
  //     },
  //   },
  //   {
  //     id: "aptNumber",
  //     label: "Apartment",
  //     style: {
  //       minWidth: 100,
  //       fontWeight: "bold",
  //     },
  //   },
  //   {
  //     id: "zipCode",
  //     label: "Zip\u00a0Code",
  //     align: "center",
  //     style: {
  //       minWidth: 100,
  //       fontWeight: "bold",
  //     },
  //   },
  //   {
  //     id: "city",
  //     label: "City/Town",
  //     align: "center",
  //     style: {
  //       minWidth: 100,
  //       fontWeight: "bold",
  //     },
  //   },
  //   {
  //     id: "state",
  //     label: "State",
  //     align: "center",
  //     style: {
  //       minWidth: 100,
  //       fontWeight: "bold",
  //     },
  //   },
  //   {
  //     id: "country",
  //     label: "Country",

  //     style: {
  //       minWidth: 100,
  //       fontWeight: "bold",
  //     },
  //   },
  //   {
  //     id: "mortgage",
  //     label: "Total\u00a0Mortgage",
  //     style: {
  //       minWidth: 100,
  //       fontWeight: "bold",
  //     },
  //     format: "openMortgage",
  //   },
  //   {
  //     id: "estPropertyVal",
  //     label: "Property\u00a0Value",

  //     style: {
  //       minWidth: 100,
  //       fontWeight: "bold",
  //     },
  //   },
  //   {
  //     id: "propertyTax",
  //     label: "Property\u00a0Tax",

  //     style: {
  //       minWidth: 100,
  //       fontWeight: "bold",
  //     },
  //   },

  //   {
  //     id: "equity",
  //     label: "Estimated\u00a0Equity",
  //     style: {
  //       minWidth: 100,
  //       fontWeight: "bold",
  //     },
  //   },
  //   {
  //     id: "caption",
  //     label: "Tax\u00a0Doc\u00a0Caption",
  //     format: "buttons",
  //     style: {
  //       minWidth: 100,
  //       fontWeight: "bold",
  //     },
  //   },
  //   {
  //     id: "notes",
  //     label: "Note",
  //     style: {
  //       minWidth: 100,
  //       fontWeight: "bold",
  //     },
  //     format: "shortText",
  //   },
  //   {
  //     id: "document",
  //     label: "Document",
  //     format: "button",
  //     style: {
  //       minWidth: 100,
  //       fontWeight: "bold",
  //     },
  //     align: "center",
  //   },
  //   {
  //     id: "action",
  //     label: "Action",
  //     align: "center",
  //     format: "action",
  //     style: {
  //       padding: 0,
  //       minWidth: 100,
  //       fontWeight: "bold",
  //     },
  //   },
  // ];

  const handleChangesZipCode = (e, field) => {
    const inputValue = e.target.value;
    if (/^\d{0,5}$/.test(inputValue)) {
      // Validation to allow only up to 5 digits (or empty string)
      // setData({ ...data, [field]: inputValue });
      setData((prevData) => ({
        ...prevData,
        realEstate: {
          ...prevData.realEstate,
          zipCode: inputValue,
        },
      }));
    }
  };

  useEffect(() => {
    const fetchDataFromAPI = () => {
      if (data.realEstate.zipCode.length === 5) {
        const apiUrl = `https://api.zippopotam.us/us/${data.realEstate.zipCode}`;
        axios
          .get(apiUrl)
          .then((res) => {
            if (res.data && res.data.places && res.data.places.length > 0) {
              const placeName = res.data.places[0]["place name"];
              // setData((prevData) => ({
              //   ...prevData,
              //   city: placeName,
              //   country: res.data.country,
              //   state: res.data.places[0]["state"],
              // }));
              setData((prevData) => ({
                ...prevData,
                realEstate: {
                  ...prevData.realEstate,
                  city: placeName,
                  country: res.data.country,
                  state: res.data.places[0]["state"],
                },
              }));
            } else {
            }
          })
          .catch((error) => {
            // toast.error("Failed to fetch address data from API.");
          });
      } else if (data.realEstate.zipCode.length > 5) {
        // Clear input field if more than 5 digits are entered
        // setData((prevData) => ({
        //   ...prevData.realEstate,
        //   zipCode: '',
        //   country: '',
        //   city: '',
        //   state: '',
        // }));
        setData((prevData) => ({
          ...prevData,
          realEstate: {
            ...prevData.realEstate,
            zipCode: "",
            city: "",
            country: "",
            state: "",
          },
        }));
      } else if (data.realEstate.zipCode.length <= 4) {
        // Reset all fields if zipCode is null
        // setData((prevData) => ({
        //   ...prevData.realEstate,
        //   country: '',
        //   city: '',
        //   state: '',
        // }));
        setData((prevData) => ({
          ...prevData,
          realEstate: {
            ...prevData.realEstate,
            city: "",
            country: "",
            state: "",
          },
        }));
      }
    };

    fetchDataFromAPI();
  }, [data.realEstate.zipCode]);

  // add multiple mordgages
  const [mordgage, setMordgage] = useState([]);
  const [visibleColumnIndex, setVisibleColumnIndex] = useState(0);
  const mordgages = [0, 1, 2, 3, 4];
  const handleAddColumn = () => {
    if (visibleColumnIndex < 4) {
      setMordgage([...mordgage, { label: visibleColumnIndex + 1 }]);
      setVisibleColumnIndex(visibleColumnIndex + 1);
    }
  };

  const handleRemoveColumn = (indexToRemove) => {
    setMordgage(mordgage.filter((_, index) => index !== indexToRemove));
    setVisibleColumnIndex(visibleColumnIndex - 1);

    setData((prevData) => {
      const updatedMortgages = prevData.mortgages.map((mortgage, index) => {
        if (index === indexToRemove) {
          return { mortgage: "" }; // Reset the mortgage value to an empty string
        }
        return mortgage; // Return the original mortgage object for other indices
      });

      // Filter out mortgages with empty strings
      const filteredMortgages = updatedMortgages.filter(
        (mortgage) => mortgage.mortgage !== ""
      );

      // Return the new state with empty mortgages removed
      return {
        ...prevData,
        mortgages: filteredMortgages,
      };
    });
  };

  // beneficiary addition in form
  const [beneficiary, setBenificiary] = useState([]);
  const getBenificiarydata = () => {
    let userId = getUser().id;

    let token = "Bearer " + getToken();
    getBeneficiary(token, userId)
      .then((res) => {
        setBenificiary(res);
      })
      .catch((err) => {});
  };

  // other property
  let [otherPropertyTypes, setOtherPropertyTypes] = useState(false);

  //$ on click
  const [isTextFieldClicked, setIsTextFieldClicked] = useState(false);
  const [isTextFieldClicked2, setIsTextFieldClicked2] = useState(false);
  const [isTextFieldClicked3, setIsTextFieldClicked3] = useState(false);

  const validateZipCode = (value) => {
    return /^\d{5}$/.test(value); // Validates a 5-digit numeric input
  };

  //

  const [benevisible, setbeneVisible] = useState(false);
  const [distributionType, setDistributionType] = useState("");
  const [selectedBeneficiaries, setSelectedBeneficiaries] = useState([]);
  const [beneficiaryDetails, setBeneficiaryDetails] = useState({});
  const [estimatedTotalAmount, setEstimatedTotalAmount] = useState(0);
  const [beneficiaryVisible, setBeneficiaryVisible] = useState(false);
  let [showAdditionField1, setshowAdditionField1] = useState(false);

  const handleShowBeneficiary = () => {
    setbeneVisible(true);

    setEstimatedTotalAmount(data.realEstate.estPropertyVal);
    // data.sharedDetails = [];
  };

  const handleReset = () => {
    setbeneVisible(false);
    setDistributionType("");
    setSelectedBeneficiaries([]);
    setBeneficiaryDetails({});
  };

  const handleDistributionTypeChange = (event) => {
    const newType = event.target.value;
    const resetDetails = {};
    Object.keys(beneficiaryDetails).forEach((beneficiary) => {
      resetDetails[beneficiary] = { percentage: "", value: "" };
    });
    setDistributionType(newType);
    setBeneficiaryDetails(resetDetails);
  };

  const handleBeneficiarySelection = (event) => {
    const selectedBeneficiary = event.target.value;
    if (!selectedBeneficiaries.includes(selectedBeneficiary)) {
      setSelectedBeneficiaries([...selectedBeneficiaries, selectedBeneficiary]);
      setBeneficiaryDetails({
        ...beneficiaryDetails,
        [selectedBeneficiary]: { percentage: "", value: "" },
      });
    }
  };

  const handleBeneficiaryClose = (beneficiary) => {
    const updatedBeneficiaries = selectedBeneficiaries.filter(
      (b) => b !== beneficiary
    );
    setSelectedBeneficiaries(updatedBeneficiaries);
    const updatedDetails = { ...beneficiaryDetails };
    delete updatedDetails[beneficiary];
    setBeneficiaryDetails(updatedDetails);
  };

  const getBenificiaryName = (id) => {
    var foundBenificiary = null;
    if (id.beneficiary === undefined) {
      foundBenificiary = beneficiary.find((b) => b.id === parseInt(id));
    } else {
      foundBenificiary = beneficiary.find(
        (b) => b.id === parseInt(id.beneficiary)
      );
    }

    if (foundBenificiary) {
      return `${foundBenificiary.firstName} ${foundBenificiary.lastName}`;
    } else {
      return "Benificiary not found"; // Or handle the case where beneficiary with the given ID isn't found
    }
  };

  const handleFieldChange = (beneficiary, field, value) => {
    if (!estimatedTotalAmount || parseFloat(estimatedTotalAmount) === 0) {
      toast.error(
        "Please provide a valid estimated value before adding percentages!"
      );
      return;
    }
    let updatedDetails = { ...beneficiaryDetails };
    if (/^\d*$/.test(value)) {
      updatedDetails = {
        ...updatedDetails,
        [beneficiary]: {
          ...updatedDetails[beneficiary],
          [field]: value,
        },
      };
    }
    let totalPercentage = 0;
    let totalValue = 0;
    Object.keys(updatedDetails).forEach((key) => {
      const beneficiaryPercentage = parseFloat(updatedDetails[key]?.percentage);
      const beneficiaryValue = parseFloat(updatedDetails[key]?.value);
      if (!isNaN(beneficiaryPercentage) && beneficiaryPercentage >= 0) {
        totalPercentage += beneficiaryPercentage;
      }
      if (!isNaN(beneficiaryValue) && beneficiaryValue >= 0) {
        totalValue += beneficiaryValue;
      }
    });
    if (
      totalPercentage > 100 ||
      totalValue > parseFloat(estimatedTotalAmount)
    ) {
      updatedDetails[beneficiary][field] = "";
      toast.error(
        "Total percentage exceeds 100% or total value exceeds estimated value!"
      );
    } else {
      setBeneficiaryDetails(updatedDetails);
    }
  };

  const calculateDistributedAmount = (
    distributionType,
    balance,
    beneficiaryDetail
  ) => {
    if (distributionType === "percentage") {
      return (
        (parseFloat(balance) * parseFloat(beneficiaryDetail?.percentage || 0)) /
        100
      ).toFixed(2);
    } else if (distributionType === "dollar") {
      return parseFloat(beneficiaryDetail?.value || 0).toFixed(2);
    }
    return "0.00";
  };

  const handleSave = () => {
    toast.success("Saved successfully!");
    setbeneVisible(false);
    var i = 0;
    Object.keys(beneficiaryDetails).forEach((key) => {
      const beneficiaryPercentage = parseFloat(
        beneficiaryDetails[key]?.percentage
      );
      const beneficiaryValue = parseFloat(beneficiaryDetails[key]?.value);

      if (!isNaN(beneficiaryPercentage) && beneficiaryPercentage >= 0) {
        const distributedAmount = calculateDistributedAmount(
          "percentage",
          estimatedTotalAmount,
          beneficiaryDetails[key]
        );
        handleChanges2(
          "percentage",
          beneficiaryPercentage,
          distributedAmount,
          parseInt(key),
          i
        );
        i += 1;
      }

      if (!isNaN(beneficiaryValue) && beneficiaryValue >= 0) {
        const distributedAmount = calculateDistributedAmount(
          "dollar",
          estimatedTotalAmount,
          beneficiaryDetails[key]
        );
        handleChanges2(
          "dollar",
          beneficiaryValue,
          distributedAmount,
          parseInt(key),
          i
        );
        i += 1;
      }
    });
  };

  const handleChanges2 = (t, v, a, b, i) => {
    const updatedSharedDetails = [...data.sharedDetails]; // Create a copy of the sharedDetails array

    // Check if the index i is within the bounds of the array
    // if (i >= 0 && i < updatedSharedDetails.length) {
    // Create a new object to update the specific element at index i
    const updatedElement = {
      ...updatedSharedDetails[i], // Copy existing properties
      distributedType: t,
      distributedValue: v,
      distributedAmount: a,
      beneficiaryId: b,
    };

    updatedSharedDetails[i] = updatedElement; // Update the element at index i

    setData((prevState) => ({
      ...prevState,
      sharedDetails: updatedSharedDetails, // Update the sharedDetails in the state
    }));
    data.sharedDetails[i] = updatedSharedDetails[i];
  };

  const handleChanges1 = (e, field, { index }) => {
    const { value } = e.target;

    setData((prevData) => {
      const updatedMortgages = [...prevData.mortgages];
      if (!updatedMortgages[index]) {
        updatedMortgages[index] = {};
      }
      updatedMortgages[index][field] = value;

      return {
        ...prevData,
        mortgages: updatedMortgages,
      };
    });
  };

  useEffect(() => {
    getBenificiarydata();
  }, []);

  return (
    <>
      {form1 && (
        <>
          <div className="fade-in-element">
            <UserBase>
              <div className="mt-5">
                <SideBar>
                  <div
                    className="overlay1"
                    style={{ transition: "500ms", height: "" }}
                  >
                    <div className="addform_ichest">
                      <div className="addform_main">
                        <div className="addform_heading">
                          <h3 className="addform_heading_h1">
                            Add Real Estate Properties
                          </h3>
                          <div className="addform_close" onClick={toggle}>
                            <FontAwesomeIcon icon={faXmark} />
                          </div>
                        </div>
                        <div className="addform_body">
                          <Form onSubmit={AddForm} className="addform_body">
                            <div className="addform_select_body">
                              <div className="addform_body_left">
                                <div className="address_input">
                                  <div className="mt-2 addform_input_half">
                                    <div>
                                      <Tooltip title="Enter Caption for your assets">
                                        <div>
                                          <FormLabel>Assets Caption</FormLabel>
                                          <Input
                                            className="input_mui_joy"
                                            placeholder="Enter asset caption"
                                            onChange={(e) =>
                                              handleChangesData(
                                                e,
                                                "assetCaption"
                                              )
                                            }
                                            value={data1.assetCaption}
                                          />
                                        </div>
                                      </Tooltip>
                                    </div>
                                  </div>

                                  <div className="mt-2 addform_input_half">
                                    <div>
                                      <Tooltip title="Enter the Country of your assets">
                                        <div>
                                          <FormLabel required>
                                            Country Name
                                          </FormLabel>

                                          <Select
                                            placeholder="Select country name"
                                            onChange={(e, newValue) =>
                                              handleChangesCountry(e, newValue)
                                            }
                                            value={data1.countryName}
                                            required
                                          >
                                            {Country.getAllCountries().map(
                                              (v, index) => {
                                                return (
                                                  <Option
                                                    key={index}
                                                    value={v.name}
                                                  >
                                                    {v.name}
                                                  </Option>
                                                );
                                              }
                                            )}
                                          </Select>
                                        </div>
                                      </Tooltip>
                                    </div>
                                  </div>
                                </div>

                                <div>
                                  <Tooltip
                                    title="Select Owner"
                                    className="mt-2"
                                  >
                                    <div>
                                      <FormLabel required>
                                        Select Owner
                                      </FormLabel>

                                      <Select
                                        placeholder="Select Owner"
                                        value={ownerName}
                                        multiple
                                        onChange={(event, newValue) =>
                                          handleChange(event, newValue, "owner")
                                        }
                                        sx={{
                                          minWidth: "13rem",
                                        }}
                                        slotProps={{
                                          listbox: {
                                            sx: {
                                              width: "100%",
                                            },
                                          },
                                        }}
                                        required
                                      >
                                        {ownerNames.map((name) => (
                                          <Option key={name} value={name}>
                                            {name}
                                          </Option>
                                        ))}
                                      </Select>
                                    </div>
                                  </Tooltip>
                                </div>

                                <div>
                                  <Tooltip title="Enter Heading For Property ">
                                    <div className="mt-2">
                                      <FormLabel>Property Heading</FormLabel>
                                      <Input
                                        className="input_mui_joy"
                                        placeholder="Enter property heading"
                                        value={data.realEstate.propertyCaption}
                                        onChange={(e) =>
                                          handleChanges(e, "propertyCaption")
                                        }
                                      />
                                    </div>
                                  </Tooltip>
                                </div>

                                <div>
                                  <Tooltip title="Select The Type Of Property">
                                    <div className="mt-2">
                                      <FormLabel required>
                                        Type Of Property
                                      </FormLabel>

                                      <Select
                                        value={data.realEstate.propertyType}
                                        onChange={(event, newValue) =>
                                          handleChange(
                                            event,
                                            newValue,
                                            "propertyType"
                                          )
                                        }
                                        required
                                      >
                                        <Option value="">
                                          Select Your Type Of Property
                                        </Option>
                                        <Option value="Residential">
                                          Residential
                                        </Option>
                                        <Option value="Commercial">
                                          Commercial
                                        </Option>
                                        <Option value="Industrial">
                                          Industrial
                                        </Option>
                                        <Option value="Land">Land</Option>
                                        <Option
                                          value="Other"
                                          onClick={() => {
                                            setOtherPropertyTypes(
                                              !otherPropertyTypes
                                            );
                                          }}
                                        >
                                          Other
                                        </Option>
                                      </Select>
                                    </div>
                                  </Tooltip>
                                </div>

                                {otherPropertyTypes && (
                                  <div className="mt-2">
                                    <Tooltip title="Enter Your Other Property Type ">
                                      <div>
                                        <FormLabel>Other Property</FormLabel>
                                        <Input
                                          className="input_mui_joy"
                                          placeholder="Enter Other Property"
                                          id="otherprop"
                                          onChange={(e) =>
                                            handleChanges(
                                              e,
                                              "otherPropertyType"
                                            )
                                          }
                                          value={
                                            data.realEstate.otherPropertyType
                                          }
                                        />
                                      </div>
                                    </Tooltip>
                                  </div>
                                )}
                                <div className="address_input">
                                  <div className="mt-2 addform_input_half">
                                    <div>
                                      <Tooltip title="Enter a valid Zip-code ">
                                        <div>
                                          <FormLabel required>
                                            Zip Code
                                          </FormLabel>
                                          <Input
                                            className="input_mui_joy"
                                            type="number"
                                            placeholder="Enter your Zip Code"
                                            id="zipCode"
                                            onChange={(e) =>
                                              handleChangesZipCode(e, "zipCode")
                                            }
                                            value={data.realEstate.zipCode}
                                            inputprops={{
                                              minLength: 5,
                                              maxLength: 5,
                                            }}
                                            error={
                                              !validateZipCode(
                                                data.realEstate.zipCode
                                              )
                                            }
                                            required
                                          />
                                          {!validateZipCode(
                                            data.realEstate.zipCode
                                          ) && (
                                            <Typography
                                              variant="body2"
                                              color="danger"
                                              sx={{
                                                marginTop: "4px",
                                                fontSize: "0.8rem",
                                              }}
                                            >
                                              Please enter a valid 5-digit Zip
                                              Code
                                            </Typography>
                                          )}
                                        </div>
                                      </Tooltip>
                                    </div>
                                  </div>

                                  <div className="mt-2 addform_input_half">
                                    <div>
                                      <Tooltip title="Automatically populate based on the zip code">
                                        <div style={{ width: "100%" }}>
                                          <FormLabel>City/Town *</FormLabel>
                                          <Input
                                            className="input_mui_joy"
                                            placeholder="City/Town *"
                                            value={data.realEstate.city}
                                            readOnly
                                            disabled
                                          />
                                        </div>
                                      </Tooltip>
                                    </div>
                                  </div>
                                </div>

                                <div className="address_input">
                                  <div className="mt-2 addform_input_half">
                                    <div>
                                      <Tooltip title="Automatically populate based on the zip code">
                                        <div>
                                          <FormLabel>State *</FormLabel>
                                          <Input
                                            className="input_mui_joy"
                                            placeholder="State *"
                                            value={data.realEstate.state}
                                            readOnly
                                            disabled
                                          />
                                        </div>
                                      </Tooltip>
                                    </div>
                                  </div>

                                  <div className="mt-2 addform_input_half">
                                    <div>
                                      <Tooltip title="Automatically populate based on the zip code">
                                        <div>
                                          <FormLabel>Country *</FormLabel>
                                          <Input
                                            className="input_mui_joy"
                                            placeholder="Country *"
                                            value={data.realEstate.country}
                                            readOnly
                                            disabled
                                          />
                                        </div>
                                      </Tooltip>
                                    </div>
                                  </div>
                                </div>
                                <div className="address_input">
                                  <div className="mt-2 addform_input_half">
                                    <div>
                                      <Tooltip title="Enter Your Street Address ">
                                        <div style={{ width: "100%" }}>
                                          <FormLabel>Street Address</FormLabel>
                                          <Input
                                            className="input_mui_joy"
                                            placeholder="Street Address *"
                                            onChange={(e) =>
                                              handleChanges(e, "streetAddress")
                                            }
                                            value={
                                              data.realEstate.streetAddress
                                            }
                                          />
                                        </div>
                                      </Tooltip>
                                    </div>
                                  </div>

                                  <div className="mt-2 addform_input_half">
                                    <div>
                                      <Tooltip title="Enter Your Apartment Number ">
                                        <div style={{ width: "100%" }}>
                                          <FormLabel>Apartment</FormLabel>
                                          <Input
                                            className="input_mui_joy"
                                            placeholder="Apartment"
                                            onChange={(e) =>
                                              handleChanges(e, "aptNumber")
                                            }
                                            value={data.realEstate.aptNumber}
                                          />
                                        </div>
                                      </Tooltip>
                                    </div>
                                  </div>
                                </div>
                                <div className="address_input">
                                  <div className="mt-2 addform_input_half">
                                    <div>
                                      <Tooltip title="Enter your estimated Property Value">
                                        <div style={{ width: "100%" }}>
                                          <FormLabel required>
                                            Property Value
                                          </FormLabel>
                                          <Input
                                            className="input_mui_joy"
                                            placeholder="Estimated Property Value"
                                            onChange={(e) =>
                                              handleChanges(e, "estPropertyVal")
                                            }
                                            value={
                                              data.realEstate.estPropertyVal
                                            }
                                            startDecorator={<div>$</div>}
                                            required
                                          />
                                        </div>
                                      </Tooltip>
                                    </div>
                                  </div>

                                  <div className="mt-2 addform_input_half">
                                    <div>
                                      <Tooltip title="Enter your Estimated Annual Property Tax">
                                        <div style={{ width: "100%" }}>
                                          <FormLabel required>
                                            Annual Property Tax
                                          </FormLabel>
                                          <Input
                                            className="input_mui_joy"
                                            placeholder="Estimated Annual Property Tax"
                                            onChange={(e) =>
                                              handleChanges(e, "propertyTax")
                                            }
                                            value={data.realEstate.propertyTax}
                                            onClick={() =>
                                              setIsTextFieldClicked(true)
                                            }
                                            startDecorator={<div>$</div>}
                                            required
                                          />
                                        </div>
                                      </Tooltip>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="addform_body_right">
                                <div>
                                  {mordgages.map((index) => (
                                    <div
                                      key={index}
                                      style={{
                                        marginBottom: "10px",
                                        display:
                                          index <= visibleColumnIndex
                                            ? "block"
                                            : "none",
                                      }}
                                    >
                                      <div className="address_input">
                                        <div className="mt-2 addform_input_half">
                                          <Tooltip
                                            title={
                                              index === 0
                                                ? `Add your primary mortgage`
                                                : "Add your secondary mortgage"
                                            }
                                          >
                                            <div>
                                              {index === 0 ? (
                                                <FormLabel required>
                                                  {`Primary Mortgage`}
                                                </FormLabel>
                                              ) : (
                                                <FormLabel required>
                                                  {`Secondary Number`}
                                                </FormLabel>
                                              )}
                                              <Input
                                                className="input_mui_joy"
                                                placeholder={
                                                  index === 0
                                                    ? `Primary Mortgage`
                                                    : "Secondary Number"
                                                }
                                                id={`mortgageNumber${
                                                  index + 1
                                                }`}
                                                required={
                                                  visibleColumnIndex >= index
                                                }
                                                onChange={(e) =>
                                                  handleChanges1(
                                                    e,
                                                    "mortgageNumber",
                                                    {
                                                      index,
                                                    }
                                                  )
                                                }
                                                value={
                                                  data.mortgages[index]
                                                    ? data.mortgages[index]
                                                        .mortgageNumber
                                                    : ""
                                                }
                                              />
                                            </div>
                                          </Tooltip>
                                        </div>
                                        <div className="mt-2 addform_input_half">
                                          <Tooltip
                                            title={`Add your mortgage ${
                                              index + 1
                                            }`}
                                          >
                                            <div>
                                              <FormLabel
                                                required
                                              >{`Amount`}</FormLabel>
                                              <Input
                                                className="input_mui_joy"
                                                type="number"
                                                placeholder="Amount"
                                                id={`mortgage${index + 1}`}
                                                required={
                                                  visibleColumnIndex >= index
                                                }
                                                onChange={(e) =>
                                                  handleChanges1(
                                                    e,
                                                    "mortgage",
                                                    {
                                                      index,
                                                    }
                                                  )
                                                }
                                                value={
                                                  data.mortgages[index]
                                                    ? data.mortgages[index]
                                                        .mortgage
                                                    : ""
                                                }
                                                onClick={() =>
                                                  setIsTextFieldClicked3(true)
                                                }
                                                startDecorator={
                                                  isTextFieldClicked3 ? (
                                                    <div>$</div>
                                                  ) : null
                                                }
                                              />
                                            </div>
                                          </Tooltip>
                                        </div>
                                      </div>
                                      {index !== 0 && (
                                        <div style={{ marginTop: "4px" }}>
                                          <Button
                                            style={{
                                              height: "30px",
                                              width: "30px",
                                              display: "flex",
                                              justifyContent: "center",
                                              alignItems: "center",
                                              borderRadius: "50%",
                                              backgroundColor:
                                                "rgb(255, 74, 74)",
                                              border: "none",
                                            }}
                                            onClick={() =>
                                              handleRemoveColumn(index)
                                            }
                                          >
                                            <FontAwesomeIcon icon={faMinus} />
                                          </Button>
                                        </div>
                                      )}
                                    </div>
                                  ))}
                                  {visibleColumnIndex < 4 && (
                                    <div style={{ marginTop: "2px" }}>
                                      <Button
                                        style={{
                                          height: "30px",
                                          width: "30px",
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          borderRadius: "50%",
                                          backgroundColor: "#4aafff",
                                          border: "none",
                                        }}
                                        onClick={handleAddColumn}
                                      >
                                        <FontAwesomeIcon icon={faPlus} />
                                      </Button>
                                    </div>
                                  )}
                                </div>
                                <div className="address_input">
                                  <div className="mt-2 addform_input_half">
                                    <div
                                      className="bene-select mt-3"
                                      onClick={handleShowBeneficiary}
                                      style={{ cursor: "pointer" }}
                                    >
                                      Select Beneficiary
                                    </div>
                                  </div>

                                  <div className="mt-2 addform_input_half">
                                    <div style={{ width: "100%" }}>
                                      <Tooltip title="Upload your property related file it should be under 400 kb">
                                        <div>
                                          <FormLabel>
                                            Supporting Document
                                          </FormLabel>
                                          <input
                                            style={{
                                              border: "solid 1px lightgray",
                                              borderLeft: "none",
                                              width: "100%",
                                              borderRadius: "2px",
                                            }}
                                            type="file"
                                            name="myfile"
                                            id="exampleFile"
                                            onChange={handleImageChange}
                                            accept=".pdf"
                                            multiple
                                          />
                                        </div>
                                      </Tooltip>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="mt-2 form1-double"
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    width: "100%",
                                    justifyContent: "space-between",
                                    marginTop: "-12px",
                                  }}
                                ></div>

                                <div className="address_input">
                                  <div
                                    style={{ width: "100%" }}
                                    className="mt-2"
                                  >
                                    <Tooltip title="Enter notes for your real estate">
                                      <div>
                                        <FormLabel>Notes</FormLabel>
                                        <Textarea
                                          sx={{
                                            height: "182px",
                                            minHeight: "52px",
                                          }}
                                          placeholder="Notes"
                                          id="notes"
                                          onChange={(e) =>
                                            handleChanges(e, "notes")
                                          }
                                          value={data.realEstate.notes}
                                        />
                                      </div>
                                    </Tooltip>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="text-center">
                              <Button
                                className="my-estate-clear-btn"
                                onClick={resetForm}
                                type="reset"
                                outline
                              >
                                Clear
                              </Button>
                              <Button
                                outline
                                type=""
                                className="my-estate-add-btn"
                              >
                                Add
                              </Button>
                            </div>
                          </Form>
                        </div>
                      </div>
                    </div>
                  </div>
                </SideBar>
              </div>
            </UserBase>
          </div>
        </>
      )}

      {benevisible && (
        // beneShow &&
        <div className="popup">
          <div
            className="popup-content popup-content-download"
            style={{
              minWidth: "350px",
              width: "100%",
              maxWidth: "700px",
            }}
          >
            <div className="note_popup">
              <div className="note_popup_heading">
                <div className="share_property_heading">
                  <h2>Share Property </h2>
                </div>
                <div>
                  <button
                    className="note_popup_heading_close_btn"
                    onClick={handleReset}
                  >
                    <FontAwesomeIcon icon={faXmark} />
                  </button>
                </div>
              </div>
              <div className="share_property_est_value">
                <p>
                  Estimated Value:{" "}
                  <code style={{ color: "green", fontWeight: "bold" }}>
                    ${estimatedTotalAmount}
                  </code>
                </p>
              </div>
              <div className="BeneficiarySelect">
                <div className="BeneficiarySelectContainer">
                  <div className="BeneficiarySelectRow">
                    <div class="share_property_Type">
                      <p class="share_property_Type_paragraph">
                        Choose Distribution Type:{" "}
                      </p>
                      <select
                        value={distributionType}
                        onChange={handleDistributionTypeChange}
                        class="share_property_Type_select"
                      >
                        <option value="">Select Type</option>
                        <option value="percentage">Percentage</option>
                        <option value="dollar">Dollar</option>
                      </select>
                    </div>
                    <div className="SelectContainer">
                      <select
                        className="Property-inputfiled"
                        onChange={handleBeneficiarySelection}
                        value=""
                        disabled={!distributionType}
                      >
                        <option value="" disabled hidden>
                          {distributionType
                            ? "Select Your Beneficiary Username"
                            : "Select Type First"}
                        </option>
                        {beneficiary.map((benif) => (
                          <option
                            key={benif.id}
                            value={benif.id}
                            disabled={selectedBeneficiaries.includes(benif.id)}
                          >
                            {/* {benif.username} */}
                            {`${benif.firstName} ${benif.lastName}`}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="share_beneficiary_main_card">
                    {selectedBeneficiaries.map(
                      (beneficiary) => (
                        console.log("this is  beneficiary ", beneficiary),
                        (
                          <div key={beneficiary} class="share_beneficiary_card">
                            <div>
                              <p className="share_beneficiary_card_para">
                                Beneficiary:{" "}
                                {getBenificiaryName({ beneficiary })}
                              </p>
                              {distributionType === "percentage" && (
                                <input
                                  type="text"
                                  className="share_ben_percentage"
                                  placeholder="Percentage"
                                  value={
                                    beneficiaryDetails[beneficiary]
                                      ?.percentage || ""
                                  }
                                  onChange={(e) =>
                                    handleFieldChange(
                                      beneficiary,
                                      "percentage",
                                      e.target.value
                                    )
                                  }
                                />
                              )}
                              {distributionType === "dollar" && (
                                <input
                                  type="text"
                                  className="share_ben_percentage"
                                  placeholder="Dollar Value"
                                  value={
                                    beneficiaryDetails[beneficiary]?.value || ""
                                  }
                                  onChange={(e) =>
                                    handleFieldChange(
                                      beneficiary,
                                      "value",
                                      e.target.value
                                    )
                                  }
                                />
                              )}

                              {distributionType && (
                                <p className="share_beneficiary_card_para">
                                  Distributed Value: $
                                  {distributionType === "percentage"
                                    ? calculateDistributedAmount(
                                        "percentage",
                                        estimatedTotalAmount,
                                        beneficiaryDetails[beneficiary]
                                      )
                                    : calculateDistributedAmount(
                                        "dollar",
                                        estimatedTotalAmount,
                                        beneficiaryDetails[beneficiary]
                                      )}
                                </p>
                              )}
                            </div>
                            <div
                              className="share_beneficiary_card_close"
                              onClick={() =>
                                handleBeneficiaryClose(beneficiary)
                              }
                            >
                              <FontAwesomeIcon icon={faXmark} />
                            </div>
                          </div>
                        )
                      )
                    )}
                  </div>
                </div>
              </div>
            </div>
            {selectedBeneficiaries.length > 0 && (
              <button onClick={handleSave}>Save</button>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export function InternationalAssetBank() {
  // const [bankName, setBankName] = React.useState("");
  const [selectedImage, setSelectedImage] = useState([]);

  const [category, setCategory] = useState([]);
  const [popupVisible, setPopupVisible] = useState(false);
  const [beneficiaryVisible, setBeneficiaryVisible] = useState(false);

  const [selectedNote, setSelectedNote] = useState("");
  const [popupVisibleDownlaod, setPopupVisibleDownlaod] = useState(false);
  const [selectedDownlaod, setSelectDownload] = useState("");
  const [selectedBeneficiaries, setSelectedBeneficiaries] = useState([]);
  const [beneficiaryDetails, setBeneficiaryDetails] = useState({});

  const [benevisible, setbeneVisible] = useState(false);
  const [estimatedTotalAmount, setEstimatedTotalAmount] = useState(0);
  const [isChecked, setIsChecked] = useState(false);
  const [show, setShow] = useState(false);
  const [beneficiary, setBenificiary] = useState([]);
  const [distributionType, setDistributionType] = useState("");
  const [bankFieldClicked, setBankFieldClicked] = useState(false);
  const [account, setAccount] = useState([]);
  const [visibleColumnIndex, setVisibleColumnIndex] = useState(0);
  let [showAdditionField, SetshowAdditionField] = useState(false);
  let [card, setCard] = useState([]);
  let [showDetail, setShowDetail] = useState([]);
  let [show1, setShow1] = useState(false);
  let [otherPropertyTypes, setOtherPropertyTypes] = useState(false);

  const navigate = useNavigate();

  let [form1, setForm1] = useState(true);
  const toggle = () => {
    setForm1(!form1);
    navigate("/user/my-estate/International_assets");
  };

  const [data, setData] = useState({
    bank: {
      owner: "",

      bankName: "",
      safetyBox: "",
      safetyBoxNumber: "",
      notes: "",
      bankType: "",
      totalAmount: "",
    },
    accounts: [
      {
        accountType: "",
        accountNumber: "",
        balance: "",
      },
    ],
    sharedDetails: [
      {
        distributedType: "",
        distributedValue: "",
        distributedAmount: "",
        beneficiaryId: "",
      },
    ],
  });

  const [data1, setData1] = useState({
    assetCaption: "",
    assetType: "bank",
    countryName: "",
  });

  const handleChangesData = (e, field) => {
    const newValue = e.target.value;
    setData1((prevData) => ({
      ...prevData,
      [field]: newValue,
    }));
  };

  const handleChangesCountry = (event, newValue) => {
    setData1((prevData) => ({
      ...prevData,
      countryName: newValue,
    }));
  };

  // for owner field
  const [ownerName, setOwnerName] = useState([]);

  const handleChange = (event, newValue, stringValue) => {
    if (stringValue === "owner") {
      // Convert ownerName array to a single string

      const comingValue =
        typeof newValue === "string" ? newValue.split(",") : newValue;
      const ownerString = comingValue.join(", ");

      setData((prevData) => ({
        ...prevData,
        bank: {
          ...prevData.bank,
          owner: ownerString,
        },
      }));

      // Update the ownerName state afterwards
      setOwnerName(newValue);
    }
  };

  // Define an array to store user names
  const ownerNames = [];

  // Get primaryUser data
  const primaryUserDetails = getUser();
  const primaryUserName =
    primaryUserDetails.firstName + " " + primaryUserDetails.lastName;

  // Get secondaryUser data
  const secondaryUserDetails = getSecondaryUser();
  let secondaryUserName = "";

  // Check if secondary user exists
  if (secondaryUserDetails !== undefined) {
    secondaryUserName =
      secondaryUserDetails.firstName + " " + secondaryUserDetails.lastName;

    // Push both user names into the array
    ownerNames.push(primaryUserName, secondaryUserName);
    ownerNames.push("Other");
  } else {
    // If secondary user doesn't exist, only push primary user name
    ownerNames.push(primaryUserName);
    ownerNames.push("Other");
  }

  const resetForm = () => {
    getBankName("");
    setBankFieldClicked(false);
    setVisibleColumnIndex(0);
    setSelectedBankTypes("");
    setData({
      bank: {
        owner: "",
        bankName: "",
        safetyBox: "",
        safetyBoxNumber: "",
        notes: "",
        bankType: "",
        totalAmount: "",
      },
      accounts: [
        {
          accountType: "",
          accountNumber: "",
          balance: "",
        },
      ],
      sharedDetails: [
        {
          distributedType: "",
          distributedValue: "",
          distributedAmount: "",
          beneficiaryId: "",
        },
      ],
    });
  };

  const handleImageChange = (event, maxFilesAllowed) => {
    const selectedFiles = event.target.files;
    const allowedExtensions = ["pdf"];

    if (selectedFiles) {
      const selectedFilesArray = Array.from(selectedFiles);

      if (selectedFilesArray.length > maxFilesAllowed) {
        // Notify the user if they exceed the file limit
        toast.error(`You can only select up to ${maxFilesAllowed} files.`, {
          position: toast.POSITION.BOTTOM_CENTER,
        });
        event.target.value = ""; // Clear the file input
      } else {
        const invalidFiles = selectedFilesArray.filter((file) => {
          const fileNameParts = file.name.split(".");
          const fileExtension =
            fileNameParts[fileNameParts.length - 1].toLowerCase();
          return !allowedExtensions.includes(fileExtension);
        });

        if (invalidFiles.length === 0) {
          const formData = new FormData();
          selectedFilesArray.forEach((file, index) => {
            formData.append(`file${index + 1}`, file);
          });

          // Update your state or perform actions with the selected files
          setSelectedImage(selectedFilesArray);
        } else {
          const invalidExtensions = invalidFiles
            .map((file) => file.name.split(".").pop())
            .join(", ");
          // Notify the user about invalid file extensions
          toast.error(
            `Invalid file extensions: ${invalidExtensions}. Please select valid document formats.`,
            {
              position: toast.POSITION.BOTTOM_CENTER,
            }
          );

          // Clear the file input field
          event.target.value = "";
        }
      }
    }
  };
  const bankHandleChange = (event, newValue) => {
    const selectedBank = newValue;
    if (selectedBank === "other") {
      setOtherPropertyTypes(true);
      setData({
        ...data,
        bank: {
          ...data.bank,
          bankType: "other",
          bankName: "",
        },
      });
    } else {
      setOtherPropertyTypes(false);
      setData({
        ...data,
        bank: {
          ...data.bank,
          bankType: "",
          bankName: selectedBank,
        },
      });
    }
  };

  const BankForm = (event) => {
    event.preventDefault();
    let token = "Bearer " + getToken();

    // for (let i = 0; i < data.accounts.length; i++) {
    //   const account = data.accounts[i];
    //   if (!/^\d{15}$/.test(account.accountNumber)) {
    //     // Display an error message or handle validation failure
    //     toast.error("Enter valid 15 digit account number", {
    //       position: toast.POSITION.BOTTOM_CENTER,
    //     });
    //     return; // Exit the function without hitting the API
    //   }
    // }

    const formData = new FormData();
    for (let i = 0; i < selectedImage.length; i++) {
      formData.append(`files`, selectedImage[i]);
    }
    formData.append("asset", JSON.stringify(data));
    formData.append("data", JSON.stringify(data1));

    addInternationalAsset(formData, token)
      .then(() => {
        toast.success("Data Added !!", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
        toggle();
      })
      .catch(() => {});
  };

  const getBenificiarydata = () => {
    let userId = getUser().id;

    let token = "Bearer " + getToken();
    getBeneficiary(token, userId)
      .then((res) => {
        setBenificiary(res);
      })
      .catch(() => {});
  };
  const getBankName = (bankName) => {
    return category.some((item) => item.bank.bankName === bankName);
  };

  const getData = () => {
    let userId = getUser().commonId;
    let token = "Bearer " + getToken();
    getInternationalBank(token, userId)
      .then((res) => {
        setCategory(res);
      })
      .catch((error) => {});
  };

  const getBenificiaryName = (id) => {
    var foundBenificiary = null;
    if (id.beneficiary === undefined) {
      foundBenificiary = beneficiary.find((b) => b.id === parseInt(id));
    } else {
      foundBenificiary = beneficiary.find(
        (b) => b.id === parseInt(id.beneficiary)
      );
    }

    if (foundBenificiary) {
      return `${foundBenificiary.firstName} ${foundBenificiary.lastName}`;
    } else {
      return "Benificiary not found"; // Or handle the case where beneficiary with the given ID isn't found
    }
  };

  const bankLogo = [
    { "JPMorgan Chase & Co": "/logos/J.P._Morgan_Chase_logo_PNG3.png" },
    { "Bank of America": "/logos/Bank_of_America_logo_PNG4.png" },
    { "Wells Fargo & Co": "/logos/Wells_fargo_logo_PNG2.png" },
    { "Citigroup Inc": "/logos/Citigroup_logo_PNG1.png" },
    { "U.S. Bancorp": "/logos/US-Bank-Logo-PNG3.png" },
    { "PNC bank": "/logos/PNC_Bank_logo_PNG1.png" },
    { "TD Bank": "/logos/TD_Bank_logo_PNG1.png" },
    { "Capital One": "/logos/Capital_One_logo_PNG1.png" },
    { "Fifth Third Bank": "/logos/Harris-Teeter-Logo-PNG_003-1.png" },
    { "Ally Financial Inc": "/logos/Ally_Financial_logo_PNG4.png" },
    { "Huntington Bancshares Incorporated": "/logos/huntington.webp" },
    { KeyCorp: "/logos/KeyBank_logo_PNG7.png" },
  ];

  const handleSwitchChange = () => {
    setIsChecked(!isChecked);
    data.bank.safetyBox = !isChecked;
    if (isChecked) {
      data.bank.safetyBoxNumber = null;
    }
  };

  const handleChangesBank = (e, field) => {
    const newValue = e.target.value;

    setData((prevData) => ({
      ...prevData,
      bank: {
        ...prevData.bank,
        [field]: newValue,
      },
    }));
  };

  const handleChanges1 = (e, newValue, field, { index }) => {
    // const { value } = e.target;
    let value = e.target.value;

    if (field === "accountType") {
      value = newValue;
      setSelectedBankTypes((prevSelectedBankTypes) => {
        const newSelectedBankTypes = [...prevSelectedBankTypes];
        newSelectedBankTypes[index] = value;
        return newSelectedBankTypes;
      });
    }

    setData((prevData) => {
      const updatedAccounts = [...prevData.accounts];
      if (!updatedAccounts[index]) {
        updatedAccounts[index] = {};
      }
      updatedAccounts[index][field] = value;

      return {
        ...prevData,
        accounts: updatedAccounts,
      };
    });

    const balances = data.accounts.map((account) => account.balance || 0);
    const newTotalBalance = balances.reduce(
      (acc, curr) => acc + parseFloat(curr),
      0
    );
    setEstimatedTotalAmount(newTotalBalance);
  };

  const handleShowBeneficiary = () => {
    setbeneVisible(true);
    setShow1(false);

    data.sharedDetails = [];
  };
  const handleBeneficiarySelection = (event) => {
    const selectedBeneficiary = event.target.value;
    if (!selectedBeneficiaries.includes(selectedBeneficiary)) {
      setSelectedBeneficiaries([...selectedBeneficiaries, selectedBeneficiary]);
      setBeneficiaryDetails({
        ...beneficiaryDetails,
        [selectedBeneficiary]: { percentage: "", value: "" },
      });
    }
  };
  const handleBeneficiaryClose = (beneficiary) => {
    const updatedBeneficiaries = selectedBeneficiaries.filter(
      (b) => b !== beneficiary
    );
    setSelectedBeneficiaries(updatedBeneficiaries);
    const updatedDetails = { ...beneficiaryDetails };
    delete updatedDetails[beneficiary];
    setBeneficiaryDetails(updatedDetails);
  };
  const handleFieldChange = (beneficiary, field, value) => {
    if (!estimatedTotalAmount || parseFloat(estimatedTotalAmount) === 0) {
      toast.error(
        "Please provide a valid estimated value before adding percentages!"
      );
      return;
    }
    let updatedDetails = { ...beneficiaryDetails };
    if (/^\d*$/.test(value)) {
      updatedDetails = {
        ...updatedDetails,
        [beneficiary]: {
          ...updatedDetails[beneficiary],
          [field]: value,
        },
      };
    }
    let totalPercentage = 0;
    let totalValue = 0;
    Object.keys(updatedDetails).forEach((key) => {
      const beneficiaryPercentage = parseFloat(updatedDetails[key]?.percentage);
      const beneficiaryValue = parseFloat(updatedDetails[key]?.value);
      if (!isNaN(beneficiaryPercentage) && beneficiaryPercentage >= 0) {
        totalPercentage += beneficiaryPercentage;
      }
      if (!isNaN(beneficiaryValue) && beneficiaryValue >= 0) {
        totalValue += beneficiaryValue;
      }
    });
    if (
      totalPercentage > 100 ||
      totalValue > parseFloat(estimatedTotalAmount)
    ) {
      updatedDetails[beneficiary][field] = "";
      toast.error(
        "Total percentage exceeds 100% or total value exceeds estimated value!"
      );
    } else {
      setBeneficiaryDetails(updatedDetails);
    }
  };
  const handleDistributionTypeChange = (event) => {
    const newType = event.target.value;
    const resetDetails = {};
    Object.keys(beneficiaryDetails).forEach((beneficiary) => {
      resetDetails[beneficiary] = { percentage: "", value: "" };
    });
    setDistributionType(newType);
    setBeneficiaryDetails(resetDetails);
  };

  const maskAccountNumber = (accountNumber) => {
    if (!accountNumber) return "";
    const maskedPart = "*".repeat(accountNumber.length - 4);
    const lastFourDigits = accountNumber.slice(-4);
    return `${maskedPart}${lastFourDigits}`;
  };

  // multiple account
  const multipleAccount = [0, 1, 2, 3];
  const handleAddBank = () => {
    if (visibleColumnIndex < 3) {
      setAccount([...account, { label: visibleColumnIndex + 1 }]);
      setVisibleColumnIndex(visibleColumnIndex + 1);
    }
  };

  const handleRemoveBank = (indexToRemove) => {
    if (visibleColumnIndex > 0) {
      // Filter out the account with the index to remove
      const updatedAccounts = account.filter(
        (_, index) => index !== indexToRemove - 1
      );
      setAccount(updatedAccounts);

      // Adjust visibleColumnIndex if the removed index is within the current visible range
      if (indexToRemove - 1 < visibleColumnIndex) {
        setVisibleColumnIndex(visibleColumnIndex - 1);
      }
    }

    setData((prevData) => {
      const updatedAccounts = prevData.accounts.map((account, index) => {
        if (index === indexToRemove) {
          // Create a new object with empty values for the removed index
          return {
            accountType: "",
            accountNumber: "",
            balance: "",
          };
        }
        return account; // Return the original bank object for other indices
      });

      // Filter out accounts with empty strings for accountType, accountNumber, and balance
      const filteredAccounts = updatedAccounts.filter(
        (account) =>
          account.accountType !== "" ||
          account.accountNumber !== "" ||
          account.balance !== ""
      );

      // Update the state with the new array of bank accounts
      const newData = {
        ...prevData,
        accounts: filteredAccounts,
      };

      // Perform further operations directly after updating the state
      // Filter out accounts with empty strings for accountType, accountNumber, and balance
      const furtherFilteredAccounts = newData.accounts.filter(
        (account) =>
          account.accountType !== "" ||
          account.accountNumber !== "" ||
          account.balance !== ""
      );

      // Update the state with the further filtered accounts
      setData({
        ...newData,
        accounts: furtherFilteredAccounts,
      });

      return newData;
    });

    setSelectedBankTypes((prevSelectedBankTypes) => {
      const newSelectedBankTypes = [...prevSelectedBankTypes];
      newSelectedBankTypes.splice(indexToRemove, 1);
      return newSelectedBankTypes;
    });
  };

  const [selectedBankTypes, setSelectedBankTypes] = useState(
    Array(multipleAccount.length).fill("")
  );
  const handleReset = () => {
    setbeneVisible(false);
    setDistributionType("");
    setSelectedBeneficiaries([]);
    setBeneficiaryDetails({});
  };

  const calculateDistributedAmount = (
    distributionType,
    balance,
    beneficiaryDetail
  ) => {
    if (distributionType === "percentage") {
      return (
        (parseFloat(balance) * parseFloat(beneficiaryDetail?.percentage || 0)) /
        100
      ).toFixed(2);
    } else if (distributionType === "dollar") {
      return parseFloat(beneficiaryDetail?.value || 0).toFixed(2);
    }
    return "0.00";
  };

  const handleChanges2 = (t, v, a, b, i) => {
    const updatedSharedDetails = [...data.sharedDetails]; // Create a copy of the sharedDetails array

    // Check if the index i is within the bounds of the array
    // if (i >= 0 && i < updatedSharedDetails.length) {
    // Create a new object to update the specific element at index i
    const updatedElement = {
      ...updatedSharedDetails[i], // Copy existing properties
      distributedType: t,
      distributedValue: v,
      distributedAmount: a,
      beneficiaryId: b,
    };

    updatedSharedDetails[i] = updatedElement; // Update the element at index i

    setData((prevState) => ({
      ...prevState,
      sharedDetails: updatedSharedDetails, // Update the sharedDetails in the state
    }));
    data.sharedDetails[i] = updatedSharedDetails[i];
  };
  const handleSave = () => {
    toast.success("Saved successfully!");
    setbeneVisible(false);
    var i = 0;
    Object.keys(beneficiaryDetails).forEach((key) => {
      const beneficiaryPercentage = parseFloat(
        beneficiaryDetails[key]?.percentage
      );
      const beneficiaryValue = parseFloat(beneficiaryDetails[key]?.value);

      if (!isNaN(beneficiaryPercentage) && beneficiaryPercentage >= 0) {
        const distributedAmount = calculateDistributedAmount(
          "percentage",
          estimatedTotalAmount,
          beneficiaryDetails[key]
        );
        handleChanges2(
          "percentage",
          beneficiaryPercentage,
          distributedAmount,
          parseInt(key),
          i
        );
        i += 1;
      }

      if (!isNaN(beneficiaryValue) && beneficiaryValue >= 0) {
        const distributedAmount = calculateDistributedAmount(
          "dollar",
          estimatedTotalAmount,
          beneficiaryDetails[key]
        );
        handleChanges2(
          "dollar",
          beneficiaryValue,
          distributedAmount,
          parseInt(key),
          i
        );
        i += 1;
      }
    });
    // beneficiaryDetails.map(value)
    // const length = data.sharedDetails.length;
    // data.sharedDetails[length] = beneficiaryDetails;
  };

  const validateBankAccountNum = (index) => {
    for (let i = 0; i < data.accounts.length; i++) {
      if (index === i) {
        const account = data.accounts[i];
        if (!/^\d{15}$/.test(account.accountNumber)) {
          return false; // Return false if the validation fails
        } else {
          return true; // Return true if all account numbers pass the validation
        }
      }
    }
  };

  const [visibleAccountIndices, setVisibleAccountIndices] = useState([]);

  const toggleVisibility = (index) => {
    const currentIndex = visibleAccountIndices.indexOf(index);
    const newVisibleIndices = [...visibleAccountIndices];

    if (currentIndex === -1) {
      newVisibleIndices.push(index);
    } else {
      newVisibleIndices.splice(currentIndex, 1);
    }

    setVisibleAccountIndices(newVisibleIndices);
  };

  const renderAccountNumber = (accountNumber, index) => {
    if (visibleAccountIndices.includes(index)) {
      return accountNumber;
    } else {
      // Mask all but the last four digits
      const maskedDigits = accountNumber.slice(0, -4).replace(/\d/g, "*");
      const lastFourDigits = accountNumber.slice(-4);
      return maskedDigits + lastFourDigits;
    }
  };

  useEffect(() => {
    getBenificiarydata();
    getData();
  }, []);

  return (
    <>
      {form1 && (
        <>
          <div className="fade-in-element">
            <UserBase>
              <div className="mt-5">
                <SideBar>
                  <div
                    className="overlay1"
                    style={{ transition: "500ms", height: "" }}
                  >
                    <div className="addform_ichest">
                      <div className="addform_main">
                        <div className="addform_heading">
                          <h3 className="addform_heading_h1">
                            Add Bank Properties
                          </h3>
                          <div className="addform_close" onClick={toggle}>
                            <FontAwesomeIcon icon={faXmark} />
                          </div>
                        </div>
                        <div className="addform_body">
                          <Form onSubmit={BankForm} className="addform_body">
                            <div className="addform_select_body">
                              <div className="addform_body_left">
                                <div className="address_input">
                                  <div className="mt-2 addform_input_half">
                                    <div>
                                      <Tooltip title="Enter Caption for your assets">
                                        <div>
                                          <FormLabel>Assets Caption</FormLabel>
                                          <Input
                                            className="input_mui_joy"
                                            placeholder="Enter asset caption"
                                            onChange={(e) =>
                                              handleChangesData(
                                                e,
                                                "assetCaption"
                                              )
                                            }
                                            value={data1.assetCaption}
                                          />
                                        </div>
                                      </Tooltip>
                                    </div>
                                  </div>

                                  <div className="mt-2 addform_input_half">
                                    <div>
                                      <Tooltip title="Enter the Country of your assets">
                                        <div>
                                          <FormLabel required>
                                            Country Name
                                          </FormLabel>

                                          <Select
                                            placeholder="Select country name"
                                            onChange={(e, newValue) =>
                                              handleChangesCountry(e, newValue)
                                            }
                                            value={data1.countryName}
                                            required
                                          >
                                            {Country.getAllCountries().map(
                                              (v, index) => {
                                                return (
                                                  <Option
                                                    key={index}
                                                    value={v.name}
                                                  >
                                                    {v.name}
                                                  </Option>
                                                );
                                              }
                                            )}
                                          </Select>
                                        </div>
                                      </Tooltip>
                                    </div>
                                  </div>
                                </div>
                                <div>
                                  <Tooltip
                                    title="Select Owner"
                                    className="mt-2"
                                  >
                                    <div required>
                                      <FormLabel required>
                                        Select Owner
                                      </FormLabel>
                                      <Select
                                        value={ownerName}
                                        multiple
                                        placeholder="Select Name"
                                        onChange={(event, newValue) =>
                                          handleChange(event, newValue, "owner")
                                        }
                                        sx={{
                                          minWidth: "13rem",
                                        }}
                                        required
                                      >
                                        {ownerNames.map((name) => (
                                          <Option key={name} value={name}>
                                            {name}
                                          </Option>
                                        ))}
                                      </Select>
                                    </div>
                                  </Tooltip>
                                </div>

                                <div className="mt-2">
                                  <Tooltip title="Enter name of the bank">
                                    <div>
                                      <FormLabel required>Bank Name</FormLabel>
                                      <Select
                                        id="banks"
                                        placeholder="Select Bank Name"
                                        // onChange={bankHandleChange}
                                        onChange={(event, newValue) =>
                                          bankHandleChange(event, newValue)
                                        }
                                        required
                                        // value={
                                        //   data.bank.bankType === "other"
                                        //     ? data.bank.bankType
                                        //     : data.bank.bankName
                                        // }
                                      >
                                        <Option
                                          value={"JPMorgan Chase & Co"}
                                          disabled={getBankName(
                                            "JPMorgan Chase & Co"
                                          )}
                                        >
                                          <img
                                            src="/logos/J.P._Morgan_Chase_logo_PNG3.png"
                                            alt="logo"
                                            style={{
                                              width: "45px",
                                              aspectRatio: "16/9",
                                              objectFit: "contain",
                                            }}
                                          />
                                          &nbsp;&nbsp;JPMorgan Chase & Co
                                        </Option>
                                        <Option
                                          value={"Bank of America"}
                                          disabled={getBankName(
                                            "Bank of America"
                                          )}
                                        >
                                          <img
                                            src="/logos/Bank_of_America_logo_PNG4.png"
                                            alt="logo"
                                            style={{
                                              width: "45px",
                                              aspectRatio: "16/9",
                                              objectFit: "contain",
                                            }}
                                          />
                                          &nbsp;&nbsp;Bank of America
                                        </Option>
                                        <Option
                                          value={"Wells Fargo & Co"}
                                          disabled={getBankName(
                                            "Wells Fargo & Co"
                                          )}
                                        >
                                          <img
                                            src="/logos/Wells_fargo_logo_PNG2.png"
                                            alt="logo"
                                            style={{
                                              width: "45px",
                                              aspectRatio: "16/9",
                                              objectFit: "contain",
                                            }}
                                          />
                                          &nbsp;Wells Fargo & Co
                                        </Option>
                                        <Option
                                          value={"Citigroup Inc"}
                                          disabled={getBankName(
                                            "Citigroup Inc"
                                          )}
                                        >
                                          <img
                                            src="/logos/Citigroup_logo_PNG1.png"
                                            alt="logo"
                                            style={{
                                              width: "40px",
                                              aspectRatio: "16/9",
                                              objectFit: "contain",
                                            }}
                                          />
                                          &nbsp;&nbsp;Citigroup Inc
                                        </Option>
                                        <Option
                                          value={"U.S. Bancorp"}
                                          disabled={getBankName("U.S. Bancorp")}
                                        >
                                          <img
                                            src="/logos/US-Bank-Logo-PNG3.png"
                                            alt="logo"
                                            style={{
                                              width: "45px",
                                              aspectRatio: "16/9",
                                              objectFit: "contain",
                                            }}
                                          />
                                          &nbsp;U.S. Bancorp
                                        </Option>
                                        <Option
                                          value={"PNC bank"}
                                          disabled={getBankName("PNC bank")}
                                        >
                                          <img
                                            src="/logos/PNC_Bank_logo_PNG1.png"
                                            alt="logo"
                                            style={{
                                              width: "45px",
                                              aspectRatio: "16/9",
                                              objectFit: "contain",
                                            }}
                                          />
                                          &nbsp;&nbsp;PNC bank
                                        </Option>
                                        <Option
                                          value={"TD Bank"}
                                          disabled={getBankName("TD Bank")}
                                        >
                                          <img
                                            src="/logos/TD_Bank_logo_PNG1.png"
                                            alt="logo"
                                            style={{
                                              width: "45px",
                                              aspectRatio: "16/9",
                                              objectFit: "contain",
                                            }}
                                          />
                                          &nbsp;TD Bank
                                        </Option>
                                        <Option
                                          value={"Capital One"}
                                          disabled={getBankName("Capital One")}
                                        >
                                          <img
                                            src="/logos/Capital_One_logo_PNG1.png"
                                            alt="logo"
                                            style={{
                                              width: "45px",
                                              aspectRatio: "16/9",
                                              objectFit: "contain",
                                            }}
                                          />
                                          &nbsp;&nbsp;Capital One
                                        </Option>
                                        <Option
                                          value={"Fifth Third Bank"}
                                          disabled={getBankName(
                                            "Fifth Third Bank"
                                          )}
                                        >
                                          <img
                                            src="/logos/Harris-Teeter-Logo-PNG_003-1.png"
                                            alt="logo"
                                            style={{
                                              width: "45px",
                                              aspectRatio: "16/9",
                                              objectFit: "contain",
                                            }}
                                          />
                                          &nbsp;Fifth Third Bank
                                        </Option>
                                        <Option
                                          value={"Ally Financial Inc"}
                                          disabled={getBankName(
                                            "Ally Financial Inc"
                                          )}
                                        >
                                          <img
                                            src="/logos/Ally_Financial_logo_PNG4.png"
                                            alt="logo"
                                            style={{
                                              width: "45px",
                                              aspectRatio: "16/9",
                                              objectFit: "contain",
                                            }}
                                          />
                                          &nbsp;Ally Financial Inc
                                        </Option>
                                        <Option
                                          value={
                                            "Huntington Bancshares Incorporated"
                                          }
                                          disabled={getBankName(
                                            "Huntington Bancshares Incorporated"
                                          )}
                                        >
                                          <img
                                            src="/logos/huntington.webp"
                                            alt="logo"
                                            style={{
                                              width: "45px",
                                              aspectRatio: "16/9",
                                              objectFit: "contain",
                                            }}
                                          />
                                          &nbsp;Huntington Bancshares
                                          Incorporated
                                        </Option>
                                        <Option
                                          value={"KeyCorp"}
                                          disabled={getBankName("KeyCorp")}
                                        >
                                          <img
                                            src="/logos/KeyBank_logo_PNG7.png"
                                            alt="logo"
                                            style={{
                                              width: "45px",
                                              aspectRatio: "16/9",
                                              objectFit: "contain",
                                            }}
                                          />
                                          &nbsp;&nbsp;KeyCorp
                                        </Option>
                                        <Option value={"other"}>Other</Option>
                                      </Select>
                                    </div>
                                  </Tooltip>
                                </div>
                                {otherPropertyTypes && (
                                  <div className="mt-2">
                                    <Tooltip title="Enter Your Other Bank Name ">
                                      <div>
                                        <FormLabel>Other Bank</FormLabel>
                                        <Input
                                          className="input_mui_joy"
                                          placeholder="Enter Other Bank"
                                          id="otherprop"
                                          onChange={(e) =>
                                            handleChangesBank(e, "bankName")
                                          }
                                          value={data.bank.bankName}
                                          aria-readonly
                                        />
                                      </div>
                                    </Tooltip>
                                  </div>
                                )}
                                <div
                                  className="mt-3 form1-double"
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    width: "100%",
                                    gap: "5px",
                                  }}
                                >
                                  <div
                                    style={{
                                      width: "49.5%",
                                      display: "flex",
                                      justifyContent: "flex-start",
                                    }}
                                  >
                                    <div
                                      className="txt"
                                      style={{
                                        alignSelf: "center",
                                        marginRight: "10px",
                                        flex: "1 1 100%",
                                      }}
                                    >
                                      Safety Box
                                    </div>
                                    <div
                                      className="switch"
                                      style={{
                                        flex: "1 1 100%",
                                      }}
                                    >
                                      <FormControlLabel
                                        control={
                                          <Switch
                                            checked={isChecked}
                                            onChange={handleSwitchChange}
                                            color="primary"
                                            size="normal"
                                          />
                                        }
                                        label={isChecked ? "Yes" : "No"}
                                        labelPlacement="end"
                                      />
                                    </div>
                                  </div>

                                  {isChecked && (
                                    <div className="addform_input_half">
                                      <Tooltip title="Enter Your SafetyBox ID">
                                        <div>
                                          <Input
                                            className="input_mui_joy"
                                            placeholder="Safety Box ID"
                                            id="safetyBoxNumber"
                                            onChange={(e) =>
                                              handleChangesBank(
                                                e,
                                                "safetyBoxNumber"
                                              )
                                            }
                                            value={data.bank.safetyBoxNumber}
                                          />
                                        </div>
                                      </Tooltip>
                                    </div>
                                  )}
                                </div>

                                <div>
                                  <Tooltip title="Add your banks related file">
                                    <div className="mt-3">
                                      <FormLabel>Supporting Document</FormLabel>
                                      <input
                                        style={{
                                          border: "solid 1px lightgray",
                                          borderLeft: "none",
                                          width: "100%",
                                          borderRadius: "5px",
                                        }}
                                        type="file"
                                        name="myfile"
                                        id="exampleFile"
                                        onChange={(e) =>
                                          handleImageChange(e, 5)
                                        }
                                        accept=".pdf"
                                        multiple
                                      />
                                    </div>
                                  </Tooltip>
                                </div>

                                <div
                                  className="bene-select mt-3"
                                  onClick={handleShowBeneficiary}
                                  style={{ cursor: "pointer" }}
                                >
                                  Select Your Beneficiary
                                </div>
                              </div>

                              <div className="addform_body_right_bank">
                                <div>
                                  {multipleAccount.map((index) => (
                                    <div
                                      className="mt-2"
                                      key={index}
                                      style={{
                                        flexDirection: "column",
                                        display:
                                          index <= visibleColumnIndex
                                            ? "flex"
                                            : "none",
                                      }}
                                    >
                                      <div>
                                        <Tooltip
                                          title={`Add your bank details for bank ${
                                            index + 1
                                          }`}
                                        >
                                          <div>
                                            <FormLabel
                                              id={`accountType${index + 1}`}
                                            >
                                              Bank Type{" "}
                                              {index >= 0 && <span>*</span>}
                                            </FormLabel>
                                            <Select
                                              id={`accountType${index + 1}`}
                                              placeholder="Select Your Account Type"
                                              // value={
                                              //   data.accounts[index]?.accountType || ""
                                              // }
                                              onChange={(e, newValue) =>
                                                handleChanges1(
                                                  e,
                                                  newValue,
                                                  "accountType",
                                                  {
                                                    index,
                                                  }
                                                )
                                              }
                                              onClick={() =>
                                                setBankFieldClicked(true)
                                              }
                                              required={
                                                visibleColumnIndex >= index
                                              }
                                            >
                                              <Option
                                                value="Checking Account"
                                                disabled={selectedBankTypes.includes(
                                                  "Checking Account"
                                                )}
                                              >
                                                Checking Account
                                              </Option>
                                              <Option
                                                value="Savings Account"
                                                disabled={selectedBankTypes.includes(
                                                  "Savings Account"
                                                )}
                                              >
                                                Savings Account
                                              </Option>
                                              <Option
                                                value="Investment Account"
                                                disabled={selectedBankTypes.includes(
                                                  "Investment Account"
                                                )}
                                              >
                                                Investment Account
                                              </Option>
                                              <Option
                                                value="C.D Account"
                                                disabled={selectedBankTypes.includes(
                                                  "C.D Account"
                                                )}
                                              >
                                                C.D Account
                                              </Option>
                                            </Select>
                                          </div>
                                        </Tooltip>
                                      </div>

                                      <div className="address_input">
                                        <div className="mt-2 addform_input_half">
                                          <Tooltip
                                            title={`Add your bank details for bank ${
                                              index + 1
                                            }`}
                                          >
                                            <div>
                                              <FormLabel
                                                required
                                              >{`Bank Account ${
                                                index + 1
                                              }`}</FormLabel>
                                              <Input
                                                type="number"
                                                placeholder={`Bank Account ${
                                                  index + 1
                                                }`}
                                                id={`BankAccount${index + 1}`}
                                                required={
                                                  visibleColumnIndex >= index
                                                }
                                                onChange={(e) =>
                                                  handleChanges1(
                                                    e,
                                                    null,
                                                    "accountNumber",
                                                    {
                                                      index,
                                                    }
                                                  )
                                                }
                                                value={
                                                  data.accounts[index]
                                                    ?.accountNumber || ""
                                                }
                                                // inputprops={{
                                                //   minLength: 15,
                                                //   maxLength: 15,
                                                // }}
                                                // error={!validateBankAccountNum(index)}
                                              />
                                              {/* {!validateBankAccountNum(index) && (
                                      <Typography
                                        variant="body2"
                                        color="danger"
                                        sx={{
                                          marginTop: "4px",
                                          fontSize: "0.8rem",
                                        }}
                                      >
                                        Please enter a valid 15-digit Account No
                                      </Typography>
                                    )} */}
                                            </div>
                                          </Tooltip>
                                        </div>
                                        <div className="mt-2 addform_input_half">
                                          <Tooltip
                                            title={`Add your bank details for bank ${
                                              index + 1
                                            }`}
                                          >
                                            <div>
                                              <FormLabel
                                                required
                                              >{`Account Balance ${
                                                index + 1
                                              }`}</FormLabel>
                                              <Input
                                                type="number"
                                                placeholder={`Account Balance ${
                                                  index + 1
                                                }`}
                                                id={`Account Balance ${
                                                  index + 1
                                                }`}
                                                required={
                                                  visibleColumnIndex >= index
                                                }
                                                onChange={(e) =>
                                                  handleChanges1(
                                                    e,
                                                    null,
                                                    "balance",
                                                    {
                                                      index,
                                                    }
                                                  )
                                                }
                                                value={
                                                  data.accounts[index]
                                                    ?.balance || ""
                                                }
                                                onClick={() =>
                                                  setBankFieldClicked(true)
                                                }
                                                startDecorator={
                                                  bankFieldClicked ? (
                                                    <div>$</div>
                                                  ) : null
                                                }
                                              />
                                            </div>
                                          </Tooltip>
                                        </div>
                                      </div>
                                      {index !== 0 && (
                                        <Button
                                          style={{
                                            marginTop: "7px",
                                            height: "30px",
                                            width: "30px",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            borderRadius: "50%",
                                            backgroundColor: "#ff4a4a",
                                            border: "none",
                                          }}
                                          onClick={() =>
                                            handleRemoveBank(index)
                                          }
                                        >
                                          <FontAwesomeIcon icon={faMinus} />
                                        </Button>
                                      )}
                                    </div>
                                  ))}
                                  {visibleColumnIndex < 3 && (
                                    <div
                                      style={{
                                        marginTop: "7px",
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <Button
                                        style={{
                                          height: "30px",
                                          width: "30px",
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          borderRadius: "50%",
                                          backgroundColor: "#4aafff",
                                          border: "none",
                                        }}
                                        onClick={handleAddBank}
                                      >
                                        <FontAwesomeIcon icon={faPlus} />
                                      </Button>
                                    </div>
                                  )}
                                </div>

                                <div className="address_input">
                                  <div
                                    style={{ width: "100%" }}
                                    className="mt-2"
                                  >
                                    <Tooltip title="Enter notes for your real estate">
                                      <div>
                                        <FormLabel>Notes</FormLabel>
                                        <Textarea
                                          sx={{
                                            height: "182px",
                                            minHeight: "52px",
                                          }}
                                          placeholder="Notes"
                                          id="notes"
                                          onChange={(e) =>
                                            handleChangesBank(e, "notes")
                                          }
                                          value={data.bank.notes}
                                        />
                                      </div>
                                    </Tooltip>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="text-center">
                              <Button
                                className="my-estate-clear-btn"
                                type="reset"
                                // onClick={resetForm}
                                outline
                              >
                                Clear
                              </Button>
                              <Button outline className="my-estate-add-btn">
                                Add
                              </Button>
                            </div>
                          </Form>
                        </div>
                      </div>
                    </div>
                  </div>
                </SideBar>
              </div>
            </UserBase>
          </div>
        </>
      )}

      {benevisible && (
        // beneShow &&
        <div className="popup">
          <div
            className="popup-content popup-content-download"
            style={{
              minWidth: "350px",
              width: "100%",
              maxWidth: "700px",
            }}
          >
            <div className="note_popup">
              <div className="note_popup_heading">
                <div className="share_property_heading">
                  <h2>Share Property </h2>
                </div>
                <div>
                  <button
                    className="note_popup_heading_close_btn"
                    onClick={handleReset}
                  >
                    <FontAwesomeIcon icon={faXmark} />
                  </button>
                </div>
              </div>
              <div className="share_property_est_value">
                <p>
                  Estimated Value:{" "}
                  <code style={{ color: "green", fontWeight: "bold" }}>
                    ${estimatedTotalAmount}
                  </code>
                </p>
              </div>
              <div className="BeneficiarySelect">
                <div className="BeneficiarySelectContainer">
                  <div className="BeneficiarySelectRow">
                    <div class="share_property_Type">
                      <p class="share_property_Type_paragraph">
                        Choose Distribution Type:{" "}
                      </p>
                      <select
                        value={distributionType}
                        onChange={handleDistributionTypeChange}
                        class="share_property_Type_select"
                      >
                        <option value="">Select Type</option>
                        <option value="percentage">Percentage</option>
                        <option value="dollar">Dollar</option>
                      </select>
                    </div>
                    <div className="SelectContainer">
                      <select
                        className="Property-inputfiled"
                        onChange={handleBeneficiarySelection}
                        value=""
                        disabled={!distributionType}
                      >
                        <option value="" disabled hidden>
                          {distributionType
                            ? "Select Your Beneficiary Username"
                            : "Select Type First"}
                        </option>
                        {beneficiary.map((benif) => (
                          <option
                            key={benif.id}
                            value={benif.id}
                            disabled={selectedBeneficiaries.includes(benif.id)}
                          >
                            {/* {benif.username} */}
                            {`${benif.firstName} ${benif.lastName}`}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="share_beneficiary_main_card">
                    {selectedBeneficiaries.map(
                      (beneficiary) => (
                        console.log("this is  beneficiary ", beneficiary),
                        (
                          <div key={beneficiary} class="share_beneficiary_card">
                            <div>
                              <p className="share_beneficiary_card_para">
                                Beneficiary:{" "}
                                {getBenificiaryName({ beneficiary })}
                              </p>
                              {distributionType === "percentage" && (
                                <input
                                  type="text"
                                  className="share_ben_percentage"
                                  placeholder="Percentage"
                                  value={
                                    beneficiaryDetails[beneficiary]
                                      ?.percentage || ""
                                  }
                                  onChange={(e) =>
                                    handleFieldChange(
                                      beneficiary,
                                      "percentage",
                                      e.target.value
                                    )
                                  }
                                />
                              )}
                              {distributionType === "dollar" && (
                                <input
                                  type="text"
                                  className="share_ben_percentage"
                                  placeholder="Dollar Value"
                                  value={
                                    beneficiaryDetails[beneficiary]?.value || ""
                                  }
                                  onChange={(e) =>
                                    handleFieldChange(
                                      beneficiary,
                                      "value",
                                      e.target.value
                                    )
                                  }
                                />
                              )}

                              {distributionType && (
                                <p className="share_beneficiary_card_para">
                                  Distributed Value: $
                                  {distributionType === "percentage"
                                    ? calculateDistributedAmount(
                                        "percentage",
                                        estimatedTotalAmount,
                                        beneficiaryDetails[beneficiary]
                                      )
                                    : calculateDistributedAmount(
                                        "dollar",
                                        estimatedTotalAmount,
                                        beneficiaryDetails[beneficiary]
                                      )}
                                </p>
                              )}
                            </div>
                            <div
                              className="share_beneficiary_card_close"
                              onClick={() =>
                                handleBeneficiaryClose(beneficiary)
                              }
                            >
                              <FontAwesomeIcon icon={faXmark} />
                            </div>
                          </div>
                        )
                      )
                    )}
                  </div>
                </div>
              </div>
            </div>
            {selectedBeneficiaries.length > 0 && (
              <button onClick={handleSave}>Save</button>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export function InternationalAssetInvestment() {
  const navigate = useNavigate();
  let userId = getUser().id;
  //  form show button
  let [form1, setForm1] = useState(true);

  const toggle = () => {
    setForm1(!form1);
    navigate("/user/my-estate/International_assets");
  };

  const [data, setData] = useState({
    investment: {
      owner: "",

      investment: "",
      nameOfTheInvestment: "",
      investmentCaption: "",
      notes: "",
      totalAmount: "",
    },
    sharedDetails: [
      {
        distributedType: "",
        distributedValue: "",
        distributedAmount: "",
        beneficiaryId: "",
      },
    ],
  });

  const [data1, setData1] = useState({
    assetCaption: "",
    assetType: "investment",
    countryName: "",
  });

  const handleChangesData = (e, field) => {
    const newValue = e.target.value;
    setData1((prevData) => ({
      ...prevData,
      [field]: newValue,
    }));
  };

  const handleChangesCountry = (event, newValue) => {
    setData1((prevData) => ({
      ...prevData,
      countryName: newValue,
    }));
  };

  // for owner field
  const [ownerName, setOwnerName] = useState([]);

  const handleChange = (event, newValue, stringValue) => {
    if (stringValue === "investment") {
      setData((prevData) => ({
        ...prevData,
        investment: {
          ...prevData.investment,
          investment: newValue,
        },
      }));
    }

    if (stringValue === "owner") {
      // Convert ownerName array to a single string

      const comingValue =
        typeof newValue === "string" ? newValue.split(",") : newValue;
      const ownerString = comingValue.join(", ");

      setData((prevData) => ({
        ...prevData,
        investment: {
          ...prevData.investment,
          owner: ownerString,
        },
      }));

      // Update the ownerName state afterwards
      setOwnerName(newValue);
    }
  };

  // Define an array to store user names
  const ownerNames = [];

  // Get primaryUser data
  const primaryUserDetails = getUser();
  const primaryUserName =
    primaryUserDetails.firstName + " " + primaryUserDetails.lastName;

  // Get secondaryUser data
  const secondaryUserDetails = getSecondaryUser();
  let secondaryUserName = "";

  // Check if secondary user exists
  if (secondaryUserDetails !== undefined) {
    secondaryUserName =
      secondaryUserDetails.firstName + " " + secondaryUserDetails.lastName;

    // Push both user names into the array
    ownerNames.push(primaryUserName, secondaryUserName);
    ownerNames.push("Other");
  } else {
    // If secondary user doesn't exist, only push primary user name
    ownerNames.push(primaryUserName);
    ownerNames.push("Other");
  }

  // use state to set the selected images
  const [selectedImage, setSelectedImage] = useState([]);

  const handleChanges = (e, field) => {
    const newValue = e.target.value;
    setData((prevData) => ({
      ...prevData,
      investment: {
        ...prevData.investment,
        [field]: newValue,
      },
    }));
    setEstimatedTotalAmount(data.investment.totalAmount);
  };

  // Handle image
  const handleImageChange = (event) => {
    const selectedFiles = event.target.files;
    const allowedExtensions = ["pdf"];

    if (selectedFiles) {
      const selectedFilesArray = Array.from(selectedFiles);

      // Check each file's extension
      const invalidFiles = selectedFilesArray.filter((file) => {
        const fileNameParts = file.name.split(".");
        const fileExtension =
          fileNameParts[fileNameParts.length - 1].toLowerCase();
        return !allowedExtensions.includes(fileExtension);
      });

      if (invalidFiles.length === 0) {
        const formData = new FormData();

        selectedFilesArray.forEach((file, index) => {
          formData.append(`file${index + 1}`, file);
        });

        // Set the selected image state with the array of files
        setSelectedImage(selectedFilesArray);

        // Now, you can use the formData object to send files to the API
        // Example:
        // apiCall(formData);
      } else {
        // Handle invalid file extensions
        const invalidExtensions = invalidFiles
          .map((file) => file.name.split(".").pop())
          .join(", ");
        toast.error(
          `Invalid file extensions: ${invalidExtensions}. Please select valid document formats.`,
          {
            position: toast.POSITION.BOTTOM_CENTER,
          }
        );
        event.target.value = ""; // Clear the input field
      }
    }
  };
  // auto matic clear the data
  const resetForm = () => {
    setIsTextFieldClicked(false);
    setData({
      investment: {
        investment: "",
        nameOfTheInvestment: "",
        investmentCaption: "",
        notes: "",
        totalAmount: "",
      },
      sharedDetails: [
        {
          distributedType: "",
          distributedValue: "",
          distributedAmount: "",
          beneficiaryId: "",
        },
      ],
    });
  };

  // Set the form
  const investmentForm = (event) => {
    event.preventDefault();

    let token = "Bearer " + getToken();

    if (
      data.investment.investment === "" ||
      data.investment.totalAmount === "" ||
      data.investment.nameOfTheInvestment === ""
    ) {
      toast.error("Please Fill All required field Then Submit .", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
      return;
    }

    //create form data to send a file and remaining class data
    const formData = new FormData();

    for (let i = 0; i < selectedImage.length; i++) {
      formData.append(`files`, selectedImage[i]);
    }
    formData.append("asset", JSON.stringify(data));
    formData.append("data", JSON.stringify(data1));

    addInternationalAsset(formData, token)
      .then((resp) => {
        toast.success("Data Added !!", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
        toggle();
      })
      .catch((error) => {});
  };

  // beneficiary addition in form
  const [beneficiary, setBenificiary] = useState([]);
  const getBenificiarydata = () => {
    let userId = getUser().id;

    let token = "Bearer " + getToken();
    getBeneficiary(token, userId)
      .then((res) => {
        setBenificiary(res);
      })
      .catch((err) => {});
  };

  const [isTextFieldClicked, setIsTextFieldClicked] = useState(false);

  //
  const [benevisible, setbeneVisible] = useState(false);
  const [distributionType, setDistributionType] = useState("");
  const [selectedBeneficiaries, setSelectedBeneficiaries] = useState([]);
  const [beneficiaryDetails, setBeneficiaryDetails] = useState({});
  const [estimatedTotalAmount, setEstimatedTotalAmount] = useState(0);
  const [beneficiaryVisible, setBeneficiaryVisible] = useState(false);

  const handleShowBeneficiary = () => {
    setbeneVisible(true);

    setEstimatedTotalAmount(data.investment.totalAmount);

    data.sharedDetails = [];
  };

  const handleReset = () => {
    setbeneVisible(false);
    setDistributionType("");
    setSelectedBeneficiaries([]);
    setBeneficiaryDetails({});
  };

  const handleDistributionTypeChange = (event) => {
    const newType = event.target.value;
    const resetDetails = {};
    Object.keys(beneficiaryDetails).forEach((beneficiary) => {
      resetDetails[beneficiary] = { percentage: "", value: "" };
    });
    setDistributionType(newType);
    setBeneficiaryDetails(resetDetails);
  };

  const handleBeneficiarySelection = (event) => {
    const selectedBeneficiary = event.target.value;
    if (!selectedBeneficiaries.includes(selectedBeneficiary)) {
      setSelectedBeneficiaries([...selectedBeneficiaries, selectedBeneficiary]);
      setBeneficiaryDetails({
        ...beneficiaryDetails,
        [selectedBeneficiary]: { percentage: "", value: "" },
      });
    }
  };

  const handleBeneficiaryClose = (beneficiary) => {
    const updatedBeneficiaries = selectedBeneficiaries.filter(
      (b) => b !== beneficiary
    );
    setSelectedBeneficiaries(updatedBeneficiaries);
    const updatedDetails = { ...beneficiaryDetails };
    delete updatedDetails[beneficiary];
    setBeneficiaryDetails(updatedDetails);
  };

  const getBenificiaryName = (id) => {
    var foundBenificiary = null;
    if (id.beneficiary === undefined) {
      foundBenificiary = beneficiary.find((b) => b.id === parseInt(id));
    } else {
      foundBenificiary = beneficiary.find(
        (b) => b.id === parseInt(id.beneficiary)
      );
    }

    if (foundBenificiary) {
      return `${foundBenificiary.firstName} ${foundBenificiary.lastName}`;
    } else {
      return "Benificiary not found"; // Or handle the case where beneficiary with the given ID isn't found
    }
  };

  const handleFieldChange = (beneficiary, field, value) => {
    if (!estimatedTotalAmount || parseFloat(estimatedTotalAmount) === 0) {
      toast.error(
        "Please provide a valid estimated value before adding percentages!"
      );
      return;
    }
    let updatedDetails = { ...beneficiaryDetails };
    if (/^\d*$/.test(value)) {
      updatedDetails = {
        ...updatedDetails,
        [beneficiary]: {
          ...updatedDetails[beneficiary],
          [field]: value,
        },
      };
    }
    let totalPercentage = 0;
    let totalValue = 0;
    Object.keys(updatedDetails).forEach((key) => {
      const beneficiaryPercentage = parseFloat(updatedDetails[key]?.percentage);
      const beneficiaryValue = parseFloat(updatedDetails[key]?.value);
      if (!isNaN(beneficiaryPercentage) && beneficiaryPercentage >= 0) {
        totalPercentage += beneficiaryPercentage;
      }
      if (!isNaN(beneficiaryValue) && beneficiaryValue >= 0) {
        totalValue += beneficiaryValue;
      }
    });
    if (
      totalPercentage > 100 ||
      totalValue > parseFloat(estimatedTotalAmount)
    ) {
      updatedDetails[beneficiary][field] = "";
      toast.error(
        "Total percentage exceeds 100% or total value exceeds estimated value!"
      );
    } else {
      setBeneficiaryDetails(updatedDetails);
    }
  };

  const calculateDistributedAmount = (
    distributionType,
    balance,
    beneficiaryDetail
  ) => {
    if (distributionType === "percentage") {
      return (
        (parseFloat(balance) * parseFloat(beneficiaryDetail?.percentage || 0)) /
        100
      ).toFixed(2);
    } else if (distributionType === "dollar") {
      return parseFloat(beneficiaryDetail?.value || 0).toFixed(2);
    }
    return "0.00";
  };

  const handleSave = () => {
    toast.success("Saved successfully!");
    setbeneVisible(false);
    var i = 0;
    Object.keys(beneficiaryDetails).forEach((key) => {
      const beneficiaryPercentage = parseFloat(
        beneficiaryDetails[key]?.percentage
      );
      const beneficiaryValue = parseFloat(beneficiaryDetails[key]?.value);

      if (!isNaN(beneficiaryPercentage) && beneficiaryPercentage >= 0) {
        const distributedAmount = calculateDistributedAmount(
          "percentage",
          estimatedTotalAmount,
          beneficiaryDetails[key]
        );
        handleChanges2(
          "percentage",
          beneficiaryPercentage,
          distributedAmount,
          parseInt(key),
          i
        );
        i += 1;
      }

      if (!isNaN(beneficiaryValue) && beneficiaryValue >= 0) {
        const distributedAmount = calculateDistributedAmount(
          "dollar",
          estimatedTotalAmount,
          beneficiaryDetails[key]
        );
        handleChanges2(
          "dollar",
          beneficiaryValue,
          distributedAmount,
          parseInt(key),
          i
        );
        i += 1;
      }
    });
  };

  const handleChanges2 = (t, v, a, b, i) => {
    const updatedSharedDetails = [...data.sharedDetails]; // Create a copy of the sharedDetails array

    // Check if the index i is within the bounds of the array
    // if (i >= 0 && i < updatedSharedDetails.length) {
    // Create a new object to update the specific element at index i
    const updatedElement = {
      ...updatedSharedDetails[i], // Copy existing properties
      distributedType: t,
      distributedValue: v,
      distributedAmount: a,
      beneficiaryId: b,
    };

    updatedSharedDetails[i] = updatedElement; // Update the element at index i

    setData((prevState) => ({
      ...prevState,
      sharedDetails: updatedSharedDetails, // Update the sharedDetails in the state
    }));
    data.sharedDetails[i] = updatedSharedDetails[i];
  };

  useEffect(() => {
    getBenificiarydata();
  }, []);

  return (
    <>
      {form1 && (
        <>
          <div className="fade-in-element">
            <UserBase>
              <div className="mt-5">
                <SideBar>
                  <div
                    className="overlay1"
                    style={{ transition: "500ms", height: "" }}
                  >
                    <div className="addform_ichest">
                      <div className="addform_main">
                        <div className="addform_heading">
                          <h3 className="addform_heading_h1">Add Investment</h3>
                          <div className="addform_close" onClick={toggle}>
                            <FontAwesomeIcon icon={faXmark} />
                          </div>
                        </div>
                        <div className="addform_body">
                          <Form
                            onSubmit={investmentForm}
                            className="addform_body"
                          >
                            <div className="addform_select_body">
                              <div className="addform_body_left">
                                <div className="address_input">
                                  <div className="mt-2 addform_input_half">
                                    <div>
                                      <Tooltip title="Enter Caption for your assets">
                                        <div>
                                          <FormLabel>Assets Caption</FormLabel>
                                          <Input
                                            className="input_mui_joy"
                                            placeholder="Enter asset caption"
                                            onChange={(e) =>
                                              handleChangesData(
                                                e,
                                                "assetCaption"
                                              )
                                            }
                                            value={data1.assetCaption}
                                          />
                                        </div>
                                      </Tooltip>
                                    </div>
                                  </div>

                                  <div className="mt-2 addform_input_half">
                                    <div>
                                      <Tooltip title="Enter the Country of your assets">
                                        <div>
                                          <FormLabel required>
                                            Country Name
                                          </FormLabel>

                                          <Select
                                            placeholder="Select country name"
                                            onChange={(e, newValue) =>
                                              handleChangesCountry(e, newValue)
                                            }
                                            value={data1.countryName}
                                            required
                                          >
                                            {Country.getAllCountries().map(
                                              (v, index) => {
                                                return (
                                                  <Option
                                                    key={index}
                                                    value={v.name}
                                                  >
                                                    {v.name}
                                                  </Option>
                                                );
                                              }
                                            )}
                                          </Select>
                                        </div>
                                      </Tooltip>
                                    </div>
                                  </div>
                                </div>
                                <div>
                                  <Tooltip
                                    title="Select Owner"
                                    className="mt-2"
                                  >
                                    <div>
                                      <FormLabel required>
                                        Select Owner
                                      </FormLabel>

                                      <Select
                                        placeholder="Select Owner"
                                        value={ownerName}
                                        multiple
                                        onChange={(event, newValue) =>
                                          handleChange(event, newValue, "owner")
                                        }
                                        sx={{
                                          minWidth: "13rem",
                                        }}
                                        slotProps={{
                                          listbox: {
                                            sx: {
                                              width: "100%",
                                            },
                                          },
                                        }}
                                        required
                                      >
                                        {ownerNames.map((name) => (
                                          <Option key={name} value={name}>
                                            {name}
                                          </Option>
                                        ))}
                                      </Select>
                                    </div>
                                  </Tooltip>
                                </div>

                                <div>
                                  <Tooltip title="Enter Heading for Investment">
                                    <div className="mt-2">
                                      <FormLabel>Investment Heading</FormLabel>
                                      <Input
                                        className="input_mui_joy"
                                        placeholder="Enter investment heading"
                                        value={
                                          data.investment.investmentCaption
                                        }
                                        onChange={(e) =>
                                          handleChanges(e, "investmentCaption")
                                        }
                                      />
                                    </div>
                                  </Tooltip>
                                </div>

                                <div>
                                  <Tooltip title="Select Investment Type">
                                    <div className="mt-2">
                                      <FormLabel required>Investment</FormLabel>
                                      <Select
                                        value={data.investment.investment}
                                        onChange={(event, newValue) =>
                                          handleChange(
                                            event,
                                            newValue,
                                            "investment"
                                          )
                                        }
                                        required
                                      >
                                        <Option value="">
                                          Select Your Type Of investment
                                        </Option>
                                        <Option value="Stocks">Stocks</Option>
                                        <Option value="Mutual Funds">
                                          Mutual Funds
                                        </Option>
                                        <Option value="Bonds">Bonds</Option>
                                        <Option value="401K">401K</Option>
                                        <Option value="Exchange-Traded Funds (ETFs)">
                                          Exchange-Traded Funds (ETFs)
                                        </Option>
                                        <Option value="Certificates of Deposit (CDs)">
                                          Certificates of Deposit (CDs)
                                        </Option>
                                        <Option value="Money Market Funds">
                                          Money Market Funds
                                        </Option>
                                        <Option value="Alternative Investments">
                                          Alternative Investments
                                        </Option>
                                      </Select>
                                    </div>
                                  </Tooltip>
                                </div>

                                <div>
                                  <Tooltip title="Enter the name of Exchange">
                                    <div className="mt-2">
                                      <FormLabel>Name Of Exchange</FormLabel>
                                      <Input
                                        className="input_mui_joy"
                                        placeholder="Enter name of Exchange"
                                        onChange={(e) =>
                                          handleChanges(
                                            e,
                                            "nameOfTheInvestment"
                                          )
                                        }
                                        value={
                                          data.investment.nameOfTheInvestment
                                        }
                                      />
                                    </div>
                                  </Tooltip>
                                </div>

                                <div>
                                  <Tooltip title="Enter Your Estimated Amount ">
                                    <div className="mt-2">
                                      <FormLabel required>
                                        Estimated Amount
                                      </FormLabel>
                                      <Input
                                        placeholder="Estimated Investment Amount"
                                        required
                                        type="number"
                                        onChange={(e) => {
                                          handleChanges(e, "totalAmount");
                                        }}
                                        value={data.investment.totalAmount}
                                        onClick={() => {
                                          setIsTextFieldClicked(true);
                                        }}
                                        startDecorator={
                                          isTextFieldClicked ? (
                                            <div>$</div>
                                          ) : null
                                        }
                                      />
                                    </div>
                                  </Tooltip>
                                </div>
                              </div>

                              <div className="addform_body_right_investment">
                                <div>
                                  <Tooltip title="Add your banks related file">
                                    <div className="mt-2">
                                      <FormLabel>Supporting Document</FormLabel>
                                      <input
                                        style={{
                                          border: "solid 1px lightgray",
                                          borderLeft: "none",
                                          width: "100%",
                                          borderRadius: "5px",
                                        }}
                                        type="file"
                                        name="myfile"
                                        id="exampleFile"
                                        onChange={(e) =>
                                          handleImageChange(e, 5)
                                        }
                                        accept=".pdf"
                                        multiple
                                      />
                                    </div>
                                  </Tooltip>
                                </div>
                                <div
                                  className="bene-select mt-3"
                                  onClick={handleShowBeneficiary}
                                  style={{ cursor: "pointer" }}
                                >
                                  Select Your Beneficiary
                                </div>

                                <div className="address_input">
                                  <div
                                    style={{ width: "100%" }}
                                    className="mt-2"
                                  >
                                    <Tooltip title="Enter notes for your real estate">
                                      <div>
                                        <FormLabel>Notes</FormLabel>
                                        <Textarea
                                          sx={{
                                            height: "182px",
                                            minHeight: "52px",
                                          }}
                                          placeholder="Notes"
                                          id="notes"
                                          onChange={(e) =>
                                            handleChanges(e, "notes")
                                          }
                                          value={data.investment.notes}
                                        />
                                      </div>
                                    </Tooltip>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="text-center">
                              <Button
                                className="my-estate-clear-btn"
                                // onClick={resetForm}
                                type="reset"
                                outline
                              >
                                Clear
                              </Button>
                              <Button
                                outline
                                type=""
                                className="my-estate-add-btn"
                              >
                                Add
                              </Button>
                            </div>
                          </Form>
                        </div>
                      </div>
                    </div>
                  </div>
                </SideBar>
              </div>
            </UserBase>
          </div>
        </>
      )}

      {benevisible && (
        // beneShow &&
        <div className="popup">
          <div
            className="popup-content"
            style={{
              minWidth: "350px",
              width: "100%",
              maxWidth: "700px",
            }}
          >
            <div className="note_popup">
              <div className="note_popup_heading">
                <div className="share_property_heading">
                  <h2>Share Property </h2>
                </div>
                <div>
                  <button
                    className="note_popup_heading_close_btn"
                    onClick={handleReset}
                  >
                    <FontAwesomeIcon icon={faXmark} />
                  </button>
                </div>
              </div>
              <div className="share_property_est_value">
                <p>
                  Estimated Value:{" "}
                  <code style={{ color: "green", fontWeight: "bold" }}>
                    ${estimatedTotalAmount}
                  </code>
                </p>
              </div>
              <div className="BeneficiarySelect">
                <div className="BeneficiarySelectContainer">
                  <div className="BeneficiarySelectRow">
                    <div class="share_property_Type">
                      <p class="share_property_Type_paragraph">
                        Choose Distribution Type:{" "}
                      </p>
                      <select
                        value={distributionType}
                        onChange={handleDistributionTypeChange}
                        class="share_property_Type_select"
                      >
                        <option value="">Select Type</option>
                        <option value="percentage">Percentage</option>
                        <option value="dollar">Dollar</option>
                      </select>
                    </div>
                    <div className="SelectContainer">
                      <select
                        className="Property-inputfiled"
                        onChange={handleBeneficiarySelection}
                        value=""
                        disabled={!distributionType}
                      >
                        <option value="" disabled hidden>
                          {distributionType
                            ? "Select Your Beneficiary Username"
                            : "Select Type First"}
                        </option>
                        {beneficiary.map((benif) => (
                          <option
                            key={benif.id}
                            value={benif.id}
                            disabled={selectedBeneficiaries.includes(benif.id)}
                          >
                            {/* {benif.username} */}
                            {`${benif.firstName} ${benif.lastName}`}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="share_beneficiary_main_card">
                    {selectedBeneficiaries.map(
                      (beneficiary) => (
                        console.log("this is  beneficiary ", beneficiary),
                        (
                          <div key={beneficiary} class="share_beneficiary_card">
                            <div>
                              <p className="share_beneficiary_card_para">
                                Beneficiary:{" "}
                                {getBenificiaryName({ beneficiary })}
                              </p>
                              {distributionType === "percentage" && (
                                <input
                                  type="text"
                                  className="share_ben_percentage"
                                  placeholder="Percentage"
                                  value={
                                    beneficiaryDetails[beneficiary]
                                      ?.percentage || ""
                                  }
                                  onChange={(e) =>
                                    handleFieldChange(
                                      beneficiary,
                                      "percentage",
                                      e.target.value
                                    )
                                  }
                                />
                              )}
                              {distributionType === "dollar" && (
                                <input
                                  type="text"
                                  className="share_ben_percentage"
                                  placeholder="Dollar Value"
                                  value={
                                    beneficiaryDetails[beneficiary]?.value || ""
                                  }
                                  onChange={(e) =>
                                    handleFieldChange(
                                      beneficiary,
                                      "value",
                                      e.target.value
                                    )
                                  }
                                />
                              )}

                              {distributionType && (
                                <p className="share_beneficiary_card_para">
                                  Distributed Value: $
                                  {distributionType === "percentage"
                                    ? calculateDistributedAmount(
                                        "percentage",
                                        estimatedTotalAmount,
                                        beneficiaryDetails[beneficiary]
                                      )
                                    : calculateDistributedAmount(
                                        "dollar",
                                        estimatedTotalAmount,
                                        beneficiaryDetails[beneficiary]
                                      )}
                                </p>
                              )}
                            </div>
                            <div
                              className="share_beneficiary_card_close"
                              onClick={() =>
                                handleBeneficiaryClose(beneficiary)
                              }
                            >
                              <FontAwesomeIcon icon={faXmark} />
                            </div>
                          </div>
                        )
                      )
                    )}
                  </div>
                </div>
              </div>
            </div>
            {selectedBeneficiaries.length > 0 && (
              <button onClick={handleSave}>Save</button>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export function InternationalAssetCrypto() {
  const navigate = useNavigate();
  //  form show button
  let [form1, setForm1] = useState(true);

  const toggle = () => {
    setForm1(!form1);
    navigate("/user/my-estate/International_assets");
  };

  const [data, setData] = useState({
    cryptoAssest: {
      owner: "",

      cryptoCaption: "",
      coin: "",
      quantity: "",
      exchange: "",
      wallet: "",
      notes: "",
      estValue: "",
    },
    sharedDetails: [
      {
        distributedType: "",
        distributedValue: "",
        distributedAmount: "",
        beneficiaryId: "",
      },
    ],
  });

  const [data1, setData1] = useState({
    assetCaption: "",
    assetType: "crypto",
    countryName: "",
  });

  const handleChangesData = (e, field) => {
    const newValue = e.target.value;
    setData1((prevData) => ({
      ...prevData,
      [field]: newValue,
    }));
  };

  const handleChangesCountry = (event, newValue) => {
    setData1((prevData) => ({
      ...prevData,
      countryName: newValue,
    }));
  };

  // for owner field
  const [ownerName, setOwnerName] = useState([]);

  const handleChange = (event, newValue, stringValue) => {
    if (stringValue === "coin") {
      setData((prevData) => ({
        ...prevData,
        cryptoAssest: {
          ...prevData.cryptoAssest,
          coin: newValue,
        },
      }));
    }

    if (stringValue === "exchange") {
      setData((prevData) => ({
        ...prevData,
        cryptoAssest: {
          ...prevData.cryptoAssest,
          exchange: newValue,
        },
      }));
    }

    if (stringValue === "wallet") {
      setData((prevData) => ({
        ...prevData,
        cryptoAssest: {
          ...prevData.cryptoAssest,
          wallet: newValue,
        },
      }));
    }

    if (stringValue === "owner") {
      // Convert ownerName array to a single string

      const comingValue =
        typeof newValue === "string" ? newValue.split(",") : newValue;
      const ownerString = comingValue.join(", ");

      setData((prevData) => ({
        ...prevData,
        cryptoAssest: {
          ...prevData.cryptoAssest,
          owner: ownerString,
        },
      }));

      // Update the ownerName state afterwards
      setOwnerName(newValue);
    }
  };

  // Define an array to store user names
  const ownerNames = [];

  // Get primaryUser data
  const primaryUserDetails = getUser();
  const primaryUserName =
    primaryUserDetails.firstName + " " + primaryUserDetails.lastName;

  // Get secondaryUser data
  const secondaryUserDetails = getSecondaryUser();
  let secondaryUserName = "";

  // Check if secondary user exists
  if (secondaryUserDetails !== undefined) {
    secondaryUserName =
      secondaryUserDetails.firstName + " " + secondaryUserDetails.lastName;

    ownerNames.push("Other");
  } else {
    // If secondary user doesn't exist, only push primary user name
    ownerNames.push(primaryUserName);

    ownerNames.push("Other");
  }

  // use state to set the selected images
  const [selectedImage, setSelectedImage] = useState([]);
  // const [error, setError] = useState({
  //   errors: {},
  //   isError: false,
  // });

  const handleChanges = (e, field) => {
    const newValue = e.target.value;
    setData((prevData) => ({
      ...prevData,
      cryptoAssest: {
        ...prevData.cryptoAssest,
        [field]: newValue,
      },
    }));
    setEstimatedTotalAmount(data.cryptoAssest.estValue);
  };

  // Handle image
  const handleImageChange = (event) => {
    const selectedFiles = event.target.files;
    const allowedExtensions = ["pdf"];

    if (selectedFiles) {
      const selectedFilesArray = Array.from(selectedFiles);

      // Check each file's extension
      const invalidFiles = selectedFilesArray.filter((file) => {
        const fileNameParts = file.name.split(".");
        const fileExtension =
          fileNameParts[fileNameParts.length - 1].toLowerCase();
        return !allowedExtensions.includes(fileExtension);
      });

      if (invalidFiles.length === 0) {
        const formData = new FormData();

        selectedFilesArray.forEach((file, index) => {
          formData.append(`file${index + 1}`, file);
        });

        // Set the selected image state with the array of files
        setSelectedImage(selectedFilesArray);

        // Now, you can use the formData object to send files to the API
        // Example:
        // apiCall(formData);
      } else {
        // Handle invalid file extensions
        const invalidExtensions = invalidFiles
          .map((file) => file.name.split(".").pop())
          .join(", ");
        toast.error(
          `Invalid file extensions: ${invalidExtensions}. Please select valid document formats.`,
          {
            position: toast.POSITION.BOTTOM_CENTER,
          }
        );
        event.target.value = ""; // Clear the input field
      }
    }
  };

  const resetForm = () => {
    setIsTextFieldClicked(false);
    setData({
      cryptoAssest: {
        cryptoCaption: "",
        coin: "",
        quantity: "",
        exchange: "",
        wallet: "",
        notes: "",
        estValue: "",
      },
      sharedDetails: [
        {
          distributedType: "",
          distributedValue: "",
          distributedAmount: "",
          beneficiaryId: "",
        },
      ],
    });
  };

  // Set the form
  const cryptoForm = (event) => {
    event.preventDefault();
    let token = "Bearer " + getToken();

    if (
      data.cryptoAssest.coin === "" ||
      data.cryptoAssest.exchange === "" ||
      data.cryptoAssest.wallet === "" ||
      data.cryptoAssest.quantity === ""
    ) {
      toast.error("Please Fill All required field Then Submit .", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
      return;
    }
    //create form data to send a file and remaining class data
    const formData = new FormData();

    const { estimatedValue, ...formDataValues } = data;

    for (let i = 0; i < selectedImage.length; i++) {
      formData.append(`files`, selectedImage[i]);
    }
    formData.append("asset", JSON.stringify(data));
    formData.append("data", JSON.stringify(data1));

    addInternationalAsset(formData, token)
      .then((resp) => {
        toast.success("Data Added !!", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
        toggle();
      })
      .catch((error) => {});
  };

  // beneficiary addition in form
  const [beneficiary, setBenificiary] = useState([]);
  const getBenificiarydata = () => {
    let userId = getUser().id;

    let token = "Bearer " + getToken();
    getBeneficiary(token, userId)
      .then((res) => {
        setBenificiary(res);
      })
      .catch((err) => {});
  };
  useEffect(() => {
    getBenificiarydata();
  }, []);

  const [isTextFieldClicked, setIsTextFieldClicked] = useState(false);

  //
  const [benevisible, setbeneVisible] = useState(false);
  const [distributionType, setDistributionType] = useState("");
  const [selectedBeneficiaries, setSelectedBeneficiaries] = useState([]);
  const [beneficiaryDetails, setBeneficiaryDetails] = useState({});
  const [estimatedTotalAmount, setEstimatedTotalAmount] = useState(0);
  const [beneficiaryVisible, setBeneficiaryVisible] = useState(false);

  const handleShowBeneficiary = () => {
    setbeneVisible(true);
    setEstimatedTotalAmount(data.cryptoAssest.estValue);
    data.sharedDetails = [];
  };

  const handleReset = () => {
    setbeneVisible(false);
    setDistributionType("");
    setSelectedBeneficiaries([]);
    setBeneficiaryDetails({});
  };

  const handleDistributionTypeChange = (event) => {
    const newType = event.target.value;
    const resetDetails = {};
    Object.keys(beneficiaryDetails).forEach((beneficiary) => {
      resetDetails[beneficiary] = { percentage: "", value: "" };
    });
    setDistributionType(newType);
    setBeneficiaryDetails(resetDetails);
  };

  const handleBeneficiarySelection = (event) => {
    const selectedBeneficiary = event.target.value;
    if (!selectedBeneficiaries.includes(selectedBeneficiary)) {
      setSelectedBeneficiaries([...selectedBeneficiaries, selectedBeneficiary]);
      setBeneficiaryDetails({
        ...beneficiaryDetails,
        [selectedBeneficiary]: { percentage: "", value: "" },
      });
    }
  };

  const handleBeneficiaryClose = (beneficiary) => {
    const updatedBeneficiaries = selectedBeneficiaries.filter(
      (b) => b !== beneficiary
    );
    setSelectedBeneficiaries(updatedBeneficiaries);
    const updatedDetails = { ...beneficiaryDetails };
    delete updatedDetails[beneficiary];
    setBeneficiaryDetails(updatedDetails);
  };

  const getBenificiaryName = (id) => {
    var foundBenificiary = null;
    if (id.beneficiary === undefined) {
      foundBenificiary = beneficiary.find((b) => b.id === parseInt(id));
    } else {
      foundBenificiary = beneficiary.find(
        (b) => b.id === parseInt(id.beneficiary)
      );
    }

    if (foundBenificiary) {
      return `${foundBenificiary.firstName} ${foundBenificiary.lastName}`;
    } else {
      return "Benificiary not found"; // Or handle the case where beneficiary with the given ID isn't found
    }
  };

  const handleFieldChange = (beneficiary, field, value) => {
    if (!estimatedTotalAmount || parseFloat(estimatedTotalAmount) === 0) {
      toast.error(
        "Please provide a valid estimated value before adding percentages!"
      );
      return;
    }
    let updatedDetails = { ...beneficiaryDetails };
    if (/^\d*$/.test(value)) {
      updatedDetails = {
        ...updatedDetails,
        [beneficiary]: {
          ...updatedDetails[beneficiary],
          [field]: value,
        },
      };
    }
    let totalPercentage = 0;
    let totalValue = 0;
    Object.keys(updatedDetails).forEach((key) => {
      const beneficiaryPercentage = parseFloat(updatedDetails[key]?.percentage);
      const beneficiaryValue = parseFloat(updatedDetails[key]?.value);
      if (!isNaN(beneficiaryPercentage) && beneficiaryPercentage >= 0) {
        totalPercentage += beneficiaryPercentage;
      }
      if (!isNaN(beneficiaryValue) && beneficiaryValue >= 0) {
        totalValue += beneficiaryValue;
      }
    });
    if (
      totalPercentage > 100 ||
      totalValue > parseFloat(estimatedTotalAmount)
    ) {
      updatedDetails[beneficiary][field] = "";
      toast.error(
        "Total percentage exceeds 100% or total value exceeds estimated value!"
      );
    } else {
      setBeneficiaryDetails(updatedDetails);
    }
  };

  const calculateDistributedAmount = (
    distributionType,
    balance,
    beneficiaryDetail
  ) => {
    if (distributionType === "percentage") {
      return (
        (parseFloat(balance) * parseFloat(beneficiaryDetail?.percentage || 0)) /
        100
      ).toFixed(2);
    } else if (distributionType === "dollar") {
      return parseFloat(beneficiaryDetail?.value || 0).toFixed(2);
    }
    return "0.00";
  };

  const handleSave = () => {
    toast.success("Saved successfully!");
    setbeneVisible(false);
    var i = 0;
    Object.keys(beneficiaryDetails).forEach((key) => {
      const beneficiaryPercentage = parseFloat(
        beneficiaryDetails[key]?.percentage
      );
      const beneficiaryValue = parseFloat(beneficiaryDetails[key]?.value);

      if (!isNaN(beneficiaryPercentage) && beneficiaryPercentage >= 0) {
        const distributedAmount = calculateDistributedAmount(
          "percentage",
          estimatedTotalAmount,
          beneficiaryDetails[key]
        );
        handleChanges2(
          "percentage",
          beneficiaryPercentage,
          distributedAmount,
          parseInt(key),
          i
        );
        i += 1;
      }

      if (!isNaN(beneficiaryValue) && beneficiaryValue >= 0) {
        const distributedAmount = calculateDistributedAmount(
          "dollar",
          estimatedTotalAmount,
          beneficiaryDetails[key]
        );
        handleChanges2(
          "dollar",
          beneficiaryValue,
          distributedAmount,
          parseInt(key),
          i
        );
        i += 1;
      }
    });
  };

  const handleChanges2 = (t, v, a, b, i) => {
    const updatedSharedDetails = [...data.sharedDetails]; // Create a copy of the sharedDetails array

    // Check if the index i is within the bounds of the array
    // if (i >= 0 && i < updatedSharedDetails.length) {
    // Create a new object to update the specific element at index i
    const updatedElement = {
      ...updatedSharedDetails[i], // Copy existing properties
      distributedType: t,
      distributedValue: v,
      distributedAmount: a,
      beneficiaryId: b,
    };

    updatedSharedDetails[i] = updatedElement; // Update the element at index i

    setData((prevState) => ({
      ...prevState,
      sharedDetails: updatedSharedDetails, // Update the sharedDetails in the state
    }));
    data.sharedDetails[i] = updatedSharedDetails[i];
  };

  return (
    <>
      {form1 && (
        <>
          <div className="fade-in-element">
            <UserBase>
              <div className="mt-5">
                <SideBar>
                  <div
                    className="overlay1"
                    style={{ transition: "500ms", height: "" }}
                  >
                    <div className="addform_ichest">
                      <div className="addform_main">
                        <div className="addform_heading">
                          <h3 className="addform_heading_h1">
                            Add Bitcoin BTC Assets
                          </h3>
                          <div className="addform_close" onClick={toggle}>
                            <FontAwesomeIcon icon={faXmark} />
                          </div>
                        </div>
                        <div className="addform_body">
                          <Form onSubmit={cryptoForm} className="addform_body">
                            <div className="addform_select_body">
                              <div className="addform_body_left">
                                <div className="address_input">
                                  <div className="mt-2 addform_input_half">
                                    <div>
                                      <Tooltip title="Enter Caption for your assets">
                                        <div>
                                          <FormLabel>Assets Caption</FormLabel>
                                          <Input
                                            className="input_mui_joy"
                                            placeholder="Enter asset caption"
                                            onChange={(e) =>
                                              handleChangesData(
                                                e,
                                                "assetCaption"
                                              )
                                            }
                                            value={data1.assetCaption}
                                          />
                                        </div>
                                      </Tooltip>
                                    </div>
                                  </div>

                                  <div className="mt-2 addform_input_half">
                                    <div>
                                      <Tooltip title="Enter the Country of your assets">
                                        <div>
                                          <FormLabel required>
                                            Country Name
                                          </FormLabel>

                                          <Select
                                            placeholder="Select country name"
                                            onChange={(e, newValue) =>
                                              handleChangesCountry(e, newValue)
                                            }
                                            value={data1.countryName}
                                            required
                                          >
                                            {Country.getAllCountries().map(
                                              (v, index) => {
                                                return (
                                                  <Option
                                                    key={index}
                                                    value={v.name}
                                                  >
                                                    {v.name}
                                                  </Option>
                                                );
                                              }
                                            )}
                                          </Select>
                                        </div>
                                      </Tooltip>
                                    </div>
                                  </div>
                                </div>
                                <div>
                                  <Tooltip
                                    title="Select Owner"
                                    className="mt-2"
                                  >
                                    <div>
                                      <FormLabel required>
                                        Select Owner
                                      </FormLabel>

                                      <Select
                                        placeholder="Select Owner"
                                        value={ownerName}
                                        multiple
                                        onChange={(event, newValue) =>
                                          handleChange(event, newValue, "owner")
                                        }
                                        sx={{
                                          minWidth: "13rem",
                                        }}
                                        slotProps={{
                                          listbox: {
                                            sx: {
                                              width: "100%",
                                            },
                                          },
                                        }}
                                        required
                                      >
                                        {ownerNames.map((name) => (
                                          <Option key={name} value={name}>
                                            {name}
                                          </Option>
                                        ))}
                                      </Select>
                                    </div>
                                  </Tooltip>
                                </div>

                                <div>
                                  <Tooltip title="Enter Heading For Your Coin">
                                    <div className="mt-2">
                                      <FormLabel>BitCoin Heading</FormLabel>
                                      <Input
                                        className="input_mui_joy"
                                        placeholder="Enter bitcoin heading"
                                        value={data.cryptoAssest.cryptoCaption}
                                        onChange={(e) =>
                                          handleChanges(e, "cryptoCaption")
                                        }
                                      />
                                    </div>
                                  </Tooltip>
                                </div>

                                <div>
                                  <Tooltip title="Select Coin Type">
                                    <div className="mt-2">
                                      <FormLabel required>Coin Type</FormLabel>
                                      <Select
                                        value={data.cryptoAssest.coin}
                                        onChange={(event, newValue) =>
                                          handleChange(event, newValue, "coin")
                                        }
                                        required
                                      >
                                        <Option value="">
                                          Select Your Type Of coin
                                        </Option>
                                        <Option value="BitCoin">BitCoin</Option>
                                      </Select>
                                    </div>
                                  </Tooltip>
                                </div>

                                <div>
                                  <Tooltip title="Enter name of the exchange">
                                    <div className="mt-2">
                                      <FormLabel required>Exchange</FormLabel>
                                      <Select
                                        value={data.cryptoAssest.exchange}
                                        onChange={(event, newValue) =>
                                          handleChange(
                                            event,
                                            newValue,
                                            "exchange"
                                          )
                                        }
                                        required
                                      >
                                        <Option value="">
                                          Select Your Type Of exchange
                                        </Option>
                                        <Option value="Coinbase">
                                          Coinbase
                                        </Option>
                                        <Option value="Binance.US">
                                          Binance.US
                                        </Option>
                                        <Option value="Kraken">Kraken</Option>
                                        <Option value="Gemini">Gemini</Option>
                                        <Option value="Bitfinex">
                                          Bitfinex
                                        </Option>
                                        <Option value="Bitstamp">
                                          Bitstamp
                                        </Option>
                                        <Option value="Huobi US">
                                          Huobi US
                                        </Option>
                                        <Option value="Crypto.com">
                                          Crypto.com
                                        </Option>
                                        <Option value="BitFlyer">
                                          BitFlyer
                                        </Option>
                                        <Option value="OKCoin">OKCoin</Option>
                                      </Select>
                                    </div>
                                  </Tooltip>
                                </div>

                                <div>
                                  <Tooltip title="Enter name of the wallet">
                                    <div className="mt-2">
                                      <FormLabel required>Wallet</FormLabel>
                                      <Select
                                        value={data.cryptoAssest.wallet}
                                        onChange={(event, newValue) =>
                                          handleChange(
                                            event,
                                            newValue,
                                            "wallet"
                                          )
                                        }
                                        required
                                      >
                                        <Option value="">
                                          Select Your Type Of wallet
                                        </Option>
                                        <Option value="Ledger Nano S">
                                          Ledger Nano S
                                        </Option>
                                        <Option value="Trezor Model T">
                                          Trezor Model T
                                        </Option>
                                        <Option value="Exodus">Exodus</Option>
                                        <Option value="Atomic Wallet">
                                          Atomic Wallet
                                        </Option>
                                        <Option value="Trust Wallet">
                                          Trust Wallet
                                        </Option>
                                        <Option value="MyEtherWallet">
                                          MyEtherWallet
                                        </Option>
                                        <Option value="Coinomi">Coinomi</Option>
                                        <Option value="MetaMask">
                                          MetaMask
                                        </Option>
                                        <Option value="Freewallet">
                                          Freewallet
                                        </Option>
                                        <Option value="KeepKey">KeepKey</Option>
                                      </Select>
                                    </div>
                                  </Tooltip>
                                </div>
                              </div>

                              <div className="addform_body_right_crypto">
                                <div className="address_input">
                                  <div className="mt-2 addform_input_half">
                                    <div>
                                      <Tooltip title="Enter your coin quantity">
                                        <div>
                                          <FormLabel>Quantity</FormLabel>
                                          <Input
                                            className="input_mui_joy"
                                            placeholder="Enter quantity"
                                            onChange={(e) =>
                                              handleChanges(e, "quantity")
                                            }
                                            value={data.cryptoAssest.quantity}
                                          />
                                        </div>
                                      </Tooltip>
                                    </div>
                                  </div>

                                  <div className="mt-2 addform_input_half">
                                    <div>
                                      <Tooltip title="Enter Your Estimated Value ">
                                        <div>
                                          <FormLabel required>
                                            Estimated Value
                                          </FormLabel>
                                          <Input
                                            placeholder="Estimated Investment Value"
                                            required
                                            type="number"
                                            onChange={(e) => {
                                              handleChanges(e, "estValue");
                                            }}
                                            value={data.cryptoAssest.estValue}
                                            onClick={() => {
                                              setIsTextFieldClicked(true);
                                            }}
                                            startDecorator={
                                              isTextFieldClicked ? (
                                                <div>$</div>
                                              ) : null
                                            }
                                          />
                                        </div>
                                      </Tooltip>
                                    </div>
                                  </div>
                                </div>

                                <div>
                                  <Tooltip title="Add your banks related file">
                                    <div className="mt-2">
                                      <FormLabel>Supporting Document</FormLabel>
                                      <input
                                        style={{
                                          border: "solid 1px lightgray",
                                          borderLeft: "none",
                                          width: "100%",
                                          borderRadius: "5px",
                                        }}
                                        type="file"
                                        name="myfile"
                                        id="exampleFile"
                                        onChange={(e) =>
                                          handleImageChange(e, 5)
                                        }
                                        accept=".pdf"
                                        multiple
                                      />
                                    </div>
                                  </Tooltip>
                                </div>
                                <div
                                  className="bene-select mt-3"
                                  onClick={handleShowBeneficiary}
                                  style={{ cursor: "pointer" }}
                                >
                                  Select Your Beneficiary
                                </div>

                                <div className="address_input">
                                  <div
                                    style={{ width: "100%" }}
                                    className="mt-2"
                                  >
                                    <Tooltip title="Enter notes for your coin">
                                      <div>
                                        <FormLabel>Notes</FormLabel>
                                        <Textarea
                                          sx={{
                                            height: "182px",
                                            minHeight: "52px",
                                          }}
                                          placeholder="Notes"
                                          id="notes"
                                          onChange={(e) =>
                                            handleChanges(e, "notes")
                                          }
                                          value={data.cryptoAssest.notes}
                                        />
                                      </div>
                                    </Tooltip>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="text-center">
                              <Button
                                className="my-estate-clear-btn"
                                // onClick={resetForm}
                                type="reset"
                                outline
                              >
                                Clear
                              </Button>
                              <Button
                                outline
                                type=""
                                className="my-estate-add-btn"
                              >
                                Add
                              </Button>
                            </div>
                          </Form>
                        </div>
                      </div>
                    </div>
                  </div>
                </SideBar>
              </div>
            </UserBase>
          </div>
        </>
      )}

      {benevisible && (
        // beneShow &&
        <div className="popup">
          <div
            className="popup-content"
            style={{
              minWidth: "350px",
              width: "100%",
              maxWidth: "700px",
            }}
          >
            <div className="note_popup">
              <div className="note_popup_heading">
                <div className="share_property_heading">
                  <h2>Share Property </h2>
                </div>
                <div>
                  <button
                    className="note_popup_heading_close_btn"
                    onClick={handleReset}
                  >
                    <FontAwesomeIcon icon={faXmark} />
                  </button>
                </div>
              </div>
              <div className="share_property_est_value">
                <p>
                  Estimated Value:{" "}
                  <code style={{ color: "green", fontWeight: "bold" }}>
                    ${estimatedTotalAmount}
                  </code>
                </p>
              </div>
              <div className="BeneficiarySelect">
                <div className="BeneficiarySelectContainer">
                  <div className="BeneficiarySelectRow">
                    <div class="share_property_Type">
                      <p class="share_property_Type_paragraph">
                        Choose Distribution Type:{" "}
                      </p>
                      <select
                        value={distributionType}
                        onChange={handleDistributionTypeChange}
                        class="share_property_Type_select"
                      >
                        <option value="">Select Type</option>
                        <option value="percentage">Percentage</option>
                        <option value="dollar">Dollar</option>
                      </select>
                    </div>
                    <div className="SelectContainer">
                      <select
                        className="Property-inputfiled"
                        onChange={handleBeneficiarySelection}
                        value=""
                        disabled={!distributionType}
                      >
                        <option value="" disabled hidden>
                          {distributionType
                            ? "Select Your Beneficiary Username"
                            : "Select Type First"}
                        </option>
                        {beneficiary.map((benif) => (
                          <option
                            key={benif.id}
                            value={benif.id}
                            disabled={selectedBeneficiaries.includes(benif.id)}
                          >
                            {/* {benif.username} */}
                            {`${benif.firstName} ${benif.lastName}`}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="share_beneficiary_main_card">
                    {selectedBeneficiaries.map(
                      (beneficiary) => (
                        console.log("this is  beneficiary ", beneficiary),
                        (
                          <div key={beneficiary} class="share_beneficiary_card">
                            <div>
                              <p className="share_beneficiary_card_para">
                                Beneficiary:{" "}
                                {getBenificiaryName({ beneficiary })}
                              </p>
                              {distributionType === "percentage" && (
                                <input
                                  type="text"
                                  className="share_ben_percentage"
                                  placeholder="Percentage"
                                  value={
                                    beneficiaryDetails[beneficiary]
                                      ?.percentage || ""
                                  }
                                  onChange={(e) =>
                                    handleFieldChange(
                                      beneficiary,
                                      "percentage",
                                      e.target.value
                                    )
                                  }
                                />
                              )}
                              {distributionType === "dollar" && (
                                <input
                                  type="text"
                                  className="share_ben_percentage"
                                  placeholder="Dollar Value"
                                  value={
                                    beneficiaryDetails[beneficiary]?.value || ""
                                  }
                                  onChange={(e) =>
                                    handleFieldChange(
                                      beneficiary,
                                      "value",
                                      e.target.value
                                    )
                                  }
                                />
                              )}

                              {distributionType && (
                                <p className="share_beneficiary_card_para">
                                  Distributed Value: $
                                  {distributionType === "percentage"
                                    ? calculateDistributedAmount(
                                        "percentage",
                                        estimatedTotalAmount,
                                        beneficiaryDetails[beneficiary]
                                      )
                                    : calculateDistributedAmount(
                                        "dollar",
                                        estimatedTotalAmount,
                                        beneficiaryDetails[beneficiary]
                                      )}
                                </p>
                              )}
                            </div>
                            <div
                              className="share_beneficiary_card_close"
                              onClick={() =>
                                handleBeneficiaryClose(beneficiary)
                              }
                            >
                              <FontAwesomeIcon icon={faXmark} />
                            </div>
                          </div>
                        )
                      )
                    )}
                  </div>
                </div>
              </div>
            </div>
            {selectedBeneficiaries.length > 0 && (
              <button onClick={handleSave}>Save</button>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export function InternationalAssetJewelry() {
  const navigate = useNavigate();
  //  form show button
  let [form1, setForm1] = useState(true);

  const toggle = () => {
    setForm1(!form1);
    navigate("/user/my-estate/International_assets");
  };

  const [data, setData] = useState({
    jewelry: {
      owner: "",

      jewelryCaption: "",
      jewelryName: "",
      otherJewelryName: "",
      estimatedValue: "",
      caratValue: "",
      weight: "",
      notes: "",
    },
    sharedDetails: [
      {
        distributedType: "",
        distributedValue: "",
        distributedAmount: "",
        beneficiaryId: "",
      },
    ],
  });

  const [data1, setData1] = useState({
    assetCaption: "",
    assetType: "jewelry",
    countryName: "",
  });

  const handleChangesData = (e, field) => {
    const newValue = e.target.value;
    setData1((prevData) => ({
      ...prevData,
      [field]: newValue,
    }));
  };

  const handleChangesCountry = (event, newValue) => {
    setData1((prevData) => ({
      ...prevData,
      countryName: newValue,
    }));
  };

  // for owner field
  const [ownerName, setOwnerName] = useState([]);
  const handleChange = (event, newValue, stringValue) => {
    if (stringValue === "caratValue") {
      setData((prevData) => ({
        ...prevData,
        jewelry: {
          ...prevData.jewelry,
          caratValue: newValue,
        },
      }));
    }

    if (stringValue === "jewelryName") {
      setData((prevData) => ({
        ...prevData,
        jewelry: {
          ...prevData.jewelry,
          jewelryName: newValue,
        },
      }));
    }

    if (stringValue === "owner") {
      // Convert ownerName array to a single string

      const comingValue =
        typeof newValue === "string" ? newValue.split(",") : newValue;
      const ownerString = comingValue.join(", ");

      setData((prevData) => ({
        ...prevData,
        jewelry: {
          ...prevData.jewelry,
          owner: ownerString,
        },
      }));

      // Update the ownerName state afterwards
      setOwnerName(newValue);
    }
  };

  // Define an array to store user names
  const ownerNames = [];

  // Get primaryUser data
  const primaryUserDetails = getUser();
  const primaryUserName =
    primaryUserDetails.firstName + " " + primaryUserDetails.lastName;

  // Get secondaryUser data
  const secondaryUserDetails = getSecondaryUser();
  let secondaryUserName = "";

  // Check if secondary user exists
  if (secondaryUserDetails !== undefined) {
    secondaryUserName =
      secondaryUserDetails.firstName + " " + secondaryUserDetails.lastName;

    // Push both user names into the array
    ownerNames.push(primaryUserName, secondaryUserName);
    ownerNames.push("Other");
  } else {
    // If secondary user doesn't exist, only push primary user name
    ownerNames.push(primaryUserName);
    ownerNames.push("Other");
  }

  // use state to set the selected images
  const [selectedImage, setSelectedImage] = useState([]);
  const [selectedImage1, setSelectedImage1] = useState([]);

  const handleChanges = (e, field) => {
    const newValue = e.target.value;
    setData((prevData) => ({
      ...prevData,
      jewelry: {
        ...prevData.jewelry,
        [field]: newValue,
      },
    }));
    setEstimatedTotalAmount(data.jewelry.estimatedValue);
  };

  // Handle image
  const handleImageChange = (event) => {
    const selectedFiles = event.target.files;
    const allowedExtensions = ["pdf"];

    if (selectedFiles) {
      const selectedFilesArray = Array.from(selectedFiles);

      // Check each file's extension
      const invalidFiles = selectedFilesArray.filter((file) => {
        const fileNameParts = file.name.split(".");
        const fileExtension =
          fileNameParts[fileNameParts.length - 1].toLowerCase();
        return !allowedExtensions.includes(fileExtension);
      });

      if (invalidFiles.length === 0) {
        const formData = new FormData();

        selectedFilesArray.forEach((file, index) => {
          formData.append(`file${index + 1}`, file);
        });

        // Set the selected image state with the array of files
        setSelectedImage(selectedFilesArray);
      } else {
        // Handle invalid file extensions
        const invalidExtensions = invalidFiles
          .map((file) => file.name.split(".").pop())
          .join(", ");
        toast.error(
          `Invalid file extensions: ${invalidExtensions}. Please select valid document formats.`,
          {
            position: toast.POSITION.BOTTOM_CENTER,
          }
        );
        event.target.value = ""; // Clear the input field
      }
    }
  };

  const handleImageChange1 = (event) => {
    const selectedFiles = event.target.files;
    const allowedExtensions = ["jpg", "jpeg", "png", "gif"];

    if (selectedFiles) {
      const selectedFilesArray = Array.from(selectedFiles);

      // Check each file's extension
      const invalidFiles = selectedFilesArray.filter((file) => {
        const fileNameParts = file.name.split(".");
        const fileExtension =
          fileNameParts[fileNameParts.length - 1].toLowerCase();
        return !allowedExtensions.includes(fileExtension);
      });

      if (invalidFiles.length === 0) {
        const formData = new FormData();

        selectedFilesArray.forEach((file, index) => {
          formData.append(`imageFiles${index + 1}`, file);
        });

        // Set the selected image state with the array of files
        setSelectedImage1(selectedFilesArray);
      } else {
        // Handle invalid file extensions
        const invalidExtensions = invalidFiles
          .map((file) => file.name.split(".").pop())
          .join(", ");
        toast.error(
          `Invalid file extensions: ${invalidExtensions}. Please select valid image formats.`,
          {
            position: toast.POSITION.BOTTOM_CENTER,
          }
        );
        event.target.value = ""; // Clear the input field
      }
    }
  };

  const resetForm = () => {
    setIsTextFieldClicked(false);
    setData({
      jewelry: {
        owner: "",
        jewelryCaption: "",
        jewelryName: "",
        estimatedValue: "",
        caratValue: "",
        weight: "",
        notes: "",
      },
      sharedDetails: [
        {
          distributedType: "",
          distributedValue: "",
          distributedAmount: "",
          beneficiaryId: "",
        },
      ],
    });
  };

  // Set the form
  const jewelryForm = (event) => {
    event.preventDefault();
    let token = "Bearer " + getToken();

    if (
      data.details === "" ||
      data.estimatedValue === "" ||
      data.keratValue === ""
    ) {
      toast.error("Please Fill All required field Then Submit .", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
      return;
    }
    //create form data to send a file and remaining class data
    const formData = new FormData();

    for (let i = 0; i < selectedImage.length; i++) {
      formData.append(`files`, selectedImage[i]);
    }

    for (let i = 0; i < selectedImage1.length; i++) {
      formData.append(`imageFiles`, selectedImage1[i]);
    }

    formData.append("asset", JSON.stringify(data));
    formData.append("data", JSON.stringify(data1));

    // return null;
    addInternationalAsset(formData, token)
      .then((resp) => {
        toast.success("Data Added !!", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
        toggle();
      })
      .catch((error) => {});
  };

  // beneficiary addition in form
  const [beneficiary, setBenificiary] = useState([]);
  const getBenificiarydata = () => {
    let userId = getUser().id;

    let token = "Bearer " + getToken();
    getBeneficiary(token, userId)
      .then((res) => {
        setBenificiary(res);
      })
      .catch((err) => {});
  };
  useEffect(() => {
    getBenificiarydata();
  }, []);

  // for add field pop
  const [isTextFieldClicked, setIsTextFieldClicked] = useState(false);

  //
  const [benevisible, setbeneVisible] = useState(false);
  const [distributionType, setDistributionType] = useState("");
  const [selectedBeneficiaries, setSelectedBeneficiaries] = useState([]);
  const [beneficiaryDetails, setBeneficiaryDetails] = useState({});
  const [estimatedTotalAmount, setEstimatedTotalAmount] = useState(0);
  const [beneficiaryVisible, setBeneficiaryVisible] = useState(false);
  const [selectedBeneficiary, setSelectedBeneficiary] = useState("");

  const handleShowBeneficiary = () => {
    setbeneVisible(true);
    setEstimatedTotalAmount(data.jewelry.estimatedValue);
    data.sharedDetails = [];
  };

  const handleReset = () => {
    setbeneVisible(false);
    setDistributionType("");
    setSelectedBeneficiaries([]);
    setBeneficiaryDetails({});
  };

  const handleDistributionTypeChange = (event) => {
    const newType = event.target.value;
    const resetDetails = {};
    Object.keys(beneficiaryDetails).forEach((beneficiary) => {
      resetDetails[beneficiary] = { percentage: "", value: "" };
    });
    setDistributionType(newType);
    setBeneficiaryDetails(resetDetails);
  };

  const handleBeneficiarySelection = (event) => {
    const selectedBeneficiary = event.target.value;
    if (!selectedBeneficiaries.includes(selectedBeneficiary)) {
      setSelectedBeneficiaries([...selectedBeneficiaries, selectedBeneficiary]);
      setBeneficiaryDetails({
        ...beneficiaryDetails,
        [selectedBeneficiary]: { percentage: "", value: "" },
      });
    }
  };

  const handleBeneficiaryClose = (beneficiary) => {
    const updatedBeneficiaries = selectedBeneficiaries.filter(
      (b) => b !== beneficiary
    );
    setSelectedBeneficiaries(updatedBeneficiaries);
    const updatedDetails = { ...beneficiaryDetails };
    delete updatedDetails[beneficiary];
    setBeneficiaryDetails(updatedDetails);
  };

  const getBenificiaryName = (id) => {
    var foundBenificiary = null;
    if (id.beneficiary === undefined) {
      foundBenificiary = beneficiary.find((b) => b.id === parseInt(id));
    } else {
      foundBenificiary = beneficiary.find(
        (b) => b.id === parseInt(id.beneficiary)
      );
    }

    if (foundBenificiary) {
      return `${foundBenificiary.firstName} ${foundBenificiary.lastName}`;
    } else {
      return "Benificiary not found"; // Or handle the case where beneficiary with the given ID isn't found
    }
  };

  const handleFieldChange = (beneficiary, field, value) => {
    if (!estimatedTotalAmount || parseFloat(estimatedTotalAmount) === 0) {
      toast.error(
        "Please provide a valid estimated value before adding percentages!"
      );
      return;
    }
    let updatedDetails = { ...beneficiaryDetails };
    if (/^\d*$/.test(value)) {
      updatedDetails = {
        ...updatedDetails,
        [beneficiary]: {
          ...updatedDetails[beneficiary],
          [field]: value,
        },
      };
    }
    let totalPercentage = 0;
    let totalValue = 0;
    Object.keys(updatedDetails).forEach((key) => {
      const beneficiaryPercentage = parseFloat(updatedDetails[key]?.percentage);
      const beneficiaryValue = parseFloat(updatedDetails[key]?.value);
      if (!isNaN(beneficiaryPercentage) && beneficiaryPercentage >= 0) {
        totalPercentage += beneficiaryPercentage;
      }
      if (!isNaN(beneficiaryValue) && beneficiaryValue >= 0) {
        totalValue += beneficiaryValue;
      }
    });
    if (
      totalPercentage > 100 ||
      totalValue > parseFloat(estimatedTotalAmount)
    ) {
      updatedDetails[beneficiary][field] = "";
      toast.error(
        "Total percentage exceeds 100% or total value exceeds estimated value!"
      );
    } else {
      setBeneficiaryDetails(updatedDetails);
    }
  };

  const calculateDistributedAmount = (
    distributionType,
    balance,
    beneficiaryDetail
  ) => {
    if (distributionType === "percentage") {
      return (
        (parseFloat(balance) * parseFloat(beneficiaryDetail?.percentage || 0)) /
        100
      ).toFixed(2);
    } else if (distributionType === "dollar") {
      return parseFloat(beneficiaryDetail?.value || 0).toFixed(2);
    }
    return "0.00";
  };

  const handleSave = () => {
    toast.success("Saved successfully!");
    setbeneVisible(false);
    var i = 0;
    Object.keys(beneficiaryDetails).forEach((key) => {
      const beneficiaryPercentage = parseFloat(
        beneficiaryDetails[key]?.percentage
      );
      const beneficiaryValue = parseFloat(beneficiaryDetails[key]?.value);

      if (!isNaN(beneficiaryPercentage) && beneficiaryPercentage >= 0) {
        const distributedAmount = calculateDistributedAmount(
          "percentage",
          estimatedTotalAmount,
          beneficiaryDetails[key]
        );
        handleChanges2(
          "percentage",
          beneficiaryPercentage,
          distributedAmount,
          parseInt(key),
          i
        );
        i += 1;
      }

      if (!isNaN(beneficiaryValue) && beneficiaryValue >= 0) {
        const distributedAmount = calculateDistributedAmount(
          "dollar",
          estimatedTotalAmount,
          beneficiaryDetails[key]
        );
        handleChanges2(
          "dollar",
          beneficiaryValue,
          distributedAmount,
          parseInt(key),
          i
        );
        i += 1;
      }
    });
  };

  const handleChanges2 = (t, v, a, b, i) => {
    const updatedSharedDetails = [...data.sharedDetails]; // Create a copy of the sharedDetails array

    // Check if the index i is within the bounds of the array
    // if (i >= 0 && i < updatedSharedDetails.length) {
    // Create a new object to update the specific element at index i
    const updatedElement = {
      ...updatedSharedDetails[i], // Copy existing properties
      distributedType: t,
      distributedValue: v,
      distributedAmount: a,
      beneficiaryId: b,
    };

    updatedSharedDetails[i] = updatedElement; // Update the element at index i

    setData((prevState) => ({
      ...prevState,
      sharedDetails: updatedSharedDetails, // Update the sharedDetails in the state
    }));
    data.sharedDetails[i] = updatedSharedDetails[i];
  };

  return (
    <>
      {form1 && (
        <>
          <div className="fade-in-element">
            <UserBase>
              <div className="mt-5">
                <SideBar>
                  <div
                    className="overlay1"
                    style={{ transition: "500ms", height: "" }}
                  >
                    <div className="addform_ichest">
                      <div className="addform_main">
                        <div className="addform_heading">
                          <h3 className="addform_heading_h1">Add Jewelry</h3>
                          <div className="addform_close" onClick={toggle}>
                            <FontAwesomeIcon icon={faXmark} />
                          </div>
                        </div>
                        <div className="addform_body">
                          <Form onSubmit={jewelryForm} className="addform_body">
                            <div className="addform_select_body">
                              <div className="addform_body_left">
                                <div className="address_input">
                                  <div className="mt-2 addform_input_half">
                                    <div>
                                      <Tooltip title="Enter Caption for your assets">
                                        <div>
                                          <FormLabel>Assets Caption</FormLabel>
                                          <Input
                                            className="input_mui_joy"
                                            placeholder="Enter asset caption"
                                            onChange={(e) =>
                                              handleChangesData(
                                                e,
                                                "assetCaption"
                                              )
                                            }
                                            value={data1.assetCaption}
                                          />
                                        </div>
                                      </Tooltip>
                                    </div>
                                  </div>

                                  <div className="mt-2 addform_input_half">
                                    <div>
                                      <Tooltip title="Enter the Country of your assets">
                                        <div>
                                          <FormLabel required>
                                            Country Name
                                          </FormLabel>

                                          <Select
                                            placeholder="Select country name"
                                            onChange={(e, newValue) =>
                                              handleChangesCountry(e, newValue)
                                            }
                                            value={data1.countryName}
                                            required
                                          >
                                            {Country.getAllCountries().map(
                                              (v, index) => {
                                                return (
                                                  <Option
                                                    key={index}
                                                    value={v.name}
                                                  >
                                                    {v.name}
                                                  </Option>
                                                );
                                              }
                                            )}
                                          </Select>
                                        </div>
                                      </Tooltip>
                                    </div>
                                  </div>
                                </div>
                                <div>
                                  <Tooltip
                                    title="Select Owner"
                                    className="mt-2"
                                  >
                                    <div>
                                      <FormLabel required>
                                        Select Owner
                                      </FormLabel>

                                      <Select
                                        placeholder="Select Owner"
                                        value={ownerName}
                                        multiple
                                        onChange={(event, newValue) =>
                                          handleChange(event, newValue, "owner")
                                        }
                                        sx={{
                                          minWidth: "13rem",
                                        }}
                                        slotProps={{
                                          listbox: {
                                            sx: {
                                              width: "100%",
                                            },
                                          },
                                        }}
                                        required
                                      >
                                        {ownerNames.map((name) => (
                                          <Option key={name} value={name}>
                                            {name}
                                          </Option>
                                        ))}
                                      </Select>
                                    </div>
                                  </Tooltip>
                                </div>

                                <div>
                                  <Tooltip title="Enter type of the jewelry">
                                    <div className="mt-2">
                                      <FormLabel required>
                                        Jewelry Type
                                      </FormLabel>
                                      <Select
                                        value={data.jewelry.jewelryName}
                                        onChange={(event, newValue) =>
                                          handleChange(
                                            event,
                                            newValue,
                                            "jewelryName"
                                          )
                                        }
                                        required
                                      >
                                        <Option value="">
                                          Select Your Type Of jewelry
                                        </Option>
                                        <Option value="Gold">Gold</Option>
                                        <Option value="Silver">Silver</Option>
                                        <Option value="Platinum">
                                          Platinum
                                        </Option>
                                        <Option value="Palladium">
                                          Palladium
                                        </Option>
                                        <Option value="Stone">Stone</Option>
                                        <Option value="Other">Other</Option>
                                      </Select>
                                    </div>
                                  </Tooltip>
                                </div>

                                {data.jewelry.jewelryName === "Other" && (
                                  <div className="mt-2">
                                    <Tooltip title="Enter Your Other Jewelry Type ">
                                      <div>
                                        <FormLabel>
                                          Other Jewelry Type
                                        </FormLabel>
                                        <Input
                                          className="input_mui_joy"
                                          placeholder="Enter Other otherJewelryName"
                                          id="otherprop"
                                          onChange={(e) =>
                                            handleChanges(e, "otherJewelryName")
                                          }
                                          value={data.jewelry.otherJewelryName}
                                        />
                                      </div>
                                    </Tooltip>
                                  </div>
                                )}

                                <div>
                                  <Tooltip title="Enter your jewelry name">
                                    <div className="mt-2">
                                      <FormLabel>Jewelry Name</FormLabel>
                                      <Input
                                        className="input_mui_joy"
                                        placeholder="Enter jewelry name"
                                        onChange={(e) =>
                                          handleChanges(e, "jewelryCaption")
                                        }
                                        value={data.jewelry.jewelryCaption}
                                      />
                                    </div>
                                  </Tooltip>
                                </div>

                                <div>
                                  <Tooltip title="Select Carats Value of jewelry">
                                    <div className="mt-2">
                                      <FormLabel required>
                                        Select Carat Value
                                      </FormLabel>
                                      <Select
                                        value={data.jewelry.caratValue}
                                        onChange={(event, newValue) =>
                                          handleChange(
                                            event,
                                            newValue,
                                            "caratValue"
                                          )
                                        }
                                        required
                                      >
                                        <Option value="">
                                          Select Your Type Of Carat
                                        </Option>
                                        <Option value="24k">24k</Option>
                                        <Option value="22k">22k</Option>
                                        <Option value="21k">21k</Option>
                                        <Option value="20k">20k</Option>
                                        <Option value="18k">18k</Option>
                                        <Option value="16k">16k</Option>
                                        <Option value="14k">14k</Option>
                                        <Option value="10k">10k</Option>
                                      </Select>
                                    </div>
                                  </Tooltip>
                                </div>

                                <div className="address_input">
                                  <div className="mt-2 addform_input_half">
                                    <div>
                                      <Tooltip title="Enter the weight of Jewelry (gram)">
                                        <div>
                                          <FormLabel>Weight</FormLabel>
                                          <Input
                                            type="number"
                                            className="input_mui_joy"
                                            placeholder="Enter weight of jewelry"
                                            onChange={(e) =>
                                              handleChanges(e, "weight")
                                            }
                                            value={data.jewelry.weight}
                                          />
                                        </div>
                                      </Tooltip>
                                    </div>
                                  </div>

                                  <div className="mt-2 addform_input_half">
                                    <div>
                                      <Tooltip title="Enter Appraised Value of the Jewelry">
                                        <div>
                                          <FormLabel required>
                                            Appraised Value
                                          </FormLabel>
                                          <Input
                                            placeholder="Appraised Jewelry Value"
                                            required
                                            type="number"
                                            onChange={(e) => {
                                              handleChanges(
                                                e,
                                                "estimatedValue"
                                              );
                                            }}
                                            value={data.jewelry.estimatedValue}
                                            onClick={() => {
                                              setIsTextFieldClicked(true);
                                            }}
                                            startDecorator={
                                              isTextFieldClicked ? (
                                                <div>$</div>
                                              ) : null
                                            }
                                          />
                                        </div>
                                      </Tooltip>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="addform_body_right_jewelry">
                                <div>
                                  <Tooltip title="Add your banks related file">
                                    <div className="mt-2">
                                      <FormLabel>Jewelry Images</FormLabel>
                                      <input
                                        style={{
                                          border: "solid 1px lightgray",
                                          borderLeft: "none",
                                          width: "100%",
                                          borderRadius: "5px",
                                        }}
                                        type="file"
                                        name="images"
                                        id="imageFiles"
                                        onChange={(e) => handleImageChange1(e)}
                                        multiple
                                        accept="image/*"
                                      />
                                    </div>
                                  </Tooltip>
                                </div>

                                <div>
                                  <Tooltip title="Add your banks related file">
                                    <div className="mt-2">
                                      <FormLabel>Supporting Document</FormLabel>
                                      <input
                                        style={{
                                          border: "solid 1px lightgray",
                                          borderLeft: "none",
                                          width: "100%",
                                          borderRadius: "5px",
                                        }}
                                        type="file"
                                        name="myfile"
                                        id="exampleFile"
                                        onChange={(e) =>
                                          handleImageChange(e, 5)
                                        }
                                        accept=".pdf"
                                        multiple
                                      />
                                    </div>
                                  </Tooltip>
                                </div>
                                <div
                                  className="bene-select mt-3"
                                  onClick={handleShowBeneficiary}
                                  style={{ cursor: "pointer" }}
                                >
                                  Select Your Beneficiary
                                </div>

                                <div className="address_input">
                                  <div
                                    style={{ width: "100%" }}
                                    className="mt-2"
                                  >
                                    <Tooltip title="Enter notes for your jewelry">
                                      <div>
                                        <FormLabel>Notes</FormLabel>
                                        <Textarea
                                          sx={{
                                            height: "182px",
                                            minHeight: "52px",
                                          }}
                                          placeholder="Notes"
                                          id="notes"
                                          onChange={(e) =>
                                            handleChanges(e, "notes")
                                          }
                                          value={data.jewelry.notes}
                                        />
                                      </div>
                                    </Tooltip>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="text-center">
                              <Button
                                className="my-estate-clear-btn"
                                // onClick={resetForm}
                                type="reset"
                                outline
                              >
                                Clear
                              </Button>
                              <Button
                                outline
                                type=""
                                className="my-estate-add-btn"
                              >
                                Add
                              </Button>
                            </div>
                          </Form>
                        </div>
                      </div>
                    </div>
                  </div>
                </SideBar>
              </div>
            </UserBase>
          </div>
        </>
      )}

      {benevisible && (
        // beneShow &&
        <div className="popup">
          <div
            className="popup-content"
            style={{
              minWidth: "350px",
              width: "100%",
              maxWidth: "700px",
            }}
          >
            <div className="note_popup">
              <div className="note_popup_heading">
                <div className="share_property_heading">
                  <h2>Share Property </h2>
                </div>
                <div>
                  <button
                    className="note_popup_heading_close_btn"
                    onClick={handleReset}
                  >
                    <FontAwesomeIcon icon={faXmark} />
                  </button>
                </div>
              </div>
              <div className="share_property_est_value">
                <p>
                  Estimated Value:{" "}
                  <code style={{ color: "green", fontWeight: "bold" }}>
                    ${estimatedTotalAmount}
                  </code>
                </p>
              </div>
              <div className="BeneficiarySelect">
                <div className="BeneficiarySelectContainer">
                  <div className="BeneficiarySelectRow">
                    <div class="share_property_Type">
                      <p class="share_property_Type_paragraph">
                        Choose Distribution Type:{" "}
                      </p>
                      <select
                        value={distributionType}
                        onChange={handleDistributionTypeChange}
                        class="share_property_Type_select"
                      >
                        <option value="">Select Type</option>
                        <option value="percentage">Percentage</option>
                        <option value="dollar">Dollar</option>
                      </select>
                    </div>
                    <div className="SelectContainer">
                      <select
                        className="Property-inputfiled"
                        onChange={handleBeneficiarySelection}
                        value=""
                        disabled={!distributionType}
                      >
                        <option value="" disabled hidden>
                          {distributionType
                            ? "Select Your Beneficiary Username"
                            : "Select Type First"}
                        </option>
                        {beneficiary.map((benif) => (
                          <option
                            key={benif.id}
                            value={benif.id}
                            disabled={selectedBeneficiaries.includes(benif.id)}
                          >
                            {/* {benif.username} */}
                            {`${benif.firstName} ${benif.lastName}`}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="share_beneficiary_main_card">
                    {selectedBeneficiaries.map(
                      (beneficiary) => (
                        console.log("this is  beneficiary ", beneficiary),
                        (
                          <div key={beneficiary} class="share_beneficiary_card">
                            <div>
                              <p className="share_beneficiary_card_para">
                                Beneficiary:{" "}
                                {getBenificiaryName({ beneficiary })}
                              </p>
                              {distributionType === "percentage" && (
                                <input
                                  type="text"
                                  className="share_ben_percentage"
                                  placeholder="Percentage"
                                  value={
                                    beneficiaryDetails[beneficiary]
                                      ?.percentage || ""
                                  }
                                  onChange={(e) =>
                                    handleFieldChange(
                                      beneficiary,
                                      "percentage",
                                      e.target.value
                                    )
                                  }
                                />
                              )}
                              {distributionType === "dollar" && (
                                <input
                                  type="text"
                                  className="share_ben_percentage"
                                  placeholder="Dollar Value"
                                  value={
                                    beneficiaryDetails[beneficiary]?.value || ""
                                  }
                                  onChange={(e) =>
                                    handleFieldChange(
                                      beneficiary,
                                      "value",
                                      e.target.value
                                    )
                                  }
                                />
                              )}

                              {distributionType && (
                                <p className="share_beneficiary_card_para">
                                  Distributed Value: $
                                  {distributionType === "percentage"
                                    ? calculateDistributedAmount(
                                        "percentage",
                                        estimatedTotalAmount,
                                        beneficiaryDetails[beneficiary]
                                      )
                                    : calculateDistributedAmount(
                                        "dollar",
                                        estimatedTotalAmount,
                                        beneficiaryDetails[beneficiary]
                                      )}
                                </p>
                              )}
                            </div>
                            <div
                              className="share_beneficiary_card_close"
                              onClick={() =>
                                handleBeneficiaryClose(beneficiary)
                              }
                            >
                              <FontAwesomeIcon icon={faXmark} />
                            </div>
                          </div>
                        )
                      )
                    )}
                  </div>
                </div>
              </div>
            </div>
            {selectedBeneficiaries.length > 0 && (
              <button onClick={handleSave}>Save</button>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export function InternationalAssetInsurance() {
  const navigate = useNavigate();
  //  form show button
  let [form1, setForm1] = useState(true);

  const toggle = () => {
    setForm1(!form1);
    navigate("/user/my-estate/International_assets");
  };

  // set Add data
  const [data, setData] = useState({
    insurance: {
      owner: "",

      insuranceCaption: "",
      insuranceAmount: "",
      details: "",
      detailsOfpoint: "",
      notes: "",
    },
    sharedDetails: [
      {
        distributedType: "",
        distributedValue: "",
        distributedAmount: "",
        beneficiaryId: "",
      },
    ],
  });

  const [data1, setData1] = useState({
    assetCaption: "",
    assetType: "insurance",
    countryName: "",
  });

  const handleChangesData = (e, field) => {
    const newValue = e.target.value;
    setData1((prevData) => ({
      ...prevData,
      [field]: newValue,
    }));
  };

  const handleChangesCountry = (event, newValue) => {
    setData1((prevData) => ({
      ...prevData,
      countryName: newValue,
    }));
  };

  const [ownerName, setOwnerName] = useState([]);
  const handleChange = (event, newValue, stringValue) => {
    if (stringValue === "owner") {
      // Convert ownerName array to a single string

      const comingValue =
        typeof newValue === "string" ? newValue.split(",") : newValue;
      const ownerString = comingValue.join(", ");

      setData((prevData) => ({
        ...prevData,
        insurance: {
          ...prevData.insurance,
          owner: ownerString,
        },
      }));

      // Update the ownerName state afterwards
      setOwnerName(newValue);
    }
  };

  // Define an array to store user names
  const ownerNames = [];

  // Get primaryUser data
  const primaryUserDetails = getUser();
  const primaryUserName =
    primaryUserDetails.firstName + " " + primaryUserDetails.lastName;

  // Get secondaryUser data
  const secondaryUserDetails = getSecondaryUser();
  let secondaryUserName = "";

  // Check if secondary user exists
  if (secondaryUserDetails !== undefined) {
    secondaryUserName =
      secondaryUserDetails.firstName + " " + secondaryUserDetails.lastName;

    // Push both user names into the array
    ownerNames.push(primaryUserName, secondaryUserName, "other");
  } else {
    // If secondary user doesn't exist, only push primary user name
    ownerNames.push(primaryUserName, "other");
  }

  // use state to set the selected images
  const [selectedImage, setSelectedImage] = useState([]);

  // const [error, setError] = useState({
  //   errors: {},
  //   isError: false,
  // });

  const handleChanges = (e, field) => {
    const newValue = e.target.value;
    setData((prevData) => ({
      ...prevData,
      insurance: {
        ...prevData.insurance,
        [field]: newValue,
      },
    }));
  };

  // Handle image
  const handleImageChange = (event) => {
    const selectedFiles = event.target.files;
    const allowedExtensions = ["pdf"];

    if (selectedFiles) {
      const selectedFilesArray = Array.from(selectedFiles);

      // Check each file's extension
      const invalidFiles = selectedFilesArray.filter((file) => {
        const fileNameParts = file.name.split(".");
        const fileExtension =
          fileNameParts[fileNameParts.length - 1].toLowerCase();
        return !allowedExtensions.includes(fileExtension);
      });

      if (invalidFiles.length === 0) {
        const formData = new FormData();

        selectedFilesArray.forEach((file, index) => {
          formData.append(`file${index + 1}`, file);
        });

        // Set the selected image state with the array of files
        setSelectedImage(selectedFilesArray);

        // Now, you can use the formData object to send files to the API
        // Example:
        // apiCall(formData);
      } else {
        // Handle invalid file extensions
        const invalidExtensions = invalidFiles
          .map((file) => file.name.split(".").pop())
          .join(", ");
        toast.error(
          `Invalid file extensions: ${invalidExtensions}. Please select valid document formats.`,
          {
            position: toast.POSITION.BOTTOM_CENTER,
          }
        );
        event.target.value = ""; // Clear the input field
      }
    }
  };

  const resetForm = () => {
    setData({
      insurance: {
        insuranceCaption: "",
        insuranceAmount: "",
        details: "",
        detailsOfpoint: "",
        notes: "",
      },
      sharedDetails: [
        {
          distributedType: "",
          distributedValue: "",
          distributedAmount: "",
          beneficiaryId: "",
        },
      ],
    });
  };

  // Set the form
  const lifeForm = (event) => {
    event.preventDefault();
    if (
      data.details === "" ||
      // data.supportingDcument === "" ||
      data.detailsOfpoint === ""
    ) {
      toast.error("Please fill all required fields.");
      return;
    }

    let token = "Bearer " + getToken();

    //create form data to send a file and remaining class data
    const formData = new FormData();
    for (let i = 0; i < selectedImage.length; i++) {
      formData.append(`files`, selectedImage[i]);
    }
    formData.append("asset", JSON.stringify(data));
    formData.append("data", JSON.stringify(data1));

    addInternationalAsset(formData, token)
      .then((resp) => {
        toast.success("Data Added !!", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
        toggle();
      })
      .catch((error) => {});
  };

  // beneficiary addition in form
  const [beneficiary, setBenificiary] = useState([]);
  const getBenificiarydata = () => {
    let userId = getUser().id;

    let token = "Bearer " + getToken();
    getBeneficiary(token, userId)
      .then((res) => {
        setBenificiary(res);
      })
      .catch((err) => {});
  };
  useEffect(() => {
    getBenificiarydata();
  }, []);

  //
  // let [show1, setShow1] = useState(false);
  const [benevisible, setbeneVisible] = useState(false);
  const [distributionType, setDistributionType] = useState("");
  const [selectedBeneficiaries, setSelectedBeneficiaries] = useState([]);
  const [beneficiaryDetails, setBeneficiaryDetails] = useState({});
  const [estimatedTotalAmount, setEstimatedTotalAmount] = useState(0);
  const [beneficiaryVisible, setBeneficiaryVisible] = useState(false);
  // const [SelectedBeneficiary, setSelectedBeneficiary] = useState("");

  const handleShowBeneficiary = () => {
    setbeneVisible(true);
    setEstimatedTotalAmount(data.insurance.insuranceAmount);
    data.sharedDetails = [];
  };

  const handleReset = () => {
    setbeneVisible(false);
    setDistributionType("");
    setSelectedBeneficiaries([]);
    setBeneficiaryDetails({});
  };

  const handleDistributionTypeChange = (event) => {
    const newType = event.target.value;
    const resetDetails = {};
    Object.keys(beneficiaryDetails).forEach((beneficiary) => {
      resetDetails[beneficiary] = { percentage: "", value: "" };
    });
    setDistributionType(newType);
    setBeneficiaryDetails(resetDetails);
  };

  const handleBeneficiarySelection = (event) => {
    const selectedBeneficiary = event.target.value;
    if (!selectedBeneficiaries.includes(selectedBeneficiary)) {
      setSelectedBeneficiaries([...selectedBeneficiaries, selectedBeneficiary]);
      setBeneficiaryDetails({
        ...beneficiaryDetails,
        [selectedBeneficiary]: { percentage: "", value: "" },
      });
    }
  };

  const handleBeneficiaryClose = (beneficiary) => {
    const updatedBeneficiaries = selectedBeneficiaries.filter(
      (b) => b !== beneficiary
    );
    setSelectedBeneficiaries(updatedBeneficiaries);
    const updatedDetails = { ...beneficiaryDetails };
    delete updatedDetails[beneficiary];
    setBeneficiaryDetails(updatedDetails);
  };

  const getBenificiaryName = (id) => {
    var foundBenificiary = null;
    if (id.beneficiary === undefined) {
      foundBenificiary = beneficiary.find((b) => b.id === parseInt(id));
    } else {
      foundBenificiary = beneficiary.find(
        (b) => b.id === parseInt(id.beneficiary)
      );
    }

    if (foundBenificiary) {
      return `${foundBenificiary.firstName} ${foundBenificiary.lastName}`;
    } else {
      return "Benificiary not found"; // Or handle the case where beneficiary with the given ID isn't found
    }
  };

  const handleFieldChange = (beneficiary, field, value) => {
    if (!estimatedTotalAmount || parseFloat(estimatedTotalAmount) === 0) {
      toast.error(
        "Please provide a valid estimated value before adding percentages!"
      );
      return;
    }
    let updatedDetails = { ...beneficiaryDetails };
    if (/^\d*$/.test(value)) {
      updatedDetails = {
        ...updatedDetails,
        [beneficiary]: {
          ...updatedDetails[beneficiary],
          [field]: value,
        },
      };
    }
    let totalPercentage = 0;
    let totalValue = 0;
    Object.keys(updatedDetails).forEach((key) => {
      const beneficiaryPercentage = parseFloat(updatedDetails[key]?.percentage);
      const beneficiaryValue = parseFloat(updatedDetails[key]?.value);
      if (!isNaN(beneficiaryPercentage) && beneficiaryPercentage >= 0) {
        totalPercentage += beneficiaryPercentage;
      }
      if (!isNaN(beneficiaryValue) && beneficiaryValue >= 0) {
        totalValue += beneficiaryValue;
      }
    });
    if (
      totalPercentage > 100 ||
      totalValue > parseFloat(estimatedTotalAmount)
    ) {
      updatedDetails[beneficiary][field] = "";
      toast.error(
        "Total percentage exceeds 100% or total value exceeds estimated value!"
      );
    } else {
      setBeneficiaryDetails(updatedDetails);
    }
  };

  const calculateDistributedAmount = (
    distributionType,
    balance,
    beneficiaryDetail
  ) => {
    if (distributionType === "percentage") {
      return (
        (parseFloat(balance) * parseFloat(beneficiaryDetail?.percentage || 0)) /
        100
      ).toFixed(2);
    } else if (distributionType === "dollar") {
      return parseFloat(beneficiaryDetail?.value || 0).toFixed(2);
    }
    return "0.00";
  };

  const handleSave = () => {
    toast.success("Saved successfully!");
    setbeneVisible(false);
    var i = 0;
    Object.keys(beneficiaryDetails).forEach((key) => {
      const beneficiaryPercentage = parseFloat(
        beneficiaryDetails[key]?.percentage
      );
      const beneficiaryValue = parseFloat(beneficiaryDetails[key]?.value);

      if (!isNaN(beneficiaryPercentage) && beneficiaryPercentage >= 0) {
        const distributedAmount = calculateDistributedAmount(
          "percentage",
          estimatedTotalAmount,
          beneficiaryDetails[key]
        );
        handleChanges2(
          "percentage",
          beneficiaryPercentage,
          distributedAmount,
          parseInt(key),
          i
        );
        i += 1;
      }

      if (!isNaN(beneficiaryValue) && beneficiaryValue >= 0) {
        const distributedAmount = calculateDistributedAmount(
          "dollar",
          estimatedTotalAmount,
          beneficiaryDetails[key]
        );
        handleChanges2(
          "dollar",
          beneficiaryValue,
          distributedAmount,
          parseInt(key),
          i
        );
        i += 1;
      }
    });
  };

  const handleChanges2 = (t, v, a, b, i) => {
    const updatedSharedDetails = [...data.sharedDetails]; // Create a copy of the sharedDetails array

    // Check if the index i is within the bounds of the array
    // if (i >= 0 && i < updatedSharedDetails.length) {
    // Create a new object to update the specific element at index i
    const updatedElement = {
      ...updatedSharedDetails[i], // Copy existing properties
      distributedType: t,
      distributedValue: v,
      distributedAmount: a,
      beneficiaryId: b,
    };

    updatedSharedDetails[i] = updatedElement; // Update the element at index i

    setData((prevState) => ({
      ...prevState,
      sharedDetails: updatedSharedDetails, // Update the sharedDetails in the state
    }));
    data.sharedDetails[i] = updatedSharedDetails[i];
  };

  return (
    <>
      {form1 && (
        <>
          <div className="fade-in-element">
            <UserBase>
              <div className="mt-5">
                <SideBar>
                  <div className="overlay1" style={{ transition: "500ms" }}>
                    <div className="property_form">
                      <Container>
                        <Card color="" outline>
                          <CardHeader>
                            <h3 className="form1-heading">Life Insurance</h3>
                            <div className="Close" onClick={toggle}>
                              <FontAwesomeIcon icon={faXmark} />
                            </div>
                          </CardHeader>
                          <CardBody>
                            <Form onSubmit={lifeForm}>
                              <div className="mt-2 ">
                                <div>
                                  <Tooltip title="Enter Caption for your assets">
                                    <div>
                                      <FormLabel>Assets Caption</FormLabel>
                                      <Input
                                        className="input_mui_joy"
                                        placeholder="Enter asset caption"
                                        onChange={(e) =>
                                          handleChangesData(e, "assetCaption")
                                        }
                                        value={data1.assetCaption}
                                      />
                                    </div>
                                  </Tooltip>
                                </div>
                              </div>

                              <div className="mt-2">
                                <div>
                                  <Tooltip title="Enter the Country of your assets">
                                    <div>
                                      <FormLabel required>
                                        Country Name
                                      </FormLabel>

                                      <Select
                                        placeholder="Select country name"
                                        onChange={(e, newValue) =>
                                          handleChangesCountry(e, newValue)
                                        }
                                        value={data1.countryName}
                                        required
                                      >
                                        {Country.getAllCountries().map(
                                          (v, index) => {
                                            return (
                                              <Option
                                                key={index}
                                                value={v.name}
                                              >
                                                {v.name}
                                              </Option>
                                            );
                                          }
                                        )}
                                      </Select>
                                    </div>
                                  </Tooltip>
                                </div>
                              </div>

                              <div>
                                <Tooltip title="Select Owner">
                                  <div>
                                    <FormLabel required>Select Owner</FormLabel>

                                    <Select
                                      placeholder="Select Owner"
                                      value={ownerName}
                                      multiple
                                      onChange={(event, newValue) =>
                                        handleChange(event, newValue, "owner")
                                      }
                                      sx={{
                                        minWidth: "13rem",
                                      }}
                                      slotProps={{
                                        listbox: {
                                          sx: {
                                            width: "100%",
                                          },
                                        },
                                      }}
                                      required
                                    >
                                      {ownerNames.map((name) => (
                                        <Option key={name} value={name}>
                                          {name}
                                        </Option>
                                      ))}
                                    </Select>
                                  </div>
                                </Tooltip>
                              </div>

                              <div>
                                <Tooltip title="Enter Heading for Insurance">
                                  <div className="mt-2">
                                    <FormLabel>Insurance Heading</FormLabel>
                                    <Input
                                      className="input_mui_joy"
                                      placeholder="Enter insurance heading"
                                      onChange={(e) =>
                                        handleChanges(e, "insuranceCaption")
                                      }
                                      value={data.insurance.insuranceCaption}
                                    />
                                  </div>
                                </Tooltip>
                              </div>

                              <div>
                                <Tooltip title="Enter The Name Of Your Insurance ">
                                  <div className="mt-2">
                                    <FormLabel>Details</FormLabel>
                                    <Input
                                      className="input_mui_joy"
                                      placeholder="Enter details"
                                      onChange={(e) =>
                                        handleChanges(e, "details")
                                      }
                                      value={data.insurance.details}
                                    />
                                  </div>
                                </Tooltip>
                              </div>

                              <div>
                                <Tooltip title="Enter your Details of Point Contact Name">
                                  <div className="mt-2">
                                    <FormLabel>
                                      Details of Point Contact Name
                                    </FormLabel>
                                    <Input
                                      className="input_mui_joy"
                                      placeholder="Enter contact name"
                                      onChange={(e) =>
                                        handleChanges(e, "detailsOfpoint")
                                      }
                                      value={data.insurance.detailsOfpoint}
                                    />
                                  </div>
                                </Tooltip>
                              </div>

                              <div>
                                <Tooltip title="Enter your Insurance Amount">
                                  <div className="mt-2">
                                    <FormLabel>Insurance Amount</FormLabel>
                                    <Input
                                      className="input_mui_joy"
                                      placeholder="Enter insurance amount"
                                      onChange={(e) =>
                                        handleChanges(e, "insuranceAmount")
                                      }
                                      value={data.insurance.insuranceAmount}
                                      startDecorator={<div>$</div>}
                                      required
                                    />
                                  </div>
                                </Tooltip>
                              </div>

                              <div className="mt-3">
                                <Tooltip title="Add your insurance related file">
                                  <div>
                                    <label
                                      style={{
                                        display: "block",
                                        marginBottom: "5px",
                                      }}
                                    >
                                      Supporting Document<span></span>
                                    </label>
                                    <input
                                      style={{
                                        border: "solid 1px lightgray",
                                        borderLeft: "none",
                                        width: "100%",
                                        borderRadius: "5px",
                                      }}
                                      type="file"
                                      name="myfile"
                                      id="exampleFile"
                                      multiple
                                      accept=".pdf"
                                      onChange={(e) => handleImageChange(e)}
                                    />
                                  </div>
                                </Tooltip>
                              </div>

                              <div
                                className="bene-select mt-3"
                                onClick={handleShowBeneficiary}
                                style={{ cursor: "pointer" }}
                              >
                                Select Your Beneficiary
                              </div>

                              {/* <div className="mt-3">
                          <Tooltip title="Select The Type Of Property">
                            <FormControl
                              // required
                              fullWidth
                              sx={{ minWidth: 120 }}
                              size="small"
                            >
                              <InputLabel id="demo-simple-select-label">
                                Select Your Single Beneficiary
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="username"
                                label="Select Your Single Beneficiary"
                                onChange={(e) =>
                                  handleChanges(e, "beneficiary")
                                }
                                value={data.insurance.beneficiary}
                              >
                                {beneficiary.map((benif) => (
                                  <MenuItem
                                    key={benif.username}
                                    value={benif.username}
                                  >
                                    {`${benif.firstName} ${benif.lastName}`}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Tooltip>
                        </div> */}

                              <div className="address_input">
                                <div style={{ width: "100%" }} className="mt-2">
                                  <Tooltip title="Enter notes for your insurance">
                                    <div>
                                      <FormLabel>Notes</FormLabel>
                                      <Textarea
                                        sx={{
                                          height: "182px",
                                          minHeight: "52px",
                                        }}
                                        placeholder="Notes"
                                        id="notes"
                                        onChange={(e) =>
                                          handleChanges(e, "notes")
                                        }
                                        value={data.insurance.notes}
                                      />
                                    </div>
                                  </Tooltip>
                                </div>
                              </div>

                              <Container className="text-center">
                                <Button
                                  // onClick={resetForm}
                                  className="my-estate-clear-btn"
                                  type="reset"
                                  outline
                                >
                                  Clear
                                </Button>
                                <Button outline className="my-estate-add-btn">
                                  Add
                                </Button>
                              </Container>
                            </Form>
                          </CardBody>
                        </Card>
                      </Container>
                    </div>
                  </div>
                </SideBar>
              </div>
            </UserBase>
          </div>
        </>
      )}

      {benevisible && (
        // beneShow &&
        <div className="popup">
          <div
            className="popup-content popup-content-download"
            style={{
              minWidth: "350px",
              width: "100%",
              maxWidth: "700px",
            }}
          >
            <div className="note_popup">
              <div className="note_popup_heading">
                <div className="share_property_heading">
                  <h2>Share Property </h2>
                </div>
                <div>
                  <button
                    className="note_popup_heading_close_btn"
                    onClick={handleReset}
                  >
                    <FontAwesomeIcon icon={faXmark} />
                  </button>
                </div>
              </div>
              <div className="share_property_est_value">
                <p>
                  Estimated Value:{" "}
                  <code style={{ color: "green", fontWeight: "bold" }}>
                    ${estimatedTotalAmount}
                  </code>
                </p>
              </div>
              <div className="BeneficiarySelect">
                <div className="BeneficiarySelectContainer">
                  <div className="BeneficiarySelectRow">
                    <div class="share_property_Type">
                      <p class="share_property_Type_paragraph">
                        Choose Distribution Type:{" "}
                      </p>
                      <select
                        value={distributionType}
                        onChange={handleDistributionTypeChange}
                        class="share_property_Type_select"
                      >
                        <option value="">Select Type</option>
                        <option value="percentage">Percentage</option>
                        <option value="dollar">Dollar</option>
                      </select>
                    </div>
                    <div className="SelectContainer">
                      <select
                        className="Property-inputfiled"
                        onChange={handleBeneficiarySelection}
                        value=""
                        disabled={!distributionType}
                      >
                        <option value="" disabled hidden>
                          {distributionType
                            ? "Select Your Beneficiary Username"
                            : "Select Type First"}
                        </option>
                        {beneficiary.map((benif) => (
                          <option
                            key={benif.id}
                            value={benif.id}
                            disabled={selectedBeneficiaries.includes(benif.id)}
                          >
                            {/* {benif.username} */}
                            {`${benif.firstName} ${benif.lastName}`}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="share_beneficiary_main_card">
                    {selectedBeneficiaries.map(
                      (beneficiary) => (
                        console.log("this is  beneficiary ", beneficiary),
                        (
                          <div key={beneficiary} class="share_beneficiary_card">
                            <div>
                              <p className="share_beneficiary_card_para">
                                Beneficiary:{" "}
                                {getBenificiaryName({ beneficiary })}
                              </p>
                              {distributionType === "percentage" && (
                                <input
                                  type="text"
                                  className="share_ben_percentage"
                                  placeholder="Percentage"
                                  value={
                                    beneficiaryDetails[beneficiary]
                                      ?.percentage || ""
                                  }
                                  onChange={(e) =>
                                    handleFieldChange(
                                      beneficiary,
                                      "percentage",
                                      e.target.value
                                    )
                                  }
                                />
                              )}
                              {distributionType === "dollar" && (
                                <input
                                  type="text"
                                  className="share_ben_percentage"
                                  placeholder="Dollar Value"
                                  value={
                                    beneficiaryDetails[beneficiary]?.value || ""
                                  }
                                  onChange={(e) =>
                                    handleFieldChange(
                                      beneficiary,
                                      "value",
                                      e.target.value
                                    )
                                  }
                                />
                              )}

                              {distributionType && (
                                <p className="share_beneficiary_card_para">
                                  Distributed Value: $
                                  {distributionType === "percentage"
                                    ? calculateDistributedAmount(
                                        "percentage",
                                        estimatedTotalAmount,
                                        beneficiaryDetails[beneficiary]
                                      )
                                    : calculateDistributedAmount(
                                        "dollar",
                                        estimatedTotalAmount,
                                        beneficiaryDetails[beneficiary]
                                      )}
                                </p>
                              )}
                            </div>
                            <div
                              className="share_beneficiary_card_close"
                              onClick={() =>
                                handleBeneficiaryClose(beneficiary)
                              }
                            >
                              <FontAwesomeIcon icon={faXmark} />
                            </div>
                          </div>
                        )
                      )
                    )}
                  </div>
                </div>
              </div>
            </div>
            {selectedBeneficiaries.length > 0 && (
              <button onClick={handleSave}>Save</button>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export function InternationalAssetVehicles() {
  const navigate = useNavigate();
  //  form show button
  let [form1, setForm1] = useState(true);

  const toggle = () => {
    setForm1(!form1);
    navigate("/user/my-estate/International_assets");
  };

  const [data, setData] = useState({
    vehicle: {
      owner: "",

      vehicleCaption: "",
      vehicleType: "",
      year: "",
      loan: "",
      make: "",
      estValue: "",
      model: "",
      miles: "",
      notes: "",
    },
    sharedDetails: [
      {
        distributedType: "",
        distributedValue: "",
        distributedAmount: "",
        beneficiaryId: "",
      },
    ],
  });

  const [data1, setData1] = useState({
    assetCaption: "",
    assetType: "vehicle",
    countryName: "",
  });

  const handleChangesData = (e, field) => {
    const newValue = e.target.value;
    setData1((prevData) => ({
      ...prevData,
      [field]: newValue,
    }));
  };

  const handleChangesCountry = (event, newValue) => {
    setData1((prevData) => ({
      ...prevData,
      countryName: newValue,
    }));
  };

  // for owner field
  const [ownerName, setOwnerName] = useState([]);
  const handleChange = (event, newValue, stringValue) => {
    if (stringValue === "vehicleType") {
      setData((prevData) => ({
        ...prevData,
        vehicle: {
          ...prevData.vehicle,
          vehicleType: newValue,
        },
      }));
    }

    if (stringValue === "make") {
      setData((prevData) => ({
        ...prevData,
        vehicle: {
          ...prevData.vehicle,
          make: newValue,
        },
      }));
    }

    if (stringValue === "model") {
      setData((prevData) => ({
        ...prevData,
        vehicle: {
          ...prevData.vehicle,
          model: newValue,
        },
      }));
    }

    if (stringValue === "owner") {
      // Convert ownerName array to a single string

      const comingValue =
        typeof newValue === "string" ? newValue.split(",") : newValue;
      const ownerString = comingValue.join(", ");

      setData((prevData) => ({
        ...prevData,
        vehicle: {
          ...prevData.vehicle,
          owner: ownerString,
        },
      }));

      // Update the ownerName state afterwards
      setOwnerName(newValue);
    }
  };

  // Define an array to store user names
  const ownerNames = [];

  // Get primaryUser data
  const primaryUserDetails = getUser();
  const primaryUserName =
    primaryUserDetails.firstName + " " + primaryUserDetails.lastName;

  // Get secondaryUser data
  const secondaryUserDetails = getSecondaryUser();
  let secondaryUserName = "";

  // Check if secondary user exists
  if (secondaryUserDetails !== undefined) {
    secondaryUserName =
      secondaryUserDetails.firstName + " " + secondaryUserDetails.lastName;
    ownerNames.push("Other");
  } else {
    // If secondary user doesn't exist, only push primary user name
    ownerNames.push(primaryUserName);
    ownerNames.push("Other");
  }

  // use state to set the selected images
  const [selectedImage, setSelectedImage] = useState([]);

  const handleChanges = (e, field) => {
    const newValue = e.target.value;
    setData((prevData) => ({
      ...prevData,
      vehicle: {
        ...prevData.vehicle,
        [field]: newValue,
      },
    }));
    setEstimatedTotalAmount(data.vehicle.estValue);
  };
  // Handle image
  const handleImageChange = (event) => {
    const selectedFiles = event.target.files;
    const allowedExtensions = ["pdf"];

    if (selectedFiles) {
      const selectedFilesArray = Array.from(selectedFiles);

      // Check each file's extension
      const invalidFiles = selectedFilesArray.filter((file) => {
        const fileNameParts = file.name.split(".");
        const fileExtension =
          fileNameParts[fileNameParts.length - 1].toLowerCase();
        return !allowedExtensions.includes(fileExtension);
      });

      if (invalidFiles.length === 0) {
        const formData = new FormData();

        selectedFilesArray.forEach((file, index) => {
          formData.append(`file${index + 1}`, file);
        });

        // Set the selected image state with the array of files
        setSelectedImage(selectedFilesArray);

        // Now, you can use the formData object to send files to the API
        // Example:
        // apiCall(formData);
      } else {
        // Handle invalid file extensions
        const invalidExtensions = invalidFiles
          .map((file) => file.name.split(".").pop())
          .join(", ");
        toast.error(
          `Invalid file extensions: ${invalidExtensions}. Please select valid document formats.`,
          {
            position: toast.POSITION.BOTTOM_CENTER,
          }
        );
        event.target.value = ""; // Clear the input field
      }
    }
  };
  const resetForm = () => {
    setIsTextFieldClicked(false);
    setData({
      vehicle: {
        vehicleCaption: "",
        vehicleType: "",
        year: "",
        loan: "",
        make: "",
        estValue: "",
        model: "",
        miles: "",
        notes: "",
      },
      sharedDetails: [
        {
          distributedType: "",
          distributedValue: "",
          distributedAmount: "",
          beneficiaryId: "",
        },
      ],
    });
  };

  // Set the form
  const vehiclesForm = (event) => {
    event.preventDefault();
    if (
      data.vehicle.vehicleType === "" ||
      data.vehicle.year === "" ||
      data.vehicle.loan === "" ||
      data.vehicle.make === "" ||
      data.vehicle.miles === "" ||
      data.vehicle.model === "" ||
      data.vehicle.estValue === ""
    ) {
      toast.error("Please fill all required feilds.");
      return;
    }

    let token = "Bearer " + getToken();

    //create form data to send a file and remaining class data
    const formData = new FormData();

    for (let i = 0; i < selectedImage.length; i++) {
      formData.append(`files`, selectedImage[i]);
    }
    formData.append("asset", JSON.stringify(data));
    formData.append("data", JSON.stringify(data1));

    addInternationalAsset(formData, token)
      .then((resp) => {
        toast.success("Data Added !!", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
        toggle();
      })
      .catch((error) => {});
  };

  const handleDateChanges = (date, property) => {
    const adjustedDate = dayjs(date).format("YYYY-MM-DD");

    // setData({ ...data, [property]: adjustedDate });

    setData((prevData) => ({
      ...prevData,
      vehicle: {
        ...prevData.vehicle,
        year: adjustedDate,
      },
    }));
  };

  // beneficiary addition in form
  const [beneficiary, setBenificiary] = useState([]);
  const getBenificiarydata = () => {
    let userId = getUser().id;

    let token = "Bearer " + getToken();
    getBeneficiary(token, userId)
      .then((res) => {
        setBenificiary(res);
      })
      .catch((err) => {});
  };
  useEffect(() => {
    getBenificiarydata();
  }, []);

  const [isTextFieldClicked, setIsTextFieldClicked] = useState(false);

  //
  const [benevisible, setbeneVisible] = useState(false);
  const [distributionType, setDistributionType] = useState("");
  const [selectedBeneficiaries, setSelectedBeneficiaries] = useState([]);
  const [beneficiaryDetails, setBeneficiaryDetails] = useState({});
  const [estimatedTotalAmount, setEstimatedTotalAmount] = useState(0);
  const [beneficiaryVisible, setBeneficiaryVisible] = useState(false);

  const handleShowBeneficiary = () => {
    setbeneVisible(true);
    setEstimatedTotalAmount(data.vehicle.estValue);
    data.sharedDetails = [];
  };

  const handleReset = () => {
    setbeneVisible(false);
    setDistributionType("");
    setSelectedBeneficiaries([]);
    setBeneficiaryDetails({});
  };

  const handleDistributionTypeChange = (event) => {
    const newType = event.target.value;
    const resetDetails = {};
    Object.keys(beneficiaryDetails).forEach((beneficiary) => {
      resetDetails[beneficiary] = { percentage: "", value: "" };
    });
    setDistributionType(newType);
    setBeneficiaryDetails(resetDetails);
  };

  const handleBeneficiarySelection = (event) => {
    const selectedBeneficiary = event.target.value;
    if (!selectedBeneficiaries.includes(selectedBeneficiary)) {
      setSelectedBeneficiaries([...selectedBeneficiaries, selectedBeneficiary]);
      setBeneficiaryDetails({
        ...beneficiaryDetails,
        [selectedBeneficiary]: { percentage: "", value: "" },
      });
    }
  };

  const handleBeneficiaryClose = (beneficiary) => {
    const updatedBeneficiaries = selectedBeneficiaries.filter(
      (b) => b !== beneficiary
    );
    setSelectedBeneficiaries(updatedBeneficiaries);
    const updatedDetails = { ...beneficiaryDetails };
    delete updatedDetails[beneficiary];
    setBeneficiaryDetails(updatedDetails);
  };

  const getBenificiaryName = (id) => {
    var foundBenificiary = null;
    if (id.beneficiary === undefined) {
      foundBenificiary = beneficiary.find((b) => b.id === parseInt(id));
    } else {
      foundBenificiary = beneficiary.find(
        (b) => b.id === parseInt(id.beneficiary)
      );
    }

    if (foundBenificiary) {
      return `${foundBenificiary.firstName} ${foundBenificiary.lastName}`;
    } else {
      return "Benificiary not found"; // Or handle the case where beneficiary with the given ID isn't found
    }
  };

  const handleFieldChange = (beneficiary, field, value) => {
    if (!estimatedTotalAmount || parseFloat(estimatedTotalAmount) === 0) {
      toast.error(
        "Please provide a valid estimated value before adding percentages!"
      );
      return;
    }
    let updatedDetails = { ...beneficiaryDetails };
    if (/^\d*$/.test(value)) {
      updatedDetails = {
        ...updatedDetails,
        [beneficiary]: {
          ...updatedDetails[beneficiary],
          [field]: value,
        },
      };
    }
    let totalPercentage = 0;
    let totalValue = 0;
    Object.keys(updatedDetails).forEach((key) => {
      const beneficiaryPercentage = parseFloat(updatedDetails[key]?.percentage);
      const beneficiaryValue = parseFloat(updatedDetails[key]?.value);
      if (!isNaN(beneficiaryPercentage) && beneficiaryPercentage >= 0) {
        totalPercentage += beneficiaryPercentage;
      }
      if (!isNaN(beneficiaryValue) && beneficiaryValue >= 0) {
        totalValue += beneficiaryValue;
      }
    });
    if (
      totalPercentage > 100 ||
      totalValue > parseFloat(estimatedTotalAmount)
    ) {
      updatedDetails[beneficiary][field] = "";
      toast.error(
        "Total percentage exceeds 100% or total value exceeds estimated value!"
      );
    } else {
      setBeneficiaryDetails(updatedDetails);
    }
  };

  const calculateDistributedAmount = (
    distributionType,
    balance,
    beneficiaryDetail
  ) => {
    if (distributionType === "percentage") {
      return (
        (parseFloat(balance) * parseFloat(beneficiaryDetail?.percentage || 0)) /
        100
      ).toFixed(2);
    } else if (distributionType === "dollar") {
      return parseFloat(beneficiaryDetail?.value || 0).toFixed(2);
    }
    return "0.00";
  };

  const handleSave = () => {
    toast.success("Saved successfully!");
    setbeneVisible(false);
    var i = 0;
    Object.keys(beneficiaryDetails).forEach((key) => {
      const beneficiaryPercentage = parseFloat(
        beneficiaryDetails[key]?.percentage
      );
      const beneficiaryValue = parseFloat(beneficiaryDetails[key]?.value);

      if (!isNaN(beneficiaryPercentage) && beneficiaryPercentage >= 0) {
        const distributedAmount = calculateDistributedAmount(
          "percentage",
          estimatedTotalAmount,
          beneficiaryDetails[key]
        );
        handleChanges2(
          "percentage",
          beneficiaryPercentage,
          distributedAmount,
          parseInt(key),
          i
        );
        i += 1;
      }

      if (!isNaN(beneficiaryValue) && beneficiaryValue >= 0) {
        const distributedAmount = calculateDistributedAmount(
          "dollar",
          estimatedTotalAmount,
          beneficiaryDetails[key]
        );
        handleChanges2(
          "dollar",
          beneficiaryValue,
          distributedAmount,
          parseInt(key),
          i
        );
        i += 1;
      }
    });
  };

  const handleChanges2 = (t, v, a, b, i) => {
    const updatedSharedDetails = [...data.sharedDetails]; // Create a copy of the sharedDetails array

    // Check if the index i is within the bounds of the array
    // if (i >= 0 && i < updatedSharedDetails.length) {
    // Create a new object to update the specific element at index i
    const updatedElement = {
      ...updatedSharedDetails[i], // Copy existing properties
      distributedType: t,
      distributedValue: v,
      distributedAmount: a,
      beneficiaryId: b,
    };

    updatedSharedDetails[i] = updatedElement; // Update the element at index i

    setData((prevState) => ({
      ...prevState,
      sharedDetails: updatedSharedDetails, // Update the sharedDetails in the state
    }));
    data.sharedDetails[i] = updatedSharedDetails[i];
  };

  const handleOpenBeneficiary = (showDetail) => {
    setBeneficiaryVisible(true);
  };

  return (
    <>
      {form1 && (
        <>
          <div className="fade-in-element">
            <UserBase>
              <div className="mt-5">
                <SideBar>
                  <div
                    className="overlay1"
                    style={{ transition: "500ms", height: "" }}
                  >
                    <div className="addform_ichest">
                      <div className="addform_main">
                        <div className="addform_heading">
                          <h3 className="addform_heading_h1">Add Vehicle</h3>
                          <div className="addform_close" onClick={toggle}>
                            <FontAwesomeIcon icon={faXmark} />
                          </div>
                        </div>
                        <div className="addform_body">
                          <Form
                            onSubmit={vehiclesForm}
                            className="addform_body"
                          >
                            <div className="addform_select_body">
                              <div className="addform_body_left">
                                <div className="address_input">
                                  <div className="mt-2 addform_input_half">
                                    <div>
                                      <Tooltip title="Enter Caption for your assets">
                                        <div>
                                          <FormLabel>Assets Caption</FormLabel>
                                          <Input
                                            className="input_mui_joy"
                                            placeholder="Enter asset caption"
                                            onChange={(e) =>
                                              handleChangesData(
                                                e,
                                                "assetCaption"
                                              )
                                            }
                                            value={data1.assetCaption}
                                          />
                                        </div>
                                      </Tooltip>
                                    </div>
                                  </div>

                                  <div className="mt-2 addform_input_half">
                                    <div>
                                      <Tooltip title="Enter the Country of your assets">
                                        <div>
                                          <FormLabel required>
                                            Country Name
                                          </FormLabel>

                                          <Select
                                            placeholder="Select country name"
                                            onChange={(e, newValue) =>
                                              handleChangesCountry(e, newValue)
                                            }
                                            value={data1.countryName}
                                            required
                                          >
                                            {Country.getAllCountries().map(
                                              (v, index) => {
                                                return (
                                                  <Option
                                                    key={index}
                                                    value={v.name}
                                                  >
                                                    {v.name}
                                                  </Option>
                                                );
                                              }
                                            )}
                                          </Select>
                                        </div>
                                      </Tooltip>
                                    </div>
                                  </div>
                                </div>
                                <div>
                                  <Tooltip
                                    title="Select Owner"
                                    className="mt-2"
                                  >
                                    <div>
                                      <FormLabel required>
                                        Select Owner
                                      </FormLabel>

                                      <Select
                                        placeholder="Select Owner"
                                        value={ownerName}
                                        multiple
                                        onChange={(event, newValue) =>
                                          handleChange(event, newValue, "owner")
                                        }
                                        sx={{
                                          minWidth: "13rem",
                                        }}
                                        slotProps={{
                                          listbox: {
                                            sx: {
                                              width: "100%",
                                            },
                                          },
                                        }}
                                        required
                                      >
                                        {ownerNames.map((name) => (
                                          <Option key={name} value={name}>
                                            {name}
                                          </Option>
                                        ))}
                                      </Select>
                                    </div>
                                  </Tooltip>
                                </div>

                                <div>
                                  <Tooltip title="Enter your vehicle heading">
                                    <div className="mt-2">
                                      <FormLabel>Vehicle Heading</FormLabel>
                                      <Input
                                        className="input_mui_joy"
                                        placeholder="Enter vehicle heading"
                                        onChange={(e) =>
                                          handleChanges(e, "vehicleCaption")
                                        }
                                        value={data.vehicle.vehicleCaption}
                                      />
                                    </div>
                                  </Tooltip>
                                </div>

                                <div>
                                  <Tooltip title="Enter the type of your vehicle">
                                    <div className="mt-2">
                                      <FormLabel required>
                                        Vehicle Type
                                      </FormLabel>
                                      <Select
                                        value={data.vehicle.vehicleType}
                                        onChange={(event, newValue) =>
                                          handleChange(
                                            event,
                                            newValue,
                                            "vehicleType"
                                          )
                                        }
                                        required
                                      >
                                        <Option value="">
                                          Select Your Type Of vehicle
                                        </Option>
                                        <Option value="Sedan">Sedan</Option>
                                        <Option value="Coupe">Coupe</Option>
                                        <Option value="Convertible">
                                          Convertible
                                        </Option>
                                        <Option value="Hatchback">
                                          Hatchback
                                        </Option>
                                        <Option value="SUV">SUV</Option>
                                        <Option value="Crossover">
                                          Crossover
                                        </Option>
                                        <Option value="Pickup Truck">
                                          Pickup Truck
                                        </Option>
                                        <Option value="Sports Car">
                                          Sports Car
                                        </Option>
                                        <Option value="Electric Car">
                                          Electric Car
                                        </Option>
                                        <Option value="Hybrid Car">
                                          Hybrid Car
                                        </Option>
                                        <Option value="Luxury Car">
                                          Luxury Car
                                        </Option>
                                        <Option value="Other">Other</Option>
                                      </Select>
                                    </div>
                                  </Tooltip>
                                </div>

                                <div>
                                  <Tooltip title="Enter the year of Manufacture of your vehicle">
                                    <div className="mt-2">
                                      <FormLabel required>
                                        Manufactured Date
                                      </FormLabel>
                                      <LocalizationProvider
                                        dateAdapter={AdapterDayjs}
                                      >
                                        <DatePicker
                                          className="input_mui_joy"
                                          onChange={(date) =>
                                            handleDateChanges(date, "year")
                                          }
                                          value={data.vehicle.year}
                                          style={{ borderColor: "black" }}
                                          required
                                        />
                                        {/* </Tooltip> */}
                                      </LocalizationProvider>
                                    </div>
                                  </Tooltip>
                                </div>

                                <div>
                                  <Tooltip title="Enter the company of your vehicle">
                                    <div className="mt-2">
                                      <FormLabel required>Make</FormLabel>
                                      <Select
                                        value={data.vehicle.make}
                                        onChange={(event, newValue) =>
                                          handleChange(event, newValue, "make")
                                        }
                                        required
                                      >
                                        <Option value="">
                                          Select Your Type Of company
                                        </Option>
                                        <Option value="BMW">BMW</Option>
                                        <Option value="Mercedes-Benz">
                                          Mercedes-Benz
                                        </Option>
                                        <Option value="Audi">Audi</Option>
                                        <Option value="Toyota">Toyota</Option>
                                        <Option value="Honda">Honda</Option>
                                        <Option value="Ford">Ford</Option>
                                        <Option value="Other">Other</Option>
                                      </Select>
                                    </div>
                                  </Tooltip>
                                </div>

                                <div className="address_input">
                                  <div className="mt-2 addform_input_half">
                                    <div>
                                      <Tooltip title="Enter the model of your vehicle">
                                        <div>
                                          <FormLabel required>Model</FormLabel>
                                          <Select
                                            value={data.vehicle.model}
                                            onChange={(event, newValue) =>
                                              handleChange(
                                                event,
                                                newValue,
                                                "model"
                                              )
                                            }
                                            required
                                          >
                                            <Option value="">
                                              Select Your Type Of model
                                            </Option>
                                            <Option value="Camry">Camry</Option>
                                            <Option value="C-Class">
                                              C-Class
                                            </Option>
                                            <Option value="E-Class">
                                              E-Class
                                            </Option>
                                            <Option value="R8">R8</Option>
                                            <Option value="F-150">F-150</Option>
                                            <Option value="Mustang">
                                              Mustang
                                            </Option>
                                            <Option value="Other">Other</Option>
                                          </Select>
                                        </div>
                                      </Tooltip>
                                    </div>
                                  </div>

                                  <div className="mt-2 addform_input_half">
                                    <div>
                                      <Tooltip title="Enter your vehicle mileage">
                                        <div>
                                          <FormLabel required>Miles</FormLabel>
                                          <Input
                                            placeholder="Enter miles"
                                            required
                                            type="number"
                                            onChange={(e) =>
                                              handleChanges(e, "miles")
                                            }
                                            value={data.vehicle.miles}
                                          />
                                        </div>
                                      </Tooltip>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="addform_body_right_crypto">
                                <div className="address_input">
                                  <div className="mt-2 addform_input_half">
                                    <div>
                                      <Tooltip title="Enter your Loan against your vehicle">
                                        <div>
                                          <FormLabel>Loan</FormLabel>
                                          <Input
                                            className="input_mui_joy"
                                            placeholder="Enter loan"
                                            onChange={(e) =>
                                              handleChanges(e, "loan")
                                            }
                                            value={data.vehicle.loan}
                                          />
                                        </div>
                                      </Tooltip>
                                    </div>
                                  </div>

                                  <div className="mt-2 addform_input_half">
                                    <div>
                                      <Tooltip title="Enter Your Estimated Value ">
                                        <div>
                                          <FormLabel required>
                                            Estimated Value
                                          </FormLabel>
                                          <Input
                                            placeholder="Enter your Estimated valuation of vehicle"
                                            required
                                            type="number"
                                            onChange={(e) => {
                                              handleChanges(e, "estValue");
                                            }}
                                            value={data.vehicle.estValue}
                                            onClick={() => {
                                              setIsTextFieldClicked(true);
                                            }}
                                            startDecorator={
                                              isTextFieldClicked ? (
                                                <div>$</div>
                                              ) : null
                                            }
                                          />
                                        </div>
                                      </Tooltip>
                                    </div>
                                  </div>
                                </div>

                                <div>
                                  <Tooltip title="Add your banks related file">
                                    <div className="mt-2">
                                      <FormLabel>Supporting Document</FormLabel>
                                      <input
                                        style={{
                                          border: "solid 1px lightgray",
                                          borderLeft: "none",
                                          width: "100%",
                                          borderRadius: "5px",
                                        }}
                                        type="file"
                                        name="myfile"
                                        id="exampleFile"
                                        onChange={(e) =>
                                          handleImageChange(e, 5)
                                        }
                                        accept=".pdf"
                                        multiple
                                      />
                                    </div>
                                  </Tooltip>
                                </div>
                                <div
                                  className="bene-select mt-3"
                                  onClick={handleShowBeneficiary}
                                  style={{ cursor: "pointer" }}
                                >
                                  Select Your Beneficiary
                                </div>

                                <div className="address_input">
                                  <div
                                    style={{ width: "100%" }}
                                    className="mt-2"
                                  >
                                    <Tooltip title="Enter notes for your jewelry">
                                      <div>
                                        <FormLabel>Notes</FormLabel>
                                        <Textarea
                                          sx={{
                                            height: "182px",
                                            minHeight: "52px",
                                          }}
                                          placeholder="Notes"
                                          id="notes"
                                          onChange={(e) =>
                                            handleChanges(e, "notes")
                                          }
                                          value={data.vehicle.notes}
                                        />
                                      </div>
                                    </Tooltip>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="text-center">
                              <Button
                                className="my-estate-clear-btn"
                                // onClick={resetForm}
                                type="reset"
                                outline
                              >
                                Clear
                              </Button>
                              <Button
                                outline
                                type=""
                                className="my-estate-add-btn"
                              >
                                Add
                              </Button>
                            </div>
                          </Form>
                        </div>
                      </div>
                    </div>
                  </div>
                </SideBar>
              </div>
            </UserBase>
          </div>
        </>
      )}

      {benevisible && (
        // beneShow &&
        <div className="popup">
          <div
            className="popup-content"
            style={{
              minWidth: "350px",
              width: "100%",
              maxWidth: "700px",
            }}
          >
            <div className="note_popup">
              <div className="note_popup_heading">
                <div className="share_property_heading">
                  <h2>Share Property </h2>
                </div>
                <div>
                  <button
                    className="note_popup_heading_close_btn"
                    onClick={handleReset}
                  >
                    <FontAwesomeIcon icon={faXmark} />
                  </button>
                </div>
              </div>
              <div className="share_property_est_value">
                <p>
                  Estimated Value:{" "}
                  <code style={{ color: "green", fontWeight: "bold" }}>
                    ${estimatedTotalAmount}
                  </code>
                </p>
              </div>
              <div className="BeneficiarySelect">
                <div className="BeneficiarySelectContainer">
                  <div className="BeneficiarySelectRow">
                    <div class="share_property_Type">
                      <p class="share_property_Type_paragraph">
                        Choose Distribution Type:{" "}
                      </p>
                      <select
                        value={distributionType}
                        onChange={handleDistributionTypeChange}
                        class="share_property_Type_select"
                      >
                        <option value="">Select Type</option>
                        <option value="percentage">Percentage</option>
                        <option value="dollar">Dollar</option>
                      </select>
                    </div>
                    <div className="SelectContainer">
                      <select
                        className="Property-inputfiled"
                        onChange={handleBeneficiarySelection}
                        value=""
                        disabled={!distributionType}
                      >
                        <option value="" disabled hidden>
                          {distributionType
                            ? "Select Your Beneficiary Username"
                            : "Select Type First"}
                        </option>
                        {beneficiary.map((benif) => (
                          <option
                            key={benif.id}
                            value={benif.id}
                            disabled={selectedBeneficiaries.includes(benif.id)}
                          >
                            {/* {benif.username} */}
                            {`${benif.firstName} ${benif.lastName}`}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="share_beneficiary_main_card">
                    {selectedBeneficiaries.map(
                      (beneficiary) => (
                        console.log("this is  beneficiary ", beneficiary),
                        (
                          <div key={beneficiary} class="share_beneficiary_card">
                            <div>
                              <p className="share_beneficiary_card_para">
                                Beneficiary:{" "}
                                {getBenificiaryName({ beneficiary })}
                              </p>
                              {distributionType === "percentage" && (
                                <input
                                  type="text"
                                  className="share_ben_percentage"
                                  placeholder="Percentage"
                                  value={
                                    beneficiaryDetails[beneficiary]
                                      ?.percentage || ""
                                  }
                                  onChange={(e) =>
                                    handleFieldChange(
                                      beneficiary,
                                      "percentage",
                                      e.target.value
                                    )
                                  }
                                />
                              )}
                              {distributionType === "dollar" && (
                                <input
                                  type="text"
                                  className="share_ben_percentage"
                                  placeholder="Dollar Value"
                                  value={
                                    beneficiaryDetails[beneficiary]?.value || ""
                                  }
                                  onChange={(e) =>
                                    handleFieldChange(
                                      beneficiary,
                                      "value",
                                      e.target.value
                                    )
                                  }
                                />
                              )}

                              {distributionType && (
                                <p className="share_beneficiary_card_para">
                                  Distributed Value: $
                                  {distributionType === "percentage"
                                    ? calculateDistributedAmount(
                                        "percentage",
                                        estimatedTotalAmount,
                                        beneficiaryDetails[beneficiary]
                                      )
                                    : calculateDistributedAmount(
                                        "dollar",
                                        estimatedTotalAmount,
                                        beneficiaryDetails[beneficiary]
                                      )}
                                </p>
                              )}
                            </div>
                            <div
                              className="share_beneficiary_card_close"
                              onClick={() =>
                                handleBeneficiaryClose(beneficiary)
                              }
                            >
                              <FontAwesomeIcon icon={faXmark} />
                            </div>
                          </div>
                        )
                      )
                    )}
                  </div>
                </div>
              </div>
            </div>
            {selectedBeneficiaries.length > 0 && (
              <button onClick={handleSave}>Save</button>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export function InternationalAssetIncome() {
  const navigate = useNavigate();
  // new update
  let [form1, setForm1] = useState(true);
  const toggle = () => {
    setForm1(!form1);
    navigate("/user/my-estate/International_assets");
  };
  // set Add data
  const [data, setData] = useState({
    income: {
      owner: "",

      incomeCaption: "",
      incomeAmount: "",
      businessSource: "",
      notes: "",
    },
    sharedDetails: [
      {
        distributedType: "",
        distributedValue: "",
        distributedAmount: "",
        beneficiaryId: "",
      },
    ],
  });

  const [data1, setData1] = useState({
    assetCaption: "",
    assetType: "income",
    countryName: "",
  });

  const handleChangesData = (e, field) => {
    const newValue = e.target.value;
    setData1((prevData) => ({
      ...prevData,
      [field]: newValue,
    }));
  };

  const handleChangesCountry = (event, newValue) => {
    setData1((prevData) => ({
      ...prevData,
      countryName: newValue,
    }));
  };

  const [ownerName, setOwnerName] = useState([]);
  const handleChange = (event, newValue, stringValue) => {
    if (stringValue === "owner") {
      // Convert ownerName array to a single string

      const comingValue =
        typeof newValue === "string" ? newValue.split(",") : newValue;
      const ownerString = comingValue.join(", ");

      setData((prevData) => ({
        ...prevData,
        income: {
          ...prevData.income,
          owner: ownerString,
        },
      }));

      // Update the ownerName state afterwards
      setOwnerName(newValue);
    }
  };

  // Define an array to store user names
  const ownerNames = [];

  // Get primaryUser data
  const primaryUserDetails = getUser();
  const primaryUserName =
    primaryUserDetails.firstName + " " + primaryUserDetails.lastName;

  // Get secondaryUser data
  const secondaryUserDetails = getSecondaryUser();
  let secondaryUserName = "";

  // Check if secondary user exists
  if (secondaryUserDetails !== undefined) {
    secondaryUserName =
      secondaryUserDetails.firstName + " " + secondaryUserDetails.lastName;

    // Push both user names into the array
    ownerNames.push(primaryUserName, secondaryUserName, "Other");
  } else {
    // If secondary user doesn't exist, only push primary user name
    ownerNames.push(primaryUserName, "Other");
  }

  // use state to set the selected images
  const [selectedImage, setSelectedImage] = useState([]);

  const handleChanges = (e, field) => {
    const newValue = e.target.value;
    setData((prevData) => ({
      ...prevData,
      income: {
        ...prevData.income,
        [field]: newValue,
      },
    }));
    setEstimatedTotalAmount(data.income.incomeAmount);
  };

  // Handle image
  const handleImageChange = (event) => {
    const selectedFiles = event.target.files;
    const allowedExtensions = ["pdf"];

    if (selectedFiles) {
      const selectedFilesArray = Array.from(selectedFiles);

      // Check each file's extension
      const invalidFiles = selectedFilesArray.filter((file) => {
        const fileNameParts = file.name.split(".");
        const fileExtension =
          fileNameParts[fileNameParts.length - 1].toLowerCase();
        return !allowedExtensions.includes(fileExtension);
      });

      if (invalidFiles.length === 0) {
        const formData = new FormData();

        selectedFilesArray.forEach((file, index) => {
          formData.append(`file${index + 1}`, file);
        });

        // Set the selected image state with the array of files
        setSelectedImage(selectedFilesArray);

        // Now, you can use the formData object to send files to the API
        // Example:
        // apiCall(formData);
      } else {
        // Handle invalid file extensions
        const invalidExtensions = invalidFiles
          .map((file) => file.name.split(".").pop())
          .join(", ");
        toast.error(
          `Invalid file extensions: ${invalidExtensions}. Please select valid document formats.`,
          {
            position: toast.POSITION.BOTTOM_CENTER,
          }
        );
        event.target.value = ""; // Clear the input field
      }
    }
  };

  // auto matic clear the data
  const resetForm = () => {
    setIsTextFieldClicked(false);
    setData({
      income: {
        owner: "",
        incomeCaption: "",
        incomeAmount: "",
        businessSource: "",
        notes: "",
      },
      sharedDetails: [
        {
          distributedType: "",
          distributedValue: "",
          distributedAmount: "",
          beneficiaryId: "",
        },
      ],
    });
  };

  // Set the form
  const activeincomeForm = (event) => {
    event.preventDefault();
    if (
      data.payCheck === "" ||
      data.businessSource === "" ||
      data.benificiary === ""
    ) {
      // console.log("Error log");
      toast.error("Please fill all required feilds.");
      return;
    }

    let token = "Bearer " + getToken();

    // console.log("Token : " + token);

    //create form data to send a file and remaining class data
    const formData = new FormData();
    for (let i = 0; i < selectedImage.length; i++) {
      formData.append(`files`, selectedImage[i]);
      console.log("this is file indexs", selectedImage[i]);
    }
    formData.append("asset", JSON.stringify(data));
    formData.append("data", JSON.stringify(data1));

    addInternationalAsset(formData, token)
      .then((resp) => {
        toast.success("Data Added !!", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
        toggle();
      })
      .catch((error) => {});
  };

  // beneficiary addition in form
  const [beneficiary, setBenificiary] = useState([]);
  const getBenificiarydata = () => {
    let userId = getUser().id;

    let token = "Bearer " + getToken();
    getBeneficiary(token, userId)
      .then((res) => {
        setBenificiary(res);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    getBenificiarydata();
  }, []);

  // for add field pop
  const [isTextFieldClicked, setIsTextFieldClicked] = useState(false);

  //
  // let [show1, setShow1] = useState(false);
  const [benevisible, setbeneVisible] = useState(false);
  const [distributionType, setDistributionType] = useState("");
  const [selectedBeneficiaries, setSelectedBeneficiaries] = useState([]);
  const [beneficiaryDetails, setBeneficiaryDetails] = useState({});
  const [estimatedTotalAmount, setEstimatedTotalAmount] = useState(0);
  const [beneficiaryVisible, setBeneficiaryVisible] = useState(false);

  const handleShowBeneficiary = () => {
    setbeneVisible(true);
    setEstimatedTotalAmount(data.income.incomeAmount);
    data.sharedDetails = [];
  };

  const handleReset = () => {
    setbeneVisible(false);
    setDistributionType("");
    setSelectedBeneficiaries([]);
    setBeneficiaryDetails({});
  };

  const handleDistributionTypeChange = (event) => {
    const newType = event.target.value;
    const resetDetails = {};
    Object.keys(beneficiaryDetails).forEach((beneficiary) => {
      resetDetails[beneficiary] = { percentage: "", value: "" };
    });
    setDistributionType(newType);
    setBeneficiaryDetails(resetDetails);
  };

  const handleBeneficiarySelection = (event) => {
    const selectedBeneficiary = event.target.value;
    if (!selectedBeneficiaries.includes(selectedBeneficiary)) {
      setSelectedBeneficiaries([...selectedBeneficiaries, selectedBeneficiary]);
      setBeneficiaryDetails({
        ...beneficiaryDetails,
        [selectedBeneficiary]: { percentage: "", value: "" },
      });
    }
  };

  const handleBeneficiaryClose = (beneficiary) => {
    const updatedBeneficiaries = selectedBeneficiaries.filter(
      (b) => b !== beneficiary
    );
    setSelectedBeneficiaries(updatedBeneficiaries);
    const updatedDetails = { ...beneficiaryDetails };
    delete updatedDetails[beneficiary];
    setBeneficiaryDetails(updatedDetails);
  };

  const getBenificiaryName = (id) => {
    console.log("getBenificiaryName id : ", id);
    var foundBenificiary = null;
    if (id.beneficiary === undefined) {
      console.log("IF condition");
      foundBenificiary = beneficiary.find((b) => b.id === parseInt(id));
    } else {
      foundBenificiary = beneficiary.find(
        (b) => b.id === parseInt(id.beneficiary)
      );
    }
    console.log("foundBenificiary details : ", foundBenificiary);
    if (foundBenificiary) {
      return `${foundBenificiary.firstName} ${foundBenificiary.lastName}`;
    } else {
      return "Benificiary not found"; // Or handle the case where beneficiary with the given ID isn't found
    }
  };

  const handleFieldChange = (beneficiary, field, value) => {
    if (!estimatedTotalAmount || parseFloat(estimatedTotalAmount) === 0) {
      toast.error(
        "Please provide a valid estimated value before adding percentages!"
      );
      return;
    }
    let updatedDetails = { ...beneficiaryDetails };
    if (/^\d*$/.test(value)) {
      updatedDetails = {
        ...updatedDetails,
        [beneficiary]: {
          ...updatedDetails[beneficiary],
          [field]: value,
        },
      };
    }
    let totalPercentage = 0;
    let totalValue = 0;
    Object.keys(updatedDetails).forEach((key) => {
      const beneficiaryPercentage = parseFloat(updatedDetails[key]?.percentage);
      const beneficiaryValue = parseFloat(updatedDetails[key]?.value);
      if (!isNaN(beneficiaryPercentage) && beneficiaryPercentage >= 0) {
        totalPercentage += beneficiaryPercentage;
      }
      if (!isNaN(beneficiaryValue) && beneficiaryValue >= 0) {
        totalValue += beneficiaryValue;
      }
    });
    if (
      totalPercentage > 100 ||
      totalValue > parseFloat(estimatedTotalAmount)
    ) {
      updatedDetails[beneficiary][field] = "";
      toast.error(
        "Total percentage exceeds 100% or total value exceeds estimated value!"
      );
    } else {
      setBeneficiaryDetails(updatedDetails);
    }
  };

  const calculateDistributedAmount = (
    distributionType,
    balance,
    beneficiaryDetail
  ) => {
    if (distributionType === "percentage") {
      return (
        (parseFloat(balance) * parseFloat(beneficiaryDetail?.percentage || 0)) /
        100
      ).toFixed(2);
    } else if (distributionType === "dollar") {
      return parseFloat(beneficiaryDetail?.value || 0).toFixed(2);
    }
    return "0.00";
  };

  const handleSave = () => {
    toast.success("Saved successfully!");
    setbeneVisible(false);
    var i = 0;
    Object.keys(beneficiaryDetails).forEach((key) => {
      const beneficiaryPercentage = parseFloat(
        beneficiaryDetails[key]?.percentage
      );
      const beneficiaryValue = parseFloat(beneficiaryDetails[key]?.value);

      if (!isNaN(beneficiaryPercentage) && beneficiaryPercentage >= 0) {
        const distributedAmount = calculateDistributedAmount(
          "percentage",
          estimatedTotalAmount,
          beneficiaryDetails[key]
        );
        handleChanges2(
          "percentage",
          beneficiaryPercentage,
          distributedAmount,
          parseInt(key),
          i
        );
        i += 1;
      }

      if (!isNaN(beneficiaryValue) && beneficiaryValue >= 0) {
        const distributedAmount = calculateDistributedAmount(
          "dollar",
          estimatedTotalAmount,
          beneficiaryDetails[key]
        );
        handleChanges2(
          "dollar",
          beneficiaryValue,
          distributedAmount,
          parseInt(key),
          i
        );
        i += 1;
      }
    });
    // beneficiaryDetails.map(value)
    console.log("beneficiaryDetails data: ", data);
    // const length = data.sharedDetails.length;
    // data.sharedDetails[length] = beneficiaryDetails;
  };

  const handleChanges2 = (t, v, a, b, i) => {
    const updatedSharedDetails = [...data.sharedDetails]; // Create a copy of the sharedDetails array

    // Check if the index i is within the bounds of the array
    // if (i >= 0 && i < updatedSharedDetails.length) {
    // Create a new object to update the specific element at index i
    const updatedElement = {
      ...updatedSharedDetails[i], // Copy existing properties
      distributedType: t,
      distributedValue: v,
      distributedAmount: a,
      beneficiaryId: b,
    };

    updatedSharedDetails[i] = updatedElement; // Update the element at index i
    console.log("updatedSharedDetails[i] : ", updatedSharedDetails[i]);
    setData((prevState) => ({
      ...prevState,
      sharedDetails: updatedSharedDetails, // Update the sharedDetails in the state
    }));
    data.sharedDetails[i] = updatedSharedDetails[i];
  };

  return (
    <>
      {form1 && (
        <>
          <div className="fade-in-element">
            <UserBase>
              <div className="mt-5">
                <SideBar>
                  <div className="overlay1" style={{ transition: "500ms" }}>
                    <div className="property_form">
                      <Container
                        style={{ height: "auto", boxSizing: "border-box" }}
                      >
                        <Card color="outline">
                          <CardHeader>
                            <h2 className="form1-heading">
                              Business-Passive Income
                            </h2>
                            <div className="Close" onClick={toggle}>
                              <FontAwesomeIcon icon={faXmark} />
                            </div>
                          </CardHeader>
                          <CardBody>
                            <Form onSubmit={activeincomeForm}>
                              <div className="mt-2 ">
                                <div>
                                  <Tooltip title="Enter Caption for your assets">
                                    <div>
                                      <FormLabel>Assets Caption</FormLabel>
                                      <Input
                                        className="input_mui_joy"
                                        placeholder="Enter asset caption"
                                        onChange={(e) =>
                                          handleChangesData(e, "assetCaption")
                                        }
                                        value={data1.assetCaption}
                                      />
                                    </div>
                                  </Tooltip>
                                </div>
                              </div>

                              <div className="mt-2">
                                <div>
                                  <Tooltip title="Enter the Country of your assets">
                                    <div>
                                      <FormLabel required>
                                        Country Name
                                      </FormLabel>

                                      <Select
                                        placeholder="Select country name"
                                        onChange={(e, newValue) =>
                                          handleChangesCountry(e, newValue)
                                        }
                                        value={data1.countryName}
                                        required
                                      >
                                        {Country.getAllCountries().map(
                                          (v, index) => {
                                            return (
                                              <Option
                                                key={index}
                                                value={v.name}
                                              >
                                                {v.name}
                                              </Option>
                                            );
                                          }
                                        )}
                                      </Select>
                                    </div>
                                  </Tooltip>
                                </div>
                              </div>
                              <div>
                                <Tooltip title="Select Owner">
                                  <div>
                                    <FormLabel required>Select Owner</FormLabel>

                                    <Select
                                      placeholder="Select Owner"
                                      value={ownerName}
                                      multiple
                                      onChange={(event, newValue) =>
                                        handleChange(event, newValue, "owner")
                                      }
                                      sx={{
                                        minWidth: "13rem",
                                      }}
                                      slotProps={{
                                        listbox: {
                                          sx: {
                                            width: "100%",
                                          },
                                        },
                                      }}
                                      required
                                    >
                                      {ownerNames.map((name) => (
                                        <Option key={name} value={name}>
                                          {name}
                                        </Option>
                                      ))}
                                    </Select>
                                  </div>
                                </Tooltip>
                              </div>

                              <div>
                                <Tooltip title="Enter Heading for Income">
                                  <div className="mt-2">
                                    <FormLabel>Income Heading</FormLabel>
                                    <Input
                                      className="input_mui_joy"
                                      placeholder="Enter income heading"
                                      value={data.income.incomeCaption}
                                      onChange={(e) =>
                                        handleChanges(e, "incomeCaption")
                                      }
                                    />
                                  </div>
                                </Tooltip>
                              </div>

                              <div>
                                <Tooltip title="Enter your Income">
                                  <div className="mt-2">
                                    <FormLabel required>Income</FormLabel>
                                    <Input
                                      type="number"
                                      className="input_mui_joy"
                                      placeholder="Enter income"
                                      value={data.income.incomeAmount}
                                      onChange={(e) =>
                                        handleChanges(e, "incomeAmount")
                                      }
                                      onClick={() =>
                                        setIsTextFieldClicked(true)
                                      }
                                      startDecorator={
                                        isTextFieldClicked ? <div>$</div> : null
                                      }
                                      required
                                    />
                                  </div>
                                </Tooltip>
                              </div>

                              <div>
                                <Tooltip title="Enter Source of your income">
                                  <div className="mt-2">
                                    <FormLabel required>
                                      Income Source
                                    </FormLabel>
                                    <Input
                                      className="input_mui_joy"
                                      placeholder="Enter income heading"
                                      value={data.income.businessSource}
                                      onChange={(e) =>
                                        handleChanges(e, "businessSource")
                                      }
                                      required
                                    />
                                  </div>
                                </Tooltip>
                              </div>

                              <div className="mt-3">
                                <Tooltip title="Add your income related file">
                                  <div>
                                    <label
                                      style={{
                                        display: "block",
                                        marginBottom: "5px",
                                      }}
                                    >
                                      Supporting Document<span></span>
                                    </label>
                                    <input
                                      style={{
                                        border: "solid 1px lightgray",
                                        borderLeft: "none",
                                        width: "100%",
                                        borderRadius: "5px",
                                      }}
                                      type="file"
                                      name="myfile"
                                      id="exampleFile"
                                      multiple
                                      accept=".pdf"
                                      onChange={(e) => handleImageChange(e)}
                                    />
                                  </div>
                                </Tooltip>
                              </div>

                              <div
                                className="bene-select mt-3"
                                onClick={handleShowBeneficiary}
                                style={{ cursor: "pointer" }}
                              >
                                Select Your Beneficiary
                              </div>

                              <div className="address_input">
                                <div style={{ width: "100%" }} className="mt-2">
                                  <Tooltip title="Enter notes for your income">
                                    <div>
                                      <FormLabel>Notes</FormLabel>
                                      <Textarea
                                        sx={{
                                          height: "182px",
                                          minHeight: "52px",
                                        }}
                                        placeholder="Notes"
                                        id="notes"
                                        onChange={(e) =>
                                          handleChanges(e, "notes")
                                        }
                                        value={data.income.notes}
                                      />
                                    </div>
                                  </Tooltip>
                                </div>
                              </div>
                              <Container className="text-center">
                                <Button
                                  // onClick={resetForm}
                                  color="warning"
                                  className="my-estate-add-btn"
                                  outline
                                >
                                  Clear
                                </Button>
                                <Button
                                  color="success"
                                  outline
                                  className="my-estate-add-btn"
                                >
                                  Add
                                </Button>
                              </Container>
                            </Form>
                          </CardBody>
                        </Card>
                      </Container>
                    </div>
                  </div>
                </SideBar>
              </div>
            </UserBase>
          </div>
        </>
      )}
      {benevisible && (
        // beneShow &&
        <div className="popup">
          <div
            className="popup-content"
            style={{
              minWidth: "350px",
              width: "100%",
              maxWidth: "700px",
            }}
          >
            <div className="note_popup">
              <div className="note_popup_heading">
                <div className="share_property_heading">
                  <h2>Share Property </h2>
                </div>
                <div>
                  <button
                    className="note_popup_heading_close_btn"
                    onClick={handleReset}
                  >
                    <FontAwesomeIcon icon={faXmark} />
                  </button>
                </div>
              </div>
              <div className="share_property_est_value">
                <p>
                  Estimated Value:{" "}
                  <code style={{ color: "green", fontWeight: "bold" }}>
                    ${estimatedTotalAmount}
                  </code>
                </p>
              </div>
              <div className="BeneficiarySelect">
                <div className="BeneficiarySelectContainer">
                  <div className="BeneficiarySelectRow">
                    <div class="share_property_Type">
                      <p class="share_property_Type_paragraph">
                        Choose Distribution Type:{" "}
                      </p>
                      <select
                        value={distributionType}
                        onChange={handleDistributionTypeChange}
                        class="share_property_Type_select"
                      >
                        <option value="">Select Type</option>
                        <option value="percentage">Percentage</option>
                        <option value="dollar">Dollar</option>
                      </select>
                    </div>
                    <div className="SelectContainer">
                      <select
                        className="Property-inputfiled"
                        onChange={handleBeneficiarySelection}
                        value=""
                        disabled={!distributionType}
                      >
                        <option value="" disabled hidden>
                          {distributionType
                            ? "Select Your Beneficiary Username"
                            : "Select Type First"}
                        </option>
                        {beneficiary.map((benif) => (
                          <option
                            key={benif.id}
                            value={benif.id}
                            disabled={selectedBeneficiaries.includes(benif.id)}
                          >
                            {/* {benif.username} */}
                            {`${benif.firstName} ${benif.lastName}`}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="share_beneficiary_main_card">
                    {selectedBeneficiaries.map(
                      (beneficiary) => (
                        console.log("this is  beneficiary ", beneficiary),
                        (
                          <div key={beneficiary} class="share_beneficiary_card">
                            <div>
                              <p className="share_beneficiary_card_para">
                                Beneficiary:{" "}
                                {getBenificiaryName({ beneficiary })}
                              </p>
                              {distributionType === "percentage" && (
                                <input
                                  type="text"
                                  className="share_ben_percentage"
                                  placeholder="Percentage"
                                  value={
                                    beneficiaryDetails[beneficiary]
                                      ?.percentage || ""
                                  }
                                  onChange={(e) =>
                                    handleFieldChange(
                                      beneficiary,
                                      "percentage",
                                      e.target.value
                                    )
                                  }
                                />
                              )}
                              {distributionType === "dollar" && (
                                <input
                                  type="text"
                                  className="share_ben_percentage"
                                  placeholder="Dollar Value"
                                  value={
                                    beneficiaryDetails[beneficiary]?.value || ""
                                  }
                                  onChange={(e) =>
                                    handleFieldChange(
                                      beneficiary,
                                      "value",
                                      e.target.value
                                    )
                                  }
                                />
                              )}

                              {distributionType && (
                                <p className="share_beneficiary_card_para">
                                  Distributed Value: $
                                  {distributionType === "percentage"
                                    ? calculateDistributedAmount(
                                        "percentage",
                                        estimatedTotalAmount,
                                        beneficiaryDetails[beneficiary]
                                      )
                                    : calculateDistributedAmount(
                                        "dollar",
                                        estimatedTotalAmount,
                                        beneficiaryDetails[beneficiary]
                                      )}
                                </p>
                              )}
                            </div>
                            <div
                              className="share_beneficiary_card_close"
                              onClick={() =>
                                handleBeneficiaryClose(beneficiary)
                              }
                            >
                              <FontAwesomeIcon icon={faXmark} />
                            </div>
                          </div>
                        )
                      )
                    )}
                  </div>
                </div>
              </div>
            </div>
            {selectedBeneficiaries.length > 0 && (
              <button onClick={handleSave}>Save</button>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export function InternationalAssetOtherAssets() {
  const navigate = useNavigate();
  let [form1, setForm1] = useState(true);
  const toggle = () => {
    setForm1(!form1);
    navigate("/user/my-estate/International_assets");
  };
  // set Add data
  const [data, setData] = useState({
    otherAsset: {
      owner: "",

      assetCaption: "",
      assetValue: "",
      otherAssets1: "",
      otherAssets2: "",
      otherAssets3: "",
      otherAssets4: "",
      otherAssets5: "",
      notes: "",
    },
    sharedDetails: [
      {
        distributedType: "",
        distributedValue: "",
        distributedAmount: "",
        beneficiaryId: "",
      },
    ],
  });

  const [data1, setData1] = useState({
    assetCaption: "",
    assetType: "otherAsset",
    countryName: "",
  });

  const handleChangesData = (e, field) => {
    const newValue = e.target.value;
    setData1((prevData) => ({
      ...prevData,
      [field]: newValue,
    }));
  };

  const handleChangesCountry = (event, newValue) => {
    setData1((prevData) => ({
      ...prevData,
      countryName: newValue,
    }));
  };

  // for owner field
  const [ownerName, setOwnerName] = useState([]);
  const handleChange = (event, newValue, stringValue) => {
    if (stringValue === "owner") {
      // Convert ownerName array to a single string

      const comingValue =
        typeof newValue === "string" ? newValue.split(",") : newValue;
      const ownerString = comingValue.join(", ");

      setData((prevData) => ({
        ...prevData,
        otherAsset: {
          ...prevData.otherAsset,
          owner: ownerString,
        },
      }));

      // Update the ownerName state afterwards
      setOwnerName(newValue);
    }
  };

  // Define an array to store user names
  const ownerNames = [];

  // Get primaryUser data
  const primaryUserDetails = getUser();
  const primaryUserName =
    primaryUserDetails.firstName + " " + primaryUserDetails.lastName;

  // Get secondaryUser data
  const secondaryUserDetails = getSecondaryUser();
  let secondaryUserName = "";

  // Check if secondary user exists
  if (secondaryUserDetails !== undefined) {
    secondaryUserName =
      secondaryUserDetails.firstName + " " + secondaryUserDetails.lastName;

    // Push both user names into the array
    ownerNames.push(primaryUserName, secondaryUserName, "Other");
  } else {
    // If secondary user doesn't exist, only push primary user name
    ownerNames.push(primaryUserName, "Other");
  }

  // use state to set the selected images
  const [selectedImage, setSelectedImage] = useState([]);

  const handleChanges = (e, field) => {
    const newValue = e.target.value;
    setData((prevData) => ({
      ...prevData,
      otherAsset: {
        ...prevData.otherAsset,
        [field]: newValue,
      },
    }));
    // setEstimatedTotalAmount(data.realEstate.estPropertyVal);
    setEstimatedTotalAmount(data.otherAsset.assetValue);
  };

  // Handle image
  const handleImageChange = (event) => {
    const selectedFiles = event.target.files;
    const allowedExtensions = ["pdf"];

    if (selectedFiles) {
      const selectedFilesArray = Array.from(selectedFiles);

      // Check each file's extension
      const invalidFiles = selectedFilesArray.filter((file) => {
        const fileNameParts = file.name.split(".");
        const fileExtension =
          fileNameParts[fileNameParts.length - 1].toLowerCase();
        return !allowedExtensions.includes(fileExtension);
      });

      if (invalidFiles.length === 0) {
        const formData = new FormData();

        selectedFilesArray.forEach((file, index) => {
          formData.append(`file${index + 1}`, file);
        });

        // Set the selected image state with the array of files
        setSelectedImage(selectedFilesArray);

        // Now, you can use the formData object to send files to the API
        // Example:
        // apiCall(formData);
      } else {
        // Handle invalid file extensions
        const invalidExtensions = invalidFiles
          .map((file) => file.name.split(".").pop())
          .join(", ");
        toast.error(
          `Invalid file extensions: ${invalidExtensions}. Please select valid document formats.`,
          {
            position: toast.POSITION.BOTTOM_CENTER,
          }
        );
        event.target.value = ""; // Clear the input field
      }
    }
  };

  const resetForm = () => {
    setIsTextFieldClicked(false);
    setData({
      otherAsset: {
        assetCaption: "",
        assetValue: "",
        otherAssets1: "",
        otherAssets2: "",
        otherAssets3: "",
        otherAssets4: "",
        otherAssets5: "",
        notes: "",
      },
      sharedDetails: [
        {
          distributedType: "",
          distributedValue: "",
          distributedAmount: "",
          beneficiaryId: "",
        },
      ],
    });
  };

  // Set the form
  const OtherAssestForm = (event) => {
    event.preventDefault();
    let token = "Bearer " + getToken();

    if (data.otherAssets1 === "") {
      // console.log("Error log");
      toast.error("Please fill all required feilds.");
      return;
    }
    //create form data to send a file and remaining class data
    const formData = new FormData();
    for (let i = 0; i < selectedImage.length; i++) {
      formData.append(`files`, selectedImage[i]);
      console.log("this is file indexs", selectedImage[i]);
    }
    formData.append("asset", JSON.stringify(data));
    formData.append("data", JSON.stringify(data1));

    addInternationalAsset(formData, token)
      .then((resp) => {
        toast.success("Data Added !!", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
        toggle();
      })
      .catch((error) => {});
  };

  const [otherAssets, setOtherAssets] = useState([{ name: "", notes: "" }]);
  const [visibleColumnIndex, setVisibleColumnIndex] = useState(0);
  const otherAssetss = [0, 1, 2, 3, 4];
  const handleAddColumn = () => {
    if (visibleColumnIndex < 4) {
      setOtherAssets([
        ...otherAssets,
        { name: "", notes: "", label: visibleColumnIndex + 1 },
      ]);
      setVisibleColumnIndex(visibleColumnIndex + 1);
    }
  };

  const handleRemoveColumn = (indexToRemove) => {
    if (otherAssets.length > 0) {
      // Create a copy of the otherAssets array
      const updatedAssets = [...otherAssets];
      // Remove the last element
      updatedAssets.pop();
      // Update the state with the modified array
      setOtherAssets(updatedAssets);

      // Update visibleColumnIndex if necessary
      if (visibleColumnIndex > 0) {
        setVisibleColumnIndex(visibleColumnIndex - 1);
      }
    }
    setData((prevData) => {
      const updatedOtherAsset = { ...prevData.otherAsset };

      // Reset the value of the field corresponding to the removed column to an empty string
      for (let i = 1; i <= visibleColumnIndex + 1; i++) {
        const fieldKey = `otherAssets${i + 1}`;
        if (indexToRemove === i) {
          updatedOtherAsset[fieldKey] = ""; // Reset the field value to an empty string
        }
      }

      // Return the updated state with the modified otherAsset object
      return {
        ...prevData,
        otherAsset: updatedOtherAsset,
      };
    });
  };

  // beneficiary addition in form
  const [beneficiary, setBenificiary] = useState([]);

  const getBenificiarydata = () => {
    let userId = getUser().id;

    let token = "Bearer " + getToken();
    getBeneficiary(token, userId)
      .then((res) => {
        setBenificiary(res);
      })
      .catch((err) => {});
  };

  const [isTextFieldClicked, setIsTextFieldClicked] = useState(false);

  //
  // let [show1, setShow1] = useState(false);
  const [benevisible, setbeneVisible] = useState(false);
  const [distributionType, setDistributionType] = useState("");
  const [selectedBeneficiaries, setSelectedBeneficiaries] = useState([]);
  const [beneficiaryDetails, setBeneficiaryDetails] = useState({});
  const [estimatedTotalAmount, setEstimatedTotalAmount] = useState(0);
  const [beneficiaryVisible, setBeneficiaryVisible] = useState(false);

  const handleShowBeneficiary = () => {
    setbeneVisible(true);
    setEstimatedTotalAmount(data.otherAsset.assetValue);
    data.sharedDetails = [];
  };

  const handleReset = () => {
    setbeneVisible(false);
    setDistributionType("");
    setSelectedBeneficiaries([]);
    setBeneficiaryDetails({});
  };

  const handleDistributionTypeChange = (event) => {
    const newType = event.target.value;
    const resetDetails = {};
    Object.keys(beneficiaryDetails).forEach((beneficiary) => {
      resetDetails[beneficiary] = { percentage: "", value: "" };
    });
    setDistributionType(newType);
    setBeneficiaryDetails(resetDetails);
  };

  const handleBeneficiarySelection = (event) => {
    const selectedBeneficiary = event.target.value;
    if (!selectedBeneficiaries.includes(selectedBeneficiary)) {
      setSelectedBeneficiaries([...selectedBeneficiaries, selectedBeneficiary]);
      setBeneficiaryDetails({
        ...beneficiaryDetails,
        [selectedBeneficiary]: { percentage: "", value: "" },
      });
    }
  };

  const handleBeneficiaryClose = (beneficiary) => {
    const updatedBeneficiaries = selectedBeneficiaries.filter(
      (b) => b !== beneficiary
    );
    setSelectedBeneficiaries(updatedBeneficiaries);
    const updatedDetails = { ...beneficiaryDetails };
    delete updatedDetails[beneficiary];
    setBeneficiaryDetails(updatedDetails);
  };

  const getBenificiaryName = (id) => {
    console.log("getBenificiaryName id : ", id);
    var foundBenificiary = null;
    if (id.beneficiary === undefined) {
      console.log("IF condition");
      foundBenificiary = beneficiary.find((b) => b.id === parseInt(id));
    } else {
      foundBenificiary = beneficiary.find(
        (b) => b.id === parseInt(id.beneficiary)
      );
    }
    console.log("foundBenificiary details : ", foundBenificiary);
    if (foundBenificiary) {
      return `${foundBenificiary.firstName} ${foundBenificiary.lastName}`;
    } else {
      return "Benificiary not found"; // Or handle the case where beneficiary with the given ID isn't found
    }
  };

  const handleFieldChange = (beneficiary, field, value) => {
    if (!estimatedTotalAmount || parseFloat(estimatedTotalAmount) === 0) {
      toast.error(
        "Please provide a valid estimated value before adding percentages!"
      );
      return;
    }
    let updatedDetails = { ...beneficiaryDetails };
    if (/^\d*$/.test(value)) {
      updatedDetails = {
        ...updatedDetails,
        [beneficiary]: {
          ...updatedDetails[beneficiary],
          [field]: value,
        },
      };
    }
    let totalPercentage = 0;
    let totalValue = 0;
    Object.keys(updatedDetails).forEach((key) => {
      const beneficiaryPercentage = parseFloat(updatedDetails[key]?.percentage);
      const beneficiaryValue = parseFloat(updatedDetails[key]?.value);
      if (!isNaN(beneficiaryPercentage) && beneficiaryPercentage >= 0) {
        totalPercentage += beneficiaryPercentage;
      }
      if (!isNaN(beneficiaryValue) && beneficiaryValue >= 0) {
        totalValue += beneficiaryValue;
      }
    });
    if (
      totalPercentage > 100 ||
      totalValue > parseFloat(estimatedTotalAmount)
    ) {
      updatedDetails[beneficiary][field] = "";
      toast.error(
        "Total percentage exceeds 100% or total value exceeds estimated value!"
      );
    } else {
      setBeneficiaryDetails(updatedDetails);
    }
  };

  const calculateDistributedAmount = (
    distributionType,
    balance,
    beneficiaryDetail
  ) => {
    if (distributionType === "percentage") {
      return (
        (parseFloat(balance) * parseFloat(beneficiaryDetail?.percentage || 0)) /
        100
      ).toFixed(2);
    } else if (distributionType === "dollar") {
      return parseFloat(beneficiaryDetail?.value || 0).toFixed(2);
    }
    return "0.00";
  };

  const handleSave = () => {
    toast.success("Saved successfully!");
    setbeneVisible(false);
    var i = 0;
    Object.keys(beneficiaryDetails).forEach((key) => {
      const beneficiaryPercentage = parseFloat(
        beneficiaryDetails[key]?.percentage
      );
      const beneficiaryValue = parseFloat(beneficiaryDetails[key]?.value);

      if (!isNaN(beneficiaryPercentage) && beneficiaryPercentage >= 0) {
        const distributedAmount = calculateDistributedAmount(
          "percentage",
          estimatedTotalAmount,
          beneficiaryDetails[key]
        );
        handleChanges2(
          "percentage",
          beneficiaryPercentage,
          distributedAmount,
          parseInt(key),
          i
        );
        i += 1;
      }

      if (!isNaN(beneficiaryValue) && beneficiaryValue >= 0) {
        const distributedAmount = calculateDistributedAmount(
          "dollar",
          estimatedTotalAmount,
          beneficiaryDetails[key]
        );
        handleChanges2(
          "dollar",
          beneficiaryValue,
          distributedAmount,
          parseInt(key),
          i
        );
        i += 1;
      }
    });
    // beneficiaryDetails.map(value)
    console.log("beneficiaryDetails data: ", data);
    // const length = data.sharedDetails.length;
    // data.sharedDetails[length] = beneficiaryDetails;
  };

  const handleChanges2 = (t, v, a, b, i) => {
    const updatedSharedDetails = [...data.sharedDetails]; // Create a copy of the sharedDetails array

    // Check if the index i is within the bounds of the array
    // if (i >= 0 && i < updatedSharedDetails.length) {
    // Create a new object to update the specific element at index i
    const updatedElement = {
      ...updatedSharedDetails[i], // Copy existing properties
      distributedType: t,
      distributedValue: v,
      distributedAmount: a,
      beneficiaryId: b,
    };

    updatedSharedDetails[i] = updatedElement; // Update the element at index i
    console.log("updatedSharedDetails[i] : ", updatedSharedDetails[i]);
    setData((prevState) => ({
      ...prevState,
      sharedDetails: updatedSharedDetails, // Update the sharedDetails in the state
    }));
    data.sharedDetails[i] = updatedSharedDetails[i];
  };

  useEffect(() => {
    getBenificiarydata();
  }, []);

  return (
    <>
      {form1 && (
        <>
          <div className="fade-in-element">
            <UserBase>
              <div className="mt-5">
                <SideBar>
                  <div className="overlay1" style={{ transition: "500ms" }}>
                    <div className="property_form">
                      <Container>
                        <Card color="outline">
                          <CardHeader>
                            <h2 className="form1-heading">
                              Miscellaneous Assets
                            </h2>
                            <div className="Close" onClick={toggle}>
                              <FontAwesomeIcon icon={faXmark} />
                            </div>
                          </CardHeader>
                          <CardBody>
                            <Form onSubmit={OtherAssestForm}>
                              <div className="mt-2 ">
                                <div>
                                  <Tooltip title="Enter Caption for your assets">
                                    <div>
                                      <FormLabel>Assets Caption</FormLabel>
                                      <Input
                                        className="input_mui_joy"
                                        placeholder="Enter asset caption"
                                        onChange={(e) =>
                                          handleChangesData(e, "assetCaption")
                                        }
                                        value={data1.assetCaption}
                                      />
                                    </div>
                                  </Tooltip>
                                </div>
                              </div>

                              <div className="mt-2">
                                <div>
                                  <Tooltip title="Enter the Country of your assets">
                                    <div>
                                      <FormLabel required>
                                        Country Name
                                      </FormLabel>

                                      <Select
                                        placeholder="Select country name"
                                        onChange={(e, newValue) =>
                                          handleChangesCountry(e, newValue)
                                        }
                                        value={data1.countryName}
                                        required
                                      >
                                        {Country.getAllCountries().map(
                                          (v, index) => {
                                            return (
                                              <Option
                                                key={index}
                                                value={v.name}
                                              >
                                                {v.name}
                                              </Option>
                                            );
                                          }
                                        )}
                                      </Select>
                                    </div>
                                  </Tooltip>
                                </div>
                              </div>
                              <div>
                                <Tooltip title="Select Owner" className="mt-2">
                                  <div>
                                    <FormLabel required>Select Owner</FormLabel>

                                    <Select
                                      placeholder="Select Owner"
                                      value={ownerName}
                                      multiple
                                      onChange={(event, newValue) =>
                                        handleChange(event, newValue, "owner")
                                      }
                                      sx={{
                                        minWidth: "13rem",
                                      }}
                                      slotProps={{
                                        listbox: {
                                          sx: {
                                            width: "100%",
                                          },
                                        },
                                      }}
                                      required
                                    >
                                      {ownerNames.map((name) => (
                                        <Option key={name} value={name}>
                                          {name}
                                        </Option>
                                      ))}
                                    </Select>
                                  </div>
                                </Tooltip>
                              </div>

                              <div>
                                <Tooltip title="Enter Heading for other Assets">
                                  <div className="mt-2">
                                    <FormLabel>Assets Heading</FormLabel>
                                    <Input
                                      className="input_mui_joy"
                                      placeholder="Enter assets heading"
                                      onChange={(e) =>
                                        handleChanges(e, "assetCaption")
                                      }
                                      value={data.otherAsset.assetCaption}
                                    />
                                  </div>
                                </Tooltip>
                              </div>

                              <div>
                                {otherAssetss.map((index) => (
                                  <div
                                    key={index}
                                    className="mt-2"
                                    style={{
                                      flexDirection: "row",
                                      display:
                                        index <= visibleColumnIndex
                                          ? "flex"
                                          : "none",
                                    }}
                                  >
                                    <div style={{ width: "100%" }}>
                                      <Tooltip
                                        title={
                                          index === 0
                                            ? "Add your Other Assets"
                                            : "Add More On it "
                                        }
                                      >
                                        <div>
                                          <FormLabel required>
                                            {`Assets ${index + 1}`}
                                          </FormLabel>
                                          <Input
                                            className="input_mui_joy"
                                            id={`otherAssets${index + 1}`}
                                            placeholder={`Enter Assets ${
                                              index + 1
                                            }`}
                                            onChange={(e) =>
                                              handleChanges(
                                                e,
                                                `otherAssets${index + 1}`
                                              )
                                            }
                                            value={
                                              data.otherAsset[
                                                `otherAssets${index + 1}`
                                              ] || ""
                                            }
                                            required={
                                              visibleColumnIndex >= index
                                            }
                                          ></Input>
                                        </div>
                                      </Tooltip>
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "flex-end",
                                      }}
                                    >
                                      {index !== 0 && ( // Render minus button for indexes other than 0
                                        <Button
                                          style={{
                                            height: "30px",
                                            width: "30px",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            borderRadius: "50%",
                                            backgroundColor: "#ff4a4a",
                                            border: "none",
                                            marginLeft: "5px",
                                          }}
                                          onClick={() =>
                                            handleRemoveColumn(index)
                                          }
                                        >
                                          <FontAwesomeIcon icon={faMinus} />
                                        </Button>
                                      )}
                                    </div>
                                  </div>
                                ))}
                                {visibleColumnIndex < 4 && (
                                  <div
                                    style={{
                                      marginTop: "7px",
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Button
                                      style={{
                                        height: "30px",
                                        width: "30px",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        borderRadius: "50%",
                                        backgroundColor: "#4aafff",
                                        border: "none",
                                      }}
                                      onClick={handleAddColumn}
                                    >
                                      <FontAwesomeIcon icon={faPlus} />
                                    </Button>
                                  </div>
                                )}
                              </div>

                              <div>
                                <Tooltip title="Enter value for other Assets">
                                  <div className="mt-2">
                                    <FormLabel required>Assets Value</FormLabel>
                                    <Input
                                      type="number"
                                      className="input_mui_joy"
                                      placeholder="Enter assets value"
                                      onChange={(e) =>
                                        handleChanges(e, "assetValue")
                                      }
                                      value={data.otherAsset.assetValue}
                                      onClick={() =>
                                        setIsTextFieldClicked(true)
                                      }
                                      startDecorator={
                                        isTextFieldClicked ? <div>$</div> : null
                                      }
                                      required
                                    />
                                  </div>
                                </Tooltip>
                              </div>

                              <div className="mt-3">
                                <Tooltip title="Add your income related file">
                                  <div>
                                    <label
                                      style={{
                                        display: "block",
                                        marginBottom: "5px",
                                      }}
                                    >
                                      Supporting Document<span></span>
                                    </label>
                                    <input
                                      style={{
                                        border: "solid 1px lightgray",
                                        borderLeft: "none",
                                        width: "100%",
                                        borderRadius: "5px",
                                      }}
                                      type="file"
                                      name="myfile"
                                      id="exampleFile"
                                      onChange={(e) => handleImageChange(e)}
                                      multiple
                                      accept=".pdf"
                                    />
                                  </div>
                                </Tooltip>
                              </div>

                              <div
                                className="bene-select mt-3"
                                onClick={handleShowBeneficiary}
                                style={{ cursor: "pointer" }}
                              >
                                Select Your Beneficiary
                              </div>

                              <div className="address_input">
                                <div style={{ width: "100%" }} className="mt-2">
                                  <Tooltip title="Enter notes for your assets">
                                    <div>
                                      <FormLabel>Notes</FormLabel>
                                      <Textarea
                                        sx={{
                                          height: "182px",
                                          minHeight: "52px",
                                        }}
                                        placeholder="Notes"
                                        id="notes"
                                        onChange={(e) =>
                                          handleChanges(e, "notes")
                                        }
                                        value={data.otherAsset.notes}
                                      />
                                    </div>
                                  </Tooltip>
                                </div>
                              </div>

                              {/* adding new field */}
                              {/* <div style={{ marginTop: "7px", display: "flex", alignItems: "center" }}>
                          <Button style={{
                            height: "30px",
                            width: "30px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: "50%",
                            backgroundColor: "#4aafff",
                            border: "none"
                          }}
                            onClick={handleAddField}>
                            <FontAwesomeIcon icon={faPlus} />
                          </Button>
                          Add New Field
                        </div>
                        <div style={{ width: "99.5%" }}>
                          {addField.map((index) => (
                            <div className="mt-2" key={index} style={{ flexDirection: "row", display: index < visibleField ? "flex" : "none", }}>
                              <div style={{ width: "97%" }}>
                                <Tooltip title={`Add New Field ${index + 1}`}>
                                  <TextField
                                    fullWidth
                                    type="text"
                                    label={`New Field ${index + 1}`}
                                    id={`addfield${index + 1}`}
                                    size="normal"
                                    onChange={(e) => handleChanges(e, `addfield${index + 1}`)}
                                    value={data[`addfield${index + 1}`] || ''}

                                    className="AddField"
                                  />
                                </Tooltip>
                              </div>
                              <span className="addFieldClose" onClick={() => setVisibleField(visibleField - 1)} style={{ width: "2%", paddingLeft: "5px" }}><FontAwesomeIcon icon={faXmark} /></span>
                            </div>
                          ))}

                        </div> */}

                              <Container className="text-center">
                                <Button
                                  // onClick={resetForm}
                                  color="warning"
                                  className="my-estate-add-btn"
                                  outline
                                >
                                  Clear
                                </Button>
                                <Button
                                  color="success"
                                  outline
                                  className="my-estate-add-btn"
                                >
                                  Add
                                </Button>
                              </Container>
                            </Form>
                          </CardBody>
                        </Card>
                      </Container>
                    </div>
                  </div>
                </SideBar>
              </div>
            </UserBase>
          </div>
        </>
      )}

      {benevisible && (
        // beneShow &&
        <div className="popup">
          <div
            className="popup-content popup-content-download"
            style={{
              minWidth: "350px",
              width: "100%",
              maxWidth: "700px",
            }}
          >
            <div className="note_popup">
              <div className="note_popup_heading">
                <div className="share_property_heading">
                  <h2>Share Property </h2>
                </div>
                <div>
                  <button
                    className="note_popup_heading_close_btn"
                    onClick={handleReset}
                  >
                    <FontAwesomeIcon icon={faXmark} />
                  </button>
                </div>
              </div>
              <div className="share_property_est_value">
                <p>
                  Estimated Value:{" "}
                  <code style={{ color: "green", fontWeight: "bold" }}>
                    ${estimatedTotalAmount}
                  </code>
                </p>
              </div>
              <div className="BeneficiarySelect">
                <div className="BeneficiarySelectContainer">
                  <div className="BeneficiarySelectRow">
                    <div class="share_property_Type">
                      <p class="share_property_Type_paragraph">
                        Choose Distribution Type:{" "}
                      </p>
                      <select
                        value={distributionType}
                        onChange={handleDistributionTypeChange}
                        class="share_property_Type_select"
                      >
                        <option value="">Select Type</option>
                        <option value="percentage">Percentage</option>
                        <option value="dollar">Dollar</option>
                      </select>
                    </div>
                    <div className="SelectContainer">
                      <select
                        className="Property-inputfiled"
                        onChange={handleBeneficiarySelection}
                        value=""
                        disabled={!distributionType}
                      >
                        <option value="" disabled hidden>
                          {distributionType
                            ? "Select Your Beneficiary Username"
                            : "Select Type First"}
                        </option>
                        {beneficiary.map((benif) => (
                          <option
                            key={benif.id}
                            value={benif.id}
                            disabled={selectedBeneficiaries.includes(benif.id)}
                          >
                            {/* {benif.username} */}
                            {`${benif.firstName} ${benif.lastName}`}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="share_beneficiary_main_card">
                    {selectedBeneficiaries.map(
                      (beneficiary) => (
                        console.log("this is  beneficiary ", beneficiary),
                        (
                          <div key={beneficiary} class="share_beneficiary_card">
                            <div>
                              <p className="share_beneficiary_card_para">
                                Beneficiary:{" "}
                                {getBenificiaryName({ beneficiary })}
                              </p>
                              {distributionType === "percentage" && (
                                <input
                                  type="text"
                                  className="share_ben_percentage"
                                  placeholder="Percentage"
                                  value={
                                    beneficiaryDetails[beneficiary]
                                      ?.percentage || ""
                                  }
                                  onChange={(e) =>
                                    handleFieldChange(
                                      beneficiary,
                                      "percentage",
                                      e.target.value
                                    )
                                  }
                                />
                              )}
                              {distributionType === "dollar" && (
                                <input
                                  type="text"
                                  className="share_ben_percentage"
                                  placeholder="Dollar Value"
                                  value={
                                    beneficiaryDetails[beneficiary]?.value || ""
                                  }
                                  onChange={(e) =>
                                    handleFieldChange(
                                      beneficiary,
                                      "value",
                                      e.target.value
                                    )
                                  }
                                />
                              )}

                              {distributionType && (
                                <p className="share_beneficiary_card_para">
                                  Distributed Value: $
                                  {distributionType === "percentage"
                                    ? calculateDistributedAmount(
                                        "percentage",
                                        estimatedTotalAmount,
                                        beneficiaryDetails[beneficiary]
                                      )
                                    : calculateDistributedAmount(
                                        "dollar",
                                        estimatedTotalAmount,
                                        beneficiaryDetails[beneficiary]
                                      )}
                                </p>
                              )}
                            </div>
                            <div
                              className="share_beneficiary_card_close"
                              onClick={() =>
                                handleBeneficiaryClose(beneficiary)
                              }
                            >
                              <FontAwesomeIcon icon={faXmark} />
                            </div>
                          </div>
                        )
                      )
                    )}
                  </div>
                </div>
              </div>
            </div>
            {selectedBeneficiaries.length > 0 && (
              <button onClick={handleSave}>Save</button>
            )}
          </div>
        </div>
      )}
    </>
  );
}
