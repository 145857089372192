import React, { useEffect } from "react";
import Form from "react-bootstrap/Form";
import { Button, Card, CardBody, CardHeader, Container } from "reactstrap";
import {
  getBeneficiary,
  getSecondaryUser,
  getToken,
  getUser,
} from "../../services/user-service";
//import { Accordion, data , AccordionContext } from "reactstrap";
import {
  FormLabel,
  Input,
  Option,
  Select,
  Textarea,
  Typography,
} from "@mui/joy";
import { FormControlLabel, Switch, TextField, Tooltip } from "@mui/material";
import axios from "axios";
import { useState } from "react";
import { toast } from "react-toastify";
import "../../css/myestare.css";
// import Deletebutton from "./Deletebutton";
// import UpdateButton from "./UpdateButton";
import { faMinus, faPlus, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Country } from "country-state-city";
import { useNavigate, useParams } from "react-router-dom";
import {
  getSingleInternationalAsset,
  updateInternationalAsset,
} from "../../services/InternationalAssetService";
import "./../../css/formPOPup.css";

export function EditInternationalAssestRealEstate() {
  const { id } = useParams();
  const navigate = useNavigate();
  let [form1, setForm1] = useState(true);

  const toggle = () => {
    setForm1(!form1);
    navigate("/user/my-estate/International_assets");
  };

  // set Add data
  const [data, setData] = useState({
    realEstate: {
      owner: "",
      otherOwner: "",
      propertyCaption: "",
      propertyType: "",
      otherPropertyType: "",
      aptNumber: "",
      streetAddress: "",
      estPropertyVal: "",
      zipCode: "",
      country: "",
      propertyTax: "",
      city: "",
      state: "",
      notes: "",
    },
    mortgages: [
      {
        mortgage: "",

        mortgageNumber: "",
      },
    ],
    sharedDetails: [
      {
        distributedType: "",
        distributedValue: "",
        distributedAmount: "",
        beneficiaryId: "",
      },
    ],
  });

  const [data1, setData1] = useState({
    id: "",
    assetCaption: "",
    assetType: "realEstate",
    countryName: "",
  });

  const handleChangesData = (e, field) => {
    const newValue = e.target.value;
    setData1((prevData) => ({
      ...prevData,
      [field]: newValue,
    }));
  };

  const handleChangesCountry = (event, newValue) => {
    setData1((prevData) => ({
      ...prevData,
      countryName: newValue,
    }));
  };

  // for owner field
  const [ownerName, setOwnerName] = useState([]);

  const handleChange = (event, newValue, stringValue) => {
    if (stringValue === "propertyType") {
      setData((prevData) => ({
        ...prevData,
        realEstate: {
          ...prevData.realEstate,
          propertyType: newValue,
        },
      }));
    }
    if (stringValue === "owner") {
      // Convert ownerName array to a single string

      if (newValue.toString() === "Other") {
      } else {
        setData((prevData) => ({
          ...prevData,
          realEstate: {
            ...prevData.realEstate,
            otherOwner: "",
          },
        }));
      }

      const comingValue =
        typeof newValue === "string" ? newValue.split(",") : newValue;
      const ownerString = comingValue.join(", ");

      setData((prevData) => ({
        ...prevData,
        realEstate: {
          ...prevData.realEstate,
          owner: ownerString,
        },
      }));

      // Update the ownerName state afterwards
      setOwnerName(newValue);
    }
  };

  // Define an array to store user names
  const ownerNames = [];

  // Get primaryUser data
  const primaryUserDetails = getUser();
  const primaryUserName =
    primaryUserDetails.firstName + " " + primaryUserDetails.lastName;

  // Get secondaryUser data
  const secondaryUserDetails = getSecondaryUser();
  let secondaryUserName = "";

  // Check if secondary user exists
  if (secondaryUserDetails !== undefined) {
    secondaryUserName =
      secondaryUserDetails.firstName + " " + secondaryUserDetails.lastName;

    // Push both user names into the array
    ownerNames.push(primaryUserName, secondaryUserName);
    ownerNames.push("Other");
  } else {
    // If secondary user doesn't exist, only push primary user name
    ownerNames.push(primaryUserName);
    ownerNames.push("Other");
  }

  // use state to set the selected images
  const [selectedImage, setSelectedImage] = useState(null);

  const [error, setError] = useState({
    errors: {},
    isError: false,
  });

  const handleChangesRealEstate = (e, field) => {
    const newValue = e.target.value;

    setData((prevData) => ({
      ...prevData,
      realEstate: {
        ...prevData.realEstate,
        [field]: newValue,
      },
    }));
    // const value = data.realEstate.estPropertyVal;
    // setEstimatedTotalAmount(value);
  };

  // Handle image
  const handleImageChange = (event) => {
    const selectedFiles = event.target.files;
    const allowedExtensions = ["pdf"];

    if (selectedFiles) {
      const selectedFilesArray = Array.from(selectedFiles);

      // Check each file's extension
      const invalidFiles = selectedFilesArray.filter((file) => {
        const fileNameParts = file.name.split(".");
        const fileExtension =
          fileNameParts[fileNameParts.length - 1].toLowerCase();
        return !allowedExtensions.includes(fileExtension);
      });

      if (invalidFiles.length === 0) {
        const formData = new FormData();

        selectedFilesArray.forEach((file, index) => {
          formData.append(`file${index + 1}`, file);
        });
        setSelectedImage(selectedFilesArray);
      } else {
        // Handle invalid file extensions
        const invalidExtensions = invalidFiles
          .map((file) => file.name.split(".").pop())
          .join(", ");
        toast.error(
          `Invalid file extensions: ${invalidExtensions}. Please select valid document formats.`,
          {
            position: toast.POSITION.BOTTOM_CENTER,
          }
        );
        event.target.value = ""; // Clear the input field
      }
    }
  };

  // add multiple mordgages
  const [mordgage, setMordgage] = useState([]);
  const [visibleColumnIndex, setVisibleColumnIndex] = useState(0);
  const mordgages = [0, 1, 2, 3, 4];
  const handleAddColumn = () => {
    if (visibleColumnIndex < 4) {
      setMordgage([...mordgage, { label: visibleColumnIndex + 1 }]);
      setVisibleColumnIndex(visibleColumnIndex + 1);
    }
  };

  const handleRemoveColumn = (indexToRemove) => {
    setMordgage(mordgage.filter((_, index) => index !== indexToRemove));
    setVisibleColumnIndex(visibleColumnIndex - 1);

    setData((prevData) => {
      const updatedMortgages = prevData.mortgages.map((mortgage, index) => {
        if (index === indexToRemove) {
          return { mortgage: "" }; // Reset the mortgage value to an empty string
        }
        return mortgage; // Return the original mortgage object for other indices
      });

      // Filter out mortgages with empty strings
      const filteredMortgages = updatedMortgages.filter(
        (mortgage) => mortgage.mortgage !== ""
      );

      // Return the new state with empty mortgages removed
      return {
        ...prevData,
        mortgages: filteredMortgages,
      };
    });
  };

  // post the form
  const AddForm = (event) => {
    event.preventDefault();
    let token = "Bearer " + getToken();
    const formData = new FormData();
    if (null != selectedImage) {
      for (let i = 0; i < selectedImage.length; i++) {
        formData.append(`files`, selectedImage[i]);
      }
    }
    formData.append("asset", JSON.stringify(data));
    formData.append("data", JSON.stringify(data1));

    updateInternationalAsset(formData, token)
      .then((resp) => {
        toast.success("Updated Successfully!!", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
        toggle();
      })
      .catch((error) => {
        toast.error("An error occurred. Please try again.", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      });
  };

  const [showAfterCloseBene, setShowAfterCloseBene] = useState(true);
  let findMortgagesLength = null;
  let [findMortLength, setFindMortLength] = useState(0);
  useEffect(() => {
    const fetchData = async () => {
      try {
        let token = "Bearer " + getToken();
        const res = await getSingleInternationalAsset(token, id);

        setData({
          ...data,
          realEstate: res.assetData.realEstate,
          mortgages: res.assetData.mortgages,
          documents: res.assetData.documents,
          sharedDetails: res.assetData.sharedDetails,
        });
        setData1({
          ...data1,
          id: res.internationalAssetData.id,
          assetCaption: res.internationalAssetData.assetCaption,
          countryName: res.internationalAssetData.countryName,
        });

        const ownerNameArray = res.assetData.realEstate.owner.split(", ");
        setOwnerName(ownerNameArray);
        findMortgagesLength = [...res.assetData.mortgages];
        setFindMortLength(findMortgagesLength.length);
        setVisibleColumnIndex(findMortgagesLength.length - 1);

        const copiedSharedDetails = [...res.assetData.sharedDetails];
        setEstimatedTotalAmount(res.assetData.realEstate.estPropertyVal);

        if (copiedSharedDetails.length > 0) {
          setSharedDetails(res.assetData.sharedDetails);

          ben(copiedSharedDetails[0].distributedType);
          for (var i = 0; i < copiedSharedDetails.length; i++) {
            handleBeneficiarySelection1(copiedSharedDetails[i].beneficiaryId);
            handleFieldChange1(
              copiedSharedDetails[i].beneficiaryId,
              copiedSharedDetails[i].distributedType,
              copiedSharedDetails[i].distributedValue
            );
          }
        }
      } catch (error) {}
    };

    fetchData();
  }, []);

  const validateZipCode = (value) => {
    return /^\d{5}$/.test(value); // Validates a 5-digit numeric input
  };

  useEffect(() => {
    const fetchDataFromAPI = () => {
      if (data.realEstate.zipCode.length === 5) {
        const apiUrl = `https://api.zippopotam.us/us/${data.realEstate.zipCode}`;
        axios
          .get(apiUrl)
          .then((res) => {
            if (res.data && res.data.places && res.data.places.length > 0) {
              const placeName = res.data.places[0]["place name"];

              setData((prevData) => ({
                ...prevData,
                realEstate: {
                  ...prevData.realEstate,
                  city: placeName,
                  country: res.data.country,
                  state: res.data.places[0]["state"],
                },
              }));
            } else {
            }
          })
          .catch((error) => {});
      } else if (data.realEstate.zipCode.length > 5) {
        setData((prevData) => ({
          ...prevData,
          realEstate: {
            ...prevData.realEstate,
            zipCode: "",
            city: "",
            country: "",
            state: "",
          },
        }));
      } else if (data.realEstate.zipCode.length <= 4) {
        setData((prevData) => ({
          ...prevData,
          realEstate: {
            ...prevData.realEstate,
            city: "",
            country: "",
            state: "",
          },
        }));
      }
    };

    fetchDataFromAPI();
  }, [data.realEstate.zipCode]);

  let emaill = getUser().email;
  let to_name = getUser().firstName + " " + getUser().lastName;

  // beneficiary addition in form
  const [beneficiary, setBenificiary] = useState([]);
  const getBenificiarydata = () => {
    let userId = getUser().commonId;

    let token = "Bearer " + getToken();
    getBeneficiary(token, userId)
      .then((res) => {
        setBenificiary(res);
      })
      .catch((err) => {});
  };
  useEffect(() => {
    getBenificiarydata();
  }, []);

  const handleChanges1 = (e, field, { index }) => {
    const { value } = e.target;

    setData((prevData) => {
      const updatedMortgages = [...prevData.mortgages];
      if (!updatedMortgages[index]) {
        updatedMortgages[index] = {};
      }
      updatedMortgages[index][field] = value;

      return {
        ...prevData,
        mortgages: updatedMortgages,
      };
    });
  };

  //
  let [show1, setShow1] = useState(false);
  const [benevisible, setbeneVisible] = useState(false);
  const [distributionType, setDistributionType] = useState("");
  const [selectedBeneficiaries, setSelectedBeneficiaries] = useState([]);
  const [beneficiaryDetails, setBeneficiaryDetails] = useState({});
  const [estimatedTotalAmount, setEstimatedTotalAmount] = useState(0);
  const [beneficiaryVisible, setBeneficiaryVisible] = useState(false);
  const [SelectedBeneficiary, setSelectedBeneficiary] = useState("");
  let [showAdditionField1, setshowAdditionField1] = useState(false);
  const [distributedType, setDistributedType] = useState("");

  const [sharedDetails, setSharedDetails] = useState([
    {
      distributedType: "",
      distributedValue: "",
      distributedAmount: "",
      beneficiaryId: "",
    },
  ]);

  const ben = (newType) => {
    // const newType = sharedDetails[0].distributedType;
    const resetDetails = {};
    Object.keys(beneficiaryDetails).forEach((beneficiary) => {
      resetDetails[beneficiary] = { percentage: "", value: "" };
    });
    setDistributedType(newType);
    setBeneficiaryDetails(resetDetails);
  };

  const handleShowBeneficiary = () => {
    setbeneVisible(true);
    setShow1(false);
    setShowAfterCloseBene(true);
    setEstimatedTotalAmount(data.realEstate.estPropertyVal);
    // data.sharedDetails = [];
  };

  const handleReset = () => {
    setbeneVisible(false);
    setDistributionType("");
    setSelectedBeneficiaries([]);
    setBeneficiaryDetails({});
    setShowAfterCloseBene(false);
  };

  const handleDistributionTypeChange = (event) => {
    var distributedType = event.target.value;
    const sharedDetails1 = sharedDetails.map((detail) => {
      // Change the distributedType value as needed
      detail.distributedType = distributedType;
      return detail;
    });

    setSharedDetails(sharedDetails1);
    const newType = event.target.value;
    const resetDetails = {};
    Object.keys(beneficiaryDetails).forEach((beneficiary) => {
      resetDetails[beneficiary] = { percentage: "", dollar: "" };
    });
    setDistributedType(newType);
    setBeneficiaryDetails(resetDetails);
  };

  const handleBeneficiarySelection1 = (selectedBeneficiary) => {
    if (selectedBeneficiary === null) {
      return null;
    }
    setSelectedBeneficiaries((prevSelectedBeneficiaries) => {
      // Check if the selectedBeneficiary is already included
      if (!prevSelectedBeneficiaries.includes(selectedBeneficiary)) {
        // If not included, update the selected beneficiaries
        const newSelectedBeneficiaries = [
          ...prevSelectedBeneficiaries,
          selectedBeneficiary,
        ];

        return newSelectedBeneficiaries;
      }

      // If already included, return the previous state without any changes
      return prevSelectedBeneficiaries;
    });

    setBeneficiaryDetails((prevBeneficiaryDetails) => {
      const newBeneficiaryDetails = {
        ...prevBeneficiaryDetails,
        [selectedBeneficiary]: { percentage: "", dollar: "" },
      };

      return newBeneficiaryDetails;
    });
  };

  const handleBeneficiarySelection = (event) => {
    const selectedBeneficiary = event.target.value;

    if (!selectedBeneficiaries.includes(selectedBeneficiary)) {
      setSelectedBeneficiaries([...selectedBeneficiaries, selectedBeneficiary]);
      setBeneficiaryDetails({
        ...beneficiaryDetails,
        [selectedBeneficiary]: { percentage: "", dollar: "" },
      });
    }
  };

  const handleBeneficiaryClose = (beneficiary) => {
    const updatedBeneficiaries = selectedBeneficiaries.filter(
      (b) => b !== beneficiary
    );
    setSelectedBeneficiaries(updatedBeneficiaries);
    const updatedDetails = { ...beneficiaryDetails };
    delete updatedDetails[beneficiary];
    setBeneficiaryDetails(updatedDetails);
  };

  const getBenificiaryName = (id) => {
    var foundBenificiary = null;
    if (id.beneficiary === undefined) {
      foundBenificiary = beneficiary.find((b) => b.id === parseInt(id));
    } else {
      foundBenificiary = beneficiary.find(
        (b) => b.id === parseInt(id.beneficiary)
      );
    }

    if (foundBenificiary) {
      return `${foundBenificiary.firstName} ${foundBenificiary.lastName}`;
    } else {
      return "Benificiary not found";
    }
  };

  const handleFieldChange1 = (beneficiary, field, value) => {
    setBeneficiaryDetails((prevDetails) => {
      // Create a copy of the state
      let updatedDetails = { ...prevDetails };

      // Update the copy
      updatedDetails = {
        ...updatedDetails,
        [beneficiary]: {
          ...updatedDetails[beneficiary],
          [field]: value,
        },
      };

      // Return the updated state
      return updatedDetails;
    });
  };

  const handleFieldChange = (beneficiary, field, value) => {
    if (!estimatedTotalAmount || parseFloat(estimatedTotalAmount) === 0) {
      toast.error(
        "Please provide a valid estimated value before adding percentages!"
      );
      return;
    }
    let updatedDetails = { ...beneficiaryDetails };
    if (/^\d*$/.test(value)) {
      updatedDetails = {
        ...updatedDetails,
        [beneficiary]: {
          ...updatedDetails[beneficiary],
          [field]: value,
        },
      };
    }
    let totalPercentage = 0;
    let totalValue = 0;
    Object.keys(updatedDetails).forEach((key) => {
      const beneficiaryPercentage = parseFloat(updatedDetails[key]?.percentage);
      const beneficiaryValue = parseFloat(updatedDetails[key]?.dollar);
      if (!isNaN(beneficiaryPercentage) && beneficiaryPercentage >= 0) {
        totalPercentage += beneficiaryPercentage;
      }
      if (!isNaN(beneficiaryValue) && beneficiaryValue >= 0) {
        totalValue += beneficiaryValue;
      }
    });
    if (
      totalPercentage > 100 ||
      totalValue > parseFloat(estimatedTotalAmount)
    ) {
      updatedDetails[beneficiary][field] = "";
      toast.error(
        "Total percentage exceeds 100% or total value exceeds estimated value!"
      );
    } else {
      setBeneficiaryDetails(updatedDetails);
    }
  };

  const calculateDistributedAmount = (
    distributedType,
    balance,
    beneficiaryDetail
  ) => {
    // Assuming beneficiaryDetail is an object with a property 'value'
    if (distributedType === "percentage") {
      const calculatedAmount =
        (parseFloat(balance) * parseFloat(beneficiaryDetail.percentage)) / 100;

      return calculatedAmount.toFixed(2);
    } else if (distributedType === "dollar") {
      const detailValue = parseFloat(beneficiaryDetail.dollar);
      return detailValue.toFixed(2);
    }
    return "0.00";
  };

  const handleSave = () => {
    toast.success("Saved successfully!");
    setbeneVisible(false);
    var i = 0;
    Object.keys(beneficiaryDetails).forEach((key) => {
      const beneficiaryPercentage = parseFloat(
        beneficiaryDetails[key]?.percentage
      );
      const beneficiaryValue = parseFloat(beneficiaryDetails[key]?.dollar);
      if (!isNaN(beneficiaryPercentage) && beneficiaryPercentage >= 0) {
        const distributedAmount = calculateDistributedAmount(
          "percentage",
          estimatedTotalAmount,
          beneficiaryDetails[key]
        );
        handleChanges2(
          "percentage",
          beneficiaryPercentage,
          distributedAmount,
          parseInt(key),
          i
        );
        i += 1;
      }
      if (!isNaN(beneficiaryValue) && beneficiaryValue >= 0) {
        const distributedAmount = calculateDistributedAmount(
          "dollar",
          estimatedTotalAmount,
          beneficiaryDetails[key]
        );
        handleChanges2(
          "dollar",
          beneficiaryValue,
          distributedAmount,
          parseInt(key),
          i
        );
        i += 1;
      }
    });
  };

  const handleChanges2 = (t, v, a, b, i) => {
    const updatedSharedDetails = [...data.sharedDetails];
    const updatedElement = {
      ...updatedSharedDetails[i],
      distributedType: t,
      distributedValue: v,
      distributedAmount: a,
      beneficiaryId: b,
    };
    updatedSharedDetails[i] = updatedElement;

    setData((prevState) => ({
      ...prevState,
      sharedDetails: updatedSharedDetails,
    }));
    data.sharedDetails[i] = updatedSharedDetails[i];
  };

  return (
    <>
      {form1 && (
        <div className="overlay1" style={{ transition: "500ms", height: "" }}>
          <div className="addform_ichest">
            <div className="addform_main">
              <div className="addform_heading">
                <h3 className="addform_heading_h1">
                  Edit Real Estate Properties
                </h3>
                <div className="addform_close">
                  <FontAwesomeIcon
                    icon={faXmark}
                    onClick={() => {
                      navigate("/user/my-estate/International_assets");
                    }}
                  />
                </div>
              </div>
              <div className="addform_body">
                <Form onSubmit={AddForm} className="addform_body">
                  <div className="addform_select_body">
                    <div className="addform_body_left">
                      <div className="address_input">
                        <div className="mt-2 addform_input_half">
                          <div>
                            <Tooltip title="Enter Caption for your assets">
                              <div>
                                <FormLabel>Assets Caption</FormLabel>
                                <Input
                                  className="input_mui_joy"
                                  placeholder="Enter asset caption"
                                  onChange={(e) =>
                                    handleChangesData(e, "assetCaption")
                                  }
                                  value={data1.assetCaption}
                                />
                              </div>
                            </Tooltip>
                          </div>
                        </div>

                        <div className="mt-2 addform_input_half">
                          <div>
                            <Tooltip title="Enter the Country of your assets">
                              <div>
                                <FormLabel required>Country Name</FormLabel>

                                <Select
                                  placeholder="Select country name"
                                  onChange={(e, newValue) =>
                                    handleChangesCountry(e, newValue)
                                  }
                                  value={data1.countryName}
                                  required
                                >
                                  {Country.getAllCountries().map((v, index) => {
                                    return (
                                      <Option key={index} value={v.name}>
                                        {v.name}
                                      </Option>
                                    );
                                  })}
                                </Select>
                              </div>
                            </Tooltip>
                          </div>
                        </div>
                      </div>
                      <div>
                        <Tooltip title="Select Owner" className="mt-2">
                          <div>
                            <FormLabel required>Select Owner</FormLabel>

                            <Select
                              placeholder="Select Owner"
                              value={ownerName}
                              multiple
                              onChange={(event, newValue) =>
                                handleChange(event, newValue, "owner")
                              }
                              sx={{
                                minWidth: "13rem",
                              }}
                              slotProps={{
                                listbox: {
                                  sx: {
                                    width: "100%",
                                  },
                                },
                              }}
                              required
                            >
                              {ownerNames.map((name) => (
                                <Option key={name} value={name}>
                                  {name}
                                </Option>
                              ))}
                            </Select>
                          </div>
                        </Tooltip>
                      </div>

                      {data.realEstate.owner.includes("Other") && (
                        <div className="mt-2">
                          <Tooltip title="Enter Your Other Owner Name ">
                            <div>
                              <FormLabel>Other Owner</FormLabel>
                              <Input
                                className="input_mui_joy"
                                placeholder="Enter Other Owner"
                                id="otherprop"
                                onChange={(e) =>
                                  handleChangesRealEstate(e, "otherOwner")
                                }
                                value={data.realEstate.otherOwner}
                              />
                            </div>
                          </Tooltip>
                        </div>
                      )}

                      <div>
                        <Tooltip title="Enter Heading For Property ">
                          <div className="mt-2">
                            <FormLabel>Property Heading</FormLabel>
                            <Input
                              className="input_mui_joy"
                              placeholder="Enter property heading"
                              value={data.realEstate.propertyCaption}
                              onChange={(e) =>
                                handleChangesRealEstate(e, "propertyCaption")
                              }
                            />
                          </div>
                        </Tooltip>
                      </div>
                      <div>
                        <Tooltip title="Select The Type Of Property">
                          <div className="mt-2">
                            <FormLabel required>Type Of Property</FormLabel>

                            <Select
                              value={data.realEstate.propertyType}
                              onChange={(event, newValue) =>
                                handleChange(event, newValue, "propertyType")
                              }
                              required
                            >
                              <Option value="">
                                Select Your Type Of Property
                              </Option>
                              <Option value="Residential">Residential</Option>
                              <Option value="Commercial">Commercial</Option>
                              <Option value="Industrial">Industrial</Option>
                              <Option value="Land">Land</Option>
                              <Option value="Other">Other</Option>
                            </Select>
                          </div>
                        </Tooltip>
                      </div>

                      {data.propertyType === "Other" && (
                        <div className="mt-3">
                          <Tooltip title="Enter Your Apartment Number ">
                            <TextField
                              fullWidth
                              type="text"
                              label="Enter Other Property"
                              id="otherprop"
                              size="normal"
                              onChange={(e) =>
                                handleChangesRealEstate(e, "otherPropertyType")
                              }
                              value={data.realEstate.otherPropertyType}
                            />
                          </Tooltip>
                        </div>
                      )}
                      <div className="address_input">
                        <div className="mt-2 addform_input_half">
                          <div>
                            <Tooltip title="Enter a valid Zip-code ">
                              <div>
                                <FormLabel required>Zip Code</FormLabel>
                                <Input
                                  className="input_mui_joy"
                                  type="number"
                                  placeholder="Enter your Zip Code"
                                  id="zipCode"
                                  onChange={(e) =>
                                    handleChangesRealEstate(e, "zipCode")
                                  }
                                  value={data.realEstate.zipCode}
                                  inputprops={{
                                    minLength: 5,
                                    maxLength: 5,
                                  }}
                                  error={
                                    !validateZipCode(data.realEstate.zipCode)
                                  }
                                  required
                                />
                                {!validateZipCode(data.realEstate.zipCode) && (
                                  <Typography
                                    variant="body2"
                                    color="danger"
                                    sx={{
                                      marginTop: "4px",
                                      fontSize: "0.875rem",
                                    }}
                                  >
                                    Please enter a valid 5-digit Zip Code
                                  </Typography>
                                )}
                              </div>
                            </Tooltip>
                          </div>
                        </div>
                        <div className="mt-2 addform_input_half">
                          <div>
                            <Tooltip title="Automatically populate based on the zip code">
                              <div style={{ width: "100%" }}>
                                <FormLabel>City/Town *</FormLabel>
                                <Input
                                  className="input_mui_joy"
                                  placeholder="City/Town *"
                                  value={data.realEstate.city}
                                  readOnly
                                  disabled
                                />
                              </div>
                            </Tooltip>
                          </div>
                        </div>
                      </div>

                      <div className="address_input">
                        <div className="mt-2 addform_input_half">
                          <div>
                            <Tooltip title="Automatically populate based on the zip code">
                              <div>
                                <FormLabel>State *</FormLabel>
                                <Input
                                  className="input_mui_joy"
                                  placeholder="State *"
                                  value={data.realEstate.state}
                                  readOnly
                                  disabled
                                />
                              </div>
                            </Tooltip>
                          </div>
                        </div>

                        <div className="mt-2 addform_input_half">
                          <div>
                            <Tooltip title="Automatically populate based on the zip code">
                              <div>
                                <FormLabel>Country *</FormLabel>
                                <Input
                                  className="input_mui_joy"
                                  placeholder="Country *"
                                  value={data.realEstate.country}
                                  readOnly
                                  disabled
                                />
                              </div>
                            </Tooltip>
                          </div>
                        </div>
                      </div>
                      <div className="address_input">
                        <div className="mt-2 addform_input_half">
                          <div>
                            <Tooltip title="Enter Your Street Address ">
                              <div style={{ width: "100%" }}>
                                <FormLabel>Street Address</FormLabel>
                                <Input
                                  className="input_mui_joy"
                                  placeholder="Street Address *"
                                  onChange={(e) =>
                                    handleChangesRealEstate(e, "streetAddress")
                                  }
                                  value={data.realEstate.streetAddress}
                                />
                              </div>
                            </Tooltip>
                          </div>
                        </div>

                        <div className="mt-2 addform_input_half">
                          <div>
                            <Tooltip title="Enter Your Apartment Number ">
                              <div style={{ width: "100%" }}>
                                <FormLabel>Apartment</FormLabel>
                                <Input
                                  className="input_mui_joy"
                                  placeholder="Apartment"
                                  onChange={(e) =>
                                    handleChangesRealEstate(e, "aptNumber")
                                  }
                                  value={data.realEstate.aptNumber}
                                />
                              </div>
                            </Tooltip>
                          </div>
                        </div>
                      </div>
                      <div className="address_input">
                        <div className="mt-2 addform_input_half">
                          <div>
                            <Tooltip title="Enter your estimated Property Value">
                              <div style={{ width: "100%" }}>
                                <FormLabel>Property Value</FormLabel>
                                <Input
                                  className="input_mui_joy"
                                  placeholder="Estimated Property Value"
                                  onChange={(e) =>
                                    handleChangesRealEstate(e, "estPropertyVal")
                                  }
                                  value={data.realEstate.estPropertyVal}
                                  startDecorator={<span>$</span>}
                                />
                              </div>
                            </Tooltip>
                          </div>
                        </div>

                        <div className="mt-2 addform_input_half">
                          <div>
                            <Tooltip title="Enter your Estimated Annual Property Tax">
                              <div style={{ width: "100%" }}>
                                <FormLabel>Annual Property Tax</FormLabel>
                                <Input
                                  className="input_mui_joy"
                                  placeholder="Estimated Annual Property Tax"
                                  onChange={(e) =>
                                    handleChangesRealEstate(e, "propertyTax")
                                  }
                                  value={data.realEstate.propertyTax}
                                  startDecorator={<span>$</span>}
                                />
                              </div>
                            </Tooltip>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="addform_body_right">
                      <div>
                        {mordgages.map((index) => (
                          <div
                            key={index}
                            style={{
                              marginBottom: "10px",
                              display:
                                index <= visibleColumnIndex ? "block" : "none",
                            }}
                          >
                            <div className="address_input">
                              <div className="mt-2 addform_input_half">
                                <Tooltip
                                  title={
                                    index === 0
                                      ? `Add your primary mortgage`
                                      : "Add your secondary mortgage"
                                  }
                                >
                                  <div>
                                    {index === 0 ? (
                                      <FormLabel required>
                                        {`Primary Mortgage`}
                                      </FormLabel>
                                    ) : (
                                      <FormLabel required>
                                        {`Secondary Number`}
                                      </FormLabel>
                                    )}
                                    <Input
                                      className="input_mui_joy"
                                      placeholder={
                                        index === 0
                                          ? `Primary Mortgage`
                                          : "Secondary Number"
                                      }
                                      required={visibleColumnIndex >= index}
                                      id={`mortgageNumber${index + 1}`}
                                      onChange={(e) =>
                                        handleChanges1(e, "mortgageNumber", {
                                          index,
                                        })
                                      }
                                      value={
                                        data.mortgages[index]
                                          ? data.mortgages[index].mortgageNumber
                                          : ""
                                      }
                                    />
                                  </div>
                                </Tooltip>
                              </div>
                              <div className="mt-2 addform_input_half">
                                <Tooltip title={`Add your loan`}>
                                  <div>
                                    <FormLabel required>{`Amount`}</FormLabel>
                                    <Input
                                      className="input_mui_joy"
                                      type="number"
                                      placeholder="Amount"
                                      required={visibleColumnIndex >= index}
                                      id={`mortgage${index + 1}`}
                                      onChange={(e) =>
                                        handleChanges1(e, "mortgage", {
                                          index,
                                        })
                                      }
                                      value={
                                        data.mortgages[index]
                                          ? data.mortgages[index].mortgage
                                          : ""
                                      }
                                      startDecorator={<span>$</span>}
                                    />
                                  </div>
                                </Tooltip>
                              </div>
                            </div>
                            {index >= findMortLength && (
                              <div style={{ marginTop: "4px" }}>
                                <Button
                                  style={{
                                    height: "30px",
                                    width: "30px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    borderRadius: "50%",
                                    backgroundColor: "rgb(255, 74, 74)",
                                    border: "none",
                                  }}
                                  onClick={() => handleRemoveColumn(index)}
                                >
                                  <FontAwesomeIcon icon={faMinus} />
                                </Button>
                              </div>
                            )}
                          </div>
                        ))}
                        {visibleColumnIndex < 4 && (
                          <div style={{ marginTop: "2px" }}>
                            <Button
                              style={{
                                height: "30px",
                                width: "30px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                borderRadius: "50%",
                                backgroundColor: "#4aafff",
                                border: "none",
                              }}
                              onClick={handleAddColumn}
                            >
                              <FontAwesomeIcon icon={faPlus} />
                            </Button>
                          </div>
                        )}
                      </div>
                      <div className="address_input">
                        <div className="mt-2 addform_input_half">
                          <div
                            className="bene-select mt-3"
                            onClick={handleShowBeneficiary}
                            style={{ cursor: "pointer" }}
                          >
                            Select Beneficiary
                          </div>
                        </div>
                        <div className="mt-2 addform_input_half">
                          <div style={{ width: "100%" }}>
                            <Tooltip title="Upload your property related file it should be under 400 kb">
                              <div>
                                <FormLabel>Supporting Document</FormLabel>
                                <input
                                  style={{
                                    border: "solid 1px lightgray",
                                    borderLeft: "none",
                                    width: "100%",
                                    borderRadius: "2px",
                                  }}
                                  type="file"
                                  name="myfile"
                                  id="exampleFile"
                                  onChange={handleImageChange}
                                  accept=".pdf"
                                  multiple
                                />
                              </div>
                            </Tooltip>
                          </div>
                        </div>
                      </div>
                      <div
                        className="mt-2 form1-double"
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          width: "100%",
                          justifyContent: "space-between",
                          marginTop: "-12px",
                        }}
                      ></div>

                      <div className="address_input">
                        <div style={{ width: "100%" }} className="mt-2">
                          <Tooltip title="Enter notes for your real estate">
                            <FormLabel>Notes</FormLabel>
                            <Textarea
                              sx={{ height: "182px", minHeight: "52px" }}
                              placeholder="Notes"
                              id="notes"
                              onChange={(e) =>
                                handleChangesRealEstate(e, "notes")
                              }
                              value={data.realEstate.notes}
                            />
                          </Tooltip>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="text-center"
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <Button outline type="" className="my-estate-add-btn">
                      Update
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      )}

      {benevisible && (
        <div className="popup">
          <div
            className="popup-content"
            style={{
              minWidth: "350px",
              width: "100%",
              maxWidth: "700px",
            }}
          >
            <div className="note_popup">
              <div className="note_popup_heading">
                <div className="share_property_heading">
                  <h2>Share Property </h2>
                </div>
                <div>
                  <button
                    className="note_popup_heading_close_btn"
                    onClick={handleReset}
                  >
                    <FontAwesomeIcon icon={faXmark} />
                  </button>
                </div>
              </div>
              <div className="share_property_est_value">
                <p>
                  Estimated Value:{" "}
                  <code style={{ color: "green", fontWeight: "bold" }}>
                    ${estimatedTotalAmount}
                  </code>
                </p>
                {/* {JSON.stringify(sharedDetails)}  */}
                {/* <p>----------------------------------------------------</p>
                    {JSON.stringify(data.documents)}
                    <p>----------------------------------------------------</p>
                    {JSON.stringify(data.accounts)}
                    <p>----------------------------------------------------</p>
                    {JSON.stringify(data.bank)}
                    <p>----------------------------------------------------</p>
                    {JSON.stringify(data)} */}
              </div>
              <div className="BeneficiarySelect">
                <div className="BeneficiarySelectContainer">
                  <div className="BeneficiarySelectRow">
                    <div className="share_property_Type">
                      <p className="share_property_Type_paragraph">
                        Choose Distribution Type:{" "}
                      </p>
                      <p className="ms-2 text-black fw-normal">
                        {/* {sharedDetails[0].distributedType} */}
                      </p>
                      <select
                        value={sharedDetails[0].distributedType}
                        onChange={(e) => handleDistributionTypeChange(e)}
                        class="share_property_Type_select"
                        disabled={
                          sharedDetails[0].distributedType !== "" ? true : false
                        }
                      >
                        <option value="">Select Type</option>
                        <option value="percentage">Percentage</option>
                        <option value="dollar">Dollar</option>
                      </select>
                    </div>
                    <div className="SelectContainer">
                      <select
                        className="Property-inputfiled"
                        onChange={handleBeneficiarySelection}
                        value=""
                        disabled={!distributedType}
                      >
                        <option value="" disabled hidden>
                          {distributedType
                            ? "Select Your Beneficiary Username"
                            : "Select Type First"}
                        </option>
                        {beneficiary.map((benif) => (
                          <option
                            key={benif.id}
                            value={benif.id}
                            disabled={selectedBeneficiaries.includes(benif.id)}
                          >
                            {/* {benif.username} */}
                            {`${benif.firstName} ${benif.lastName}`}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  {/* <div className="share_beneficiary_main_card">
                        {sharedDetails[0].distributedType !== null && (
                          <>
                            {sharedDetails[0].distributedType === 'dollar' ? (
                              <div>
                                <div className="share_beneficiary_card_close" onClick={() => handleBeneficiaryClose(beneficiary)}>
                                  <FontAwesomeIcon icon={faXmark} />
                                </div> 
                                <p>Beneficiary: </p>
                                <TextField type="number" label="Dollar" />
                              </div>
                            ) : null}

                            {sharedDetails[0].distributedType === 'percentage' ? (
                              <div>
                                <p>Beneficiary: </p>
                                <TextField type="number" label="Percentage" />
                              </div>
                            ) : null}
                          </>
                        )}
                      </div> */}
                  <div className="share_beneficiary_main_card">
                    {selectedBeneficiaries.map((beneficiary) => (
                      <div key={beneficiary} class="share_beneficiary_card">
                        <div>
                          <p className="share_beneficiary_card_para">
                            Beneficiary: {getBenificiaryName({ beneficiary })}
                          </p>
                          {distributedType === "percentage" && (
                            <input
                              type="text"
                              className="share_ben_percentage"
                              placeholder="Percentage"
                              value={
                                beneficiaryDetails[beneficiary]?.percentage ||
                                ""
                              }
                              onChange={(e) =>
                                handleFieldChange(
                                  beneficiary,
                                  "percentage",
                                  e.target.value
                                )
                              }
                            />
                          )}
                          {distributedType === "dollar" && (
                            <input
                              type="text"
                              className="share_ben_percentage"
                              placeholder="Dollar Value"
                              value={
                                beneficiaryDetails[beneficiary]?.dollar || ""
                              }
                              onChange={(e) =>
                                handleFieldChange(
                                  beneficiary,
                                  "dollar",
                                  e.target.value
                                )
                              }
                            />
                          )}

                          {distributedType && (
                            <p className="share_beneficiary_card_para">
                              {/* <p></p> */}
                              Distributed Value: $
                              {distributedType === "percentage"
                                ? calculateDistributedAmount(
                                    "percentage",
                                    estimatedTotalAmount,
                                    beneficiaryDetails[beneficiary]
                                  )
                                : calculateDistributedAmount(
                                    "dollar",
                                    estimatedTotalAmount,
                                    beneficiaryDetails[beneficiary]
                                  )}
                            </p>
                          )}
                        </div>
                        {/* <div
                            className="share_beneficiary_card_close"
                            onClick={() => handleBeneficiaryClose(beneficiary)}
                          >
                            <FontAwesomeIcon icon={faXmark} />
                          </div> */}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            {selectedBeneficiaries.length > 0 && (
              <button onClick={handleSave}>Save</button>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export function EditInternationalAssestBank() {
  const { id } = useParams();
  const navigate = useNavigate();
  let [form1, setForm1] = useState(true);

  const toggle = () => {
    setForm1(!form1);
    navigate("/user/my-estate/International_assets");
  };

  const [data, setData] = useState({
    bank: {
      owner: "",
      otherOwner: "",
      bankName: "",
      safetyBox: "",
      safetyBoxNumber: "",
      notes: "",
      bankType: "",
      totalAmount: "",
    },
    accounts: [
      {
        accountType: "",
        accountNumber: "",
        balance: "",
      },
    ],
    documents: [
      {
        fileName: "",
      },
    ],
    sharedDetails: [
      {
        distributedType: "",
        distributedValue: "",
        distributedAmount: "",
        beneficiaryId: "",
      },
    ],
  });

  const [data1, setData1] = useState({
    id: "",
    assetCaption: "",
    assetType: "bank",
    countryName: "",
  });

  const handleChangesData = (e, field) => {
    const newValue = e.target.value;
    setData1((prevData) => ({
      ...prevData,
      [field]: newValue,
    }));
  };

  const handleChangesCountry = (event, newValue) => {
    setData1((prevData) => ({
      ...prevData,
      countryName: newValue,
    }));
  };

  // for owner field
  const [ownerName, setOwnerName] = useState([]);

  const handleChange = (event, newValue, stringValue) => {
    if (stringValue === "owner") {
      // Convert ownerName array to a single string

      if (newValue.toString() === "Other") {
      } else {
        setData((prevData) => ({
          ...prevData,
          bank: {
            ...prevData.bank,
            otherOwner: "",
          },
        }));
      }

      const comingValue =
        typeof newValue === "string" ? newValue.split(",") : newValue;
      const ownerString = comingValue.join(", ");

      setData((prevData) => ({
        ...prevData,
        bank: {
          ...prevData.bank,
          owner: ownerString,
        },
      }));

      // Update the ownerName state afterwards
      setOwnerName(newValue);
    }
  };

  // Define an array to store user names
  const ownerNames = [];

  // Get primaryUser data
  const primaryUserDetails = getUser();
  const primaryUserName =
    primaryUserDetails.firstName + " " + primaryUserDetails.lastName;

  // Get secondaryUser data
  const secondaryUserDetails = getSecondaryUser();
  let secondaryUserName = "";

  // Check if secondary user exists
  if (secondaryUserDetails !== undefined) {
    secondaryUserName =
      secondaryUserDetails.firstName + " " + secondaryUserDetails.lastName;

    // Push both user names into the array
    ownerNames.push(primaryUserName, secondaryUserName);
    ownerNames.push("Other");
  } else {
    // If secondary user doesn't exist, only push primary user name
    ownerNames.push(primaryUserName);
    ownerNames.push("Other");
  }

  const [selectedImage, setSelectedImage] = useState([]);

  const handleChangesBank = (e, field) => {
    const newValue = e.target.value;
    setData((prevData) => ({
      ...prevData,
      bank: {
        ...prevData.bank,
        [field]: newValue,
      },
    }));
    const balances = data.accounts.map((account) => account.balance || 0);
    const newTotalBalance = balances.reduce(
      (acc, curr) => acc + parseFloat(curr),
      0
    );
    setEstimatedTotalAmount(newTotalBalance);
  };
  const [estimatedTotalAmount, setEstimatedTotalAmount] = useState(0);
  const [visibleColumnIndex, setVisibleColumnIndex] = useState(0);
  const [account, setAccount] = useState([]);
  const [distributedType, setDistributedType] = useState("");
  const [selectedBeneficiaries, setSelectedBeneficiaries] = useState([]);
  const [beneficiaryDetails, setBeneficiaryDetails] = useState([]);

  const multipleAccount = [0, 1, 2, 3];
  const handleAddBank = () => {
    if (visibleColumnIndex < 3) {
      setAccount([...account, { label: visibleColumnIndex + 1 }]);
      setVisibleColumnIndex(visibleColumnIndex + 1);
    }
  };

  const handleRemoveBank = (indexToRemove) => {
    if (visibleColumnIndex > 0) {
      // Filter out the account with the index to remove
      const updatedAccounts = account.filter(
        (_, index) => index !== indexToRemove - 1
      );
      setAccount(updatedAccounts);

      // Adjust visibleColumnIndex if the removed index is within the current visible range
      if (indexToRemove - 1 < visibleColumnIndex) {
        setVisibleColumnIndex(visibleColumnIndex - 1);
      }
    }

    setData((prevData) => {
      const updatedAccounts = prevData.accounts.map((account, index) => {
        if (index === indexToRemove) {
          // Create a new object with empty values for the removed index
          return {
            accountType: "",
            accountNumber: "",
            balance: "",
          };
        }
        return account; // Return the original bank object for other indices
      });

      // Filter out accounts with empty strings for accountType, accountNumber, and balance
      const filteredAccounts = updatedAccounts.filter(
        (account) =>
          account.accountType !== "" ||
          account.accountNumber !== "" ||
          account.balance !== ""
      );

      // Update the state with the new array of bank accounts
      const newData = {
        ...prevData,
        accounts: filteredAccounts,
      };

      // Perform further operations directly after updating the state
      // Filter out accounts with empty strings for accountType, accountNumber, and balance
      const furtherFilteredAccounts = newData.accounts.filter(
        (account) =>
          account.accountType !== "" ||
          account.accountNumber !== "" ||
          account.balance !== ""
      );

      // Update the state with the further filtered accounts
      setData({
        ...newData,
        accounts: furtherFilteredAccounts,
      });

      return newData;
    });

    setSelectedBankTypes((prevSelectedBankTypes) => {
      const newSelectedBankTypes = [...prevSelectedBankTypes];
      newSelectedBankTypes.splice(indexToRemove, 1);
      return newSelectedBankTypes;
    });
  };

  const [selectedBankTypes, setSelectedBankTypes] = useState(
    Array(multipleAccount.length).fill("")
  );
  // const [showAfterCloseBene, setShowAfterCloseBene] = useState(true);
  const handleReset = () => {
    setbeneVisible(false);
    setDistributedType("");
    setSelectedBeneficiaries([]);
    setBeneficiaryDetails({});
  };

  // Handle image
  const handleImageChange = (event, maxFilesAllowed) => {
    const selectedFiles = event.target.files;
    const allowedExtensions = ["pdf"];
    if (selectedFiles) {
      const selectedFilesArray = Array.from(selectedFiles);
      if (selectedFilesArray.length > maxFilesAllowed) {
        // Notify the user if they exceed the file limit
        toast.error(`You can only select up to ${maxFilesAllowed} files.`, {
          position: toast.POSITION.BOTTOM_CENTER,
        });
        event.target.value = ""; // Clear the file input
      } else {
        const invalidFiles = selectedFilesArray.filter((file) => {
          const fileNameParts = file.name.split(".");
          const fileExtension =
            fileNameParts[fileNameParts.length - 1].toLowerCase();
          return !allowedExtensions.includes(fileExtension);
        });
        if (invalidFiles.length === 0) {
          const formData = new FormData();
          selectedFilesArray.forEach((file, index) => {
            formData.append(`file${index + 1}`, file);
          });
          setSelectedImage(selectedFilesArray);
        } else {
          const invalidExtensions = invalidFiles
            .map((file) => file.name.split(".").pop())
            .join(", ");
          toast.error(
            `Invalid file extensions: ${invalidExtensions}. Please select valid document formats.`,
            {
              position: toast.POSITION.BOTTOM_CENTER,
            }
          );
          event.target.value = "";
        }
      }
    }
  };
  // const [bankName, setBankName] = React.useState("");
  const bankHandleChange = (event) => {
    // if (event.target === undefined) {
    //   console.log("event wallet : ", event);
    //   setBankName(event);
    //   data.bank = event;
    //   console.log("data.banks : ", bankName);
    // } else {
    //   console.log("event target : ", event.target);
    //   setBankName(event.target.value);
    //   data.bank = event.target.value;
    // }
  };
  // post the form
  const BankForm = (event) => {
    event.preventDefault();
    let token = "Bearer " + getToken();
    const formData = new FormData();
    if (null != selectedImage) {
      for (let i = 0; i < selectedImage.length; i++) {
        formData.append(`files`, selectedImage[i]);
        console.log("this is file indexs", selectedImage[i]);
      }
    }
    formData.append("asset", JSON.stringify(data));
    formData.append("data", JSON.stringify(data1));
    updateInternationalAsset(formData, token)
      .then((resp) => {
        toast.success("Updated Successfully!!", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
        toggle();
      })
      .catch((error) => {
        toast.error("An error occurred. Please try again.", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      });
  };
  const [sharedDetails, setSharedDetails] = useState([
    {
      distributedType: "",
      distributedValue: "",
      distributedAmount: "",
      beneficiaryId: "",
    },
  ]);

  function stringToBoolean(str) {
    return str.toLowerCase() === "true";
  }

  let findAccountLength = null;
  let [findAccLength, setFindAccLength] = useState(0);
  useEffect(() => {
    const fetchData = async () => {
      try {
        let token = "Bearer " + getToken();
        const res = await getSingleInternationalAsset(token, id);

        setData({
          ...data,
          bank: res.assetData.bank,
          accounts: res.assetData.accounts,
          documents: res.assetData.documents,
          sharedDetails: res.assetData.sharedDetails,
        });

        setData1({
          ...data1,
          id: res.internationalAssetData.id,
          assetCaption: res.internationalAssetData.assetCaption,
          countryName: res.internationalAssetData.countryName,
        });
        const ownerNameArray = res.assetData.bank.owner.split(", ");
        setOwnerName(ownerNameArray);
        setChecked(stringToBoolean(res.assetData.bank.safetyBox));
        findAccountLength = [...res.assetData.accounts];
        setFindAccLength(findAccountLength.length);
        setVisibleColumnIndex(findAccountLength.length - 1);
        const copiedSharedDetails = [...res.assetData.sharedDetails];

        // setBankName(res.banks);
        setEstimatedTotalAmount(res.assetData.bank.totalAmount);

        const checkSelectedBankTypes = [...res.assetData.accounts];
        setSelectedBankTypes((prevSelectedBankTypes) => {
          const newSelectedBankTypes = [...prevSelectedBankTypes];
          // Check each account type and update the selected bank types accordingly
          checkSelectedBankTypes.forEach((account, index) => {
            if (account.accountType === "Checking Account") {
              newSelectedBankTypes[index] = "Checking Account";
            } else if (account.accountType === "Savings Account") {
              newSelectedBankTypes[index] = "Savings Account";
            } else if (account.accountType === "Investment Account") {
              newSelectedBankTypes[index] = "Investment Account";
            } else if (account.accountType === "C.D Account") {
              newSelectedBankTypes[index] = "C.D Account";
            }
          });

          return newSelectedBankTypes;
        });

        if (copiedSharedDetails.length > 0) {
          setSharedDetails(res.assetData.sharedDetails);
          console.log("check ", sharedDetails[0].distributedType);
          ben(copiedSharedDetails[0].distributedType);
          for (var i = 0; i < copiedSharedDetails.length; i++) {
            handleBeneficiarySelection1(copiedSharedDetails[i].beneficiaryId);
            handleFieldChange1(
              copiedSharedDetails[i].beneficiaryId,
              copiedSharedDetails[i].distributedType,
              copiedSharedDetails[i].distributedValue
            );
            // console.log("sajid " + sharedDetails[0])
          }

          console.log(
            "sharedDetails beneficiaryDetails : ",
            beneficiaryDetails
          );
          console.log(
            "sharedDetails selectedBeneficiaries : ",
            selectedBeneficiaries
          );
          console.log("sharedDetails distributedType : ", distributedType);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
    getBenificiarydata();
  }, []);

  const [beneficiary, setBenificiary] = useState([]);
  const getBenificiarydata = () => {
    let userId = getUser().commonId;
    console.log("user Id=" + userId);
    let token = "Bearer " + getToken();
    getBeneficiary(token, userId)
      .then((res) => {
        setBenificiary(res);
      })
      .catch((err) => console.log(err));
  };

  const [benevisible, setbeneVisible] = useState(false);

  const handleShowBeneficiary = () => {
    setbeneVisible(true);

    // setShowAfterCloseBene(true);
    // data.sharedDetails = [];
  };
  let [otherPropertyTypes, setOtherPropertyTypes] = useState(false);
  const [isChecked, setChecked] = useState(false); // Convert saftyBox value to boolean

  const handleSwitchChange = () => {
    if (isChecked === true) {
      setChecked(false);
      data.bank.safetyBoxNumber = "";
      data.bank.safetyBox = "false";
    } else {
      setChecked(true);
      data.bank.safetyBox = "true";
    }
  };

  const handleChanges1 = (e, newValue, field, { index }) => {
    // const { value } = e.target;
    if (e !== null) {
      console.log("e ", e);
      console.log("newValue ", newValue);

      let value = e.target.value;

      if (field === "accountType") {
        value = newValue;
        setSelectedBankTypes((prevSelectedBankTypes) => {
          const newSelectedBankTypes = [...prevSelectedBankTypes];
          newSelectedBankTypes[index] = value;
          return newSelectedBankTypes;
        });
      }

      setData((prevData) => {
        const updatedAccounts = [...prevData.accounts];
        if (!updatedAccounts[index]) {
          updatedAccounts[index] = {};
        }
        updatedAccounts[index][field] = value;

        return {
          ...prevData,
          accounts: updatedAccounts,
        };
      });

      const balances = data.accounts.map((account) => account.balance || 0);
      const newTotalBalance = balances.reduce(
        (acc, curr) => acc + parseFloat(curr),
        0
      );
      setEstimatedTotalAmount(newTotalBalance);
    }
  };

  const ben = (newType) => {
    // const newType = sharedDetails[0].distributedType;
    const resetDetails = {};
    Object.keys(beneficiaryDetails).forEach((beneficiary) => {
      resetDetails[beneficiary] = { percentage: "", value: "" };
    });
    setDistributedType(newType);
    setBeneficiaryDetails(resetDetails);
  };
  const handleDistributionTypeChange = (event) => {
    console.log("handleDistributionTypeChange working : ", event.target.value);
    console.log("handleDistributionTypeChange sharedDetails", sharedDetails);
    var distributedType = event.target.value;
    const sharedDetails1 = sharedDetails.map((detail) => {
      // Change the distributedType value as needed
      detail.distributedType = distributedType;
      return detail;
    });
    console.log("handleDistributionType changed sharedDetails", sharedDetails);
    setSharedDetails(sharedDetails1);
    const newType = event.target.value;
    const resetDetails = {};
    Object.keys(beneficiaryDetails).forEach((beneficiary) => {
      resetDetails[beneficiary] = { percentage: "", dollar: "" };
    });
    setDistributedType(newType);
    setBeneficiaryDetails(resetDetails);
  };
  const handleBeneficiarySelection1 = (selectedBeneficiary) => {
    console.log("handleBeneficiarySelection working : ", selectedBeneficiary);
    if (selectedBeneficiary === null) {
      return null;
    }
    setSelectedBeneficiaries((prevSelectedBeneficiaries) => {
      // Check if the selectedBeneficiary is already included
      if (!prevSelectedBeneficiaries.includes(selectedBeneficiary)) {
        // If not included, update the selected beneficiaries
        const newSelectedBeneficiaries = [
          ...prevSelectedBeneficiaries,
          selectedBeneficiary,
        ];
        console.log(
          "Updated selected beneficiaries:",
          newSelectedBeneficiaries
        );
        return newSelectedBeneficiaries;
      }

      // If already included, return the previous state without any changes
      return prevSelectedBeneficiaries;
    });

    setBeneficiaryDetails((prevBeneficiaryDetails) => {
      const newBeneficiaryDetails = {
        ...prevBeneficiaryDetails,
        [selectedBeneficiary]: { percentage: "", dollar: "" },
      };
      console.log("Updated beneficiary details:", newBeneficiaryDetails);
      return newBeneficiaryDetails;
    });

    // setSelectedBeneficiaries((prevSelectedBeneficiaries) => {
    //   if (!prevSelectedBeneficiaries.includes(selectedBeneficiary)) {
    //     console.log("handleBeneficiarySelection 1 : ", selectedBeneficiary);
    //     setBeneficiaryDetails({
    //       ...beneficiaryDetails,
    //       [selectedBeneficiary]: { percentage: "", dollar: "" },
    //     });
    //     return [...prevSelectedBeneficiaries, selectedBeneficiary];
    //   }
    //   return prevSelectedBeneficiaries;
    // });

    console.log("handleBeneficiarySelection 2 : ", selectedBeneficiaries);
    console.log("check beneficiaryDetails 2 : ", beneficiaryDetails);
  };
  const handleBeneficiarySelection = (event) => {
    const selectedBeneficiary = event.target.value;
    console.log("handleBeneficiarySelection working : ", selectedBeneficiary);
    if (!selectedBeneficiaries.includes(selectedBeneficiary)) {
      setSelectedBeneficiaries([...selectedBeneficiaries, selectedBeneficiary]);
      setBeneficiaryDetails({
        ...beneficiaryDetails,
        [selectedBeneficiary]: { percentage: "", dollar: "" },
      });
    }
  };

  const handleFieldChange = (beneficiary, field, value) => {
    if (!estimatedTotalAmount || parseFloat(estimatedTotalAmount) === 0) {
      toast.error(
        "Please provide a valid estimated value before adding percentages!"
      );
      return;
    }
    let updatedDetails = { ...beneficiaryDetails };
    if (/^\d*$/.test(value)) {
      updatedDetails = {
        ...updatedDetails,
        [beneficiary]: {
          ...updatedDetails[beneficiary],
          [field]: value,
        },
      };
    }
    let totalPercentage = 0;
    let totalValue = 0;
    Object.keys(updatedDetails).forEach((key) => {
      const beneficiaryPercentage = parseFloat(updatedDetails[key]?.percentage);
      const beneficiaryValue = parseFloat(updatedDetails[key]?.dollar);
      if (!isNaN(beneficiaryPercentage) && beneficiaryPercentage >= 0) {
        totalPercentage += beneficiaryPercentage;
      }
      if (!isNaN(beneficiaryValue) && beneficiaryValue >= 0) {
        totalValue += beneficiaryValue;
      }
    });
    if (
      totalPercentage > 100 ||
      totalValue > parseFloat(estimatedTotalAmount)
    ) {
      updatedDetails[beneficiary][field] = "";
      toast.error(
        "Total percentage exceeds 100% or total value exceeds estimated value!"
      );
    } else {
      setBeneficiaryDetails(updatedDetails);
      // setSharedDetails(updatedDetails);
      // sharedDetails[index].distributedValue = updatedDetails.value;
      console.log("updatedDetails : ", updatedDetails);
    }
  };

  const handleFieldChange1 = (beneficiary, field, value) => {
    console.log("handleFieldChange1   : ", beneficiaryDetails);

    setBeneficiaryDetails((prevDetails) => {
      // Create a copy of the state
      let updatedDetails = { ...prevDetails };

      // Update the copy
      updatedDetails = {
        ...updatedDetails,
        [beneficiary]: {
          ...updatedDetails[beneficiary],
          [field]: value,
        },
      };
      console.log("handleFieldChange1  2 : ", updatedDetails);

      // Return the updated state
      return updatedDetails;
    });
  };

  const calculateDistributedAmount = (
    distributedType,
    balance,
    beneficiaryDetail
  ) => {
    console.log("calculateDistributedAmount : ", beneficiaryDetail);
    console.log("calculateDistributedAmount balance : ", balance);
    // Assuming beneficiaryDetail is an object with a property 'value'
    if (distributedType === "percentage") {
      const calculatedAmount =
        (parseFloat(balance) * parseFloat(beneficiaryDetail.percentage)) / 100;
      console.log("calculateDistributedAmount return: ", calculatedAmount);
      return calculatedAmount.toFixed(2);
    } else if (distributedType === "dollar") {
      const detailValue = parseFloat(beneficiaryDetail.dollar);
      return detailValue.toFixed(2);
    }
    return "0.00";
  };

  const handleSave = () => {
    toast.success("Saved successfully!");
    setbeneVisible(false);
    var i = 0;
    Object.keys(beneficiaryDetails).forEach((key) => {
      const beneficiaryPercentage = parseFloat(
        beneficiaryDetails[key]?.percentage
      );
      const beneficiaryValue = parseFloat(beneficiaryDetails[key]?.dollar);
      if (!isNaN(beneficiaryPercentage) && beneficiaryPercentage >= 0) {
        const distributedAmount = calculateDistributedAmount(
          "percentage",
          estimatedTotalAmount,
          beneficiaryDetails[key]
        );
        handleChanges2(
          "percentage",
          beneficiaryPercentage,
          distributedAmount,
          parseInt(key),
          i
        );
        i += 1;
      }
      if (!isNaN(beneficiaryValue) && beneficiaryValue >= 0) {
        const distributedAmount = calculateDistributedAmount(
          "dollar",
          estimatedTotalAmount,
          beneficiaryDetails[key]
        );
        handleChanges2(
          "dollar",
          beneficiaryValue,
          distributedAmount,
          parseInt(key),
          i
        );
        i += 1;
      }
    });
    console.log("beneficiaryDetails data: ", data);
  };
  const handleChanges2 = (t, v, a, b, i) => {
    const updatedSharedDetails = [...data.sharedDetails];
    const updatedElement = {
      ...updatedSharedDetails[i],
      distributedType: t,
      distributedValue: v,
      distributedAmount: a,
      beneficiaryId: b,
    };
    updatedSharedDetails[i] = updatedElement;
    console.log("updatedSharedDetails[i] : ", updatedSharedDetails[i]);
    setData((prevState) => ({
      ...prevState,
      sharedDetails: updatedSharedDetails,
    }));
    data.sharedDetails[i] = updatedSharedDetails[i];
  };
  const getBenificiaryName = (id) => {
    // console.log('getBenificiaryName id : ', id);
    var foundBenificiary = null;
    if (id.beneficiary === undefined) {
      // console.log('IF condition');
      foundBenificiary = beneficiary.find((b) => b.id === parseInt(id));
    } else {
      foundBenificiary = beneficiary.find(
        (b) => b.id === parseInt(id.beneficiary)
      );
    }
    // console.log('foundBenificiary details : ', foundBenificiary);
    if (foundBenificiary) {
      return `${foundBenificiary.firstName} ${foundBenificiary.lastName}`;
    } else {
      return "Benificiary not found";
    }
  };

  const validateBankAccountNum = (index) => {
    for (let i = 0; i < data.accounts.length; i++) {
      if (index === i) {
        const account = data.accounts[i];
        if (!/^\d{15}$/.test(account.accountNumber)) {
          return false; // Return false if the validation fails
        } else {
          return true; // Return true if all account numbers pass the validation
        }
      }
    }
  };

  return (
    <>
      {form1 && (
        <div className="overlay1" style={{ transition: "500ms", height: "" }}>
          <div className="addform_ichest">
            <div className="addform_main">
              <div className="addform_heading">
                <h3 className="addform_heading_h1">Edit Bank Properties</h3>
                <div className="addform_close">
                  <FontAwesomeIcon
                    icon={faXmark}
                    onClick={() => {
                      navigate("/user/my-estate/International_assets");
                    }}
                  />
                </div>
              </div>
              <div className="addform_body">
                <Form onSubmit={BankForm} className="addform_body">
                  <div className="addform_select_body">
                    <div className="addform_body_left">
                      <div className="address_input">
                        <div className="mt-2 addform_input_half">
                          <div>
                            <Tooltip title="Enter Caption for your assets">
                              <div>
                                <FormLabel>Assets Caption</FormLabel>
                                <Input
                                  className="input_mui_joy"
                                  placeholder="Enter asset caption"
                                  onChange={(e) =>
                                    handleChangesData(e, "assetCaption")
                                  }
                                  value={data1.assetCaption}
                                />
                              </div>
                            </Tooltip>
                          </div>
                        </div>

                        <div className="mt-2 addform_input_half">
                          <div>
                            <Tooltip title="Enter the Country of your assets">
                              <div>
                                <FormLabel required>Country Name</FormLabel>

                                <Select
                                  placeholder="Select country name"
                                  onChange={(e, newValue) =>
                                    handleChangesCountry(e, newValue)
                                  }
                                  value={data1.countryName}
                                  required
                                >
                                  {Country.getAllCountries().map((v, index) => {
                                    return (
                                      <Option key={index} value={v.name}>
                                        {v.name}
                                      </Option>
                                    );
                                  })}
                                </Select>
                              </div>
                            </Tooltip>
                          </div>
                        </div>
                      </div>
                      <div>
                        <Tooltip title="Select Owner" className="mt-2">
                          <div>
                            <FormLabel>Select Owner</FormLabel>
                            <Select
                              value={ownerName}
                              multiple
                              placeholder="Select Name"
                              onChange={(event, newValue) =>
                                handleChange(event, newValue, "owner")
                              }
                              sx={{
                                minWidth: "13rem",
                              }}
                            >
                              {ownerNames.map((name) => (
                                <Option key={name} value={name}>
                                  {name}
                                </Option>
                              ))}
                            </Select>
                          </div>
                        </Tooltip>
                      </div>

                      {data.bank.owner.includes("Other") && (
                        <div className="mt-2">
                          <Tooltip title="Enter Your Other Owner Name ">
                            <div>
                              <FormLabel>Other Owner</FormLabel>
                              <Input
                                className="input_mui_joy"
                                placeholder="Enter Other Owner"
                                id="otherprop"
                                onChange={(e) =>
                                  handleChangesBank(e, "otherOwner")
                                }
                                value={data.bank.otherOwner}
                              />
                            </div>
                          </Tooltip>
                        </div>
                      )}

                      <div className="mt-2">
                        <Tooltip title="Enter name of the bank">
                          <div>
                            <FormLabel>
                              {data.bank.bankType === "other"
                                ? "Bank Type"
                                : "Bank Name"}
                            </FormLabel>
                            <Select
                              id="banks"
                              placeholder="Select Bank Name"
                              onChange={bankHandleChange}
                              value={
                                data.bank.bankType === "other"
                                  ? data.bank.bankType
                                  : data.bank.bankName
                              }
                              disabled
                            >
                              <Option value={"JPMorgan Chase & Co"}>
                                <img
                                  src="/logos/J.P._Morgan_Chase_logo_PNG3.png"
                                  style={{
                                    width: "45px",
                                    aspectRatio: "16/9",
                                    objectFit: "contain",
                                  }}
                                />
                                &nbsp;&nbsp;JPMorgan Chase & Co
                              </Option>
                              <Option value={"Bank of America"}>
                                <img
                                  src="/logos/Bank_of_America_logo_PNG4.png"
                                  style={{
                                    width: "45px",
                                    aspectRatio: "16/9",
                                    objectFit: "contain",
                                  }}
                                />
                                &nbsp;&nbsp;Bank of America
                              </Option>
                              <Option value={"Wells Fargo & Co"}>
                                <img
                                  src="/logos/Wells_fargo_logo_PNG2.png"
                                  style={{
                                    width: "45px",
                                    aspectRatio: "16/9",
                                    objectFit: "contain",
                                  }}
                                />
                                &nbsp;Wells Fargo & Co
                              </Option>
                              <Option value={"Citigroup Inc"}>
                                <img
                                  src="/logos/Citigroup_logo_PNG1.png"
                                  style={{
                                    width: "40px",
                                    aspectRatio: "16/9",
                                    objectFit: "contain",
                                  }}
                                />
                                &nbsp;&nbsp;Citigroup Inc
                              </Option>
                              <Option value={"U.S. Bancorp"}>
                                <img
                                  src="/logos/US-Bank-Logo-PNG3.png"
                                  style={{
                                    width: "45px",
                                    aspectRatio: "16/9",
                                    objectFit: "contain",
                                  }}
                                />
                                &nbsp;U.S. Bancorp
                              </Option>
                              <Option value={"PNC bank"}>
                                <img
                                  src="/logos/PNC_Bank_logo_PNG1.png"
                                  style={{
                                    width: "45px",
                                    aspectRatio: "16/9",
                                    objectFit: "contain",
                                  }}
                                />
                                &nbsp;&nbsp;PNC bank
                              </Option>
                              <Option value={"TD Bank"}>
                                <img
                                  src="/logos/TD_Bank_logo_PNG1.png"
                                  style={{
                                    width: "45px",
                                    aspectRatio: "16/9",
                                    objectFit: "contain",
                                  }}
                                />
                                &nbsp;TD Bank
                              </Option>
                              <Option value={"Capital One"}>
                                <img
                                  src="/logos/Capital_One_logo_PNG1.png"
                                  style={{
                                    width: "45px",
                                    aspectRatio: "16/9",
                                    objectFit: "contain",
                                  }}
                                />
                                &nbsp;&nbsp;Capital One
                              </Option>
                              <Option value={"Fifth Third Bank"}>
                                <img
                                  src="/logos/Harris-Teeter-Logo-PNG_003-1.png"
                                  style={{
                                    width: "45px",
                                    aspectRatio: "16/9",
                                    objectFit: "contain",
                                  }}
                                />
                                &nbsp;Fifth Third Bank
                              </Option>
                              <Option value={"Ally Financial Inc"}>
                                <img
                                  src="/logos/Ally_Financial_logo_PNG4.png"
                                  style={{
                                    width: "45px",
                                    aspectRatio: "16/9",
                                    objectFit: "contain",
                                  }}
                                />
                                &nbsp;Ally Financial Inc
                              </Option>
                              <Option
                                value={"Huntington Bancshares Incorporated"}
                              >
                                <img
                                  src="/logos/huntington.webp"
                                  style={{
                                    width: "45px",
                                    aspectRatio: "16/9",
                                    objectFit: "contain",
                                  }}
                                />
                                &nbsp;Huntington Bancshares Incorporated
                              </Option>
                              <Option value={"KeyCorp"}>
                                <img
                                  src="/logos/KeyBank_logo_PNG7.png"
                                  style={{
                                    width: "45px",
                                    aspectRatio: "16/9",
                                    objectFit: "contain",
                                  }}
                                />
                                &nbsp;&nbsp;KeyCorp
                              </Option>
                              <Option
                                value={"other"}
                                onClick={() => {
                                  setOtherPropertyTypes(!otherPropertyTypes);
                                }}
                              >
                                Other
                              </Option>
                            </Select>
                          </div>
                        </Tooltip>
                      </div>
                      {data.bank.bankType === "other" && (
                        <div className="mt-2">
                          <Tooltip title="Enter Your Other Bank Name ">
                            <div>
                              <FormLabel>Other Bank</FormLabel>
                              <Input
                                className="input_mui_joy"
                                placeholder="Enter Other Bank"
                                id="otherprop"
                                onChange={(e) =>
                                  handleChangesBank(e, "bankName")
                                }
                                value={data.bank.bankName}
                                aria-readonly
                                disabled
                              />
                            </div>
                          </Tooltip>
                        </div>
                      )}
                      <div
                        className="mt-3 form1-double"
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          width: "100%",
                          gap: "5px",
                        }}
                      >
                        <div
                          style={{
                            width: "49.5%",
                            display: "flex",
                            justifyContent: "flex-start",
                          }}
                        >
                          <div
                            className="txt"
                            style={{
                              alignSelf: "center",
                              marginRight: "10px",
                              flex: "1 1 100%",
                            }}
                          >
                            Safety Box
                          </div>
                          <div
                            className="switch"
                            style={{
                              flex: "1 1 100%",
                            }}
                          >
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={isChecked}
                                  onChange={handleSwitchChange}
                                  color="primary"
                                  size="normal"
                                />
                              }
                              label={isChecked ? "Yes" : "No"}
                              labelPlacement="end"
                            />
                          </div>
                        </div>

                        {isChecked && (
                          <div className="addform_input_half">
                            <Tooltip title="Enter Your SafetyBox ID">
                              <div>
                                <Input
                                  className="input_mui_joy"
                                  placeholder="Safety Box ID"
                                  id="safetyBoxNumber"
                                  onChange={(e) =>
                                    handleChangesBank(e, "safetyBoxNumber")
                                  }
                                  value={data.bank.safetyBoxNumber}
                                />
                              </div>
                            </Tooltip>
                          </div>
                        )}
                      </div>

                      <div>
                        <Tooltip title="Add your banks related file">
                          <div className="mt-3">
                            <FormLabel>Supporting Document</FormLabel>
                            <input
                              style={{
                                border: "solid 1px lightgray",
                                borderLeft: "none",
                                width: "100%",
                                borderRadius: "5px",
                              }}
                              type="file"
                              name="myfile"
                              id="exampleFile"
                              onChange={(e) => handleImageChange(e, 5)}
                              accept=".pdf"
                              multiple
                            />
                          </div>
                        </Tooltip>
                      </div>

                      <div
                        className="bene-select mt-3"
                        onClick={handleShowBeneficiary}
                        style={{ cursor: "pointer" }}
                      >
                        Select Your Beneficiary
                      </div>
                    </div>

                    <div className="addform_body_right_bank">
                      <div>
                        {multipleAccount.map((index) => (
                          <div
                            className="mt-2"
                            key={index}
                            style={{
                              flexDirection: "column",
                              display:
                                index <= visibleColumnIndex ? "flex" : "none",
                            }}
                          >
                            <div>
                              <Tooltip
                                title={`Add your bank details for bank ${
                                  index + 1
                                }`}
                              >
                                <div>
                                  <FormLabel id={`accountType${index + 1}`}>
                                    Bank Type {index >= 0 && <span>*</span>}
                                  </FormLabel>
                                  <Select
                                    placeholder="Select Your Account Type"
                                    id={`accountType${index + 1}`}
                                    value={
                                      data.accounts[index]?.accountType || ""
                                    }
                                    onChange={(e, newValue) =>
                                      handleChanges1(
                                        e,
                                        newValue,
                                        "accountType",
                                        {
                                          index,
                                        }
                                      )
                                    }
                                    // required={index === 0}
                                    required={visibleColumnIndex >= index}
                                  >
                                    <Option
                                      value="Checking Account"
                                      disabled={selectedBankTypes.includes(
                                        "Checking Account"
                                      )}
                                    >
                                      Checking Account
                                    </Option>
                                    <Option
                                      value="Savings Account"
                                      disabled={selectedBankTypes.includes(
                                        "Savings Account"
                                      )}
                                    >
                                      Savings Account
                                    </Option>
                                    <Option
                                      value="Investment Account"
                                      disabled={selectedBankTypes.includes(
                                        "Investment Account"
                                      )}
                                    >
                                      Investment Account
                                    </Option>
                                    <Option
                                      value="C.D Account"
                                      disabled={selectedBankTypes.includes(
                                        "C.D Account"
                                      )}
                                    >
                                      C.D Account
                                    </Option>
                                  </Select>
                                </div>
                              </Tooltip>
                            </div>

                            <div className="address_input">
                              <div className="mt-2 addform_input_half">
                                <Tooltip
                                  title={`Add your bank details for bank ${
                                    index + 1
                                  }`}
                                >
                                  <div>
                                    <FormLabel required>{`BankAccount ${
                                      index + 1
                                    }`}</FormLabel>
                                    <Input
                                      type="number"
                                      placeholder={`BankAccount ${index + 1}`}
                                      id={`BankAccount${index + 1}`}
                                      required={visibleColumnIndex >= index}
                                      onChange={(e) =>
                                        handleChanges1(
                                          e,
                                          null,
                                          "accountNumber",
                                          {
                                            index,
                                          }
                                        )
                                      }
                                      value={
                                        data.accounts[index]?.accountNumber ||
                                        ""
                                      }
                                      // inputprops={{
                                      //   minLength: 15,
                                      //   maxLength: 15,
                                      // }}
                                      // error={!validateBankAccountNum(index)}
                                    />
                                    {/* {!validateBankAccountNum(index) && (
                                      <Typography
                                        variant="body2"
                                        color="danger"
                                        sx={{
                                          marginTop: "4px",
                                          fontSize: "0.875rem",
                                        }}
                                      >
                                        Please enter a valid 15-digit Account No
                                      </Typography>
                                    )} */}
                                  </div>
                                </Tooltip>
                              </div>
                              <div className="mt-2 addform_input_half">
                                <Tooltip
                                  title={`Add your bank details for bank ${
                                    index + 1
                                  }`}
                                >
                                  <div>
                                    <FormLabel required>{`Account Balance ${
                                      index + 1
                                    }`}</FormLabel>
                                    <Input
                                      type="number"
                                      placeholder={`Account Balance ${
                                        index + 1
                                      }`}
                                      id={`Account Balance ${index + 1}`}
                                      required={visibleColumnIndex >= index}
                                      onChange={(e) =>
                                        handleChanges1(e, null, "balance", {
                                          index,
                                        })
                                      }
                                      value={
                                        data.accounts[index]?.balance || ""
                                      }
                                      startDecorator={<div>$</div>}
                                    />
                                  </div>
                                </Tooltip>
                              </div>
                            </div>
                            {index >= findAccLength && (
                              <Button
                                style={{
                                  height: "30px",
                                  width: "30px",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  borderRadius: "50%",
                                  backgroundColor: "#ff4a4a",
                                  border: "none",
                                }}
                                onClick={() => handleRemoveBank(index)}
                              >
                                <FontAwesomeIcon icon={faMinus} />
                              </Button>
                            )}
                          </div>
                        ))}
                        {visibleColumnIndex < 3 && (
                          <div
                            style={{
                              marginTop: "7px",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <Button
                              style={{
                                height: "30px",
                                width: "30px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                borderRadius: "50%",
                                backgroundColor: "#4aafff",
                                border: "none",
                              }}
                              onClick={handleAddBank}
                            >
                              <FontAwesomeIcon icon={faPlus} />
                            </Button>
                          </div>
                        )}
                      </div>
                      <div className="address_input">
                        <div style={{ width: "100%" }} className="mt-2">
                          <Tooltip title="Enter notes for your real estate">
                            <div>
                              <FormLabel>Notes</FormLabel>
                              <Textarea
                                sx={{ height: "182px", minHeight: "52px" }}
                                placeholder="Notes"
                                id="notes"
                                onChange={(e) => handleChangesBank(e, "notes")}
                                value={data.bank.notes}
                              />
                            </div>
                          </Tooltip>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="text-center"
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <Button outline className="my-estate-add-btn">
                      Update
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      )}

      {benevisible && (
        <div className="popup">
          <div
            className="popup-content"
            style={{
              minWidth: "350px",
              width: "100%",
              maxWidth: "700px",
            }}
          >
            <div className="note_popup">
              <div className="note_popup_heading">
                <div className="share_property_heading">
                  <h2>Share Property </h2>
                </div>
                <div>
                  <button
                    className="note_popup_heading_close_btn"
                    onClick={handleReset}
                  >
                    <FontAwesomeIcon icon={faXmark} />
                  </button>
                </div>
              </div>
              <div className="share_property_est_value">
                <p>
                  Estimated Value:{" "}
                  <code style={{ color: "green", fontWeight: "bold" }}>
                    ${estimatedTotalAmount}
                  </code>
                </p>
                {/* {JSON.stringify(sharedDetails)}  */}
                {/* <p>----------------------------------------------------</p>
                    {JSON.stringify(data.documents)}
                    <p>----------------------------------------------------</p>
                    {JSON.stringify(data.accounts)}
                    <p>----------------------------------------------------</p>
                    {JSON.stringify(data.bank)}
                    <p>----------------------------------------------------</p>
                    {JSON.stringify(data)} */}
              </div>
              <div className="BeneficiarySelect">
                <div className="BeneficiarySelectContainer">
                  <div className="BeneficiarySelectRow">
                    <div className="share_property_Type">
                      <p className="share_property_Type_paragraph">
                        Choose Distribution Type:{" "}
                      </p>
                      <p className="ms-2 text-black fw-normal">
                        {/* {sharedDetails[0].distributedType} */}
                      </p>
                      <select
                        value={sharedDetails[0].distributedType}
                        onChange={(e) => handleDistributionTypeChange(e)}
                        class="share_property_Type_select"
                        disabled={
                          sharedDetails[0].distributedType !== "" ? true : false
                        }
                      >
                        <option value="">Select Type</option>
                        <option value="percentage">Percentage</option>
                        <option value="dollar">Dollar</option>
                      </select>
                    </div>
                    <div className="SelectContainer">
                      <select
                        className="Property-inputfiled"
                        onChange={handleBeneficiarySelection}
                        value=""
                        disabled={!distributedType}
                      >
                        <option value="" disabled hidden>
                          {distributedType
                            ? "Select Your Beneficiary Username"
                            : "Select Type First"}
                        </option>
                        {beneficiary.map((benif) => (
                          <option
                            key={benif.id}
                            value={benif.id}
                            disabled={selectedBeneficiaries.includes(benif.id)}
                          >
                            {/* {benif.username} */}
                            {`${benif.firstName} ${benif.lastName}`}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  {/* <div className="share_beneficiary_main_card">
                        {sharedDetails[0].distributedType !== null && (
                          <>
                            {sharedDetails[0].distributedType === 'dollar' ? (
                              <div>
                                <div className="share_beneficiary_card_close" onClick={() => handleBeneficiaryClose(beneficiary)}>
                                  <FontAwesomeIcon icon={faXmark} />
                                </div> 
                                <p>Beneficiary: </p>
                                <TextField type="number" label="Dollar" />
                              </div>
                            ) : null}

                            {sharedDetails[0].distributedType === 'percentage' ? (
                              <div>
                                <p>Beneficiary: </p>
                                <TextField type="number" label="Percentage" />
                              </div>
                            ) : null}
                          </>
                        )}
                      </div> */}
                  <div className="share_beneficiary_main_card">
                    {selectedBeneficiaries.map((beneficiary) => (
                      <div key={beneficiary} class="share_beneficiary_card">
                        <div>
                          <p className="share_beneficiary_card_para">
                            Beneficiary: {getBenificiaryName({ beneficiary })}
                          </p>
                          {distributedType === "percentage" && (
                            <input
                              type="text"
                              className="share_ben_percentage"
                              placeholder="Percentage"
                              value={
                                beneficiaryDetails[beneficiary]?.percentage ||
                                ""
                              }
                              onChange={(e) =>
                                handleFieldChange(
                                  beneficiary,
                                  "percentage",
                                  e.target.value
                                )
                              }
                            />
                          )}
                          {distributedType === "dollar" && (
                            <input
                              type="text"
                              className="share_ben_percentage"
                              placeholder="Dollar Value"
                              value={
                                beneficiaryDetails[beneficiary]?.dollar || ""
                              }
                              onChange={(e) =>
                                handleFieldChange(
                                  beneficiary,
                                  "dollar",
                                  e.target.value
                                )
                              }
                            />
                          )}

                          {distributedType && (
                            <p className="share_beneficiary_card_para">
                              {/* <p></p> */}
                              Distributed Value: $
                              {distributedType === "percentage"
                                ? calculateDistributedAmount(
                                    "percentage",
                                    estimatedTotalAmount,
                                    beneficiaryDetails[beneficiary]
                                  )
                                : calculateDistributedAmount(
                                    "dollar",
                                    estimatedTotalAmount,
                                    beneficiaryDetails[beneficiary]
                                  )}
                            </p>
                          )}
                        </div>
                        {/* <div
                            className="share_beneficiary_card_close"
                            onClick={() => handleBeneficiaryClose(beneficiary)}
                          >
                            <FontAwesomeIcon icon={faXmark} />
                          </div> */}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            {selectedBeneficiaries.length > 0 && (
              <button onClick={handleSave}>Save</button>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export function EditInternationalAssetInvestment() {
  const { id } = useParams();
  const navigate = useNavigate();

  let [form1, setForm1] = useState(true);

  const toggle = () => {
    setForm1(!form1);
    navigate("/user/my-estate/International_assets");
  };

  const [data, setData] = useState({
    investment: {
      owner: "",
      otherOwner: "",
      investment: "",
      nameOfTheInvestment: "",
      investmentCaption: "",
      notes: "",
      totalAmount: "",
    },
    sharedDetails: [
      {
        distributedType: "",
        distributedValue: "",
        distributedAmount: "",
        beneficiaryId: "",
      },
    ],
  });

  const [data1, setData1] = useState({
    id: "",
    assetCaption: "",
    assetType: "investment",
    countryName: "",
  });

  const handleChangesData = (e, field) => {
    const newValue = e.target.value;
    setData1((prevData) => ({
      ...prevData,
      [field]: newValue,
    }));
  };

  const handleChangesCountry = (event, newValue) => {
    setData1((prevData) => ({
      ...prevData,
      countryName: newValue,
    }));
  };

  // for owner field
  const [ownerName, setOwnerName] = useState([]);

  const handleChange = (event, newValue, stringValue) => {
    if (stringValue === "investment") {
      setData((prevData) => ({
        ...prevData,
        investment: {
          ...prevData.investment,
          investment: newValue,
        },
      }));
    }

    if (stringValue === "owner") {
      // Convert ownerName array to a single string

      if (newValue.toString() === "Other") {
      } else {
        setData((prevData) => ({
          ...prevData,
          investment: {
            ...prevData.investment,
            otherOwner: "",
          },
        }));
      }

      const comingValue =
        typeof newValue === "string" ? newValue.split(",") : newValue;
      const ownerString = comingValue.join(", ");

      setData((prevData) => ({
        ...prevData,
        investment: {
          ...prevData.investment,
          owner: ownerString,
        },
      }));

      // Update the ownerName state afterwards
      setOwnerName(newValue);
    }
  };

  // Define an array to store user names
  const ownerNames = [];

  // Get primaryUser data
  const primaryUserDetails = getUser();
  const primaryUserName =
    primaryUserDetails.firstName + " " + primaryUserDetails.lastName;

  // Get secondaryUser data
  const secondaryUserDetails = getSecondaryUser();
  let secondaryUserName = "";

  // Check if secondary user exists
  if (secondaryUserDetails !== undefined) {
    secondaryUserName =
      secondaryUserDetails.firstName + " " + secondaryUserDetails.lastName;

    // Push both user names into the array
    ownerNames.push(primaryUserName, secondaryUserName);
    ownerNames.push("Other");
  } else {
    // If secondary user doesn't exist, only push primary user name
    ownerNames.push(primaryUserName);
    ownerNames.push("Other");
  }

  const [selectedImage, setSelectedImage] = useState([]);

  const handleChanges = (e, field) => {
    const newValue = e.target.value;
    setData((prevData) => ({
      ...prevData,
      investment: {
        ...prevData.investment,
        [field]: newValue,
      },
    }));
    setEstimatedTotalAmount(data.investment.totalAmount);
  };

  // Handle image
  const handleImageChange = (event) => {
    const selectedFiles = event.target.files;
    const allowedExtensions = ["pdf"];

    if (selectedFiles) {
      const selectedFilesArray = Array.from(selectedFiles);

      // Check each file's extension
      const invalidFiles = selectedFilesArray.filter((file) => {
        const fileNameParts = file.name.split(".");
        const fileExtension =
          fileNameParts[fileNameParts.length - 1].toLowerCase();
        return !allowedExtensions.includes(fileExtension);
      });

      if (invalidFiles.length === 0) {
        const formData = new FormData();

        selectedFilesArray.forEach((file, index) => {
          formData.append(`file${index + 1}`, file);
        });

        // Set the selected image state with the array of files
        setSelectedImage(selectedFilesArray);

        // Now, you can use the formData object to send files to the API
        // Example:
        // apiCall(formData);
      } else {
        // Handle invalid file extensions
        const invalidExtensions = invalidFiles
          .map((file) => file.name.split(".").pop())
          .join(", ");
        toast.error(
          `Invalid file extensions: ${invalidExtensions}. Please select valid document formats.`,
          {
            position: toast.POSITION.BOTTOM_CENTER,
          }
        );
        event.target.value = ""; // Clear the input field
      }
    }
  };
  // auto matic clear the data
  // const resetData = () => {
  //   setData({
  //     Investment_Id: investmentID,
  //     investment: "",
  //     totalAmount: "",
  //     nameOfTheInvestment: "",
  //     notes: "",
  //     benificiary: "",
  //     investmentCaption:""
  //   });
  // };

  const investmentForm = (event) => {
    event.preventDefault();
    let token = "Bearer " + getToken();
    const formData = new FormData();
    if (null != selectedImage) {
      for (let i = 0; i < selectedImage.length; i++) {
        formData.append(`files`, selectedImage[i]);
        console.log("this is file indexs", selectedImage[i]);
      }
    }
    formData.append("asset", JSON.stringify(data));
    formData.append("data", JSON.stringify(data1));

    updateInternationalAsset(formData, token)
      .then((resp) => {
        toast.success("Updated Successfully!!", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
        toggle();
      })
      .catch((error) => {
        toast.error("An error occurred. Please try again.", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      });
  };

  // const [showAfterCloseBene, setShowAfterCloseBene] = useState(true);
  useEffect(() => {
    const fetchData = async () => {
      try {
        let token = "Bearer " + getToken();
        const res = await getSingleInternationalAsset(token, id);
        console.log("this is investment responce ", res);
        setData({
          ...data,
          investment: res.assetData.investment,
          documents: res.assetData.documents,
          sharedDetails: res.assetData.sharedDetails,
        });

        setData1({
          ...data1,
          id: res.internationalAssetData.id,
          assetCaption: res.internationalAssetData.assetCaption,
          countryName: res.internationalAssetData.countryName,
        });
        const ownerNameArray = res.assetData.investment.owner.split(", ");
        setOwnerName(ownerNameArray);
        const copiedSharedDetails = [...res.assetData.sharedDetails];
        console.log("copiedSharedDetails response : ", copiedSharedDetails);
        setEstimatedTotalAmount(res.assetData.investment.totalAmount);

        if (copiedSharedDetails.length > 0) {
          setSharedDetails(res.assetData.sharedDetails);

          ben(copiedSharedDetails[0].distributedType);
          for (var i = 0; i < copiedSharedDetails.length; i++) {
            handleBeneficiarySelection1(copiedSharedDetails[i].beneficiaryId);
            handleFieldChange1(
              copiedSharedDetails[i].beneficiaryId,
              copiedSharedDetails[i].distributedType,
              copiedSharedDetails[i].distributedValue
            );
            // console.log("sajid " + sharedDetails[0])
          }

          console.log(
            "sharedDetails beneficiaryDetails : ",
            beneficiaryDetails
          );
          console.log(
            "sharedDetails selectedBeneficiaries : ",
            selectedBeneficiaries
          );
          console.log("sharedDetails distributedType : ", distributedType);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  // beneficiary addition in form
  const [beneficiary, setBenificiary] = useState([]);
  const getBenificiarydata = () => {
    let userId = getUser().commonId;
    console.log("user Id=" + userId);
    let token = "Bearer " + getToken();
    getBeneficiary(token, userId)
      .then((res) => {
        setBenificiary(res);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    getBenificiarydata();
  }, []);

  //
  const [benevisible, setbeneVisible] = useState(false);
  const [selectedBeneficiaries, setSelectedBeneficiaries] = useState([]);
  const [beneficiaryDetails, setBeneficiaryDetails] = useState({});
  const [estimatedTotalAmount, setEstimatedTotalAmount] = useState(0);
  const [distributedType, setDistributedType] = useState("");

  const [sharedDetails, setSharedDetails] = useState([
    {
      distributedType: "",
      distributedValue: "",
      distributedAmount: "",
      beneficiaryId: "",
    },
  ]);

  const ben = (newType) => {
    // const newType = sharedDetails[0].distributedType;
    const resetDetails = {};
    Object.keys(beneficiaryDetails).forEach((beneficiary) => {
      resetDetails[beneficiary] = { percentage: "", value: "" };
    });
    setDistributedType(newType);
    setBeneficiaryDetails(resetDetails);
  };

  const handleShowBeneficiary = () => {
    setbeneVisible(true);

    // setShowAfterCloseBene(true);
  };

  const handleReset = () => {
    setbeneVisible(false);
    setSelectedBeneficiaries([]);
    setBeneficiaryDetails({});
    // setShowAfterCloseBene(false);
  };

  const handleDistributionTypeChange = (event) => {
    console.log("handleDistributionTypeChange working : ", event.target.value);
    console.log("handleDistributionTypeChange sharedDetails", sharedDetails);
    var distributedType = event.target.value;
    const sharedDetails1 = sharedDetails.map((detail) => {
      // Change the distributedType value as needed
      detail.distributedType = distributedType;
      return detail;
    });
    console.log("handleDistributionType changed sharedDetails", sharedDetails);
    setSharedDetails(sharedDetails1);
    const newType = event.target.value;
    const resetDetails = {};
    Object.keys(beneficiaryDetails).forEach((beneficiary) => {
      resetDetails[beneficiary] = { percentage: "", dollar: "" };
    });
    setDistributedType(newType);
    setBeneficiaryDetails(resetDetails);
  };

  const handleBeneficiarySelection1 = (selectedBeneficiary) => {
    console.log("handleBeneficiarySelection working : ", selectedBeneficiary);
    if (selectedBeneficiary === null) {
      return null;
    }
    setSelectedBeneficiaries((prevSelectedBeneficiaries) => {
      // Check if the selectedBeneficiary is already included
      if (!prevSelectedBeneficiaries.includes(selectedBeneficiary)) {
        // If not included, update the selected beneficiaries
        const newSelectedBeneficiaries = [
          ...prevSelectedBeneficiaries,
          selectedBeneficiary,
        ];
        console.log(
          "Updated selected beneficiaries:",
          newSelectedBeneficiaries
        );
        return newSelectedBeneficiaries;
      }

      // If already included, return the previous state without any changes
      return prevSelectedBeneficiaries;
    });

    setBeneficiaryDetails((prevBeneficiaryDetails) => {
      const newBeneficiaryDetails = {
        ...prevBeneficiaryDetails,
        [selectedBeneficiary]: { percentage: "", dollar: "" },
      };
      console.log("Updated beneficiary details:", newBeneficiaryDetails);
      return newBeneficiaryDetails;
    });

    // setSelectedBeneficiaries((prevSelectedBeneficiaries) => {
    //   if (!prevSelectedBeneficiaries.includes(selectedBeneficiary)) {
    //     console.log("handleBeneficiarySelection 1 : ", selectedBeneficiary);
    //     setBeneficiaryDetails({
    //       ...beneficiaryDetails,
    //       [selectedBeneficiary]: { percentage: "", dollar: "" },
    //     });
    //     return [...prevSelectedBeneficiaries, selectedBeneficiary];
    //   }
    //   return prevSelectedBeneficiaries;
    // });

    console.log("handleBeneficiarySelection 2 : ", selectedBeneficiaries);
    console.log("check beneficiaryDetails 2 : ", beneficiaryDetails);
  };

  const handleBeneficiarySelection = (event) => {
    const selectedBeneficiary = event.target.value;
    console.log("handleBeneficiarySelection working : ", selectedBeneficiary);
    if (!selectedBeneficiaries.includes(selectedBeneficiary)) {
      setSelectedBeneficiaries([...selectedBeneficiaries, selectedBeneficiary]);
      setBeneficiaryDetails({
        ...beneficiaryDetails,
        [selectedBeneficiary]: { percentage: "", dollar: "" },
      });
    }
  };

  const getBenificiaryName = (id) => {
    // console.log('getBenificiaryName id : ', id);
    var foundBenificiary = null;
    if (id.beneficiary === undefined) {
      // console.log('IF condition');
      foundBenificiary = beneficiary.find((b) => b.id === parseInt(id));
    } else {
      foundBenificiary = beneficiary.find(
        (b) => b.id === parseInt(id.beneficiary)
      );
    }
    // console.log('foundBenificiary details : ', foundBenificiary);
    if (foundBenificiary) {
      return `${foundBenificiary.firstName} ${foundBenificiary.lastName}`;
    } else {
      return "Benificiary not found";
    }
  };

  const handleFieldChange1 = (beneficiary, field, value) => {
    console.log("handleFieldChange1   : ", beneficiaryDetails);

    setBeneficiaryDetails((prevDetails) => {
      // Create a copy of the state
      let updatedDetails = { ...prevDetails };

      // Update the copy
      updatedDetails = {
        ...updatedDetails,
        [beneficiary]: {
          ...updatedDetails[beneficiary],
          [field]: value,
        },
      };
      console.log("handleFieldChange1  2 : ", updatedDetails);

      // Return the updated state
      return updatedDetails;
    });
  };

  const handleFieldChange = (beneficiary, field, value) => {
    if (!estimatedTotalAmount || parseFloat(estimatedTotalAmount) === 0) {
      toast.error(
        "Please provide a valid estimated value before adding percentages!"
      );
      return;
    }
    let updatedDetails = { ...beneficiaryDetails };
    if (/^\d*$/.test(value)) {
      updatedDetails = {
        ...updatedDetails,
        [beneficiary]: {
          ...updatedDetails[beneficiary],
          [field]: value,
        },
      };
    }
    let totalPercentage = 0;
    let totalValue = 0;
    Object.keys(updatedDetails).forEach((key) => {
      const beneficiaryPercentage = parseFloat(updatedDetails[key]?.percentage);
      const beneficiaryValue = parseFloat(updatedDetails[key]?.dollar);
      if (!isNaN(beneficiaryPercentage) && beneficiaryPercentage >= 0) {
        totalPercentage += beneficiaryPercentage;
      }
      if (!isNaN(beneficiaryValue) && beneficiaryValue >= 0) {
        totalValue += beneficiaryValue;
      }
    });
    if (
      totalPercentage > 100 ||
      totalValue > parseFloat(estimatedTotalAmount)
    ) {
      updatedDetails[beneficiary][field] = "";
      toast.error(
        "Total percentage exceeds 100% or total value exceeds estimated value!"
      );
    } else {
      setBeneficiaryDetails(updatedDetails);
      // setSharedDetails(updatedDetails);
      // sharedDetails[index].distributedValue = updatedDetails.value;
      console.log("updatedDetails : ", updatedDetails);
    }
  };

  const calculateDistributedAmount = (
    distributedType,
    balance,
    beneficiaryDetail
  ) => {
    console.log("calculateDistributedAmount : ", beneficiaryDetail);
    console.log("calculateDistributedAmount balance : ", balance);
    // Assuming beneficiaryDetail is an object with a property 'value'
    if (distributedType === "percentage") {
      const calculatedAmount =
        (parseFloat(balance) * parseFloat(beneficiaryDetail.percentage)) / 100;
      console.log("calculateDistributedAmount return: ", calculatedAmount);
      return calculatedAmount.toFixed(2);
    } else if (distributedType === "dollar") {
      const detailValue = parseFloat(beneficiaryDetail.dollar);
      return detailValue.toFixed(2);
    }
    return "0.00";
  };

  const handleSave = () => {
    toast.success("Saved successfully!");
    setbeneVisible(false);
    var i = 0;
    Object.keys(beneficiaryDetails).forEach((key) => {
      const beneficiaryPercentage = parseFloat(
        beneficiaryDetails[key]?.percentage
      );
      const beneficiaryValue = parseFloat(beneficiaryDetails[key]?.dollar);
      if (!isNaN(beneficiaryPercentage) && beneficiaryPercentage >= 0) {
        const distributedAmount = calculateDistributedAmount(
          "percentage",
          estimatedTotalAmount,
          beneficiaryDetails[key]
        );
        handleChanges2(
          "percentage",
          beneficiaryPercentage,
          distributedAmount,
          parseInt(key),
          i
        );
        i += 1;
      }
      if (!isNaN(beneficiaryValue) && beneficiaryValue >= 0) {
        const distributedAmount = calculateDistributedAmount(
          "dollar",
          estimatedTotalAmount,
          beneficiaryDetails[key]
        );
        handleChanges2(
          "dollar",
          beneficiaryValue,
          distributedAmount,
          parseInt(key),
          i
        );
        i += 1;
      }
    });
    console.log("beneficiaryDetails data: ", data);
  };

  const handleChanges2 = (t, v, a, b, i) => {
    const updatedSharedDetails = [...data.sharedDetails];
    const updatedElement = {
      ...updatedSharedDetails[i],
      distributedType: t,
      distributedValue: v,
      distributedAmount: a,
      beneficiaryId: b,
    };
    updatedSharedDetails[i] = updatedElement;
    console.log("updatedSharedDetails[i] : ", updatedSharedDetails[i]);
    setData((prevState) => ({
      ...prevState,
      sharedDetails: updatedSharedDetails,
    }));
    data.sharedDetails[i] = updatedSharedDetails[i];
  };

  return (
    <>
      {form1 && (
        <div className="overlay1" style={{ transition: "500ms", height: "" }}>
          <div className="addform_ichest">
            <div className="addform_main">
              <div className="addform_heading">
                <h3 className="addform_heading_h1">Edit Investment</h3>
                <div className="addform_close">
                  <FontAwesomeIcon
                    icon={faXmark}
                    onClick={() => {
                      navigate("/user/my-estate/International_assets");
                    }}
                  />
                </div>
              </div>
              <div className="addform_body">
                <Form onSubmit={investmentForm} className="addform_body">
                  <div className="addform_select_body">
                    <div className="addform_body_left">
                      <div className="address_input">
                        <div className="mt-2 addform_input_half">
                          <div>
                            <Tooltip title="Enter Caption for your assets">
                              <div>
                                <FormLabel>Assets Caption</FormLabel>
                                <Input
                                  className="input_mui_joy"
                                  placeholder="Enter asset caption"
                                  onChange={(e) =>
                                    handleChangesData(e, "assetCaption")
                                  }
                                  value={data1.assetCaption}
                                />
                              </div>
                            </Tooltip>
                          </div>
                        </div>

                        <div className="mt-2 addform_input_half">
                          <div>
                            <Tooltip title="Enter the Country of your assets">
                              <div>
                                <FormLabel required>Country Name</FormLabel>

                                <Select
                                  placeholder="Select country name"
                                  onChange={(e, newValue) =>
                                    handleChangesCountry(e, newValue)
                                  }
                                  value={data1.countryName}
                                  required
                                >
                                  {Country.getAllCountries().map((v, index) => {
                                    return (
                                      <Option key={index} value={v.name}>
                                        {v.name}
                                      </Option>
                                    );
                                  })}
                                </Select>
                              </div>
                            </Tooltip>
                          </div>
                        </div>
                      </div>
                      <div>
                        <Tooltip title="Select Owner" className="mt-2">
                          <div>
                            <FormLabel>Select Owner</FormLabel>
                            <Select
                              value={ownerName}
                              multiple
                              placeholder="Select Name"
                              onChange={(event, newValue) =>
                                handleChange(event, newValue, "owner")
                              }
                              sx={{
                                minWidth: "13rem",
                              }}
                            >
                              {ownerNames.map((name) => (
                                <Option key={name} value={name}>
                                  {name}
                                </Option>
                              ))}
                            </Select>
                          </div>
                        </Tooltip>
                      </div>

                      {data.investment.owner.includes("Other") && (
                        <div className="mt-2">
                          <Tooltip title="Enter Your Other Owner Name ">
                            <div>
                              <FormLabel>Other Owner</FormLabel>
                              <Input
                                className="input_mui_joy"
                                placeholder="Enter Other Owner"
                                id="otherprop"
                                onChange={(e) => handleChanges(e, "otherOwner")}
                                value={data.investment.otherOwner}
                              />
                            </div>
                          </Tooltip>
                        </div>
                      )}
                      <div>
                        <Tooltip title="Enter Heading for Investment">
                          <div className="mt-2">
                            <FormLabel>Investment Heading</FormLabel>
                            <Input
                              className="input_mui_joy"
                              placeholder="Enter investment heading"
                              value={data.investment.investmentCaption}
                              onChange={(e) =>
                                handleChanges(e, "investmentCaption")
                              }
                            />
                          </div>
                        </Tooltip>
                      </div>

                      <div>
                        <Tooltip title="Select Investment Type">
                          <div className="mt-2">
                            <FormLabel required>Investment</FormLabel>
                            <Select
                              value={data.investment.investment}
                              onChange={(event, newValue) =>
                                handleChange(event, newValue, "investment")
                              }
                              required
                            >
                              <Option value="">
                                Select Your Type Of investment
                              </Option>
                              <Option value="Stocks">Stocks</Option>
                              <Option value="Mutual Funds">Mutual Funds</Option>
                              <Option value="Bonds">Bonds</Option>
                              <Option value="401K">401K</Option>
                              <Option value="Exchange-Traded Funds (ETFs)">
                                Exchange-Traded Funds (ETFs)
                              </Option>
                              <Option value="Certificates of Deposit (CDs)">
                                Certificates of Deposit (CDs)
                              </Option>
                              <Option value="Money Market Funds">
                                Money Market Funds
                              </Option>
                              <Option value="Alternative Investments">
                                Alternative Investments
                              </Option>
                            </Select>
                          </div>
                        </Tooltip>
                      </div>

                      <div>
                        <Tooltip title="Enter the name of Exchange">
                          <div className="mt-2">
                            <FormLabel>Name Of Exchange</FormLabel>
                            <Input
                              className="input_mui_joy"
                              placeholder="Enter name of Exchange"
                              onChange={(e) =>
                                handleChanges(e, "nameOfTheInvestment")
                              }
                              value={data.investment.nameOfTheInvestment}
                            />
                          </div>
                        </Tooltip>
                      </div>

                      <div>
                        <Tooltip title="Enter Your Estimated Amount ">
                          <div className="mt-2">
                            <FormLabel required>Estimated Amount</FormLabel>
                            <Input
                              placeholder="Estimated Investment Amount"
                              required
                              type="number"
                              onChange={(e) => {
                                handleChanges(e, "totalAmount");
                              }}
                              value={data.investment.totalAmount}
                              startDecorator={<div>$</div>}
                            />
                          </div>
                        </Tooltip>
                      </div>
                    </div>

                    <div className="addform_body_right_investment">
                      <div>
                        <Tooltip title="Add your banks related file">
                          <div className="mt-2">
                            <FormLabel>Supporting Document</FormLabel>
                            <input
                              style={{
                                border: "solid 1px lightgray",
                                borderLeft: "none",
                                width: "100%",
                                borderRadius: "5px",
                              }}
                              type="file"
                              name="myfile"
                              id="exampleFile"
                              onChange={(e) => handleImageChange(e, 5)}
                              accept=".pdf"
                              multiple
                            />
                          </div>
                        </Tooltip>
                      </div>
                      <div
                        className="bene-select mt-3"
                        onClick={handleShowBeneficiary}
                        style={{ cursor: "pointer" }}
                      >
                        Select Your Beneficiary
                      </div>
                      <div className="address_input">
                        <div style={{ width: "100%" }} className="mt-2">
                          <Tooltip title="Enter notes for your real estate">
                            <div>
                              <FormLabel>Notes</FormLabel>
                              <Textarea
                                sx={{ height: "182px", minHeight: "52px" }}
                                placeholder="Notes"
                                id="notes"
                                onChange={(e) => handleChanges(e, "notes")}
                                value={data.investment.notes}
                              />
                            </div>
                          </Tooltip>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="text-center"
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <Button outline className="my-estate-add-btn">
                      Update
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      )}

      {benevisible && (
        <div className="popup">
          <div
            className="popup-content"
            style={{
              minWidth: "350px",
              width: "100%",
              maxWidth: "700px",
            }}
          >
            <div className="note_popup">
              <div className="note_popup_heading">
                <div className="share_property_heading">
                  <h2>Share Property </h2>
                </div>
                <div>
                  <button
                    className="note_popup_heading_close_btn"
                    onClick={handleReset}
                  >
                    <FontAwesomeIcon icon={faXmark} />
                  </button>
                </div>
              </div>
              <div className="share_property_est_value">
                <p>
                  Estimated Value:{" "}
                  <code style={{ color: "green", fontWeight: "bold" }}>
                    ${estimatedTotalAmount}
                  </code>
                </p>
                {/* {JSON.stringify(sharedDetails)}  */}
                {/* <p>----------------------------------------------------</p>
                    {JSON.stringify(data.documents)}
                    <p>----------------------------------------------------</p>
                    {JSON.stringify(data.accounts)}
                    <p>----------------------------------------------------</p>
                    {JSON.stringify(data.bank)}
                    <p>----------------------------------------------------</p>
                    {JSON.stringify(data)} */}
              </div>
              <div className="BeneficiarySelect">
                <div className="BeneficiarySelectContainer">
                  <div className="BeneficiarySelectRow">
                    <div className="share_property_Type">
                      <p className="share_property_Type_paragraph">
                        Choose Distribution Type:{" "}
                      </p>
                      <p className="ms-2 text-black fw-normal">
                        {/* {sharedDetails[0].distributedType} */}
                      </p>
                      <select
                        value={sharedDetails[0].distributedType}
                        onChange={(e) => handleDistributionTypeChange(e)}
                        class="share_property_Type_select"
                        disabled={
                          sharedDetails[0].distributedType !== "" ? true : false
                        }
                      >
                        <option value="">Select Type</option>
                        <option value="percentage">Percentage</option>
                        <option value="dollar">Dollar</option>
                      </select>
                    </div>
                    <div className="SelectContainer">
                      <select
                        className="Property-inputfiled"
                        onChange={handleBeneficiarySelection}
                        value=""
                        disabled={!distributedType}
                      >
                        <option value="" disabled hidden>
                          {distributedType
                            ? "Select Your Beneficiary Username"
                            : "Select Type First"}
                        </option>
                        {beneficiary.map((benif) => (
                          <option
                            key={benif.id}
                            value={benif.id}
                            disabled={selectedBeneficiaries.includes(benif.id)}
                          >
                            {/* {benif.username} */}
                            {`${benif.firstName} ${benif.lastName}`}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  {/* <div className="share_beneficiary_main_card">
                        {sharedDetails[0].distributedType !== null && (
                          <>
                            {sharedDetails[0].distributedType === 'dollar' ? (
                              <div>
                                <div className="share_beneficiary_card_close" onClick={() => handleBeneficiaryClose(beneficiary)}>
                                  <FontAwesomeIcon icon={faXmark} />
                                </div> 
                                <p>Beneficiary: </p>
                                <TextField type="number" label="Dollar" />
                              </div>
                            ) : null}

                            {sharedDetails[0].distributedType === 'percentage' ? (
                              <div>
                                <p>Beneficiary: </p>
                                <TextField type="number" label="Percentage" />
                              </div>
                            ) : null}
                          </>
                        )}
                      </div> */}
                  <div className="share_beneficiary_main_card">
                    {selectedBeneficiaries.map((beneficiary) => (
                      <div key={beneficiary} class="share_beneficiary_card">
                        <div>
                          <p className="share_beneficiary_card_para">
                            Beneficiary: {getBenificiaryName({ beneficiary })}
                          </p>
                          {distributedType === "percentage" && (
                            <input
                              type="text"
                              className="share_ben_percentage"
                              placeholder="Percentage"
                              value={
                                beneficiaryDetails[beneficiary]?.percentage ||
                                ""
                              }
                              onChange={(e) =>
                                handleFieldChange(
                                  beneficiary,
                                  "percentage",
                                  e.target.value
                                )
                              }
                            />
                          )}
                          {distributedType === "dollar" && (
                            <input
                              type="text"
                              className="share_ben_percentage"
                              placeholder="Dollar Value"
                              value={
                                beneficiaryDetails[beneficiary]?.dollar || ""
                              }
                              onChange={(e) =>
                                handleFieldChange(
                                  beneficiary,
                                  "dollar",
                                  e.target.value
                                )
                              }
                            />
                          )}

                          {distributedType && (
                            <p className="share_beneficiary_card_para">
                              {/* <p></p> */}
                              Distributed Value: $
                              {distributedType === "percentage"
                                ? calculateDistributedAmount(
                                    "percentage",
                                    estimatedTotalAmount,
                                    beneficiaryDetails[beneficiary]
                                  )
                                : calculateDistributedAmount(
                                    "dollar",
                                    estimatedTotalAmount,
                                    beneficiaryDetails[beneficiary]
                                  )}
                            </p>
                          )}
                        </div>
                        {/* <div
                            className="share_beneficiary_card_close"
                            onClick={() => handleBeneficiaryClose(beneficiary)}
                          >
                            <FontAwesomeIcon icon={faXmark} />
                          </div> */}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            {selectedBeneficiaries.length > 0 && (
              <button onClick={handleSave}>Save</button>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export function EditInternationalAssetOtherAssets() {
  const { id } = useParams();
  const navigate = useNavigate();
  let [form1, setForm1] = useState(true);

  const toggle = () => {
    setForm1(!form1);
    navigate("/user/my-estate/International_assets");
  };

  const [data, setData] = useState({
    otherAsset: {
      owner: "",
      otherOwner: "",
      assetCaption: "",
      assetValue: "",
      otherAssets1: "",
      otherAssets2: "",
      otherAssets3: "",
      otherAssets4: "",
      otherAssets5: "",
      notes: "",
    },
    sharedDetails: [
      {
        distributedType: "",
        distributedValue: "",
        distributedAmount: "",
        beneficiaryId: "",
      },
    ],
  });

  const [data1, setData1] = useState({
    id: "",
    assetCaption: "",
    assetType: "otherAsset",
    countryName: "",
  });

  const handleChangesData = (e, field) => {
    const newValue = e.target.value;
    setData1((prevData) => ({
      ...prevData,
      [field]: newValue,
    }));
  };

  const handleChangesCountry = (event, newValue) => {
    setData1((prevData) => ({
      ...prevData,
      countryName: newValue,
    }));
  };

  // for owner field
  const [ownerName, setOwnerName] = useState([]);
  const handleChange = (event, newValue, stringValue) => {
    if (stringValue === "owner") {
      // Convert ownerName array to a single string

      if (newValue.toString() === "Other") {
      } else {
        setData((prevData) => ({
          ...prevData,
          otherAsset: {
            ...prevData.otherAsset,
            otherOwner: "",
          },
        }));
      }

      const comingValue =
        typeof newValue === "string" ? newValue.split(",") : newValue;
      const ownerString = comingValue.join(", ");

      setData((prevData) => ({
        ...prevData,
        otherAsset: {
          ...prevData.otherAsset,
          owner: ownerString,
        },
      }));

      // Update the ownerName state afterwards
      setOwnerName(newValue);
    }
  };

  // Define an array to store user names
  const ownerNames = [];

  // Get primaryUser data
  const primaryUserDetails = getUser();
  const primaryUserName =
    primaryUserDetails.firstName + " " + primaryUserDetails.lastName;

  // Get secondaryUser data
  const secondaryUserDetails = getSecondaryUser();
  let secondaryUserName = "";

  // Check if secondary user exists
  if (secondaryUserDetails !== undefined) {
    secondaryUserName =
      secondaryUserDetails.firstName + " " + secondaryUserDetails.lastName;

    // Push both user names into the array
    ownerNames.push(primaryUserName, secondaryUserName, "Other");
  } else {
    // If secondary user doesn't exist, only push primary user name
    ownerNames.push(primaryUserName, "Other");
  }

  // use state to set the selected images
  const [selectedImage, setSelectedImage] = useState([]);

  // const handleChanges = (event, property) => {
  //     setData({ ...data, [property]: event.target.value });
  // };
  const handleChanges = (e, field) => {
    const newValue = e.target.value;
    setData((prevData) => ({
      ...prevData,
      otherAsset: {
        ...prevData.otherAsset,
        [field]: newValue,
      },
    }));
    // setEstimatedTotalAmount(data.realEstate.estPropertyVal);
    // setEstimatedTotalAmount(100);
  };

  // Handle image
  const handleImageChange = (event) => {
    const selectedFiles = event.target.files;
    const allowedExtensions = ["pdf"];

    if (selectedFiles) {
      const selectedFilesArray = Array.from(selectedFiles);

      // Check each file's extension
      const invalidFiles = selectedFilesArray.filter((file) => {
        const fileNameParts = file.name.split(".");
        const fileExtension =
          fileNameParts[fileNameParts.length - 1].toLowerCase();
        return !allowedExtensions.includes(fileExtension);
      });

      if (invalidFiles.length === 0) {
        const formData = new FormData();

        selectedFilesArray.forEach((file, index) => {
          formData.append(`file${index + 1}`, file);
        });

        // Set the selected image state with the array of files
        setSelectedImage(selectedFilesArray);

        // Now, you can use the formData object to send files to the API
        // Example:
        // apiCall(formData);
      } else {
        // Handle invalid file extensions
        const invalidExtensions = invalidFiles
          .map((file) => file.name.split(".").pop())
          .join(", ");
        toast.error(
          `Invalid file extensions: ${invalidExtensions}. Please select valid document formats.`,
          {
            position: toast.POSITION.BOTTOM_CENTER,
          }
        );
        event.target.value = ""; // Clear the input field
      }
    }
  };

  // Set the form
  const OtherAssestForm = (event) => {
    event.preventDefault();
    let token = "Bearer " + getToken();

    if (data.otherAssets1 === "") {
      console.log("Error log");
      toast.error("Please fill all required feilds.");
      return;
    }
    //create form data to send a file and remaining class data
    const formData = new FormData();
    for (let i = 0; i < selectedImage.length; i++) {
      formData.append(`files`, selectedImage[i]);
      console.log("this is file indexs", selectedImage[i]);
    }
    formData.append("asset", JSON.stringify(data));
    formData.append("data", JSON.stringify(data1));

    updateInternationalAsset(formData, token)
      .then((resp) => {
        toast.success("Data Added !!", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
        toggle();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // const [showAfterCloseBene, setShowAfterCloseBene] = useState(true);
  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       let token = "Bearer " + getToken();
  //       const res = await getSingleRealEstate(token, id);
  //       console.log("this is realEstate responce ", res);
  //       setData({
  //         ...data,
  //         sharedDetails: res.sharedDetails,
  //       });
  //       const copiedSharedDetails = [...res.sharedDetails];
  //       console.log("copiedSharedDetails response : ", copiedSharedDetails);
  //       setEstimatedTotalAmount(res.realEstate.estPropertyVal);

  //       console.log("check ", sharedDetails[0].distributedType);

  //       console.log("sharedDetails response : ", res.sharedDetails);
  //       console.log("sharedDetails response : ", sharedDetails);
  //       if (copiedSharedDetails.length > 0) {
  //         console.log(res.sharedDetails.length);
  //         setSharedDetails(res.sharedDetails);
  //         console.log("check ", sharedDetails[0].distributedType);
  //         ben(copiedSharedDetails[0].distributedType);
  //         for (var i = 0; i < copiedSharedDetails.length; i++) {
  //           handleBeneficiarySelection1(copiedSharedDetails[i].beneficiaryId);
  //           handleFieldChange1(
  //             copiedSharedDetails[i].beneficiaryId,
  //             copiedSharedDetails[i].distributedType,
  //             copiedSharedDetails[i].distributedValue
  //           );
  //           // console.log("sajid " + sharedDetails[0])
  //         }

  //         console.log(
  //           "sharedDetails beneficiaryDetails : ",
  //           beneficiaryDetails
  //         );
  //         console.log(
  //           "sharedDetails selectedBeneficiaries : ",
  //           selectedBeneficiaries
  //         );
  //         console.log("sharedDetails distributedType : ", distributedType);
  //       }
  //     } catch (error) {
  //       console.error("Error fetching data:", error);
  //     }
  //   };

  //   fetchData();
  // }, [showAfterCloseBene]);

  let [findOthAssetLength, setFindOthAssetLength] = useState(0);
  let dublicateOtherAsset = {};
  useEffect(() => {
    const fetchData = async () => {
      try {
        let token = "Bearer " + getToken();
        const res = await getSingleInternationalAsset(token, id);

        setData({
          ...data,
          otherAsset: res.assetData.otherAsset,
          documents: res.assetData.documents,
          sharedDetails: res.assetData.sharedDetails,
        });
        setData1({
          ...data1,
          id: res.internationalAssetData.id,
          assetCaption: res.internationalAssetData.assetCaption,
          countryName: res.internationalAssetData.countryName,
        });

        const ownerNameArray = res.assetData.otherAsset.owner.split(", ");
        setOwnerName(ownerNameArray);

        dublicateOtherAsset = { ...res.assetData.otherAsset };
        let findOtherAssetLength = 0;
        for (let i = 1; i <= 5; i++) {
          const propertyName = `otherAssets${i}`;
          const hasData = dublicateOtherAsset[propertyName].trim() !== "";
          if (hasData === true) {
            findOtherAssetLength = findOtherAssetLength + 1;
          }
        }
        setVisibleColumnIndex(findOtherAssetLength - 1);
        setFindOthAssetLength(findOtherAssetLength);

        const copiedSharedDetails = [...res.assetData.sharedDetails];

        setEstimatedTotalAmount(res.assetData.otherAsset.assetValue);

        if (copiedSharedDetails.length > 0) {
          setSharedDetails(res.assetData.sharedDetails);
          console.log("check ", sharedDetails[0].distributedType);
          ben(copiedSharedDetails[0].distributedType);
          for (var i = 0; i < copiedSharedDetails.length; i++) {
            handleBeneficiarySelection1(copiedSharedDetails[i].beneficiaryId);
            handleFieldChange1(
              copiedSharedDetails[i].beneficiaryId,
              copiedSharedDetails[i].distributedType,
              copiedSharedDetails[i].distributedValue
            );
            // console.log("sajid " + sharedDetails[0])
          }

          console.log(
            "sharedDetails beneficiaryDetails : ",
            beneficiaryDetails
          );
          console.log(
            "sharedDetails selectedBeneficiaries : ",
            selectedBeneficiaries
          );
          console.log("sharedDetails distributedType : ", distributedType);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const [otherAssets, setOtherAssets] = useState([{ name: "", notes: "" }]);
  const [visibleColumnIndex, setVisibleColumnIndex] = useState(0);
  const otherAssetss = [0, 1, 2, 3, 4];
  const handleAddColumn = () => {
    console.log("visible index is for edit : ", visibleColumnIndex);
    if (visibleColumnIndex < 4) {
      setOtherAssets([...otherAssets, { label: visibleColumnIndex + 1 }]);
      console.log("add button clicked");
      setVisibleColumnIndex(visibleColumnIndex + 1);
    }
    // console.log("add button clicked");
  };
  const handleRemoveColumn = (indexToRemove) => {
    if (otherAssets.length > 0) {
      // Create a copy of the otherAssets array
      const updatedAssets = [...otherAssets];
      // Remove the last element
      updatedAssets.pop();
      // Update the state with the modified array
      setOtherAssets(updatedAssets);

      // Update visibleColumnIndex if necessary
      if (visibleColumnIndex > 0) {
        setVisibleColumnIndex(visibleColumnIndex - 1);
      }
    }
    setData((prevData) => {
      const updatedOtherAsset = { ...prevData.otherAsset };

      // Reset the value of the field corresponding to the removed column to an empty string
      for (let i = 1; i <= visibleColumnIndex + 1; i++) {
        const fieldKey = `otherAssets${i + 1}`;
        if (indexToRemove === i) {
          updatedOtherAsset[fieldKey] = ""; // Reset the field value to an empty string
        }
      }

      // Return the updated state with the modified otherAsset object
      return {
        ...prevData,
        otherAsset: updatedOtherAsset,
      };
    });
  };

  // beneficiary addition in form
  const [beneficiary, setBenificiary] = useState([]);
  const getBenificiarydata = () => {
    let userId = getUser().commonId;
    console.log("user Id=" + userId);
    let token = "Bearer " + getToken();
    getBeneficiary(token, userId)
      .then((res) => {
        setBenificiary(res);
      })
      .catch((err) => console.log(err));
  };

  //

  const [benevisible, setbeneVisible] = useState(false);
  const [selectedBeneficiaries, setSelectedBeneficiaries] = useState([]);
  const [beneficiaryDetails, setBeneficiaryDetails] = useState({});
  const [estimatedTotalAmount, setEstimatedTotalAmount] = useState(0);
  const [distributedType, setDistributedType] = useState("");

  const [sharedDetails, setSharedDetails] = useState([
    {
      distributedType: "",
      distributedValue: "",
      distributedAmount: "",
      beneficiaryId: "",
    },
  ]);

  const ben = (newType) => {
    // const newType = sharedDetails[0].distributedType;
    const resetDetails = {};
    Object.keys(beneficiaryDetails).forEach((beneficiary) => {
      resetDetails[beneficiary] = { percentage: "", value: "" };
    });
    setDistributedType(newType);
    setBeneficiaryDetails(resetDetails);
  };

  const handleShowBeneficiary = () => {
    setbeneVisible(true);

    // setShowAfterCloseBene(true);
  };

  const handleReset = () => {
    setbeneVisible(false);
    setSelectedBeneficiaries([]);
    setBeneficiaryDetails({});
    // setShowAfterCloseBene(false);
  };

  const handleDistributionTypeChange = (event) => {
    console.log("handleDistributionTypeChange working : ", event.target.value);
    console.log("handleDistributionTypeChange sharedDetails", sharedDetails);
    var distributedType = event.target.value;
    const sharedDetails1 = sharedDetails.map((detail) => {
      // Change the distributedType value as needed
      detail.distributedType = distributedType;
      return detail;
    });
    console.log("handleDistributionType changed sharedDetails", sharedDetails);
    setSharedDetails(sharedDetails1);
    const newType = event.target.value;
    const resetDetails = {};
    Object.keys(beneficiaryDetails).forEach((beneficiary) => {
      resetDetails[beneficiary] = { percentage: "", dollar: "" };
    });
    setDistributedType(newType);
    setBeneficiaryDetails(resetDetails);
  };

  const handleBeneficiarySelection1 = (selectedBeneficiary) => {
    console.log("handleBeneficiarySelection working : ", selectedBeneficiary);
    if (selectedBeneficiary === null) {
      return null;
    }
    setSelectedBeneficiaries((prevSelectedBeneficiaries) => {
      // Check if the selectedBeneficiary is already included
      if (!prevSelectedBeneficiaries.includes(selectedBeneficiary)) {
        // If not included, update the selected beneficiaries
        const newSelectedBeneficiaries = [
          ...prevSelectedBeneficiaries,
          selectedBeneficiary,
        ];
        console.log(
          "Updated selected beneficiaries:",
          newSelectedBeneficiaries
        );
        return newSelectedBeneficiaries;
      }

      // If already included, return the previous state without any changes
      return prevSelectedBeneficiaries;
    });

    setBeneficiaryDetails((prevBeneficiaryDetails) => {
      const newBeneficiaryDetails = {
        ...prevBeneficiaryDetails,
        [selectedBeneficiary]: { percentage: "", dollar: "" },
      };
      console.log("Updated beneficiary details:", newBeneficiaryDetails);
      return newBeneficiaryDetails;
    });

    // setSelectedBeneficiaries((prevSelectedBeneficiaries) => {
    //   if (!prevSelectedBeneficiaries.includes(selectedBeneficiary)) {
    //     console.log("handleBeneficiarySelection 1 : ", selectedBeneficiary);
    //     setBeneficiaryDetails({
    //       ...beneficiaryDetails,
    //       [selectedBeneficiary]: { percentage: "", dollar: "" },
    //     });
    //     return [...prevSelectedBeneficiaries, selectedBeneficiary];
    //   }
    //   return prevSelectedBeneficiaries;
    // });

    console.log("handleBeneficiarySelection 2 : ", selectedBeneficiaries);
    console.log("check beneficiaryDetails 2 : ", beneficiaryDetails);
  };

  const handleBeneficiarySelection = (event) => {
    const selectedBeneficiary = event.target.value;
    console.log("handleBeneficiarySelection working : ", selectedBeneficiary);
    if (!selectedBeneficiaries.includes(selectedBeneficiary)) {
      setSelectedBeneficiaries([...selectedBeneficiaries, selectedBeneficiary]);
      setBeneficiaryDetails({
        ...beneficiaryDetails,
        [selectedBeneficiary]: { percentage: "", dollar: "" },
      });
    }
  };

  const getBenificiaryName = (id) => {
    // console.log('getBenificiaryName id : ', id);
    var foundBenificiary = null;
    if (id.beneficiary === undefined) {
      // console.log('IF condition');
      foundBenificiary = beneficiary.find((b) => b.id === parseInt(id));
    } else {
      foundBenificiary = beneficiary.find(
        (b) => b.id === parseInt(id.beneficiary)
      );
    }
    // console.log('foundBenificiary details : ', foundBenificiary);
    if (foundBenificiary) {
      return `${foundBenificiary.firstName} ${foundBenificiary.lastName}`;
    } else {
      return "Benificiary not found";
    }
  };

  const handleFieldChange1 = (beneficiary, field, value) => {
    console.log("handleFieldChange1   : ", beneficiaryDetails);

    setBeneficiaryDetails((prevDetails) => {
      // Create a copy of the state
      let updatedDetails = { ...prevDetails };

      // Update the copy
      updatedDetails = {
        ...updatedDetails,
        [beneficiary]: {
          ...updatedDetails[beneficiary],
          [field]: value,
        },
      };
      console.log("handleFieldChange1  2 : ", updatedDetails);

      // Return the updated state
      return updatedDetails;
    });
  };

  const handleFieldChange = (beneficiary, field, value) => {
    if (!estimatedTotalAmount || parseFloat(estimatedTotalAmount) === 0) {
      toast.error(
        "Please provide a valid estimated value before adding percentages!"
      );
      return;
    }
    let updatedDetails = { ...beneficiaryDetails };
    if (/^\d*$/.test(value)) {
      updatedDetails = {
        ...updatedDetails,
        [beneficiary]: {
          ...updatedDetails[beneficiary],
          [field]: value,
        },
      };
    }
    let totalPercentage = 0;
    let totalValue = 0;
    Object.keys(updatedDetails).forEach((key) => {
      const beneficiaryPercentage = parseFloat(updatedDetails[key]?.percentage);
      const beneficiaryValue = parseFloat(updatedDetails[key]?.dollar);
      if (!isNaN(beneficiaryPercentage) && beneficiaryPercentage >= 0) {
        totalPercentage += beneficiaryPercentage;
      }
      if (!isNaN(beneficiaryValue) && beneficiaryValue >= 0) {
        totalValue += beneficiaryValue;
      }
    });
    if (
      totalPercentage > 100 ||
      totalValue > parseFloat(estimatedTotalAmount)
    ) {
      updatedDetails[beneficiary][field] = "";
      toast.error(
        "Total percentage exceeds 100% or total value exceeds estimated value!"
      );
    } else {
      setBeneficiaryDetails(updatedDetails);
      // setSharedDetails(updatedDetails);
      // sharedDetails[index].distributedValue = updatedDetails.value;
      console.log("updatedDetails : ", updatedDetails);
    }
  };

  const calculateDistributedAmount = (
    distributedType,
    balance,
    beneficiaryDetail
  ) => {
    console.log("calculateDistributedAmount : ", beneficiaryDetail);
    console.log("calculateDistributedAmount balance : ", balance);
    // Assuming beneficiaryDetail is an object with a property 'value'
    if (distributedType === "percentage") {
      const calculatedAmount =
        (parseFloat(balance) * parseFloat(beneficiaryDetail.percentage)) / 100;
      console.log("calculateDistributedAmount return: ", calculatedAmount);
      return calculatedAmount.toFixed(2);
    } else if (distributedType === "dollar") {
      const detailValue = parseFloat(beneficiaryDetail.dollar);
      return detailValue.toFixed(2);
    }
    return "0.00";
  };

  const handleSave = () => {
    toast.success("Saved successfully!");
    setbeneVisible(false);
    var i = 0;
    Object.keys(beneficiaryDetails).forEach((key) => {
      const beneficiaryPercentage = parseFloat(
        beneficiaryDetails[key]?.percentage
      );
      const beneficiaryValue = parseFloat(beneficiaryDetails[key]?.dollar);
      if (!isNaN(beneficiaryPercentage) && beneficiaryPercentage >= 0) {
        const distributedAmount = calculateDistributedAmount(
          "percentage",
          estimatedTotalAmount,
          beneficiaryDetails[key]
        );
        handleChanges2(
          "percentage",
          beneficiaryPercentage,
          distributedAmount,
          parseInt(key),
          i
        );
        i += 1;
      }
      if (!isNaN(beneficiaryValue) && beneficiaryValue >= 0) {
        const distributedAmount = calculateDistributedAmount(
          "dollar",
          estimatedTotalAmount,
          beneficiaryDetails[key]
        );
        handleChanges2(
          "dollar",
          beneficiaryValue,
          distributedAmount,
          parseInt(key),
          i
        );
        i += 1;
      }
    });
    console.log("beneficiaryDetails data: ", data);
  };

  const handleChanges2 = (t, v, a, b, i) => {
    const updatedSharedDetails = [...data.sharedDetails];
    const updatedElement = {
      ...updatedSharedDetails[i],
      distributedType: t,
      distributedValue: v,
      distributedAmount: a,
      beneficiaryId: b,
    };
    updatedSharedDetails[i] = updatedElement;
    console.log("updatedSharedDetails[i] : ", updatedSharedDetails[i]);
    setData((prevState) => ({
      ...prevState,
      sharedDetails: updatedSharedDetails,
    }));
    data.sharedDetails[i] = updatedSharedDetails[i];
  };

  useEffect(() => {
    getBenificiarydata();
  }, []);

  return (
    <>
      {form1 && (
        <div className="overlay1-edit">
          <div
            className="propertyform"
            style={{
              display: "flex",
              justifyContent: "left",
              paddingTop: "30px",
            }}
          >
            <Container style={{ width: "425px" }}>
              <Card color="outline">
                <CardHeader>
                  <h2 className="form1-heading">Edit Assets</h2>
                  <div
                    className="Close"
                    onClick={() => {
                      navigate("/user/my-estate/International_assets");
                    }}
                  >
                    <FontAwesomeIcon icon={faXmark} />
                  </div>
                </CardHeader>
                <CardBody>
                  <Form onSubmit={OtherAssestForm}>
                    <div className="mt-2 ">
                      <div>
                        <Tooltip title="Enter Caption for your assets">
                          <div>
                            <FormLabel>Assets Caption</FormLabel>
                            <Input
                              className="input_mui_joy"
                              placeholder="Enter asset caption"
                              onChange={(e) =>
                                handleChangesData(e, "assetCaption")
                              }
                              value={data1.assetCaption}
                            />
                          </div>
                        </Tooltip>
                      </div>
                    </div>

                    <div className="mt-2">
                      <div>
                        <Tooltip title="Enter the Country of your assets">
                          <div>
                            <FormLabel required>Country Name</FormLabel>

                            <Select
                              placeholder="Select country name"
                              onChange={(e, newValue) =>
                                handleChangesCountry(e, newValue)
                              }
                              value={data1.countryName}
                              required
                            >
                              {Country.getAllCountries().map((v, index) => {
                                return (
                                  <Option key={index} value={v.name}>
                                    {v.name}
                                  </Option>
                                );
                              })}
                            </Select>
                          </div>
                        </Tooltip>
                      </div>
                    </div>
                    <div>
                      <Tooltip title="Select Owner" className="mt-2">
                        <div>
                          <FormLabel required>Select Owner</FormLabel>

                          <Select
                            placeholder="Select Owner"
                            value={ownerName}
                            multiple
                            onChange={(event, newValue) =>
                              handleChange(event, newValue, "owner")
                            }
                            sx={{
                              minWidth: "13rem",
                            }}
                            slotProps={{
                              listbox: {
                                sx: {
                                  width: "100%",
                                },
                              },
                            }}
                            required
                          >
                            {ownerNames.map((name) => (
                              <Option key={name} value={name}>
                                {name}
                              </Option>
                            ))}
                          </Select>
                        </div>
                      </Tooltip>
                    </div>

                    {data.otherAsset.owner?.includes("Other") && (
                      <div className="mt-2">
                        <Tooltip title="Enter Your Other Owner Name ">
                          <div>
                            <FormLabel>Other Owner</FormLabel>
                            <Input
                              className="input_mui_joy"
                              placeholder="Enter Other Owner"
                              id="otherprop"
                              onChange={(e) => handleChanges(e, "otherOwner")}
                              value={data.otherAsset.otherOwner}
                            />
                          </div>
                        </Tooltip>
                      </div>
                    )}

                    <div>
                      <Tooltip title="Enter Heading for other Assets">
                        <div className="mt-2">
                          <FormLabel>Assets Heading</FormLabel>
                          <Input
                            className="input_mui_joy"
                            placeholder="Enter assets heading"
                            onChange={(e) => handleChanges(e, "assetCaption")}
                            value={data.otherAsset.assetCaption}
                          />
                        </div>
                      </Tooltip>
                    </div>

                    <div>
                      {otherAssetss.map((index) => (
                        <div
                          key={index}
                          className="mt-2"
                          style={{
                            flexDirection: "row",
                            display:
                              index <= visibleColumnIndex ? "flex" : "none",
                          }}
                        >
                          <div style={{ width: "100%" }}>
                            <Tooltip
                              title={
                                index === 0
                                  ? "Add your Other Assets"
                                  : "Add More On it "
                              }
                            >
                              <div>
                                <FormLabel required>
                                  {`Assets ${index + 1}`}
                                </FormLabel>
                                <Input
                                  className="input_mui_joy"
                                  id={`otherAssets${index + 1}`}
                                  placeholder={`Enter Assets ${index + 1}`}
                                  onChange={(e) =>
                                    handleChanges(e, `otherAssets${index + 1}`)
                                  }
                                  value={
                                    data.otherAsset[
                                      `otherAssets${index + 1}`
                                    ] || ""
                                  }
                                  required={visibleColumnIndex >= index}
                                ></Input>
                              </div>
                            </Tooltip>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "flex-end",
                            }}
                          >
                            {index >= findOthAssetLength && ( // Render minus button for indexes other than 0
                              <Button
                                style={{
                                  height: "30px",
                                  width: "30px",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  borderRadius: "50%",
                                  backgroundColor: "#ff4a4a",
                                  border: "none",
                                  marginLeft: "5px",
                                }}
                                onClick={() => handleRemoveColumn(index)}
                              >
                                <FontAwesomeIcon icon={faMinus} />
                              </Button>
                            )}
                          </div>
                        </div>
                      ))}
                      {visibleColumnIndex < 4 && (
                        <div
                          style={{
                            marginTop: "7px",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Button
                            style={{
                              height: "30px",
                              width: "30px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              borderRadius: "50%",
                              backgroundColor: "#4aafff",
                              border: "none",
                            }}
                            onClick={handleAddColumn}
                          >
                            <FontAwesomeIcon icon={faPlus} />
                          </Button>
                        </div>
                      )}
                    </div>

                    <div>
                      <Tooltip title="Enter value for other Assets">
                        <div className="mt-2">
                          <FormLabel required>Assets Value</FormLabel>
                          <Input
                            type="number"
                            className="input_mui_joy"
                            placeholder="Enter assets value"
                            onChange={(e) => handleChanges(e, "assetValue")}
                            value={data.otherAsset.assetValue}
                            startDecorator={<div>$</div>}
                            required
                          />
                        </div>
                      </Tooltip>
                    </div>

                    <div className="mt-3">
                      <Tooltip title="Add your income related file">
                        <div>
                          <label
                            style={{
                              display: "block",
                              marginBottom: "5px",
                            }}
                          >
                            Supporting Document<span></span>
                          </label>
                          <input
                            style={{
                              border: "solid 1px lightgray",
                              borderLeft: "none",
                              width: "100%",
                              borderRadius: "5px",
                            }}
                            type="file"
                            name="myfile"
                            id="exampleFile"
                            onChange={(e) => handleImageChange(e)}
                            multiple
                            accept=".pdf"
                          />
                        </div>
                      </Tooltip>
                    </div>

                    <div
                      className="bene-select mt-3"
                      onClick={handleShowBeneficiary}
                      style={{ cursor: "pointer" }}
                    >
                      Select Your Beneficiary
                    </div>

                    <div className="address_input">
                      <div style={{ width: "100%" }} className="mt-2">
                        <Tooltip title="Enter notes for your assets">
                          <div>
                            <FormLabel>Notes</FormLabel>
                            <Textarea
                              sx={{ height: "182px", minHeight: "52px" }}
                              placeholder="Notes"
                              id="notes"
                              onChange={(e) => handleChanges(e, "notes")}
                              value={data.otherAsset.notes}
                            />
                          </div>
                        </Tooltip>
                      </div>
                    </div>

                    {/* adding new field */}
                    {/* <div style={{ marginTop: "7px", display: "flex", alignItems: "center" }}>
                        <Button style={{
                          height: "30px",
                          width: "30px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: "50%",
                          backgroundColor: "#4aafff",
                          border: "none"
                        }}
                          onClick={handleAddField}>
                          <FontAwesomeIcon icon={faPlus} />
                        </Button>
                        Add New Field
                      </div>
                      <div style={{ width: "99.5%" }}>
                        {addField.map((index) => (
                          <div className="mt-2" key={index} style={{ flexDirection: "row", display: index < visibleField ? "flex" : "none", }}>
                            <div style={{ width: "97%" }}>
                              <Tooltip title={`Add New Field ${index + 1}`}>
                                <TextField
                                  fullWidth
                                  type="text"
                                  label={`New Field ${index + 1}`}
                                  id={`addfield${index + 1}`}
                                  size="normal"
                                  onChange={(e) => handleChanges(e, `addfield${index + 1}`)}
                                  value={data[`addfield${index + 1}`] || ''}

                                  className="AddField"
                                />
                              </Tooltip>
                            </div>
                            <span className="addFieldClose" onClick={() => setVisibleField(visibleField - 1)} style={{ width: "2%", paddingLeft: "5px" }}><FontAwesomeIcon icon={faXmark} /></span>
                          </div>
                        ))}

                      </div> */}

                    <Container
                      className="text-center"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <Button
                        color="success"
                        outline
                        className="my-estate-add-btn"
                      >
                        Update
                      </Button>
                    </Container>
                  </Form>
                </CardBody>
              </Card>
            </Container>
          </div>
        </div>
      )}
      {benevisible && (
        <div className="popup">
          <div
            className="popup-content"
            style={{
              minWidth: "350px",
              width: "100%",
              maxWidth: "700px",
            }}
          >
            <div className="note_popup">
              <div className="note_popup_heading">
                <div className="share_property_heading">
                  <h2>Share Property </h2>
                </div>
                <div>
                  <button
                    className="note_popup_heading_close_btn"
                    onClick={handleReset}
                  >
                    <FontAwesomeIcon icon={faXmark} />
                  </button>
                </div>
              </div>
              <div className="share_property_est_value">
                <p>
                  Estimated Value:{" "}
                  <code style={{ color: "green", fontWeight: "bold" }}>
                    ${estimatedTotalAmount}
                  </code>
                </p>
                {/* {JSON.stringify(sharedDetails)}  */}
                {/* <p>----------------------------------------------------</p>
                    {JSON.stringify(data.documents)}
                    <p>----------------------------------------------------</p>
                    {JSON.stringify(data.accounts)}
                    <p>----------------------------------------------------</p>
                    {JSON.stringify(data.bank)}
                    <p>----------------------------------------------------</p>
                    {JSON.stringify(data)} */}
              </div>
              <div className="BeneficiarySelect">
                <div className="BeneficiarySelectContainer">
                  <div className="BeneficiarySelectRow">
                    <div className="share_property_Type">
                      <p className="share_property_Type_paragraph">
                        Choose Distribution Type:{" "}
                      </p>
                      <p className="ms-2 text-black fw-normal">
                        {/* {sharedDetails[0].distributedType} */}
                      </p>
                      <select
                        value={sharedDetails[0].distributedType}
                        onChange={(e) => handleDistributionTypeChange(e)}
                        class="share_property_Type_select"
                        disabled={
                          sharedDetails[0].distributedType !== "" ? true : false
                        }
                      >
                        <option value="">Select Type</option>
                        <option value="percentage">Percentage</option>
                        <option value="dollar">Dollar</option>
                      </select>
                    </div>
                    <div className="SelectContainer">
                      <select
                        className="Property-inputfiled"
                        onChange={handleBeneficiarySelection}
                        value=""
                        disabled={!distributedType}
                      >
                        <option value="" disabled hidden>
                          {distributedType
                            ? "Select Your Beneficiary Username"
                            : "Select Type First"}
                        </option>
                        {beneficiary.map((benif) => (
                          <option
                            key={benif.id}
                            value={benif.id}
                            disabled={selectedBeneficiaries.includes(benif.id)}
                          >
                            {/* {benif.username} */}
                            {`${benif.firstName} ${benif.lastName}`}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  {/* <div className="share_beneficiary_main_card">
                        {sharedDetails[0].distributedType !== null && (
                          <>
                            {sharedDetails[0].distributedType === 'dollar' ? (
                              <div>
                                <div className="share_beneficiary_card_close" onClick={() => handleBeneficiaryClose(beneficiary)}>
                                  <FontAwesomeIcon icon={faXmark} />
                                </div> 
                                <p>Beneficiary: </p>
                                <TextField type="number" label="Dollar" />
                              </div>
                            ) : null}

                            {sharedDetails[0].distributedType === 'percentage' ? (
                              <div>
                                <p>Beneficiary: </p>
                                <TextField type="number" label="Percentage" />
                              </div>
                            ) : null}
                          </>
                        )}
                      </div> */}
                  <div className="share_beneficiary_main_card">
                    {selectedBeneficiaries.map((beneficiary) => (
                      <div key={beneficiary} class="share_beneficiary_card">
                        <div>
                          <p className="share_beneficiary_card_para">
                            Beneficiary: {getBenificiaryName({ beneficiary })}
                          </p>
                          {distributedType === "percentage" && (
                            <input
                              type="text"
                              className="share_ben_percentage"
                              placeholder="Percentage"
                              value={
                                beneficiaryDetails[beneficiary]?.percentage ||
                                ""
                              }
                              onChange={(e) =>
                                handleFieldChange(
                                  beneficiary,
                                  "percentage",
                                  e.target.value
                                )
                              }
                            />
                          )}
                          {distributedType === "dollar" && (
                            <input
                              type="text"
                              className="share_ben_percentage"
                              placeholder="Dollar Value"
                              value={
                                beneficiaryDetails[beneficiary]?.dollar || ""
                              }
                              onChange={(e) =>
                                handleFieldChange(
                                  beneficiary,
                                  "dollar",
                                  e.target.value
                                )
                              }
                            />
                          )}

                          {distributedType && (
                            <p className="share_beneficiary_card_para">
                              {/* <p></p> */}
                              Distributed Value: $
                              {distributedType === "percentage"
                                ? calculateDistributedAmount(
                                    "percentage",
                                    estimatedTotalAmount,
                                    beneficiaryDetails[beneficiary]
                                  )
                                : calculateDistributedAmount(
                                    "dollar",
                                    estimatedTotalAmount,
                                    beneficiaryDetails[beneficiary]
                                  )}
                            </p>
                          )}
                        </div>
                        {/* <div
                            className="share_beneficiary_card_close"
                            onClick={() => handleBeneficiaryClose(beneficiary)}
                          >
                            <FontAwesomeIcon icon={faXmark} />
                          </div> */}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            {selectedBeneficiaries.length > 0 && (
              <button onClick={handleSave}>Save</button>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export function EditInternationalAssetIncome() {
  const { id } = useParams();
  const navigate = useNavigate();
  let [form1, setForm1] = useState(true);

  const toggle = () => {
    setForm1(!form1);
    navigate("/user/my-estate/International_assets");
  };
  // set Add data
  const [data, setData] = useState({
    income: {
      owner: "",
      otherOwner: "",
      incomeCaption: "",
      incomeAmount: "",
      businessSource: "",
      notes: "",
    },
    sharedDetails: [
      {
        distributedType: "",
        distributedValue: "",
        distributedAmount: "",
        beneficiaryId: "",
      },
    ],
  });

  const [data1, setData1] = useState({
    id: "",
    assetCaption: "",
    assetType: "income",
    countryName: "",
  });

  const handleChangesData = (e, field) => {
    const newValue = e.target.value;
    setData1((prevData) => ({
      ...prevData,
      [field]: newValue,
    }));
  };

  const handleChangesCountry = (event, newValue) => {
    setData1((prevData) => ({
      ...prevData,
      countryName: newValue,
    }));
  };

  const [ownerName, setOwnerName] = useState([]);
  const handleChange = (event, newValue, stringValue) => {
    if (stringValue === "owner") {
      // Convert ownerName array to a single string
      if (newValue.toString() === "Other") {
      } else {
        setData((prevData) => ({
          ...prevData,
          income: {
            ...prevData.income,
            otherOwner: "",
          },
        }));
      }

      const comingValue =
        typeof newValue === "string" ? newValue.split(",") : newValue;
      const ownerString = comingValue.join(", ");

      setData((prevData) => ({
        ...prevData,
        income: {
          ...prevData.income,
          owner: ownerString,
        },
      }));

      // Update the ownerName state afterwards
      setOwnerName(newValue);
    }
  };

  // Define an array to store user names
  const ownerNames = [];

  // Get primaryUser data
  const primaryUserDetails = getUser();
  const primaryUserName =
    primaryUserDetails.firstName + " " + primaryUserDetails.lastName;

  // Get secondaryUser data
  const secondaryUserDetails = getSecondaryUser();
  let secondaryUserName = "";

  // Check if secondary user exists
  if (secondaryUserDetails !== undefined) {
    secondaryUserName =
      secondaryUserDetails.firstName + " " + secondaryUserDetails.lastName;

    // Push both user names into the array
    ownerNames.push(primaryUserName, secondaryUserName, "Other");
  } else {
    // If secondary user doesn't exist, only push primary user name
    ownerNames.push(primaryUserName, "Other");
  }

  // use state to set the selected images
  const [selectedImage, setSelectedImage] = useState([]);

  // Handle image
  const handleImageChange = (event) => {
    const selectedFiles = event.target.files;
    const allowedExtensions = ["pdf"];

    if (selectedFiles) {
      const selectedFilesArray = Array.from(selectedFiles);

      // Check each file's extension
      const invalidFiles = selectedFilesArray.filter((file) => {
        const fileNameParts = file.name.split(".");
        const fileExtension =
          fileNameParts[fileNameParts.length - 1].toLowerCase();
        return !allowedExtensions.includes(fileExtension);
      });

      if (invalidFiles.length === 0) {
        const formData = new FormData();

        selectedFilesArray.forEach((file, index) => {
          formData.append(`file${index + 1}`, file);
        });

        // Set the selected image state with the array of files
        setSelectedImage(selectedFilesArray);

        // Now, you can use the formData object to send files to the API
        // Example:
        // apiCall(formData);
      } else {
        // Handle invalid file extensions
        const invalidExtensions = invalidFiles
          .map((file) => file.name.split(".").pop())
          .join(", ");
        toast.error(
          `Invalid file extensions: ${invalidExtensions}. Please select valid document formats.`,
          {
            position: toast.POSITION.BOTTOM_CENTER,
          }
        );
        event.target.value = ""; // Clear the input field
      }
    }
  };

  // const handleChanges = (event, property) => {
  //   setData({ ...data, [property]: event.target.value });
  // };
  const handleChanges = (e, field) => {
    const newValue = e.target.value;
    setData((prevData) => ({
      ...prevData,
      income: {
        ...prevData.income,
        [field]: newValue,
      },
    }));
    setEstimatedTotalAmount(data.income.incomeAmount);
  };

  // auto matic clear the data
  const resetForm = () => {
    setData({
      income: {
        owner: "",
        incomeCaption: "",
        incomeAmount: "",
        businessSource: "",
        notes: "",
      },
      sharedDetails: [
        {
          distributedType: "",
          distributedValue: "",
          distributedAmount: "",
          beneficiaryId: "",
        },
      ],
    });
  };

  // post the form
  const activeincomeForm = (event) => {
    event.preventDefault();
    let token = "Bearer " + getToken();
    const formData = new FormData();
    if (null != selectedImage) {
      for (let i = 0; i < selectedImage.length; i++) {
        formData.append(`files`, selectedImage[i]);
        console.log("this is file indexs", selectedImage[i]);
      }
    }
    formData.append("asset", JSON.stringify(data));
    formData.append("data", JSON.stringify(data1));

    updateInternationalAsset(formData, token)
      .then((resp) => {
        toast.success("Updated Successfully!!", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
        toggle();
      })
      .catch((error) => {
        toast.error("An error occurred. Please try again.", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      });
  };
  // Set the form
  // const activeincomeForm = (event) => {
  //   event.preventDefault();

  //   // if (
  //   //   // data.income.payCheck === "" ||
  //   //   data.income.businessSource === ""
  //   // ) {
  //   //   // data.customize === ""
  //   //   console.log("Error log");
  //   //   toast.error("Please fill all required feilds.");
  //   //   return;
  //   // }

  //   let token = "Bearer " + getToken();

  //   console.log("Token : " + token);

  //   //create form data to send a file and remaining class data
  //   const formData = new FormData();
  //   for (let i = 0; i < selectedImage.length; i++) {
  //     formData.append(`files`, selectedImage[i]);
  //     console.log("this is file indexs", selectedImage[i]);
  //   }
  //   formData.append("data", JSON.stringify(data));
  //   console.log(formData);
  //   updateIncome(formData, token)
  //     .then((resp) => {
  //       console.log(resp);
  //       console.log("Success log");
  //       toast.success("Updated Successfully !!", {
  //         position: toast.POSITION.BOTTOM_CENTER,
  //       });
  //       navigate("/user/my-estate/income");
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };

  // const [showAfterCloseBene, setShowAfterCloseBene] = useState(true);
  useEffect(() => {
    const fetchData = async () => {
      try {
        let token = "Bearer " + getToken();
        const res = await getSingleInternationalAsset(token, id);
        console.log("this is income responce ", res);
        setData((prevData) => ({
          ...prevData,
          income: res.assetData.income,
          documents: res.assetData.documents,
          sharedDetails: res.assetData.sharedDetails,
        }));

        setData1({
          ...data1,
          id: res.internationalAssetData.id,
          assetCaption: res.internationalAssetData.assetCaption,
          countryName: res.internationalAssetData.countryName,
        });

        const ownerNameArray = res.assetData.income.owner.split(", ");
        setOwnerName(ownerNameArray);
        const copiedSharedDetails = [...res.assetData.sharedDetails];

        setEstimatedTotalAmount(res.assetData.income.incomeAmount);

        if (copiedSharedDetails.length > 0) {
          setSharedDetails(res.assetData.sharedDetails);

          ben(copiedSharedDetails[0].distributedType);
          for (var i = 0; i < copiedSharedDetails.length; i++) {
            handleBeneficiarySelection1(copiedSharedDetails[i].beneficiaryId);
            handleFieldChange1(
              copiedSharedDetails[i].beneficiaryId,
              copiedSharedDetails[i].distributedType,
              copiedSharedDetails[i].distributedValue
            );
            // console.log("sajid " + sharedDetails[0])
          }

          console.log(
            "sharedDetails beneficiaryDetails : ",
            beneficiaryDetails
          );
          console.log(
            "sharedDetails selectedBeneficiaries : ",
            selectedBeneficiaries
          );
          console.log("sharedDetails distributedType : ", distributedType);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
    getBenificiarydata();
  }, []);

  // beneficiary addition in form
  const [beneficiary, setBenificiary] = useState([]);
  const getBenificiarydata = () => {
    let userId = getUser().commonId;
    console.log("user Id=" + userId);
    let token = "Bearer " + getToken();
    getBeneficiary(token, userId)
      .then((res) => {
        setBenificiary(res);
      })
      .catch((err) => console.log(err));
  };

  //

  const [benevisible, setbeneVisible] = useState(false);

  const [selectedBeneficiaries, setSelectedBeneficiaries] = useState([]);
  const [beneficiaryDetails, setBeneficiaryDetails] = useState({});
  const [estimatedTotalAmount, setEstimatedTotalAmount] = useState(0);
  const [distributedType, setDistributedType] = useState("");

  const [sharedDetails, setSharedDetails] = useState([
    {
      distributedType: "",
      distributedValue: "",
      distributedAmount: "",
      beneficiaryId: "",
    },
  ]);

  const ben = (newType) => {
    // const newType = sharedDetails[0].distributedType;
    const resetDetails = {};
    Object.keys(beneficiaryDetails).forEach((beneficiary) => {
      resetDetails[beneficiary] = { percentage: "", value: "" };
    });
    setDistributedType(newType);
    setBeneficiaryDetails(resetDetails);
  };

  const handleShowBeneficiary = () => {
    setbeneVisible(true);

    // setShowAfterCloseBene(true);
  };

  const handleReset = () => {
    setbeneVisible(false);
    setSelectedBeneficiaries([]);
    setBeneficiaryDetails({});
    // setShowAfterCloseBene(false);
  };

  const handleDistributionTypeChange = (event) => {
    console.log("handleDistributionTypeChange working : ", event.target.value);
    console.log("handleDistributionTypeChange sharedDetails", sharedDetails);
    var distributedType = event.target.value;
    const sharedDetails1 = sharedDetails.map((detail) => {
      // Change the distributedType value as needed
      detail.distributedType = distributedType;
      return detail;
    });
    console.log("handleDistributionType changed sharedDetails", sharedDetails);
    setSharedDetails(sharedDetails1);
    const newType = event.target.value;
    const resetDetails = {};
    Object.keys(beneficiaryDetails).forEach((beneficiary) => {
      resetDetails[beneficiary] = { percentage: "", dollar: "" };
    });
    setDistributedType(newType);
    setBeneficiaryDetails(resetDetails);
  };

  const handleBeneficiarySelection1 = (selectedBeneficiary) => {
    console.log("handleBeneficiarySelection working : ", selectedBeneficiary);
    if (selectedBeneficiary === null) {
      return null;
    }
    setSelectedBeneficiaries((prevSelectedBeneficiaries) => {
      // Check if the selectedBeneficiary is already included
      if (!prevSelectedBeneficiaries.includes(selectedBeneficiary)) {
        // If not included, update the selected beneficiaries
        const newSelectedBeneficiaries = [
          ...prevSelectedBeneficiaries,
          selectedBeneficiary,
        ];
        console.log(
          "Updated selected beneficiaries:",
          newSelectedBeneficiaries
        );
        return newSelectedBeneficiaries;
      }

      // If already included, return the previous state without any changes
      return prevSelectedBeneficiaries;
    });

    setBeneficiaryDetails((prevBeneficiaryDetails) => {
      const newBeneficiaryDetails = {
        ...prevBeneficiaryDetails,
        [selectedBeneficiary]: { percentage: "", dollar: "" },
      };
      console.log("Updated beneficiary details:", newBeneficiaryDetails);
      return newBeneficiaryDetails;
    });

    // setSelectedBeneficiaries((prevSelectedBeneficiaries) => {
    //   if (!prevSelectedBeneficiaries.includes(selectedBeneficiary)) {
    //     console.log("handleBeneficiarySelection 1 : ", selectedBeneficiary);
    //     setBeneficiaryDetails({
    //       ...beneficiaryDetails,
    //       [selectedBeneficiary]: { percentage: "", dollar: "" },
    //     });
    //     return [...prevSelectedBeneficiaries, selectedBeneficiary];
    //   }
    //   return prevSelectedBeneficiaries;
    // });

    console.log("handleBeneficiarySelection 2 : ", selectedBeneficiaries);
    console.log("check beneficiaryDetails 2 : ", beneficiaryDetails);
  };

  const handleBeneficiarySelection = (event) => {
    const selectedBeneficiary = event.target.value;
    console.log("handleBeneficiarySelection working : ", selectedBeneficiary);
    if (!selectedBeneficiaries.includes(selectedBeneficiary)) {
      setSelectedBeneficiaries([...selectedBeneficiaries, selectedBeneficiary]);
      setBeneficiaryDetails({
        ...beneficiaryDetails,
        [selectedBeneficiary]: { percentage: "", dollar: "" },
      });
    }
  };

  const getBenificiaryName = (id) => {
    // console.log('getBenificiaryName id : ', id);
    var foundBenificiary = null;
    if (id.beneficiary === undefined) {
      // console.log('IF condition');
      foundBenificiary = beneficiary.find((b) => b.id === parseInt(id));
    } else {
      foundBenificiary = beneficiary.find(
        (b) => b.id === parseInt(id.beneficiary)
      );
    }
    // console.log('foundBenificiary details : ', foundBenificiary);
    if (foundBenificiary) {
      return `${foundBenificiary.firstName} ${foundBenificiary.lastName}`;
    } else {
      return "Benificiary not found";
    }
  };

  const handleFieldChange1 = (beneficiary, field, value) => {
    console.log("handleFieldChange1   : ", beneficiaryDetails);

    setBeneficiaryDetails((prevDetails) => {
      // Create a copy of the state
      let updatedDetails = { ...prevDetails };

      // Update the copy
      updatedDetails = {
        ...updatedDetails,
        [beneficiary]: {
          ...updatedDetails[beneficiary],
          [field]: value,
        },
      };
      console.log("handleFieldChange1  2 : ", updatedDetails);

      // Return the updated state
      return updatedDetails;
    });
  };

  const handleFieldChange = (beneficiary, field, value) => {
    if (!estimatedTotalAmount || parseFloat(estimatedTotalAmount) === 0) {
      toast.error(
        "Please provide a valid estimated value before adding percentages!"
      );
      return;
    }
    let updatedDetails = { ...beneficiaryDetails };
    if (/^\d*$/.test(value)) {
      updatedDetails = {
        ...updatedDetails,
        [beneficiary]: {
          ...updatedDetails[beneficiary],
          [field]: value,
        },
      };
    }
    let totalPercentage = 0;
    let totalValue = 0;
    Object.keys(updatedDetails).forEach((key) => {
      const beneficiaryPercentage = parseFloat(updatedDetails[key]?.percentage);
      const beneficiaryValue = parseFloat(updatedDetails[key]?.dollar);
      if (!isNaN(beneficiaryPercentage) && beneficiaryPercentage >= 0) {
        totalPercentage += beneficiaryPercentage;
      }
      if (!isNaN(beneficiaryValue) && beneficiaryValue >= 0) {
        totalValue += beneficiaryValue;
      }
    });
    if (
      totalPercentage > 100 ||
      totalValue > parseFloat(estimatedTotalAmount)
    ) {
      updatedDetails[beneficiary][field] = "";
      toast.error(
        "Total percentage exceeds 100% or total value exceeds estimated value!"
      );
    } else {
      setBeneficiaryDetails(updatedDetails);
      // setSharedDetails(updatedDetails);
      // sharedDetails[index].distributedValue = updatedDetails.value;
      console.log("updatedDetails : ", updatedDetails);
    }
  };

  const calculateDistributedAmount = (
    distributedType,
    balance,
    beneficiaryDetail
  ) => {
    console.log("calculateDistributedAmount : ", beneficiaryDetail);
    console.log("calculateDistributedAmount balance : ", balance);
    // Assuming beneficiaryDetail is an object with a property 'value'
    if (distributedType === "percentage") {
      const calculatedAmount =
        (parseFloat(balance) * parseFloat(beneficiaryDetail.percentage)) / 100;
      console.log("calculateDistributedAmount return: ", calculatedAmount);
      return calculatedAmount.toFixed(2);
    } else if (distributedType === "dollar") {
      const detailValue = parseFloat(beneficiaryDetail.dollar);
      return detailValue.toFixed(2);
    }
    return "0.00";
  };

  const handleSave = () => {
    toast.success("Saved successfully!");
    setbeneVisible(false);
    var i = 0;
    Object.keys(beneficiaryDetails).forEach((key) => {
      const beneficiaryPercentage = parseFloat(
        beneficiaryDetails[key]?.percentage
      );
      const beneficiaryValue = parseFloat(beneficiaryDetails[key]?.dollar);
      if (!isNaN(beneficiaryPercentage) && beneficiaryPercentage >= 0) {
        const distributedAmount = calculateDistributedAmount(
          "percentage",
          estimatedTotalAmount,
          beneficiaryDetails[key]
        );
        handleChanges2(
          "percentage",
          beneficiaryPercentage,
          distributedAmount,
          parseInt(key),
          i
        );
        i += 1;
      }
      if (!isNaN(beneficiaryValue) && beneficiaryValue >= 0) {
        const distributedAmount = calculateDistributedAmount(
          "dollar",
          estimatedTotalAmount,
          beneficiaryDetails[key]
        );
        handleChanges2(
          "dollar",
          beneficiaryValue,
          distributedAmount,
          parseInt(key),
          i
        );
        i += 1;
      }
    });
    console.log("beneficiaryDetails data: ", data);
  };

  const handleChanges2 = (t, v, a, b, i) => {
    const updatedSharedDetails = [...data.sharedDetails];
    const updatedElement = {
      ...updatedSharedDetails[i],
      distributedType: t,
      distributedValue: v,
      distributedAmount: a,
      beneficiaryId: b,
    };
    updatedSharedDetails[i] = updatedElement;
    console.log("updatedSharedDetails[i] : ", updatedSharedDetails[i]);
    setData((prevState) => ({
      ...prevState,
      sharedDetails: updatedSharedDetails,
    }));
    data.sharedDetails[i] = updatedSharedDetails[i];
  };

  return (
    <>
      {form1 && (
        <div className="overlay1-edit">
          <div
            className="propertyform"
            style={{
              display: "flex",
              justifyContent: "left",
              paddingTop: "30px",
            }}
          >
            <Container className="edit_container">
              <Card color="outline">
                <CardHeader>
                  <h2 className="form1-heading">Edit Income </h2>
                  <div
                    className="Close"
                    onClick={() => {
                      navigate("/user/my-estate/International_assets");
                    }}
                  >
                    <FontAwesomeIcon icon={faXmark} />
                  </div>
                </CardHeader>
                <CardBody>
                  <Form onSubmit={activeincomeForm}>
                    <div className="mt-2 ">
                      <div>
                        <Tooltip title="Enter Caption for your assets">
                          <div>
                            <FormLabel>Assets Caption</FormLabel>
                            <Input
                              className="input_mui_joy"
                              placeholder="Enter asset caption"
                              onChange={(e) =>
                                handleChangesData(e, "assetCaption")
                              }
                              value={data1.assetCaption}
                            />
                          </div>
                        </Tooltip>
                      </div>
                    </div>

                    <div className="mt-2">
                      <div>
                        <Tooltip title="Enter the Country of your assets">
                          <div>
                            <FormLabel required>Country Name</FormLabel>

                            <Select
                              placeholder="Select country name"
                              onChange={(e, newValue) =>
                                handleChangesCountry(e, newValue)
                              }
                              value={data1.countryName}
                              required
                            >
                              {Country.getAllCountries().map((v, index) => {
                                return (
                                  <Option key={index} value={v.name}>
                                    {v.name}
                                  </Option>
                                );
                              })}
                            </Select>
                          </div>
                        </Tooltip>
                      </div>
                    </div>
                    <div>
                      <Tooltip title="Select Owner">
                        <div>
                          <FormLabel required>Select Owner</FormLabel>

                          <Select
                            placeholder="Select Owner"
                            value={ownerName}
                            multiple
                            onChange={(event, newValue) =>
                              handleChange(event, newValue, "owner")
                            }
                            sx={{
                              minWidth: "13rem",
                            }}
                            slotProps={{
                              listbox: {
                                sx: {
                                  width: "100%",
                                },
                              },
                            }}
                            required
                          >
                            {ownerNames.map((name) => (
                              <Option key={name} value={name}>
                                {name}
                              </Option>
                            ))}
                          </Select>
                        </div>
                      </Tooltip>
                    </div>
                    {data.income.owner?.includes("Other") && (
                      <div className="mt-2">
                        <Tooltip title="Enter Your Other Owner Name ">
                          <div>
                            <FormLabel>Other Owner</FormLabel>
                            <Input
                              className="input_mui_joy"
                              placeholder="Enter Other Owner"
                              id="otherprop"
                              onChange={(e) => handleChanges(e, "otherOwner")}
                              value={data.income.otherOwner}
                            />
                          </div>
                        </Tooltip>
                      </div>
                    )}

                    <div>
                      <Tooltip title="Enter Heading for Income">
                        <div className="mt-2">
                          <FormLabel>Income Heading</FormLabel>
                          <Input
                            className="input_mui_joy"
                            placeholder="Enter income heading"
                            value={data.income.incomeCaption}
                            onChange={(e) => handleChanges(e, "incomeCaption")}
                          />
                        </div>
                      </Tooltip>
                    </div>

                    <div>
                      <Tooltip title="Enter your Income">
                        <div className="mt-2">
                          <FormLabel required>Income</FormLabel>
                          <Input
                            type="number"
                            className="input_mui_joy"
                            placeholder="Enter income"
                            value={data.income.incomeAmount}
                            onChange={(e) => handleChanges(e, "incomeAmount")}
                            startDecorator={<div>$</div>}
                            required
                          />
                        </div>
                      </Tooltip>
                    </div>

                    <div>
                      <Tooltip title="Enter Source of your income">
                        <div className="mt-2">
                          <FormLabel required>Income Source</FormLabel>
                          <Input
                            className="input_mui_joy"
                            placeholder="Enter income heading"
                            value={data.income.businessSource}
                            onChange={(e) => handleChanges(e, "businessSource")}
                            required
                          />
                        </div>
                      </Tooltip>
                    </div>

                    <div className="mt-3">
                      <Tooltip title="Add your income related file">
                        <div>
                          <label
                            style={{
                              display: "block",
                              marginBottom: "5px",
                            }}
                          >
                            Supporting Document<span></span>
                          </label>
                          <input
                            style={{
                              border: "solid 1px lightgray",
                              borderLeft: "none",
                              width: "100%",
                              borderRadius: "5px",
                            }}
                            type="file"
                            name="myfile"
                            id="exampleFile"
                            multiple
                            accept=".pdf"
                            onChange={(e) => handleImageChange(e)}
                          />
                        </div>
                      </Tooltip>
                    </div>

                    <div
                      className="bene-select mt-3"
                      onClick={handleShowBeneficiary}
                      style={{ cursor: "pointer" }}
                    >
                      Select Your Beneficiary
                    </div>

                    <div className="address_input">
                      <div style={{ width: "100%" }} className="mt-2">
                        <Tooltip title="Enter notes for your income">
                          <div>
                            <FormLabel>Notes</FormLabel>
                            <Textarea
                              sx={{ height: "182px", minHeight: "52px" }}
                              placeholder="Notes"
                              id="notes"
                              onChange={(e) => handleChanges(e, "notes")}
                              value={data.income.notes}
                            />
                          </div>
                        </Tooltip>
                      </div>
                    </div>
                    <Container
                      className="text-center"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <Button
                        color="success"
                        outline
                        className="my-estate-add-btn"
                      >
                        Update
                      </Button>
                    </Container>
                  </Form>
                </CardBody>
              </Card>
            </Container>
          </div>
        </div>
      )}

      {benevisible && (
        <div className="popup">
          <div
            className="popup-content"
            style={{
              minWidth: "350px",
              width: "100%",
              maxWidth: "700px",
            }}
          >
            <div className="note_popup">
              <div className="note_popup_heading">
                <div className="share_property_heading">
                  <h2>Share Property </h2>
                </div>
                <div>
                  <button
                    className="note_popup_heading_close_btn"
                    onClick={handleReset}
                  >
                    <FontAwesomeIcon icon={faXmark} />
                  </button>
                </div>
              </div>
              <div className="share_property_est_value">
                <p>
                  Estimated Value:{" "}
                  <code style={{ color: "green", fontWeight: "bold" }}>
                    ${estimatedTotalAmount}
                  </code>
                </p>
                {/* {JSON.stringify(sharedDetails)}  */}
                {/* <p>----------------------------------------------------</p>
                    {JSON.stringify(data.documents)}
                    <p>----------------------------------------------------</p>
                    {JSON.stringify(data.accounts)}
                    <p>----------------------------------------------------</p>
                    {JSON.stringify(data.bank)}
                    <p>----------------------------------------------------</p>
                    {JSON.stringify(data)} */}
              </div>
              <div className="BeneficiarySelect">
                <div className="BeneficiarySelectContainer">
                  <div className="BeneficiarySelectRow">
                    <div className="share_property_Type">
                      <p className="share_property_Type_paragraph">
                        Choose Distribution Type:{" "}
                      </p>
                      <p className="ms-2 text-black fw-normal">
                        {/* {sharedDetails[0].distributedType} */}
                      </p>
                      <select
                        value={sharedDetails[0].distributedType}
                        onChange={(e) => handleDistributionTypeChange(e)}
                        class="share_property_Type_select"
                        disabled={
                          sharedDetails[0].distributedType !== "" ? true : false
                        }
                      >
                        <option value="">Select Type</option>
                        <option value="percentage">Percentage</option>
                        <option value="dollar">Dollar</option>
                      </select>
                    </div>
                    <div className="SelectContainer">
                      <select
                        className="Property-inputfiled"
                        onChange={handleBeneficiarySelection}
                        value=""
                        disabled={!distributedType}
                      >
                        <option value="" disabled hidden>
                          {distributedType
                            ? "Select Your Beneficiary Username"
                            : "Select Type First"}
                        </option>
                        {beneficiary.map((benif) => (
                          <option
                            key={benif.id}
                            value={benif.id}
                            disabled={selectedBeneficiaries.includes(benif.id)}
                          >
                            {/* {benif.username} */}
                            {`${benif.firstName} ${benif.lastName}`}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  {/* <div className="share_beneficiary_main_card">
                        {sharedDetails[0].distributedType !== null && (
                          <>
                            {sharedDetails[0].distributedType === 'dollar' ? (
                              <div>
                                <div className="share_beneficiary_card_close" onClick={() => handleBeneficiaryClose(beneficiary)}>
                                  <FontAwesomeIcon icon={faXmark} />
                                </div> 
                                <p>Beneficiary: </p>
                                <TextField type="number" label="Dollar" />
                              </div>
                            ) : null}

                            {sharedDetails[0].distributedType === 'percentage' ? (
                              <div>
                                <p>Beneficiary: </p>
                                <TextField type="number" label="Percentage" />
                              </div>
                            ) : null}
                          </>
                        )}
                      </div> */}
                  <div className="share_beneficiary_main_card">
                    {selectedBeneficiaries.map((beneficiary) => (
                      <div key={beneficiary} class="share_beneficiary_card">
                        <div>
                          <p className="share_beneficiary_card_para">
                            Beneficiary: {getBenificiaryName({ beneficiary })}
                          </p>
                          {distributedType === "percentage" && (
                            <input
                              type="text"
                              className="share_ben_percentage"
                              placeholder="Percentage"
                              value={
                                beneficiaryDetails[beneficiary]?.percentage ||
                                ""
                              }
                              onChange={(e) =>
                                handleFieldChange(
                                  beneficiary,
                                  "percentage",
                                  e.target.value
                                )
                              }
                            />
                          )}
                          {distributedType === "dollar" && (
                            <input
                              type="text"
                              className="share_ben_percentage"
                              placeholder="Dollar Value"
                              value={
                                beneficiaryDetails[beneficiary]?.dollar || ""
                              }
                              onChange={(e) =>
                                handleFieldChange(
                                  beneficiary,
                                  "dollar",
                                  e.target.value
                                )
                              }
                            />
                          )}

                          {distributedType && (
                            <p className="share_beneficiary_card_para">
                              {/* <p></p> */}
                              Distributed Value: $
                              {distributedType === "percentage"
                                ? calculateDistributedAmount(
                                    "percentage",
                                    estimatedTotalAmount,
                                    beneficiaryDetails[beneficiary]
                                  )
                                : calculateDistributedAmount(
                                    "dollar",
                                    estimatedTotalAmount,
                                    beneficiaryDetails[beneficiary]
                                  )}
                            </p>
                          )}
                        </div>
                        {/* <div
                            className="share_beneficiary_card_close"
                            onClick={() => handleBeneficiaryClose(beneficiary)}
                          >
                            <FontAwesomeIcon icon={faXmark} />
                          </div> */}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            {selectedBeneficiaries.length > 0 && (
              <button onClick={handleSave}>Save</button>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export function EditInternationalAssetVehicle() {
  const { id } = useParams();
  const navigate = useNavigate();
  let [form1, setForm1] = useState(true);

  const toggle = () => {
    setForm1(!form1);
    navigate("/user/my-estate/International_assets");
  };
  //set data
  const [data, setData] = useState({
    vehicle: {
      owner: "",
      otherOwner: "",
      vehicleCaption: "",
      vehicleType: "",
      year: "",
      loan: "",
      make: "",
      estValue: "",
      model: "",
      miles: "",
      notes: "",
    },
    sharedDetails: [
      {
        distributedType: "",
        distributedValue: "",
        distributedAmount: "",
        beneficiaryId: "",
      },
    ],
  });

  const [data1, setData1] = useState({
    id: "",
    assetCaption: "",
    assetType: "vehicle",
    countryName: "",
  });

  const handleChangesData = (e, field) => {
    const newValue = e.target.value;
    setData1((prevData) => ({
      ...prevData,
      [field]: newValue,
    }));
  };

  const handleChangesCountry = (event, newValue) => {
    setData1((prevData) => ({
      ...prevData,
      countryName: newValue,
    }));
  };

  const [ownerName, setOwnerName] = useState([]);

  const handleChange = (event, newValue, stringValue) => {
    if (stringValue === "vehicleType") {
      setData((prevData) => ({
        ...prevData,
        vehicle: {
          ...prevData.vehicle,
          vehicleType: newValue,
        },
      }));
    }

    if (stringValue === "make") {
      setData((prevData) => ({
        ...prevData,
        vehicle: {
          ...prevData.vehicle,
          make: newValue,
        },
      }));
    }

    if (stringValue === "model") {
      setData((prevData) => ({
        ...prevData,
        vehicle: {
          ...prevData.vehicle,
          model: newValue,
        },
      }));
    }

    if (stringValue === "owner") {
      // Convert ownerName array to a single string
      if (newValue.toString() === "Other") {
      } else {
        setData((prevData) => ({
          ...prevData,
          vehicle: {
            ...prevData.vehicle,
            otherOwner: "",
          },
        }));
      }

      const comingValue =
        typeof newValue === "string" ? newValue.split(",") : newValue;
      const ownerString = comingValue.join(", ");

      setData((prevData) => ({
        ...prevData,
        vehicle: {
          ...prevData.vehicle,
          owner: ownerString,
        },
      }));

      // Update the ownerName state afterwards
      setOwnerName(newValue);
    }
  };

  // Define an array to store user names
  const ownerNames = [];

  // Get primaryUser data
  const primaryUserDetails = getUser();
  const primaryUserName =
    primaryUserDetails.firstName + " " + primaryUserDetails.lastName;

  // Get secondaryUser data
  const secondaryUserDetails = getSecondaryUser();
  let secondaryUserName = "";

  // Check if secondary user exists
  if (secondaryUserDetails !== undefined) {
    secondaryUserName =
      secondaryUserDetails.firstName + " " + secondaryUserDetails.lastName;

    // Push both user names into the array
    ownerNames.push(primaryUserName, secondaryUserName, "Other");
  } else {
    // If secondary user doesn't exist, only push primary user name
    ownerNames.push(primaryUserName, "Other");
  }

  // use state to set the selected images
  const [selectedImage, setSelectedImage] = useState([]);
  // Handle image
  const handleImageChange = (event) => {
    const selectedFiles = event.target.files;
    const allowedExtensions = ["pdf"];

    if (selectedFiles) {
      const selectedFilesArray = Array.from(selectedFiles);

      // Check each file's extension
      const invalidFiles = selectedFilesArray.filter((file) => {
        const fileNameParts = file.name.split(".");
        const fileExtension =
          fileNameParts[fileNameParts.length - 1].toLowerCase();
        return !allowedExtensions.includes(fileExtension);
      });

      if (invalidFiles.length === 0) {
        const formData = new FormData();

        selectedFilesArray.forEach((file, index) => {
          formData.append(`file${index + 1}`, file);
        });

        // Set the selected image state with the array of files
        setSelectedImage(selectedFilesArray);

        // Now, you can use the formData object to send files to the API
        // Example:
        // apiCall(formData);
      } else {
        // Handle invalid file extensions
        const invalidExtensions = invalidFiles
          .map((file) => file.name.split(".").pop())
          .join(", ");
        toast.error(
          `Invalid file extensions: ${invalidExtensions}. Please select valid document formats.`,
          {
            position: toast.POSITION.BOTTOM_CENTER,
          }
        );
        event.target.value = ""; // Clear the input field
      }
    }
  };
  const handleChanges = (e, field) => {
    const newValue = e.target.value;
    setData((prevData) => ({
      ...prevData,
      vehicle: {
        ...prevData.vehicle,
        [field]: newValue,
      },
    }));
    setEstimatedTotalAmount(data.vehicle.estValue);
  };

  // const resetData = () => {
  //   setData({
  //     vehicle_Id: vehicle_Id,
  //     vehicleType: "",
  //     year: "",
  //     loan: "",
  //     make: "",
  //     miels: "",
  //     model: "",
  //     estValue: "",
  //     notes: "",
  //     benificiary: "",
  //     vehicleCaption: ""
  //   });
  // };

  // post the form
  const vehiclesForm = (event) => {
    event.preventDefault();
    let token = "Bearer " + getToken();
    const formData = new FormData();
    if (null != selectedImage) {
      for (let i = 0; i < selectedImage.length; i++) {
        formData.append(`files`, selectedImage[i]);
        console.log("this is file indexs", selectedImage[i]);
      }
    }
    formData.append("asset", JSON.stringify(data));
    formData.append("data", JSON.stringify(data1));

    updateInternationalAsset(formData, token)
      .then((resp) => {
        toast.success("Updated Successfully!!", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
        toggle();
      })
      .catch((error) => {
        toast.error("An error occurred. Please try again.", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      });
  };

  // get single data
  // const [showAfterCloseBene, setShowAfterCloseBene] = useState(true);
  useEffect(() => {
    const fetchData = async () => {
      try {
        let token = "Bearer " + getToken();
        const res = await getSingleInternationalAsset(token, id);

        setData({
          ...data,
          vehicle: res.assetData.vehicle,
          documents: res.assetData.documents,
          sharedDetails: res.assetData.sharedDetails,
        });

        setData1({
          ...data1,
          id: res.internationalAssetData.id,
          assetCaption: res.internationalAssetData.assetCaption,
          countryName: res.internationalAssetData.countryName,
        });

        const ownerNameArray = res.assetData.vehicle.owner.split(", ");
        setOwnerName(ownerNameArray);
        const copiedSharedDetails = [...res.assetData.sharedDetails];
        console.log("copiedSharedDetails response : ", copiedSharedDetails);
        setEstimatedTotalAmount(res.assetData.vehicle.estValue);

        if (copiedSharedDetails.length > 0) {
          setSharedDetails(res.assetData.sharedDetails);
          console.log("check ", sharedDetails[0].distributedType);
          ben(copiedSharedDetails[0].distributedType);
          for (var i = 0; i < copiedSharedDetails.length; i++) {
            handleBeneficiarySelection1(copiedSharedDetails[i].beneficiaryId);
            handleFieldChange1(
              copiedSharedDetails[i].beneficiaryId,
              copiedSharedDetails[i].distributedType,
              copiedSharedDetails[i].distributedValue
            );
            // console.log("sajid " + sharedDetails[0])
          }

          console.log(
            "sharedDetails beneficiaryDetails : ",
            beneficiaryDetails
          );
          console.log(
            "sharedDetails selectedBeneficiaries : ",
            selectedBeneficiaries
          );
          console.log("sharedDetails distributedType : ", distributedType);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
    getBenificiarydata();
  }, []);

  // beneficiary addition in form
  const [beneficiary, setBenificiary] = useState([]);
  const getBenificiarydata = () => {
    let userId = getUser().commonId;
    console.log("user Id=" + userId);
    let token = "Bearer " + getToken();
    getBeneficiary(token, userId)
      .then((res) => {
        setBenificiary(res);
      })
      .catch((err) => console.log(err));
  };

  //

  const [benevisible, setbeneVisible] = useState(false);

  const [selectedBeneficiaries, setSelectedBeneficiaries] = useState([]);
  const [beneficiaryDetails, setBeneficiaryDetails] = useState({});
  const [estimatedTotalAmount, setEstimatedTotalAmount] = useState(0);

  const [distributedType, setDistributedType] = useState("");

  const [sharedDetails, setSharedDetails] = useState([
    {
      distributedType: "",
      distributedValue: "",
      distributedAmount: "",
      beneficiaryId: "",
    },
  ]);

  const ben = (newType) => {
    // const newType = sharedDetails[0].distributedType;
    const resetDetails = {};
    Object.keys(beneficiaryDetails).forEach((beneficiary) => {
      resetDetails[beneficiary] = { percentage: "", value: "" };
    });
    setDistributedType(newType);
    setBeneficiaryDetails(resetDetails);
  };

  const handleShowBeneficiary = () => {
    setbeneVisible(true);

    // setShowAfterCloseBene(true);
    // data.sharedDetails = [];
  };

  const handleReset = () => {
    setbeneVisible(false);
    setSelectedBeneficiaries([]);
    setBeneficiaryDetails({});
    // setShowAfterCloseBene(false);
  };

  const handleDistributionTypeChange = (event) => {
    console.log("handleDistributionTypeChange working : ", event.target.value);
    console.log("handleDistributionTypeChange sharedDetails", sharedDetails);
    var distributedType = event.target.value;
    const sharedDetails1 = sharedDetails.map((detail) => {
      // Change the distributedType value as needed
      detail.distributedType = distributedType;
      return detail;
    });
    console.log("handleDistributionType changed sharedDetails", sharedDetails);
    setSharedDetails(sharedDetails1);
    const newType = event.target.value;
    const resetDetails = {};
    Object.keys(beneficiaryDetails).forEach((beneficiary) => {
      resetDetails[beneficiary] = { percentage: "", dollar: "" };
    });
    setDistributedType(newType);
    setBeneficiaryDetails(resetDetails);
  };

  const handleBeneficiarySelection1 = (selectedBeneficiary) => {
    console.log("handleBeneficiarySelection working : ", selectedBeneficiary);
    if (selectedBeneficiary === null) {
      return null;
    }
    setSelectedBeneficiaries((prevSelectedBeneficiaries) => {
      // Check if the selectedBeneficiary is already included
      if (!prevSelectedBeneficiaries.includes(selectedBeneficiary)) {
        // If not included, update the selected beneficiaries
        const newSelectedBeneficiaries = [
          ...prevSelectedBeneficiaries,
          selectedBeneficiary,
        ];
        console.log(
          "Updated selected beneficiaries:",
          newSelectedBeneficiaries
        );
        return newSelectedBeneficiaries;
      }

      // If already included, return the previous state without any changes
      return prevSelectedBeneficiaries;
    });

    setBeneficiaryDetails((prevBeneficiaryDetails) => {
      const newBeneficiaryDetails = {
        ...prevBeneficiaryDetails,
        [selectedBeneficiary]: { percentage: "", dollar: "" },
      };
      console.log("Updated beneficiary details:", newBeneficiaryDetails);
      return newBeneficiaryDetails;
    });

    // setSelectedBeneficiaries((prevSelectedBeneficiaries) => {
    //   if (!prevSelectedBeneficiaries.includes(selectedBeneficiary)) {
    //     console.log("handleBeneficiarySelection 1 : ", selectedBeneficiary);
    //     setBeneficiaryDetails({
    //       ...beneficiaryDetails,
    //       [selectedBeneficiary]: { percentage: "", dollar: "" },
    //     });
    //     return [...prevSelectedBeneficiaries, selectedBeneficiary];
    //   }
    //   return prevSelectedBeneficiaries;
    // });

    console.log("handleBeneficiarySelection 2 : ", selectedBeneficiaries);
    console.log("check beneficiaryDetails 2 : ", beneficiaryDetails);
  };

  const handleBeneficiarySelection = (event) => {
    const selectedBeneficiary = event.target.value;
    console.log("handleBeneficiarySelection working : ", selectedBeneficiary);
    if (!selectedBeneficiaries.includes(selectedBeneficiary)) {
      setSelectedBeneficiaries([...selectedBeneficiaries, selectedBeneficiary]);
      setBeneficiaryDetails({
        ...beneficiaryDetails,
        [selectedBeneficiary]: { percentage: "", dollar: "" },
      });
    }
  };

  const getBenificiaryName = (id) => {
    // console.log('getBenificiaryName id : ', id);
    var foundBenificiary = null;
    if (id.beneficiary === undefined) {
      // console.log('IF condition');
      foundBenificiary = beneficiary.find((b) => b.id === parseInt(id));
    } else {
      foundBenificiary = beneficiary.find(
        (b) => b.id === parseInt(id.beneficiary)
      );
    }
    // console.log('foundBenificiary details : ', foundBenificiary);
    if (foundBenificiary) {
      return `${foundBenificiary.firstName} ${foundBenificiary.lastName}`;
    } else {
      return "Benificiary not found";
    }
  };

  const handleFieldChange1 = (beneficiary, field, value) => {
    console.log("handleFieldChange1   : ", beneficiaryDetails);

    setBeneficiaryDetails((prevDetails) => {
      // Create a copy of the state
      let updatedDetails = { ...prevDetails };

      // Update the copy
      updatedDetails = {
        ...updatedDetails,
        [beneficiary]: {
          ...updatedDetails[beneficiary],
          [field]: value,
        },
      };
      console.log("handleFieldChange1  2 : ", updatedDetails);

      // Return the updated state
      return updatedDetails;
    });
  };

  const handleFieldChange = (beneficiary, field, value) => {
    if (!estimatedTotalAmount || parseFloat(estimatedTotalAmount) === 0) {
      toast.error(
        "Please provide a valid estimated value before adding percentages!"
      );
      return;
    }
    let updatedDetails = { ...beneficiaryDetails };
    if (/^\d*$/.test(value)) {
      updatedDetails = {
        ...updatedDetails,
        [beneficiary]: {
          ...updatedDetails[beneficiary],
          [field]: value,
        },
      };
    }
    let totalPercentage = 0;
    let totalValue = 0;
    Object.keys(updatedDetails).forEach((key) => {
      const beneficiaryPercentage = parseFloat(updatedDetails[key]?.percentage);
      const beneficiaryValue = parseFloat(updatedDetails[key]?.dollar);
      if (!isNaN(beneficiaryPercentage) && beneficiaryPercentage >= 0) {
        totalPercentage += beneficiaryPercentage;
      }
      if (!isNaN(beneficiaryValue) && beneficiaryValue >= 0) {
        totalValue += beneficiaryValue;
      }
    });
    if (
      totalPercentage > 100 ||
      totalValue > parseFloat(estimatedTotalAmount)
    ) {
      updatedDetails[beneficiary][field] = "";
      toast.error(
        "Total percentage exceeds 100% or total value exceeds estimated value!"
      );
    } else {
      setBeneficiaryDetails(updatedDetails);
      // setSharedDetails(updatedDetails);
      // sharedDetails[index].distributedValue = updatedDetails.value;
      console.log("updatedDetails : ", updatedDetails);
    }
  };

  const calculateDistributedAmount = (
    distributedType,
    balance,
    beneficiaryDetail
  ) => {
    console.log("calculateDistributedAmount : ", beneficiaryDetail);
    console.log("calculateDistributedAmount balance : ", balance);
    // Assuming beneficiaryDetail is an object with a property 'value'
    if (distributedType === "percentage") {
      const calculatedAmount =
        (parseFloat(balance) * parseFloat(beneficiaryDetail.percentage)) / 100;
      console.log("calculateDistributedAmount return: ", calculatedAmount);
      return calculatedAmount.toFixed(2);
    } else if (distributedType === "dollar") {
      const detailValue = parseFloat(beneficiaryDetail.dollar);
      return detailValue.toFixed(2);
    }
    return "0.00";
  };

  const handleSave = () => {
    toast.success("Saved successfully!");
    setbeneVisible(false);
    var i = 0;
    Object.keys(beneficiaryDetails).forEach((key) => {
      const beneficiaryPercentage = parseFloat(
        beneficiaryDetails[key]?.percentage
      );
      const beneficiaryValue = parseFloat(beneficiaryDetails[key]?.dollar);
      if (!isNaN(beneficiaryPercentage) && beneficiaryPercentage >= 0) {
        const distributedAmount = calculateDistributedAmount(
          "percentage",
          estimatedTotalAmount,
          beneficiaryDetails[key]
        );
        handleChanges2(
          "percentage",
          beneficiaryPercentage,
          distributedAmount,
          parseInt(key),
          i
        );
        i += 1;
      }
      if (!isNaN(beneficiaryValue) && beneficiaryValue >= 0) {
        const distributedAmount = calculateDistributedAmount(
          "dollar",
          estimatedTotalAmount,
          beneficiaryDetails[key]
        );
        handleChanges2(
          "dollar",
          beneficiaryValue,
          distributedAmount,
          parseInt(key),
          i
        );
        i += 1;
      }
    });
    console.log("beneficiaryDetails data: ", data);
  };

  const handleChanges2 = (t, v, a, b, i) => {
    const updatedSharedDetails = [...data.sharedDetails];
    const updatedElement = {
      ...updatedSharedDetails[i],
      distributedType: t,
      distributedValue: v,
      distributedAmount: a,
      beneficiaryId: b,
    };
    updatedSharedDetails[i] = updatedElement;
    console.log("updatedSharedDetails[i] : ", updatedSharedDetails[i]);
    setData((prevState) => ({
      ...prevState,
      sharedDetails: updatedSharedDetails,
    }));
    data.sharedDetails[i] = updatedSharedDetails[i];
  };

  return (
    <>
      {form1 && (
        <div className="overlay1" style={{ transition: "500ms", height: "" }}>
          <div className="addform_ichest">
            <div className="addform_main">
              <div className="addform_heading">
                <h3 className="addform_heading_h1">Edit Vehicle Assets</h3>
                <div className="addform_close">
                  <FontAwesomeIcon
                    icon={faXmark}
                    onClick={() => {
                      navigate("/user/my-estate/International_assets");
                    }}
                  />
                </div>
              </div>
              <div className="addform_body">
                <Form onSubmit={vehiclesForm} className="addform_body">
                  <div className="addform_select_body">
                    <div className="addform_body_left">
                      <div className="address_input">
                        <div className="mt-2 addform_input_half">
                          <div>
                            <Tooltip title="Enter Caption for your assets">
                              <div>
                                <FormLabel>Assets Caption</FormLabel>
                                <Input
                                  className="input_mui_joy"
                                  placeholder="Enter asset caption"
                                  onChange={(e) =>
                                    handleChangesData(e, "assetCaption")
                                  }
                                  value={data1.assetCaption}
                                />
                              </div>
                            </Tooltip>
                          </div>
                        </div>

                        <div className="mt-2 addform_input_half">
                          <div>
                            <Tooltip title="Enter the Country of your assets">
                              <div>
                                <FormLabel required>Country Name</FormLabel>

                                <Select
                                  placeholder="Select country name"
                                  onChange={(e, newValue) =>
                                    handleChangesCountry(e, newValue)
                                  }
                                  value={data1.countryName}
                                  required
                                >
                                  {Country.getAllCountries().map((v, index) => {
                                    return (
                                      <Option key={index} value={v.name}>
                                        {v.name}
                                      </Option>
                                    );
                                  })}
                                </Select>
                              </div>
                            </Tooltip>
                          </div>
                        </div>
                      </div>
                      <div>
                        <Tooltip title="Select Owner" className="mt-2">
                          <div>
                            <FormLabel required>Select Owner</FormLabel>

                            <Select
                              placeholder="Select Owner"
                              value={ownerName}
                              multiple
                              onChange={(event, newValue) =>
                                handleChange(event, newValue, "owner")
                              }
                              sx={{
                                minWidth: "13rem",
                              }}
                              slotProps={{
                                listbox: {
                                  sx: {
                                    width: "100%",
                                  },
                                },
                              }}
                              required
                            >
                              {ownerNames.map((name) => (
                                <Option key={name} value={name}>
                                  {name}
                                </Option>
                              ))}
                            </Select>
                          </div>
                        </Tooltip>
                      </div>

                      {data.vehicle.owner.includes("Other") && (
                        <div className="mt-2">
                          <Tooltip title="Enter Your Other Owner Name ">
                            <div>
                              <FormLabel>Other Owner</FormLabel>
                              <Input
                                className="input_mui_joy"
                                placeholder="Enter Other Owner"
                                id="otherprop"
                                onChange={(e) => handleChanges(e, "otherOwner")}
                                value={data.vehicle.otherOwner}
                              />
                            </div>
                          </Tooltip>
                        </div>
                      )}

                      <div>
                        <Tooltip title="Enter your vehicle heading">
                          <div className="mt-2">
                            <FormLabel>Vehicle Heading</FormLabel>
                            <Input
                              className="input_mui_joy"
                              placeholder="Enter vehicle heading"
                              onChange={(e) =>
                                handleChanges(e, "vehicleCaption")
                              }
                              value={data.vehicle.vehicleCaption}
                            />
                          </div>
                        </Tooltip>
                      </div>

                      <div>
                        <Tooltip title="Enter the type of your vehicle">
                          <div className="mt-2">
                            <FormLabel required>Vehicle Type</FormLabel>
                            <Select
                              value={data.vehicle.vehicleType}
                              onChange={(event, newValue) =>
                                handleChange(event, newValue, "vehicleType")
                              }
                              required
                            >
                              <Option value="">
                                Select Your Type Of vehicle
                              </Option>
                              <Option value="Sedan">Sedan</Option>
                              <Option value="Coupe">Coupe</Option>
                              <Option value="Convertible">Convertible</Option>
                              <Option value="Hatchback">Hatchback</Option>
                              <Option value="SUV">SUV</Option>
                              <Option value="Crossover">Crossover</Option>
                              <Option value="Pickup Truck">Pickup Truck</Option>
                              <Option value="Sports Car">Sports Car</Option>
                              <Option value="Electric Car">Electric Car</Option>
                              <Option value="Hybrid Car">Hybrid Car</Option>
                              <Option value="Luxury Car">Luxury Car</Option>
                              <Option value="Other">Other</Option>
                            </Select>
                          </div>
                        </Tooltip>
                      </div>

                      {/* <div>
                      <Tooltip title="Enter the year of Manufacture of your vehicle">
                        <div className="mt-2">
                          <FormLabel required>Manufactured Date</FormLabel>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              className="input_mui_joy"
                              onChange={(date) =>
                                handleDateChanges(date, "year")
                              }
                              value={data.vehicle.year}
                              style={{ borderColor: "black" }}
                              required
                            />
                            
                          </LocalizationProvider>
                        </div>
                      </Tooltip>
                    </div> */}

                      <div>
                        <Tooltip title="Enter the company of your vehicle">
                          <div className="mt-2">
                            <FormLabel required>Make</FormLabel>
                            <Select
                              value={data.vehicle.make}
                              onChange={(event, newValue) =>
                                handleChange(event, newValue, "make")
                              }
                              required
                            >
                              <Option value="">
                                Select Your Type Of company
                              </Option>
                              <Option value="BMW">BMW</Option>
                              <Option value="Mercedes-Benz">
                                Mercedes-Benz
                              </Option>
                              <Option value="Audi">Audi</Option>
                              <Option value="Toyota">Toyota</Option>
                              <Option value="Honda">Honda</Option>
                              <Option value="Ford">Ford</Option>
                              <Option value="Other">Other</Option>
                            </Select>
                          </div>
                        </Tooltip>
                      </div>

                      <div className="address_input">
                        <div className="mt-2 addform_input_half">
                          <div>
                            <Tooltip title="Enter the model of your vehicle">
                              <div>
                                <FormLabel required>Model</FormLabel>
                                <Select
                                  value={data.vehicle.model}
                                  onChange={(event, newValue) =>
                                    handleChange(event, newValue, "model")
                                  }
                                  required
                                >
                                  <Option value="">
                                    Select Your Type Of model
                                  </Option>
                                  <Option value="Camry">Camry</Option>
                                  <Option value="C-Class">C-Class</Option>
                                  <Option value="E-Class">E-Class</Option>
                                  <Option value="R8">R8</Option>
                                  <Option value="F-150">F-150</Option>
                                  <Option value="Mustang">Mustang</Option>
                                  <Option value="Other">Other</Option>
                                </Select>
                              </div>
                            </Tooltip>
                          </div>
                        </div>

                        <div className="mt-2 addform_input_half">
                          <div>
                            <Tooltip title="Enter your vehicle mileage">
                              <div>
                                <FormLabel required>Miles</FormLabel>
                                <Input
                                  placeholder="Enter miles"
                                  required
                                  type="number"
                                  onChange={(e) => handleChanges(e, "miles")}
                                  value={data.vehicle.miles}
                                />
                              </div>
                            </Tooltip>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="addform_body_right_crypto">
                      <div className="address_input">
                        <div className="mt-2 addform_input_half">
                          <div>
                            <Tooltip title="Enter your Loan against your vehicle">
                              <div>
                                <FormLabel>Loan</FormLabel>
                                <Input
                                  className="input_mui_joy"
                                  placeholder="Enter loan"
                                  onChange={(e) => handleChanges(e, "loan")}
                                  value={data.vehicle.loan}
                                />
                              </div>
                            </Tooltip>
                          </div>
                        </div>

                        <div className="mt-2 addform_input_half">
                          <div>
                            <Tooltip title="Enter Your Estimated Value ">
                              <div>
                                <FormLabel required>Estimated Value</FormLabel>
                                <Input
                                  placeholder="Enter your Estimated valuation of vehicle"
                                  required
                                  type="number"
                                  onChange={(e) => {
                                    handleChanges(e, "estValue");
                                  }}
                                  value={data.vehicle.estValue}
                                  startDecorator={<div>$</div>}
                                />
                              </div>
                            </Tooltip>
                          </div>
                        </div>
                      </div>

                      <div>
                        <Tooltip title="Add your banks related file">
                          <div className="mt-2">
                            <FormLabel>Supporting Document</FormLabel>
                            <input
                              style={{
                                border: "solid 1px lightgray",
                                borderLeft: "none",
                                width: "100%",
                                borderRadius: "5px",
                              }}
                              type="file"
                              name="myfile"
                              id="exampleFile"
                              onChange={(e) => handleImageChange(e, 5)}
                              accept=".pdf"
                              multiple
                            />
                          </div>
                        </Tooltip>
                      </div>
                      <div
                        className="bene-select mt-3"
                        onClick={handleShowBeneficiary}
                        style={{ cursor: "pointer" }}
                      >
                        Select Your Beneficiary
                      </div>

                      <div className="address_input">
                        <div style={{ width: "100%" }} className="mt-2">
                          <Tooltip title="Enter notes for your jewelry">
                            <div>
                              <FormLabel>Notes</FormLabel>
                              <Textarea
                                sx={{ height: "182px", minHeight: "52px" }}
                                placeholder="Notes"
                                id="notes"
                                onChange={(e) => handleChanges(e, "notes")}
                                value={data.vehicle.notes}
                              />
                            </div>
                          </Tooltip>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="text-center"
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <Button outline type="" className="my-estate-add-btn">
                      Update
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      )}
      {benevisible && (
        <div className="popup">
          <div
            className="popup-content"
            style={{
              minWidth: "350px",
              width: "100%",
              maxWidth: "700px",
            }}
          >
            <div className="note_popup">
              <div className="note_popup_heading">
                <div className="share_property_heading">
                  <h2>Share Property </h2>
                </div>
                <div>
                  <button
                    className="note_popup_heading_close_btn"
                    onClick={handleReset}
                  >
                    <FontAwesomeIcon icon={faXmark} />
                  </button>
                </div>
              </div>
              <div className="share_property_est_value">
                <p>
                  Estimated Value:{" "}
                  <code style={{ color: "green", fontWeight: "bold" }}>
                    ${estimatedTotalAmount}
                  </code>
                </p>
                {/* {JSON.stringify(sharedDetails)}  */}
                {/* <p>----------------------------------------------------</p>
                    {JSON.stringify(data.documents)}
                    <p>----------------------------------------------------</p>
                    {JSON.stringify(data.accounts)}
                    <p>----------------------------------------------------</p>
                    {JSON.stringify(data.bank)}
                    <p>----------------------------------------------------</p>
                    {JSON.stringify(data)} */}
              </div>
              <div className="BeneficiarySelect">
                <div className="BeneficiarySelectContainer">
                  <div className="BeneficiarySelectRow">
                    <div className="share_property_Type">
                      <p className="share_property_Type_paragraph">
                        Choose Distribution Type:{" "}
                      </p>
                      <p className="ms-2 text-black fw-normal">
                        {/* {sharedDetails[0].distributedType} */}
                      </p>
                      <select
                        value={sharedDetails[0].distributedType}
                        onChange={(e) => handleDistributionTypeChange(e)}
                        class="share_property_Type_select"
                        disabled={
                          sharedDetails[0].distributedType !== "" ? true : false
                        }
                      >
                        <option value="">Select Type</option>
                        <option value="percentage">Percentage</option>
                        <option value="dollar">Dollar</option>
                      </select>
                    </div>
                    <div className="SelectContainer">
                      <select
                        className="Property-inputfiled"
                        onChange={handleBeneficiarySelection}
                        value=""
                        disabled={!distributedType}
                      >
                        <option value="" disabled hidden>
                          {distributedType
                            ? "Select Your Beneficiary Username"
                            : "Select Type First"}
                        </option>
                        {beneficiary.map((benif) => (
                          <option
                            key={benif.id}
                            value={benif.id}
                            disabled={selectedBeneficiaries.includes(benif.id)}
                          >
                            {/* {benif.username} */}
                            {`${benif.firstName} ${benif.lastName}`}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  {/* <div className="share_beneficiary_main_card">
                        {sharedDetails[0].distributedType !== null && (
                          <>
                            {sharedDetails[0].distributedType === 'dollar' ? (
                              <div>
                                <div className="share_beneficiary_card_close" onClick={() => handleBeneficiaryClose(beneficiary)}>
                                  <FontAwesomeIcon icon={faXmark} />
                                </div> 
                                <p>Beneficiary: </p>
                                <TextField type="number" label="Dollar" />
                              </div>
                            ) : null}

                            {sharedDetails[0].distributedType === 'percentage' ? (
                              <div>
                                <p>Beneficiary: </p>
                                <TextField type="number" label="Percentage" />
                              </div>
                            ) : null}
                          </>
                        )}
                      </div> */}
                  <div className="share_beneficiary_main_card">
                    {selectedBeneficiaries.map((beneficiary) => (
                      <div key={beneficiary} class="share_beneficiary_card">
                        <div>
                          <p className="share_beneficiary_card_para">
                            Beneficiary: {getBenificiaryName({ beneficiary })}
                          </p>
                          {distributedType === "percentage" && (
                            <input
                              type="text"
                              className="share_ben_percentage"
                              placeholder="Percentage"
                              value={
                                beneficiaryDetails[beneficiary]?.percentage ||
                                ""
                              }
                              onChange={(e) =>
                                handleFieldChange(
                                  beneficiary,
                                  "percentage",
                                  e.target.value
                                )
                              }
                            />
                          )}
                          {distributedType === "dollar" && (
                            <input
                              type="text"
                              className="share_ben_percentage"
                              placeholder="Dollar Value"
                              value={
                                beneficiaryDetails[beneficiary]?.dollar || ""
                              }
                              onChange={(e) =>
                                handleFieldChange(
                                  beneficiary,
                                  "dollar",
                                  e.target.value
                                )
                              }
                            />
                          )}

                          {distributedType && (
                            <p className="share_beneficiary_card_para">
                              {/* <p></p> */}
                              Distributed Value: $
                              {distributedType === "percentage"
                                ? calculateDistributedAmount(
                                    "percentage",
                                    estimatedTotalAmount,
                                    beneficiaryDetails[beneficiary]
                                  )
                                : calculateDistributedAmount(
                                    "dollar",
                                    estimatedTotalAmount,
                                    beneficiaryDetails[beneficiary]
                                  )}
                            </p>
                          )}
                        </div>
                        {/* <div
                            className="share_beneficiary_card_close"
                            onClick={() => handleBeneficiaryClose(beneficiary)}
                          >
                            <FontAwesomeIcon icon={faXmark} />
                          </div> */}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            {selectedBeneficiaries.length > 0 && (
              <button onClick={handleSave}>Save</button>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export function EditInternationalAssetJewelry() {
  const { id } = useParams();
  const navigate = useNavigate();
  let [form1, setForm1] = useState(true);

  const toggle = () => {
    setForm1(!form1);
    navigate("/user/my-estate/International_assets");
  };

  // set data
  const [data, setData] = useState({
    jewelry: {
      owner: "",
      otherOwner: "",
      jewelryCaption: "",
      jewelryName: "",
      otherJewelryName: "",
      estimatedValue: "",
      caratValue: "",
      weight: "",
      notes: "",
    },
    sharedDetails: [
      {
        distributedType: "",
        distributedValue: "",
        distributedAmount: "",
        beneficiaryId: "",
      },
    ],
  });

  const [data1, setData1] = useState({
    id: "",
    assetCaption: "",
    assetType: "jewelry",
    countryName: "",
  });

  const handleChangesData = (e, field) => {
    const newValue = e.target.value;
    setData1((prevData) => ({
      ...prevData,
      [field]: newValue,
    }));
  };

  const handleChangesCountry = (event, newValue) => {
    setData1((prevData) => ({
      ...prevData,
      countryName: newValue,
    }));
  };

  // for owner field
  const [ownerName, setOwnerName] = useState([]);
  const handleChange = (event, newValue, stringValue) => {
    if (stringValue === "caratValue") {
      setData((prevData) => ({
        ...prevData,
        jewelry: {
          ...prevData.jewelry,
          caratValue: newValue,
        },
      }));
    }

    if (stringValue === "jewelryName") {
      setData((prevData) => ({
        ...prevData,
        jewelry: {
          ...prevData.jewelry,
          jewelryName: newValue,
        },
      }));
    }

    if (stringValue === "owner") {
      // Convert ownerName array to a single string

      if (newValue.toString() === "Other") {
      } else {
        setData((prevData) => ({
          ...prevData,
          jewelry: {
            ...prevData.jewelry,
            otherOwner: "",
          },
        }));
      }

      const comingValue =
        typeof newValue === "string" ? newValue.split(",") : newValue;
      const ownerString = comingValue.join(", ");

      setData((prevData) => ({
        ...prevData,
        jewelry: {
          ...prevData.jewelry,
          owner: ownerString,
        },
      }));

      // Update the ownerName state afterwards
      setOwnerName(newValue);
    }
  };

  // Define an array to store user names
  const ownerNames = [];

  // Get primaryUser data
  const primaryUserDetails = getUser();
  const primaryUserName =
    primaryUserDetails.firstName + " " + primaryUserDetails.lastName;

  // Get secondaryUser data
  const secondaryUserDetails = getSecondaryUser();
  let secondaryUserName = "";

  // Check if secondary user exists
  if (secondaryUserDetails !== undefined) {
    secondaryUserName =
      secondaryUserDetails.firstName + " " + secondaryUserDetails.lastName;

    // Push both user names into the array
    ownerNames.push(primaryUserName, secondaryUserName);
    ownerNames.push("Other");
  } else {
    // If secondary user doesn't exist, only push primary user name
    ownerNames.push(primaryUserName);
    ownerNames.push("Other");
  }

  // use state to set the selected images
  const [selectedImage, setSelectedImage] = useState([]);
  const [selectedImage1, setSelectedImage1] = useState([]);

  // Handle image
  const handleImageChange = (event) => {
    const selectedFiles = event.target.files;
    const allowedExtensions = ["pdf"];

    if (selectedFiles) {
      const selectedFilesArray = Array.from(selectedFiles);

      // Check each file's extension
      const invalidFiles = selectedFilesArray.filter((file) => {
        const fileNameParts = file.name.split(".");
        const fileExtension =
          fileNameParts[fileNameParts.length - 1].toLowerCase();
        return !allowedExtensions.includes(fileExtension);
      });

      if (invalidFiles.length === 0) {
        const formData = new FormData();

        selectedFilesArray.forEach((file, index) => {
          formData.append(`file${index + 1}`, file);
        });
        setSelectedImage(selectedFilesArray);
      } else {
        const invalidExtensions = invalidFiles
          .map((file) => file.name.split(".").pop())
          .join(", ");
        toast.error(
          `Invalid file extensions: ${invalidExtensions}. Please select valid document formats.`,
          {
            position: toast.POSITION.BOTTOM_CENTER,
          }
        );
        event.target.value = "";
      }
    }
  };

  const handleImageChange1 = (event) => {
    const selectedFiles = event.target.files;
    const allowedExtensions = ["jpg", "jpeg", "png", "gif"];

    if (selectedFiles) {
      const selectedFilesArray = Array.from(selectedFiles);

      // Check each file's extension
      const invalidFiles = selectedFilesArray.filter((file) => {
        const fileNameParts = file.name.split(".");
        const fileExtension =
          fileNameParts[fileNameParts.length - 1].toLowerCase();
        return !allowedExtensions.includes(fileExtension);
      });

      if (invalidFiles.length === 0) {
        const formData = new FormData();

        selectedFilesArray.forEach((file, index) => {
          formData.append(`imageFiles${index + 1}`, file);
        });

        // Set the selected image state with the array of files
        setSelectedImage1(selectedFilesArray);
      } else {
        // Handle invalid file extensions
        const invalidExtensions = invalidFiles
          .map((file) => file.name.split(".").pop())
          .join(", ");
        toast.error(
          `Invalid file extensions: ${invalidExtensions}. Please select valid image formats.`,
          {
            position: toast.POSITION.BOTTOM_CENTER,
          }
        );
        event.target.value = ""; // Clear the input field
      }
    }
  };

  // const handleChanges = (event, property) => {
  //   setData({ ...data, [property]: event.target.value });
  // };

  useEffect(() => {
    if (data.jewelry.jewelryName !== "Other") {
      setData((prevData) => ({
        ...prevData,
        jewelry: {
          ...prevData.jewelry,
          otherJewelryName: "",
        },
      }));
    }
  }, [data.jewelry.jewelryName]);

  const handleChanges = (e, field) => {
    const newValue = e.target.value;
    setData((prevData) => ({
      ...prevData,
      jewelry: {
        ...prevData.jewelry,
        [field]: newValue,
      },
    }));
    setEstimatedTotalAmount(data.jewelry.estimatedValue);
  };

  // const resetData = () => {
  //   setData({
  //     jewelry_Id: jewelryId,
  //     details: "",
  //     estimatedValue: "",
  //     weight: "",
  //     keratValue: "",
  //     notes: "",
  //     benificiary: "",
  //     jewelryCaption: "",
  //   });
  // };

  // post the form
  const jewelryForm = (event) => {
    event.preventDefault();
    let token = "Bearer " + getToken();
    const formData = new FormData();

    if (null != selectedImage) {
      for (let i = 0; i < selectedImage.length; i++) {
        formData.append(`files`, selectedImage[i]);
        console.log("this is file indexs", selectedImage[i]);
      }
    }

    for (let i = 0; i < selectedImage1.length; i++) {
      formData.append(`imageFiles`, selectedImage1[i]);
      console.log("this is imageFiles indexs", selectedImage1[i]);
    }

    formData.append("asset", JSON.stringify(data));
    formData.append("data", JSON.stringify(data1));

    updateInternationalAsset(formData, token)
      .then((resp) => {
        toast.success("Updated Successfully!!", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
        toggle();
      })
      .catch((error) => {
        toast.error("An error occurred. Please try again.", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      });
  };

  // Set the form
  // const jewelryForm = (event) => {
  //   event.preventDefault();
  //   let token = "Bearer " + getToken();

  //   console.log("Token : " + token);
  //   if (
  //     data.details === "" ||
  //     data.estimatedValue === "" ||
  //     data.weight === "" ||
  //     data.keratValue === ""
  //   ) {
  //     console.log("Error log");
  //     toast.error("Please Fill All required field Then Submit .", {
  //       position: toast.POSITION.BOTTOM_CENTER,
  //     });
  //     return;
  //   }

  //   //create form data to send a file and remaining class data
  //   const formData = new FormData();
  //   for (let i = 0; i < selectedImage.length; i++) {
  //     formData.append(`filename`, selectedImage[i]);
  //     console.log("this is file indexs", selectedImage[i])
  //   }
  //   formData.append("data", JSON.stringify(data));

  //   updateJewelry(formData, token, jewelryId)
  //     .then((resp) => {
  //       console.log(resp);
  //       console.log("Success log");
  //       toast.success("Updated Successfully !!", {
  //         position: toast.POSITION.BOTTOM_CENTER,
  //       });
  //       navigate("/user/my-estate/jewelry");
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };

  const [isTextFieldClicked, setIsTextFieldClicked] = useState(false);
  // const [showAfterCloseBene, setShowAfterCloseBene] = useState(true);
  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       let token = "Bearer " + getToken();
  //       const res = await getSinglejewelry(token, id);
  //       console.log("this is jewelry responce ", res);
  //       setData({
  //         ...data,
  //         jewelry: res.jewelry,
  //         documents: res.documents,
  //         sharedDetails: res.sharedDetails,
  //       });
  //       const copiedSharedDetails = [...res.sharedDetails];
  //       console.log("copiedSharedDetails response : ", copiedSharedDetails);
  //       setEstimatedTotalAmount(res.jewelry.estimatedValue);

  //       console.log("check ", sharedDetails[0].distributedType);

  //       console.log("sharedDetails response : ", res.sharedDetails);
  //       console.log("sharedDetails response : ", sharedDetails);
  //       if (copiedSharedDetails.length > 0) {
  //         console.log(res.sharedDetails.length);
  //         setSharedDetails(res.sharedDetails);
  //         console.log("check ", sharedDetails[0].distributedType);
  //         ben(copiedSharedDetails[0].distributedType);
  //         for (var i = 0; i < copiedSharedDetails.length; i++) {
  //           handleBeneficiarySelection1(copiedSharedDetails[i].beneficiaryId);
  //           handleFieldChange1(
  //             copiedSharedDetails[i].beneficiaryId,
  //             copiedSharedDetails[i].distributedType,
  //             copiedSharedDetails[i].distributedValue
  //           );
  //           // console.log("sajid " + sharedDetails[0])
  //         }

  //         console.log(
  //           "sharedDetails beneficiaryDetails : ",
  //           beneficiaryDetails
  //         );
  //         console.log(
  //           "sharedDetails selectedBeneficiaries : ",
  //           selectedBeneficiaries
  //         );
  //         console.log("sharedDetails distributedType : ", distributedType);
  //       }
  //     } catch (error) {
  //       console.error("Error fetching data:", error);
  //     }
  //   };

  //   fetchData();
  // }, [showAfterCloseBene]);

  const getData = () => {
    let token = "Bearer " + getToken();
    getSingleInternationalAsset(token, id).then((res) => {
      setData({
        ...data,
        jewelry: res.assetData.jewelry,
        documents: res.assetData.documents,
        sharedDetails: res.assetData.sharedDetails,
        supportingImages: res.assetData.supportingImages,
      });

      setData1({
        ...data1,
        id: res.internationalAssetData.id,
        assetCaption: res.internationalAssetData.assetCaption,
        countryName: res.internationalAssetData.countryName,
      });

      const ownerNameArray = res.assetData.jewelry.owner.split(", ");
      setOwnerName(ownerNameArray);
      const copiedSharedDetails = [...res.assetData.sharedDetails];
      console.log("copiedSharedDetails response : ", copiedSharedDetails);
      setEstimatedTotalAmount(res.assetData.jewelry.estimatedValue);

      if (copiedSharedDetails.length > 0) {
        setSharedDetails(res.assetData.sharedDetails);

        ben(copiedSharedDetails[0].distributedType);
        for (var i = 0; i < copiedSharedDetails.length; i++) {
          handleBeneficiarySelection1(copiedSharedDetails[i].beneficiaryId);
          handleFieldChange1(
            copiedSharedDetails[i].beneficiaryId,
            copiedSharedDetails[i].distributedType,
            copiedSharedDetails[i].distributedValue
          );
          // console.log("sajid " + sharedDetails[0])
        }

        console.log("sharedDetails beneficiaryDetails : ", beneficiaryDetails);
        console.log(
          "sharedDetails selectedBeneficiaries : ",
          selectedBeneficiaries
        );
        console.log("sharedDetails distributedType : ", distributedType);
      }
    });
  };
  // Get data based on the jewelery id
  // const getData = () => {
  //   let token = "Bearer " + getToken();
  //   getjewelery(token, jewelryId)
  //     .then((res) => {
  //       setData({
  //         ...data,
  //         details: res.details,
  //         estimatedValue: res.estimatedValue,
  //         keratValue: res.keratValue,
  //         weight: res.weight,
  //         notes: res.notes,
  //         benificiary: res.benificiary,
  //         jewelryCaption: res.jewelryCaption
  //       });
  //       console.log(res);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //       console.log("Data not loaded");

  //     });
  // };
  // const [metalPrice, setMetalPrice] = useState(0);

  // useEffect(() => {
  //   // Fetch metal price from the API and store it in the state

  //   const fetchMetalPrice = () => {
  //     if (data.jewelry.jewelryName === "") {
  //       setMetalPrice(0);
  //       if (data.jewelry.caratValue === "") {
  //         setMetalPrice(0);
  //         return;
  //       }
  //       return;
  //     }
  //     // if (data.keratValue === "") {
  //     //   setMetalPrice(0);
  //     //   return;
  //     // }

  //     const headers = {
  //       "x-access-token": "goldapi-sw18arlkmh58d1-io",
  //       "Content-Type": "application/json",
  //     };
  //     const metalAPIEndpoint = `https://www.goldapi.io/api/${data.jewelry.jewelryName}/USD`;
  //     axios
  //       .get(metalAPIEndpoint, { headers: headers })
  //       .then((res) => {
  //         setMetalPrice(res.data[data.jewelry.caratValue]); // Assuming the API response contains the price for 24K gold per gram
  //       })
  //       .catch((error) => {
  //         // console.log("Error: ", "Failed to fetch the metal price");
  //       });
  //   };

  //   fetchMetalPrice();
  //   getData(); // Moved the getData call inside the same useEffect
  // }, [data.jewelry.jewelryName, data.jewelry.caratValue]);

  // ... (remaining existing functions)

  // useEffect(() => {
  //   const calculateEstimatedValue = () => {
  //     if (data.jewelry.jewelryName && data.jewelry.weight && metalPrice) {
  //       // Assuming the selected metal unit is 24K and using the metal price for 24K gold to calculate the estimated value
  //       const estimatedValue = data.jewelry.weight * metalPrice;

  //       setData((prevData) => ({
  //         ...prevData,
  //         jewelry: {
  //           ...prevData.jewelry,
  //           estimatedValue: estimatedValue.toFixed(3),
  //         },
  //       }));

  //       // setData((prevData) => ({
  //       //   ...prevData,
  //       //   estimatedValue: estimatedValue.toFixed(3),
  //       // }));
  //     } else {
  //       setData((prevData) => ({
  //         ...prevData,
  //         jewelry: {
  //           ...prevData.jewelry,
  //           estimatedValue: "",
  //         },
  //       }));
  //     }
  //   };

  //   calculateEstimatedValue();
  // }, [data.jewelry.jewelryName, data.jewelry.weight, metalPrice]);

  // const getDisplayName = (metadataValue) => {
  //   switch (metadataValue) {
  //     case "XAU":
  //       return "Gold";
  //     case "XAG":
  //       return "Silver";
  //     case "XPT":
  //       return "Platinum";
  //     case "XPD":
  //       return "Palladium";
  //     default:
  //       return metadataValue;
  //   }
  // };
  // const getDisplayKeratValue = (keratValue) => {
  //   switch (keratValue) {
  //     case "":
  //       return "24k";
  //     case "price_gram_22k":
  //       return "22k";
  //     case "price_gram_21k":
  //       return "21k";
  //     case "price_gram_20k":
  //       return "20k";
  //     case "price_gram_18k":
  //       return "18k";
  //     case "price_gram_16k":
  //       return "16k";
  //     case "price_gram_14k":
  //       return "14k";
  //     case "price_gram_10k":
  //       return "10k";
  //     default:
  //       return keratValue;
  //   }
  // };
  useEffect(() => {
    getData();
    getBenificiarydata();
  }, []);

  // beneficiary addition in form
  const [beneficiary, setBenificiary] = useState([]);
  const getBenificiarydata = () => {
    let userId = getUser().commonId;
    console.log("user Id=" + userId);
    let token = "Bearer " + getToken();
    getBeneficiary(token, userId)
      .then((res) => {
        setBenificiary(res);
      })
      .catch((err) => console.log(err));
  };

  //
  const [benevisible, setbeneVisible] = useState(false);
  const [selectedBeneficiaries, setSelectedBeneficiaries] = useState([]);
  const [beneficiaryDetails, setBeneficiaryDetails] = useState({});
  const [estimatedTotalAmount, setEstimatedTotalAmount] = useState(0);
  const [distributedType, setDistributedType] = useState("");

  const [sharedDetails, setSharedDetails] = useState([
    {
      distributedType: "",
      distributedValue: "",
      distributedAmount: "",
      beneficiaryId: "",
    },
  ]);

  const ben = (newType) => {
    // const newType = sharedDetails[0].distributedType;
    const resetDetails = {};
    Object.keys(beneficiaryDetails).forEach((beneficiary) => {
      resetDetails[beneficiary] = { percentage: "", value: "" };
    });
    setDistributedType(newType);
    setBeneficiaryDetails(resetDetails);
  };

  const handleShowBeneficiary = () => {
    setbeneVisible(true);
    // setShowAfterCloseBene(true);
    // data.sharedDetails = [];
  };

  const handleReset = () => {
    setbeneVisible(false);

    setSelectedBeneficiaries([]);
    setBeneficiaryDetails({});
    // setShowAfterCloseBene(false);
  };

  const handleDistributionTypeChange = (event) => {
    console.log("handleDistributionTypeChange working : ", event.target.value);
    console.log("handleDistributionTypeChange sharedDetails", sharedDetails);
    var distributedType = event.target.value;
    const sharedDetails1 = sharedDetails.map((detail) => {
      // Change the distributedType value as needed
      detail.distributedType = distributedType;
      return detail;
    });
    console.log("handleDistributionType changed sharedDetails", sharedDetails);
    setSharedDetails(sharedDetails1);
    const newType = event.target.value;
    const resetDetails = {};
    Object.keys(beneficiaryDetails).forEach((beneficiary) => {
      resetDetails[beneficiary] = { percentage: "", dollar: "" };
    });
    setDistributedType(newType);
    setBeneficiaryDetails(resetDetails);
  };

  const handleBeneficiarySelection1 = (selectedBeneficiary) => {
    console.log("handleBeneficiarySelection working : ", selectedBeneficiary);
    if (selectedBeneficiary === null) {
      return null;
    }
    setSelectedBeneficiaries((prevSelectedBeneficiaries) => {
      // Check if the selectedBeneficiary is already included
      if (!prevSelectedBeneficiaries.includes(selectedBeneficiary)) {
        // If not included, update the selected beneficiaries
        const newSelectedBeneficiaries = [
          ...prevSelectedBeneficiaries,
          selectedBeneficiary,
        ];
        console.log(
          "Updated selected beneficiaries:",
          newSelectedBeneficiaries
        );
        return newSelectedBeneficiaries;
      }

      // If already included, return the previous state without any changes
      return prevSelectedBeneficiaries;
    });

    setBeneficiaryDetails((prevBeneficiaryDetails) => {
      const newBeneficiaryDetails = {
        ...prevBeneficiaryDetails,
        [selectedBeneficiary]: { percentage: "", dollar: "" },
      };
      console.log("Updated beneficiary details:", newBeneficiaryDetails);
      return newBeneficiaryDetails;
    });

    // setSelectedBeneficiaries((prevSelectedBeneficiaries) => {
    //   if (!prevSelectedBeneficiaries.includes(selectedBeneficiary)) {
    //     console.log("handleBeneficiarySelection 1 : ", selectedBeneficiary);
    //     setBeneficiaryDetails({
    //       ...beneficiaryDetails,
    //       [selectedBeneficiary]: { percentage: "", dollar: "" },
    //     });
    //     return [...prevSelectedBeneficiaries, selectedBeneficiary];
    //   }
    //   return prevSelectedBeneficiaries;
    // });

    console.log("handleBeneficiarySelection 2 : ", selectedBeneficiaries);
    console.log("check beneficiaryDetails 2 : ", beneficiaryDetails);
  };

  const handleBeneficiarySelection = (event) => {
    const selectedBeneficiary = event.target.value;
    console.log("handleBeneficiarySelection working : ", selectedBeneficiary);
    if (!selectedBeneficiaries.includes(selectedBeneficiary)) {
      setSelectedBeneficiaries([...selectedBeneficiaries, selectedBeneficiary]);
      setBeneficiaryDetails({
        ...beneficiaryDetails,
        [selectedBeneficiary]: { percentage: "", dollar: "" },
      });
    }
  };

  const getBenificiaryName = (id) => {
    // console.log('getBenificiaryName id : ', id);
    var foundBenificiary = null;
    if (id.beneficiary === undefined) {
      // console.log('IF condition');
      foundBenificiary = beneficiary.find((b) => b.id === parseInt(id));
    } else {
      foundBenificiary = beneficiary.find(
        (b) => b.id === parseInt(id.beneficiary)
      );
    }
    // console.log('foundBenificiary details : ', foundBenificiary);
    if (foundBenificiary) {
      return `${foundBenificiary.firstName} ${foundBenificiary.lastName}`;
    } else {
      return "Benificiary not found";
    }
  };

  const handleFieldChange1 = (beneficiary, field, value) => {
    console.log("handleFieldChange1   : ", beneficiaryDetails);

    setBeneficiaryDetails((prevDetails) => {
      // Create a copy of the state
      let updatedDetails = { ...prevDetails };

      // Update the copy
      updatedDetails = {
        ...updatedDetails,
        [beneficiary]: {
          ...updatedDetails[beneficiary],
          [field]: value,
        },
      };
      console.log("handleFieldChange1  2 : ", updatedDetails);

      // Return the updated state
      return updatedDetails;
    });
  };

  const handleFieldChange = (beneficiary, field, value) => {
    if (!estimatedTotalAmount || parseFloat(estimatedTotalAmount) === 0) {
      toast.error(
        "Please provide a valid estimated value before adding percentages!"
      );
      return;
    }
    let updatedDetails = { ...beneficiaryDetails };
    if (/^\d*$/.test(value)) {
      updatedDetails = {
        ...updatedDetails,
        [beneficiary]: {
          ...updatedDetails[beneficiary],
          [field]: value,
        },
      };
    }
    let totalPercentage = 0;
    let totalValue = 0;
    Object.keys(updatedDetails).forEach((key) => {
      const beneficiaryPercentage = parseFloat(updatedDetails[key]?.percentage);
      const beneficiaryValue = parseFloat(updatedDetails[key]?.dollar);
      if (!isNaN(beneficiaryPercentage) && beneficiaryPercentage >= 0) {
        totalPercentage += beneficiaryPercentage;
      }
      if (!isNaN(beneficiaryValue) && beneficiaryValue >= 0) {
        totalValue += beneficiaryValue;
      }
    });
    if (
      totalPercentage > 100 ||
      totalValue > parseFloat(estimatedTotalAmount)
    ) {
      updatedDetails[beneficiary][field] = "";
      toast.error(
        "Total percentage exceeds 100% or total value exceeds estimated value!"
      );
    } else {
      setBeneficiaryDetails(updatedDetails);
      // setSharedDetails(updatedDetails);
      // sharedDetails[index].distributedValue = updatedDetails.value;
      console.log("updatedDetails : ", updatedDetails);
    }
  };

  const calculateDistributedAmount = (
    distributedType,
    balance,
    beneficiaryDetail
  ) => {
    console.log("calculateDistributedAmount : ", beneficiaryDetail);
    console.log("calculateDistributedAmount balance : ", balance);
    // Assuming beneficiaryDetail is an object with a property 'value'
    if (distributedType === "percentage") {
      const calculatedAmount =
        (parseFloat(balance) * parseFloat(beneficiaryDetail.percentage)) / 100;
      console.log("calculateDistributedAmount return: ", calculatedAmount);
      return calculatedAmount.toFixed(2);
    } else if (distributedType === "dollar") {
      const detailValue = parseFloat(beneficiaryDetail.dollar);
      return detailValue.toFixed(2);
    }
    return "0.00";
  };

  const handleSave = () => {
    toast.success("Saved successfully!");
    setbeneVisible(false);
    var i = 0;
    Object.keys(beneficiaryDetails).forEach((key) => {
      const beneficiaryPercentage = parseFloat(
        beneficiaryDetails[key]?.percentage
      );
      const beneficiaryValue = parseFloat(beneficiaryDetails[key]?.dollar);
      if (!isNaN(beneficiaryPercentage) && beneficiaryPercentage >= 0) {
        const distributedAmount = calculateDistributedAmount(
          "percentage",
          estimatedTotalAmount,
          beneficiaryDetails[key]
        );
        handleChanges2(
          "percentage",
          beneficiaryPercentage,
          distributedAmount,
          parseInt(key),
          i
        );
        i += 1;
      }
      if (!isNaN(beneficiaryValue) && beneficiaryValue >= 0) {
        const distributedAmount = calculateDistributedAmount(
          "dollar",
          estimatedTotalAmount,
          beneficiaryDetails[key]
        );
        handleChanges2(
          "dollar",
          beneficiaryValue,
          distributedAmount,
          parseInt(key),
          i
        );
        i += 1;
      }
    });
    console.log("beneficiaryDetails data: ", data);
  };

  const handleChanges2 = (t, v, a, b, i) => {
    const updatedSharedDetails = [...data.sharedDetails];
    const updatedElement = {
      ...updatedSharedDetails[i],
      distributedType: t,
      distributedValue: v,
      distributedAmount: a,
      beneficiaryId: b,
    };
    updatedSharedDetails[i] = updatedElement;
    console.log("updatedSharedDetails[i] : ", updatedSharedDetails[i]);
    setData((prevState) => ({
      ...prevState,
      sharedDetails: updatedSharedDetails,
    }));
    data.sharedDetails[i] = updatedSharedDetails[i];
  };

  return (
    <>
      {form1 && (
        <div className="overlay1" style={{ transition: "500ms", height: "" }}>
          <div className="addform_ichest">
            <div className="addform_main">
              <div className="addform_heading">
                <h3 className="addform_heading_h1">Edit Jewelry Assets</h3>
                <div className="addform_close">
                  <FontAwesomeIcon
                    icon={faXmark}
                    onClick={() => {
                      navigate("/user/my-estate/International_assets");
                    }}
                  />
                </div>
              </div>
              <div className="addform_body">
                <Form onSubmit={jewelryForm} className="addform_body">
                  <div className="addform_select_body">
                    <div className="addform_body_left">
                      <div className="address_input">
                        <div className="mt-2 addform_input_half">
                          <div>
                            <Tooltip title="Enter Caption for your assets">
                              <div>
                                <FormLabel>Assets Caption</FormLabel>
                                <Input
                                  className="input_mui_joy"
                                  placeholder="Enter asset caption"
                                  onChange={(e) =>
                                    handleChangesData(e, "assetCaption")
                                  }
                                  value={data1.assetCaption}
                                />
                              </div>
                            </Tooltip>
                          </div>
                        </div>

                        <div className="mt-2 addform_input_half">
                          <div>
                            <Tooltip title="Enter the Country of your assets">
                              <div>
                                <FormLabel required>Country Name</FormLabel>

                                <Select
                                  placeholder="Select country name"
                                  onChange={(e, newValue) =>
                                    handleChangesCountry(e, newValue)
                                  }
                                  value={data1.countryName}
                                  required
                                >
                                  {Country.getAllCountries().map((v, index) => {
                                    return (
                                      <Option key={index} value={v.name}>
                                        {v.name}
                                      </Option>
                                    );
                                  })}
                                </Select>
                              </div>
                            </Tooltip>
                          </div>
                        </div>
                      </div>
                      <div>
                        <Tooltip title="Select Owner" className="mt-2">
                          <div>
                            <FormLabel required>Select Owner</FormLabel>

                            <Select
                              placeholder="Select Owner"
                              value={ownerName}
                              multiple
                              onChange={(event, newValue) =>
                                handleChange(event, newValue, "owner")
                              }
                              sx={{
                                minWidth: "13rem",
                              }}
                              slotProps={{
                                listbox: {
                                  sx: {
                                    width: "100%",
                                  },
                                },
                              }}
                              required
                            >
                              {ownerNames.map((name) => (
                                <Option key={name} value={name}>
                                  {name}
                                </Option>
                              ))}
                            </Select>
                          </div>
                        </Tooltip>
                      </div>

                      {data.jewelry.owner.includes("Other") && (
                        <div className="mt-2">
                          <Tooltip title="Enter Your Other Owner Name ">
                            <div>
                              <FormLabel>Other Owner</FormLabel>
                              <Input
                                className="input_mui_joy"
                                placeholder="Enter Other Owner"
                                id="otherprop"
                                onChange={(e) => handleChanges(e, "otherOwner")}
                                value={data.jewelry.otherOwner}
                              />
                            </div>
                          </Tooltip>
                        </div>
                      )}

                      <div>
                        <Tooltip title="Enter type of the jewelry">
                          <div className="mt-2">
                            <FormLabel required>Jewelry Type</FormLabel>
                            <Select
                              value={data.jewelry.jewelryName}
                              onChange={(event, newValue) =>
                                handleChange(event, newValue, "jewelryName")
                              }
                              required
                            >
                              <Option value="">
                                Select Your Type Of jewelry
                              </Option>
                              <Option value="Gold">Gold</Option>
                              <Option value="Silver">Silver</Option>
                              <Option value="Platinum">Platinum</Option>
                              <Option value="Palladium">Palladium</Option>
                              <Option value="Stone">Stone</Option>
                              <Option value="Other">Other</Option>
                            </Select>
                          </div>
                        </Tooltip>
                      </div>

                      {data.jewelry.jewelryName === "Other" && (
                        <div className="mt-2">
                          <Tooltip title="Enter Your Other Jewelry Type ">
                            <div>
                              <FormLabel>Other Jewelry Type</FormLabel>
                              <Input
                                className="input_mui_joy"
                                placeholder="Enter Other otherJewelryName"
                                id="otherprop"
                                onChange={(e) =>
                                  handleChanges(e, "otherJewelryName")
                                }
                                value={data.jewelry.otherJewelryName}
                              />
                            </div>
                          </Tooltip>
                        </div>
                      )}

                      <div>
                        <Tooltip title="Enter your jewelry name">
                          <div>
                            <FormLabel>Jewelry Name</FormLabel>
                            <Input
                              className="input_mui_joy"
                              placeholder="Enter jewelry name"
                              onChange={(e) =>
                                handleChanges(e, "jewelryCaption")
                              }
                              value={data.jewelry.jewelryCaption}
                            />
                          </div>
                        </Tooltip>
                      </div>

                      <div>
                        <Tooltip title="Select Carats Value of jewelry">
                          <div className="mt-2">
                            <FormLabel required>Select Carat Value</FormLabel>
                            <Select
                              value={data.jewelry.caratValue}
                              onChange={(event, newValue) =>
                                handleChange(event, newValue, "caratValue")
                              }
                              required
                            >
                              <Option value="">
                                Select Your Type Of Carat
                              </Option>
                              <Option value="24k">24k</Option>
                              <Option value="22k">22k</Option>
                              <Option value="21k">21k</Option>
                              <Option value="20k">20k</Option>
                              <Option value="18k">18k</Option>
                              <Option value="16k">16k</Option>
                              <Option value="14k">14k</Option>
                              <Option value="10k">10k</Option>
                            </Select>
                          </div>
                        </Tooltip>
                      </div>

                      <div className="address_input">
                        <div className="mt-2 addform_input_half">
                          <div>
                            <Tooltip title="Enter the weight of Jewelry (gram)">
                              <div>
                                <FormLabel>Weight</FormLabel>
                                <Input
                                  type="number"
                                  className="input_mui_joy"
                                  placeholder="Enter weight of jewelry"
                                  onChange={(e) => handleChanges(e, "weight")}
                                  value={data.jewelry.weight}
                                />
                              </div>
                            </Tooltip>
                          </div>
                        </div>

                        <div className="mt-2 addform_input_half">
                          <div>
                            <Tooltip title="Enter Appraised Value of the Jewelry">
                              <div>
                                <FormLabel required>Appraised Value</FormLabel>
                                <Input
                                  placeholder="Appraised Jewelry Value"
                                  required
                                  type="number"
                                  onChange={(e) => {
                                    handleChanges(e, "estimatedValue");
                                  }}
                                  value={data.jewelry.estimatedValue}
                                  startDecorator={<div>$</div>}
                                />
                              </div>
                            </Tooltip>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="addform_body_right_jewelry">
                      <div>
                        <Tooltip title="Add your banks related file">
                          <div className="mt-2">
                            <FormLabel>Jewelry Images</FormLabel>
                            <input
                              style={{
                                border: "solid 1px lightgray",
                                borderLeft: "none",
                                width: "100%",
                                borderRadius: "5px",
                              }}
                              type="file"
                              name="images"
                              id="imageFiles"
                              onChange={(e) => handleImageChange1(e)}
                              multiple
                              accept="image/*"
                            />
                          </div>
                        </Tooltip>
                      </div>

                      <div>
                        <Tooltip title="Add your banks related file">
                          <div className="mt-2">
                            <FormLabel>Supporting Document</FormLabel>
                            <input
                              style={{
                                border: "solid 1px lightgray",
                                borderLeft: "none",
                                width: "100%",
                                borderRadius: "5px",
                              }}
                              type="file"
                              name="myfile"
                              id="exampleFile"
                              onChange={(e) => handleImageChange(e, 5)}
                              accept=".pdf"
                              multiple
                            />
                          </div>
                        </Tooltip>
                      </div>
                      <div
                        className="bene-select mt-3"
                        onClick={handleShowBeneficiary}
                        style={{ cursor: "pointer" }}
                      >
                        Select Your Beneficiary
                      </div>

                      <div className="address_input">
                        <div style={{ width: "100%" }} className="mt-2">
                          <Tooltip title="Enter notes for your jewelry">
                            <div>
                              <FormLabel>Notes</FormLabel>
                              <Textarea
                                sx={{ height: "182px", minHeight: "52px" }}
                                placeholder="Notes"
                                id="notes"
                                onChange={(e) => handleChanges(e, "notes")}
                                value={data.jewelry.notes}
                              />
                            </div>
                          </Tooltip>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="text-center"
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <Button outline className="my-estate-add-btn">
                      Update
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      )}

      {benevisible && (
        <div className="popup">
          <div
            className="popup-content"
            style={{
              minWidth: "350px",
              width: "100%",
              maxWidth: "700px",
            }}
          >
            <div className="note_popup">
              <div className="note_popup_heading">
                <div className="share_property_heading">
                  <h2>Share Property </h2>
                </div>
                <div>
                  <button
                    className="note_popup_heading_close_btn"
                    onClick={handleReset}
                  >
                    <FontAwesomeIcon icon={faXmark} />
                  </button>
                </div>
              </div>
              <div className="share_property_est_value">
                <p>
                  Estimated Value:{" "}
                  <code style={{ color: "green", fontWeight: "bold" }}>
                    ${estimatedTotalAmount}
                  </code>
                </p>
                {/* {JSON.stringify(sharedDetails)}  */}
                {/* <p>----------------------------------------------------</p>
                    {JSON.stringify(data.documents)}
                    <p>----------------------------------------------------</p>
                    {JSON.stringify(data.accounts)}
                    <p>----------------------------------------------------</p>
                    {JSON.stringify(data.bank)}
                    <p>----------------------------------------------------</p>
                    {JSON.stringify(data)} */}
              </div>
              <div className="BeneficiarySelect">
                <div className="BeneficiarySelectContainer">
                  <div className="BeneficiarySelectRow">
                    <div className="share_property_Type">
                      <p className="share_property_Type_paragraph">
                        Choose Distribution Type:{" "}
                      </p>
                      <p className="ms-2 text-black fw-normal">
                        {/* {sharedDetails[0].distributedType} */}
                      </p>
                      <select
                        value={sharedDetails[0].distributedType}
                        onChange={(e) => handleDistributionTypeChange(e)}
                        class="share_property_Type_select"
                        disabled={
                          sharedDetails[0].distributedType !== "" ? true : false
                        }
                      >
                        <option value="">Select Type</option>
                        <option value="percentage">Percentage</option>
                        <option value="dollar">Dollar</option>
                      </select>
                    </div>
                    <div className="SelectContainer">
                      <select
                        className="Property-inputfiled"
                        onChange={handleBeneficiarySelection}
                        value=""
                        disabled={!distributedType}
                      >
                        <option value="" disabled hidden>
                          {distributedType
                            ? "Select Your Beneficiary Username"
                            : "Select Type First"}
                        </option>
                        {beneficiary.map((benif) => (
                          <option
                            key={benif.id}
                            value={benif.id}
                            disabled={selectedBeneficiaries.includes(benif.id)}
                          >
                            {/* {benif.username} */}
                            {`${benif.firstName} ${benif.lastName}`}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  {/* <div className="share_beneficiary_main_card">
                        {sharedDetails[0].distributedType !== null && (
                          <>
                            {sharedDetails[0].distributedType === 'dollar' ? (
                              <div>
                                <div className="share_beneficiary_card_close" onClick={() => handleBeneficiaryClose(beneficiary)}>
                                  <FontAwesomeIcon icon={faXmark} />
                                </div> 
                                <p>Beneficiary: </p>
                                <TextField type="number" label="Dollar" />
                              </div>
                            ) : null}

                            {sharedDetails[0].distributedType === 'percentage' ? (
                              <div>
                                <p>Beneficiary: </p>
                                <TextField type="number" label="Percentage" />
                              </div>
                            ) : null}
                          </>
                        )}
                      </div> */}
                  <div className="share_beneficiary_main_card">
                    {selectedBeneficiaries.map((beneficiary) => (
                      <div key={beneficiary} class="share_beneficiary_card">
                        <div>
                          <p className="share_beneficiary_card_para">
                            Beneficiary: {getBenificiaryName({ beneficiary })}
                          </p>
                          {distributedType === "percentage" && (
                            <input
                              type="text"
                              className="share_ben_percentage"
                              placeholder="Percentage"
                              value={
                                beneficiaryDetails[beneficiary]?.percentage ||
                                ""
                              }
                              onChange={(e) =>
                                handleFieldChange(
                                  beneficiary,
                                  "percentage",
                                  e.target.value
                                )
                              }
                            />
                          )}
                          {distributedType === "dollar" && (
                            <input
                              type="text"
                              className="share_ben_percentage"
                              placeholder="Dollar Value"
                              value={
                                beneficiaryDetails[beneficiary]?.dollar || ""
                              }
                              onChange={(e) =>
                                handleFieldChange(
                                  beneficiary,
                                  "dollar",
                                  e.target.value
                                )
                              }
                            />
                          )}

                          {distributedType && (
                            <p className="share_beneficiary_card_para">
                              {/* <p></p> */}
                              Distributed Value: $
                              {distributedType === "percentage"
                                ? calculateDistributedAmount(
                                    "percentage",
                                    estimatedTotalAmount,
                                    beneficiaryDetails[beneficiary]
                                  )
                                : calculateDistributedAmount(
                                    "dollar",
                                    estimatedTotalAmount,
                                    beneficiaryDetails[beneficiary]
                                  )}
                            </p>
                          )}
                        </div>
                        {/* <div
                            className="share_beneficiary_card_close"
                            onClick={() => handleBeneficiaryClose(beneficiary)}
                          >
                            <FontAwesomeIcon icon={faXmark} />
                          </div> */}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            {selectedBeneficiaries.length > 0 && (
              <button onClick={handleSave}>Save</button>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export function EditInternationalAssetInsurance() {
  const { id } = useParams();
  const navigate = useNavigate();

  let [form1, setForm1] = useState(true);

  const toggle = () => {
    setForm1(!form1);
    navigate("/user/my-estate/International_assets");
  };
  // set Add data
  const [data, setData] = useState({
    insurance: {
      owner: "",
      otherOwner: "",
      insuranceCaption: "",
      details: "",
      detailsOfpoint: "",
      notes: "",
    },
    sharedDetails: [
      {
        distributedType: "",
        distributedValue: "",
        distributedAmount: "",
        beneficiaryId: "",
      },
    ],
  });

  const [data1, setData1] = useState({
    id: "",
    assetCaption: "",
    assetType: "insurance",
    countryName: "",
  });

  const handleChangesData = (e, field) => {
    const newValue = e.target.value;
    setData1((prevData) => ({
      ...prevData,
      [field]: newValue,
    }));
  };

  const handleChangesCountry = (event, newValue) => {
    setData1((prevData) => ({
      ...prevData,
      countryName: newValue,
    }));
  };

  // for owner field
  const [ownerName, setOwnerName] = useState([]);

  const handleChange = (event, newValue, stringValue) => {
    if (stringValue === "owner") {
      // Convert ownerName array to a single string

      if (newValue.toString() === "Other") {
      } else {
        setData((prevData) => ({
          ...prevData,
          insurance: {
            ...prevData.insurance,
            otherOwner: "",
          },
        }));
      }

      const comingValue =
        typeof newValue === "string" ? newValue.split(",") : newValue;
      const ownerString = comingValue.join(", ");

      setData((prevData) => ({
        ...prevData,
        insurance: {
          ...prevData.insurance,
          owner: ownerString,
        },
      }));

      // Update the ownerName state afterwards
      setOwnerName(newValue);
    }
  };

  // Define an array to store user names
  const ownerNames = [];

  // Get primaryUser data
  const primaryUserDetails = getUser();
  const primaryUserName =
    primaryUserDetails.firstName + " " + primaryUserDetails.lastName;

  // Get secondaryUser data
  const secondaryUserDetails = getSecondaryUser();
  let secondaryUserName = "";

  // Check if secondary user exists
  if (secondaryUserDetails !== undefined) {
    secondaryUserName =
      secondaryUserDetails.firstName + " " + secondaryUserDetails.lastName;

    // Push both user names into the array
    ownerNames.push(primaryUserName, secondaryUserName);
    ownerNames.push("Other");
  } else {
    // If secondary user doesn't exist, only push primary user name
    ownerNames.push(primaryUserName);
    ownerNames.push("Other");
  }

  // use state to set the selected images
  const [selectedImage, setSelectedImage] = useState([]);

  // Handle image
  const handleImageChange = (event) => {
    const selectedFiles = event.target.files;
    const allowedExtensions = ["pdf"];

    if (selectedFiles) {
      const selectedFilesArray = Array.from(selectedFiles);

      // Check each file's extension
      const invalidFiles = selectedFilesArray.filter((file) => {
        const fileNameParts = file.name.split(".");
        const fileExtension =
          fileNameParts[fileNameParts.length - 1].toLowerCase();
        return !allowedExtensions.includes(fileExtension);
      });

      if (invalidFiles.length === 0) {
        const formData = new FormData();

        selectedFilesArray.forEach((file, index) => {
          formData.append(`file${index + 1}`, file);
        });
        setSelectedImage(selectedFilesArray);
      } else {
        const invalidExtensions = invalidFiles
          .map((file) => file.name.split(".").pop())
          .join(", ");
        toast.error(
          `Invalid file extensions: ${invalidExtensions}. Please select valid document formats.`,
          {
            position: toast.POSITION.BOTTOM_CENTER,
          }
        );
        event.target.value = ""; // Clear the input field
      }
    }
  };

  // const handleChanges = (event, property) => {
  //   setData({ ...data, [property]: event.target.value });
  // };

  const handleChanges = (e, field) => {
    const newValue = e.target.value;
    setData((prevData) => ({
      ...prevData,
      insurance: {
        ...prevData.insurance,
        [field]: newValue,
      },
    }));
  };

  // const resetData = () => {
  //   setData({
  //     lifeInsurance_Id: lifeInsurance_Id,
  //     details: "",
  //     // supportingDcument: "",
  //     detailsOfpoint: "",
  //     notes: "",
  //     benificiary: "",
  //     insuranceCaption: ""
  //   });
  // };

  // post the form
  const lifeForm = (event) => {
    event.preventDefault();
    let token = "Bearer " + getToken();
    const formData = new FormData();
    if (null != selectedImage) {
      for (let i = 0; i < selectedImage.length; i++) {
        formData.append(`files`, selectedImage[i]);
        console.log("this is file indexs", selectedImage[i]);
      }
    }
    formData.append("asset", JSON.stringify(data));
    formData.append("data", JSON.stringify(data1));

    updateInternationalAsset(formData, token)
      .then((resp) => {
        toast.success("Updated Successfully!!", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
        toggle();
      })
      .catch((error) => {
        toast.error("An error occurred. Please try again.", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      });
  };

  // beneficiary addition in form
  const [beneficiary, setBenificiary] = useState([]);
  const getBenificiarydata = () => {
    let userId = getUser().commonId;
    console.log("user Id=" + userId);
    let token = "Bearer " + getToken();
    getBeneficiary(token, userId)
      .then((res) => {
        setBenificiary(res);
      })
      .catch((err) => console.log(err));
  };

  const getData = () => {
    let token = "Bearer " + getToken();
    getSingleInternationalAsset(token, id).then((res) => {
      console.log("this is insurance responce ", res);
      setData({
        ...data,
        insurance: res.assetData.insurance,
        documents: res.assetData.documents,
        sharedDetails: res.assetData.sharedDetails,
      });

      setData1({
        ...data1,
        id: res.internationalAssetData.id,
        assetCaption: res.internationalAssetData.assetCaption,
        countryName: res.internationalAssetData.countryName,
      });
      const ownerNameArray = res.assetData.insurance.owner.split(", ");
      setOwnerName(ownerNameArray);
      // setEstimatedTotalAmount(res.realEstate.estPropertyVal);
    });
  };

  useEffect(() => {
    getBenificiarydata();
    getData();
  }, []);
  return (
    <>
      {form1 && (
        <div className="overlay1-edit">
          <div
            className="propertyform"
            style={{ display: "flex", justifyContent: "left" }}
          >
            <Container className="edit_container">
              <Card color="" outline>
                <CardHeader>
                  <h3 className="form1-heading">Edit Life Insurance</h3>
                  <div
                    className="Close"
                    onClick={() => {
                      navigate("/user/my-estate/International_assets");
                    }}
                  >
                    <FontAwesomeIcon icon={faXmark} />
                  </div>
                </CardHeader>
                <CardBody>
                  <Form onSubmit={lifeForm}>
                    <div className="mt-2 ">
                      <div>
                        <Tooltip title="Enter Caption for your assets">
                          <div>
                            <FormLabel>Assets Caption</FormLabel>
                            <Input
                              className="input_mui_joy"
                              placeholder="Enter asset caption"
                              onChange={(e) =>
                                handleChangesData(e, "assetCaption")
                              }
                              value={data1.assetCaption}
                            />
                          </div>
                        </Tooltip>
                      </div>
                    </div>

                    <div className="mt-2">
                      <div>
                        <Tooltip title="Enter the Country of your assets">
                          <div>
                            <FormLabel required>Country Name</FormLabel>

                            <Select
                              placeholder="Select country name"
                              onChange={(e, newValue) =>
                                handleChangesCountry(e, newValue)
                              }
                              value={data1?.countryName}
                              required
                            >
                              {Country.getAllCountries().map((v, index) => {
                                return (
                                  <Option key={index} value={v.name}>
                                    {v.name}
                                  </Option>
                                );
                              })}
                            </Select>
                          </div>
                        </Tooltip>
                      </div>
                    </div>
                    <div className="mt-2">
                      <Tooltip title="Select Owner">
                        <div>
                          <FormLabel required>Select Owner</FormLabel>

                          <Select
                            placeholder="Select Owner"
                            value={ownerName}
                            multiple
                            onChange={(event, newValue) =>
                              handleChange(event, newValue, "owner")
                            }
                            sx={{
                              minWidth: "13rem",
                            }}
                            slotProps={{
                              listbox: {
                                sx: {
                                  width: "100%",
                                },
                              },
                            }}
                            required
                          >
                            {ownerNames.map((name) => (
                              <Option key={name} value={name}>
                                {name}
                              </Option>
                            ))}
                          </Select>
                        </div>
                      </Tooltip>
                    </div>
                    {data.insurance.owner?.includes("Other") && (
                      <div className="mt-2">
                        <Tooltip title="Enter Your Other Owner Name ">
                          <div>
                            <FormLabel>Other Owner</FormLabel>
                            <Input
                              className="input_mui_joy"
                              placeholder="Enter Other Owner"
                              id="otherprop"
                              onChange={(e) => handleChanges(e, "otherOwner")}
                              value={data.insurance.otherOwner}
                            />
                          </div>
                        </Tooltip>
                      </div>
                    )}

                    <div>
                      <Tooltip title="Enter Heading for Insurance">
                        <div className="mt-2">
                          <FormLabel>Insurance Heading</FormLabel>
                          <Input
                            className="input_mui_joy"
                            placeholder="Enter insurance heading"
                            onChange={(e) =>
                              handleChanges(e, "insuranceCaption")
                            }
                            value={data.insurance.insuranceCaption}
                          />
                        </div>
                      </Tooltip>
                    </div>

                    <div>
                      <Tooltip title="Enter The Name Of Your Insurance ">
                        <div className="mt-2">
                          <FormLabel>Details</FormLabel>
                          <Input
                            className="input_mui_joy"
                            placeholder="Enter details"
                            onChange={(e) => handleChanges(e, "details")}
                            value={data.insurance.details}
                          />
                        </div>
                      </Tooltip>
                    </div>

                    <div>
                      <Tooltip title="Enter your Details of Point Contact Name">
                        <div className="mt-2">
                          <FormLabel>Details of Point Contact Name</FormLabel>
                          <Input
                            className="input_mui_joy"
                            placeholder="Enter contact name"
                            onChange={(e) => handleChanges(e, "detailsOfpoint")}
                            value={data.insurance.detailsOfpoint}
                          />
                        </div>
                      </Tooltip>
                    </div>

                    <div className="mt-3">
                      <Tooltip title="Add your insurance related file">
                        <div>
                          <label
                            style={{
                              display: "block",
                              marginBottom: "5px",
                            }}
                          >
                            Supporting Document<span></span>
                          </label>
                          <input
                            style={{
                              border: "solid 1px lightgray",
                              borderLeft: "none",
                              width: "100%",
                              borderRadius: "5px",
                            }}
                            type="file"
                            name="myfile"
                            id="exampleFile"
                            multiple
                            accept=".pdf"
                            onChange={(e) => handleImageChange(e)}
                          />
                        </div>
                      </Tooltip>
                    </div>

                    {/* <div
                        className="bene-select mt-3"
                        onClick={handleShowBeneficiary}
                        style={{ cursor: "pointer" }}
                      >
                        Select Your Beneficiary
                      </div> */}

                    {/* <div className="mt-3">
                        <Tooltip title="Select The Type Of Property">
                          <FormControl
                            // required
                            fullWidth
                            sx={{ minWidth: 120 }}
                            size="small"
                          >
                            <InputLabel id="demo-simple-select-label">
                              Select Your Single Beneficiary
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="username"
                              label="Select Your Single Beneficiary"
                              onChange={(e) =>
                                handleChanges(e, "beneficiary")
                              }
                              value={data.insurance.beneficiary}
                            >
                              {beneficiary.map((benif) => (
                                <MenuItem
                                  key={benif.username}
                                  value={benif.username}
                                >
                                  {`${benif.firstName} ${benif.lastName}`}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Tooltip>
                      </div> */}

                    <div className="address_input">
                      <div style={{ width: "100%" }} className="mt-2">
                        <Tooltip title="Enter notes for your insurance">
                          <div>
                            <FormLabel>Notes</FormLabel>
                            <Textarea
                              sx={{ height: "182px", minHeight: "52px" }}
                              placeholder="Notes"
                              id="notes"
                              onChange={(e) => handleChanges(e, "notes")}
                              value={data.insurance.notes}
                            />
                          </div>
                        </Tooltip>
                      </div>
                    </div>

                    <Container
                      className="text-center"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <Button outline className="my-estate-add-btn">
                        Update
                      </Button>
                    </Container>
                  </Form>
                </CardBody>
              </Card>
            </Container>
          </div>
        </div>
      )}
    </>
  );
}

export function EditInternationalAssetCrypto() {
  const { id } = useParams();
  const navigate = useNavigate();
  let [form1, setForm1] = useState(true);

  const toggle = () => {
    setForm1(!form1);
    navigate("/user/my-estate/International_assets");
  };

  // set data
  const [data, setData] = useState({
    cryptoAssest: {
      owner: "",
      otherOwner: "",
      cryptoCaption: "",
      coin: "",
      quantity: "",
      exchange: "",
      wallet: "",
      notes: "",
      estValue: "",
    },
    sharedDetails: [
      {
        distributedType: "",
        distributedValue: "",
        distributedAmount: "",
        beneficiaryId: "",
      },
    ],
  });

  const [data1, setData1] = useState({
    id: "",
    assetCaption: "",
    assetType: "crypto",
    countryName: "",
  });

  const handleChangesData = (e, field) => {
    const newValue = e.target.value;
    setData1((prevData) => ({
      ...prevData,
      [field]: newValue,
    }));
  };

  const handleChangesCountry = (event, newValue) => {
    setData1((prevData) => ({
      ...prevData,
      countryName: newValue,
    }));
  };

  // for owner field
  const [ownerName, setOwnerName] = useState([]);

  const handleChange = (event, newValue, stringValue) => {
    if (stringValue === "coin") {
      setData((prevData) => ({
        ...prevData,
        cryptoAssest: {
          ...prevData.cryptoAssest,
          coin: newValue,
        },
      }));
    }

    if (stringValue === "exchange") {
      setData((prevData) => ({
        ...prevData,
        cryptoAssest: {
          ...prevData.cryptoAssest,
          exchange: newValue,
        },
      }));
    }

    if (stringValue === "wallet") {
      setData((prevData) => ({
        ...prevData,
        cryptoAssest: {
          ...prevData.cryptoAssest,
          wallet: newValue,
        },
      }));
    }

    if (stringValue === "owner") {
      // Convert ownerName array to a single string

      if (newValue.toString() === "Other") {
      } else {
        setData((prevData) => ({
          ...prevData,
          cryptoAssest: {
            ...prevData.cryptoAssest,
            otherOwner: "",
          },
        }));
      }

      const comingValue =
        typeof newValue === "string" ? newValue.split(",") : newValue;
      const ownerString = comingValue.join(", ");

      setData((prevData) => ({
        ...prevData,
        cryptoAssest: {
          ...prevData.cryptoAssest,
          owner: ownerString,
        },
      }));

      // Update the ownerName state afterwards
      setOwnerName(newValue);
    }
  };

  // Define an array to store user names
  const ownerNames = [];

  // Get primaryUser data
  const primaryUserDetails = getUser();
  const primaryUserName =
    primaryUserDetails.firstName + " " + primaryUserDetails.lastName;

  // Get secondaryUser data
  const secondaryUserDetails = getSecondaryUser();
  let secondaryUserName = "";

  // Check if secondary user exists
  if (secondaryUserDetails !== undefined) {
    secondaryUserName =
      secondaryUserDetails.firstName + " " + secondaryUserDetails.lastName;

    // Push both user names into the array
    ownerNames.push(primaryUserName, secondaryUserName);
    ownerNames.push("Other");
  } else {
    // If secondary user doesn't exist, only push primary user name
    ownerNames.push(primaryUserName);
    ownerNames.push("Other");
  }

  // use state to set the selected images
  const [selectedImage, setSelectedImage] = useState([]);

  const handleChanges = (e, field) => {
    const newValue = e.target.value;
    setData((prevData) => ({
      ...prevData,
      cryptoAssest: {
        ...prevData.cryptoAssest,
        [field]: newValue,
      },
    }));
    setEstimatedTotalAmount(data.cryptoAssest.estValue);
  };

  // Handle image
  const handleImageChange = (event) => {
    const selectedFiles = event.target.files;
    const allowedExtensions = ["pdf"];

    if (selectedFiles) {
      const selectedFilesArray = Array.from(selectedFiles);

      // Check each file's extension
      const invalidFiles = selectedFilesArray.filter((file) => {
        const fileNameParts = file.name.split(".");
        const fileExtension =
          fileNameParts[fileNameParts.length - 1].toLowerCase();
        return !allowedExtensions.includes(fileExtension);
      });

      if (invalidFiles.length === 0) {
        const formData = new FormData();

        selectedFilesArray.forEach((file, index) => {
          formData.append(`file${index + 1}`, file);
        });

        // Set the selected image state with the array of files
        setSelectedImage(selectedFilesArray);

        // Now, you can use the formData object to send files to the API
        // Example:
        // apiCall(formData);
      } else {
        // Handle invalid file extensions
        const invalidExtensions = invalidFiles
          .map((file) => file.name.split(".").pop())
          .join(", ");
        toast.error(
          `Invalid file extensions: ${invalidExtensions}. Please select valid document formats.`,
          {
            position: toast.POSITION.BOTTOM_CENTER,
          }
        );
        event.target.value = ""; // Clear the input field
      }
    }
  };

  // const resetData = () => {
  //   setData({
  //     coin: "",
  //     exchange: "",
  //     wallet: "",
  //     quntity: "",
  //     estimatedValue: "",
  //     exampleFile: "",
  //     notes: "",
  //     benificiary: "",
  //     addfield1: "",
  //     addfield2: "",
  //     addfield3: "",
  //     addfield4: "",
  //     addfield5: "",
  //     cryptoCaption: "",
  //   });
  //   setSelectedImage(null);
  //   setCoinName("");
  //   setExchangeName("");
  //   setWalletName("");
  // };

  const cryptoForm = (event) => {
    event.preventDefault();
    let token = "Bearer " + getToken();
    if (
      data.cryptoAssest.coin === "" ||
      data.cryptoAssest.exchange === "" ||
      data.cryptoAssest.wallet === "" ||
      data.cryptoAssest.quantity === ""
    ) {
      console.log("Error log");
      toast.error("Please Fill All required field Then Submit .", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
      return;
    }
    const formData = new FormData();
    if (null != selectedImage) {
      for (let i = 0; i < selectedImage.length; i++) {
        formData.append(`files`, selectedImage[i]);
        console.log("this is file indexs", selectedImage[i]);
      }
    }
    formData.append("asset", JSON.stringify(data));
    formData.append("data", JSON.stringify(data1));

    updateInternationalAsset(formData, token)
      .then((resp) => {
        toast.success("Updated Successfully!!", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
        toggle();
      })
      .catch((error) => {
        toast.error("An error occurred. Please try again.", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      });
  };

  // const [showAfterCloseBene, setShowAfterCloseBene] = useState(true);
  useEffect(() => {
    const fetchData = async () => {
      try {
        let token = "Bearer " + getToken();
        const res = await getSingleInternationalAsset(token, id);

        setData({
          ...data,
          cryptoAssest: res.assetData.cryptoAssest,
          documents: res.assetData.documents,
          sharedDetails: res.assetData.sharedDetails,
        });
        setData1({
          ...data1,
          id: res.internationalAssetData.id,
          assetCaption: res.internationalAssetData.assetCaption,
          countryName: res.internationalAssetData.countryName,
        });

        const ownerNameArray = res.assetData.cryptoAssest.owner.split(", ");
        setOwnerName(ownerNameArray);
        setEstimatedTotalAmount(res.assetData.cryptoAssest.estValue);

        const copiedSharedDetails = [...res.assetData.sharedDetails];

        if (copiedSharedDetails.length > 0) {
          setSharedDetails(res.assetData.sharedDetails);

          ben(copiedSharedDetails[0].distributedType);
          for (var i = 0; i < copiedSharedDetails.length; i++) {
            handleBeneficiarySelection1(copiedSharedDetails[i].beneficiaryId);
            handleFieldChange1(
              copiedSharedDetails[i].beneficiaryId,
              copiedSharedDetails[i].distributedType,
              copiedSharedDetails[i].distributedValue
            );
          }

          console.log(
            "sharedDetails beneficiaryDetails : ",
            beneficiaryDetails
          );
          console.log(
            "sharedDetails selectedBeneficiaries : ",
            selectedBeneficiaries
          );
          console.log("sharedDetails distributedType : ", distributedType);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
    getBenificiarydata();
  }, []);

  // const getCoins = (value) => {
  //   console.log("Value-=----------- :  ", value);
  //   axios
  //     .get(
  //       "https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&order=market_cap_desc&per_page=100&page=1&sparkline=false&locale=en"
  //     )
  //     .then((res) => {
  //       setCoins(res.data);
  //       console.log("Coins : ", res.data);
  //       console.log("Coins getCoins: ", coins);
  //     })
  //     .catch((error) => {
  //       console.log(
  //         "ERROR : ",
  //         "You've exceeded the Limit try after some time !!"
  //       );
  //     });
  // };

  // useEffect(() => {
  //   const calculateEstimatedValue = () => {
  //     // Check if both selectCrypto and quntity fields have values
  //     if (data.cryptoAssest.coin && data.cryptoAssest.quantity) {
  //       // Perform the calculation for the estimated value
  //       const selectedCrypto = coins.find(
  //         (coin) => coin.name === data.cryptoAssest.coin
  //       );
  //       // console.log("selectedCrypto 1: ", selectedCrypto);
  //       if (selectedCrypto) {
  //         // console.log("selectedCrypto : ", selectedCrypto);
  //         const estimatedValue1 =
  //           data.cryptoAssest.quantity * selectedCrypto.current_price;
  //         // console.log("estimatedValue : ", selectedCrypto);
  //         // setEstimatedValue(estimatedValue1.toString());
  //         setData((prevData) => ({
  //           ...prevData,
  //           cryptoAssest: {
  //             ...prevData.cryptoAssest,
  //             estValue: estimatedValue1.toString(),
  //           },
  //         }));
  //       }
  //     } else {
  //       const estimatedValue = "";
  //       // console.log("estimatedValue : ", estimatedValue);
  //       setData((prevData) => ({
  //         ...prevData,
  //         cryptoAssest: {
  //           ...prevData.cryptoAssest,
  //           estValue: estimatedValue,
  //         },
  //       }));
  //     }
  //   };

  //   calculateEstimatedValue();
  // },
  // // [data.cryptoAssest.coin, data.cryptoAssest.quantity]
  // []);

  // beneficiary addition in form
  const [beneficiary, setBenificiary] = useState([]);
  const getBenificiarydata = () => {
    let userId = getUser().commonId;
    console.log("user Id=" + userId);
    let token = "Bearer " + getToken();
    getBeneficiary(token, userId)
      .then((res) => {
        setBenificiary(res);
      })
      .catch((err) => console.log(err));
  };

  //
  const [benevisible, setbeneVisible] = useState(false);
  const [selectedBeneficiaries, setSelectedBeneficiaries] = useState([]);
  const [beneficiaryDetails, setBeneficiaryDetails] = useState({});
  const [estimatedTotalAmount, setEstimatedTotalAmount] = useState(0);

  const [distributedType, setDistributedType] = useState("");

  const [sharedDetails, setSharedDetails] = useState([
    {
      distributedType: "",
      distributedValue: "",
      distributedAmount: "",
      beneficiaryId: "",
    },
  ]);

  const ben = (newType) => {
    // const newType = sharedDetails[0].distributedType;
    const resetDetails = {};
    Object.keys(beneficiaryDetails).forEach((beneficiary) => {
      resetDetails[beneficiary] = { percentage: "", value: "" };
    });
    setDistributedType(newType);
    setBeneficiaryDetails(resetDetails);
  };

  const handleShowBeneficiary = () => {
    setbeneVisible(true);
    // setShowAfterCloseBene(true);
  };

  const handleReset = () => {
    setbeneVisible(false);
    setSelectedBeneficiaries([]);
    setBeneficiaryDetails({});
    // setShowAfterCloseBene(false);
  };

  const handleDistributionTypeChange = (event) => {
    console.log("handleDistributionTypeChange working : ", event.target.value);
    console.log("handleDistributionTypeChange sharedDetails", sharedDetails);
    var distributedType = event.target.value;
    const sharedDetails1 = sharedDetails.map((detail) => {
      // Change the distributedType value as needed
      detail.distributedType = distributedType;
      return detail;
    });
    console.log("handleDistributionType changed sharedDetails", sharedDetails);
    setSharedDetails(sharedDetails1);
    const newType = event.target.value;
    const resetDetails = {};
    Object.keys(beneficiaryDetails).forEach((beneficiary) => {
      resetDetails[beneficiary] = { percentage: "", dollar: "" };
    });
    setDistributedType(newType);
    setBeneficiaryDetails(resetDetails);
  };

  const handleBeneficiarySelection1 = (selectedBeneficiary) => {
    console.log("handleBeneficiarySelection working : ", selectedBeneficiary);
    if (selectedBeneficiary === null) {
      return null;
    }
    setSelectedBeneficiaries((prevSelectedBeneficiaries) => {
      // Check if the selectedBeneficiary is already included
      if (!prevSelectedBeneficiaries.includes(selectedBeneficiary)) {
        // If not included, update the selected beneficiaries
        const newSelectedBeneficiaries = [
          ...prevSelectedBeneficiaries,
          selectedBeneficiary,
        ];
        console.log(
          "Updated selected beneficiaries:",
          newSelectedBeneficiaries
        );
        return newSelectedBeneficiaries;
      }

      // If already included, return the previous state without any changes
      return prevSelectedBeneficiaries;
    });

    setBeneficiaryDetails((prevBeneficiaryDetails) => {
      const newBeneficiaryDetails = {
        ...prevBeneficiaryDetails,
        [selectedBeneficiary]: { percentage: "", dollar: "" },
      };
      console.log("Updated beneficiary details:", newBeneficiaryDetails);
      return newBeneficiaryDetails;
    });

    // setSelectedBeneficiaries((prevSelectedBeneficiaries) => {
    //   if (!prevSelectedBeneficiaries.includes(selectedBeneficiary)) {
    //     console.log("handleBeneficiarySelection 1 : ", selectedBeneficiary);
    //     setBeneficiaryDetails({
    //       ...beneficiaryDetails,
    //       [selectedBeneficiary]: { percentage: "", dollar: "" },
    //     });
    //     return [...prevSelectedBeneficiaries, selectedBeneficiary];
    //   }
    //   return prevSelectedBeneficiaries;
    // });

    console.log("handleBeneficiarySelection 2 : ", selectedBeneficiaries);
    console.log("check beneficiaryDetails 2 : ", beneficiaryDetails);
  };

  const handleBeneficiarySelection = (event) => {
    const selectedBeneficiary = event.target.value;
    console.log("handleBeneficiarySelection working : ", selectedBeneficiary);
    if (!selectedBeneficiaries.includes(selectedBeneficiary)) {
      setSelectedBeneficiaries([...selectedBeneficiaries, selectedBeneficiary]);
      setBeneficiaryDetails({
        ...beneficiaryDetails,
        [selectedBeneficiary]: { percentage: "", dollar: "" },
      });
    }
  };

  const getBenificiaryName = (id) => {
    // console.log('getBenificiaryName id : ', id);
    var foundBenificiary = null;
    if (id.beneficiary === undefined) {
      // console.log('IF condition');
      foundBenificiary = beneficiary.find((b) => b.id === parseInt(id));
    } else {
      foundBenificiary = beneficiary.find(
        (b) => b.id === parseInt(id.beneficiary)
      );
    }
    // console.log('foundBenificiary details : ', foundBenificiary);
    if (foundBenificiary) {
      return `${foundBenificiary.firstName} ${foundBenificiary.lastName}`;
    } else {
      return "Benificiary not found";
    }
  };

  const handleFieldChange1 = (beneficiary, field, value) => {
    console.log("handleFieldChange1   : ", beneficiaryDetails);

    setBeneficiaryDetails((prevDetails) => {
      // Create a copy of the state
      let updatedDetails = { ...prevDetails };

      // Update the copy
      updatedDetails = {
        ...updatedDetails,
        [beneficiary]: {
          ...updatedDetails[beneficiary],
          [field]: value,
        },
      };
      console.log("handleFieldChange1  2 : ", updatedDetails);

      // Return the updated state
      return updatedDetails;
    });
  };

  const handleFieldChange = (beneficiary, field, value) => {
    if (!estimatedTotalAmount || parseFloat(estimatedTotalAmount) === 0) {
      toast.error(
        "Please provide a valid estimated value before adding percentages!"
      );
      return;
    }
    let updatedDetails = { ...beneficiaryDetails };
    if (/^\d*$/.test(value)) {
      updatedDetails = {
        ...updatedDetails,
        [beneficiary]: {
          ...updatedDetails[beneficiary],
          [field]: value,
        },
      };
    }
    let totalPercentage = 0;
    let totalValue = 0;
    Object.keys(updatedDetails).forEach((key) => {
      const beneficiaryPercentage = parseFloat(updatedDetails[key]?.percentage);
      const beneficiaryValue = parseFloat(updatedDetails[key]?.dollar);
      if (!isNaN(beneficiaryPercentage) && beneficiaryPercentage >= 0) {
        totalPercentage += beneficiaryPercentage;
      }
      if (!isNaN(beneficiaryValue) && beneficiaryValue >= 0) {
        totalValue += beneficiaryValue;
      }
    });
    if (
      totalPercentage > 100 ||
      totalValue > parseFloat(estimatedTotalAmount)
    ) {
      updatedDetails[beneficiary][field] = "";
      toast.error(
        "Total percentage exceeds 100% or total value exceeds estimated value!"
      );
    } else {
      setBeneficiaryDetails(updatedDetails);
      // setSharedDetails(updatedDetails);
      // sharedDetails[index].distributedValue = updatedDetails.value;
      console.log("updatedDetails : ", updatedDetails);
    }
  };

  const calculateDistributedAmount = (
    distributedType,
    balance,
    beneficiaryDetail
  ) => {
    console.log("calculateDistributedAmount : ", beneficiaryDetail);
    console.log("calculateDistributedAmount balance : ", balance);
    // Assuming beneficiaryDetail is an object with a property 'value'
    if (distributedType === "percentage") {
      const calculatedAmount =
        (parseFloat(balance) * parseFloat(beneficiaryDetail.percentage)) / 100;
      console.log("calculateDistributedAmount return: ", calculatedAmount);
      return calculatedAmount.toFixed(2);
    } else if (distributedType === "dollar") {
      const detailValue = parseFloat(beneficiaryDetail.dollar);
      return detailValue.toFixed(2);
    }
    return "0.00";
  };

  const handleSave = () => {
    toast.success("Saved successfully!");
    setbeneVisible(false);
    var i = 0;
    Object.keys(beneficiaryDetails).forEach((key) => {
      const beneficiaryPercentage = parseFloat(
        beneficiaryDetails[key]?.percentage
      );
      const beneficiaryValue = parseFloat(beneficiaryDetails[key]?.dollar);
      if (!isNaN(beneficiaryPercentage) && beneficiaryPercentage >= 0) {
        const distributedAmount = calculateDistributedAmount(
          "percentage",
          estimatedTotalAmount,
          beneficiaryDetails[key]
        );
        handleChanges2(
          "percentage",
          beneficiaryPercentage,
          distributedAmount,
          parseInt(key),
          i
        );
        i += 1;
      }
      if (!isNaN(beneficiaryValue) && beneficiaryValue >= 0) {
        const distributedAmount = calculateDistributedAmount(
          "dollar",
          estimatedTotalAmount,
          beneficiaryDetails[key]
        );
        handleChanges2(
          "dollar",
          beneficiaryValue,
          distributedAmount,
          parseInt(key),
          i
        );
        i += 1;
      }
    });
    console.log("beneficiaryDetails data: ", data);
  };

  const handleChanges2 = (t, v, a, b, i) => {
    const updatedSharedDetails = [...data.sharedDetails];
    const updatedElement = {
      ...updatedSharedDetails[i],
      distributedType: t,
      distributedValue: v,
      distributedAmount: a,
      beneficiaryId: b,
    };
    updatedSharedDetails[i] = updatedElement;
    console.log("updatedSharedDetails[i] : ", updatedSharedDetails[i]);
    setData((prevState) => ({
      ...prevState,
      sharedDetails: updatedSharedDetails,
    }));
    data.sharedDetails[i] = updatedSharedDetails[i];
  };

  return (
    <>
      {form1 && (
        <div className="overlay1" style={{ transition: "500ms", height: "" }}>
          <div className="addform_ichest">
            <div className="addform_main">
              <div className="addform_heading">
                <h3 className="addform_heading_h1">Edit Bit Coin Assets</h3>
                <div className="addform_close">
                  <FontAwesomeIcon
                    icon={faXmark}
                    onClick={() => {
                      navigate("/user/my-estate/International_assets");
                    }}
                  />
                </div>
              </div>
              <div className="addform_body">
                <Form onSubmit={cryptoForm} className="addform_body">
                  <div className="addform_select_body">
                    <div className="addform_body_left">
                      <div className="address_input">
                        <div className="mt-2 addform_input_half">
                          <div>
                            <Tooltip title="Enter Caption for your assets">
                              <div>
                                <FormLabel>Assets Caption</FormLabel>
                                <Input
                                  className="input_mui_joy"
                                  placeholder="Enter asset caption"
                                  onChange={(e) =>
                                    handleChangesData(e, "assetCaption")
                                  }
                                  value={data1.assetCaption}
                                />
                              </div>
                            </Tooltip>
                          </div>
                        </div>

                        <div className="mt-2 addform_input_half">
                          <div>
                            <Tooltip title="Enter the Country of your assets">
                              <div>
                                <FormLabel required>Country Name</FormLabel>

                                <Select
                                  placeholder="Select country name"
                                  onChange={(e, newValue) =>
                                    handleChangesCountry(e, newValue)
                                  }
                                  value={data1.countryName}
                                  required
                                >
                                  {Country.getAllCountries().map((v, index) => {
                                    return (
                                      <Option key={index} value={v.name}>
                                        {v.name}
                                      </Option>
                                    );
                                  })}
                                </Select>
                              </div>
                            </Tooltip>
                          </div>
                        </div>
                      </div>
                      <div>
                        <Tooltip title="Select Owner" className="mt-2">
                          <div>
                            <FormLabel>Select Owner</FormLabel>
                            <Select
                              value={ownerName}
                              multiple
                              placeholder="Select Name"
                              onChange={(event, newValue) =>
                                handleChange(event, newValue, "owner")
                              }
                              sx={{
                                minWidth: "13rem",
                              }}
                            >
                              {ownerNames.map((name) => (
                                <Option key={name} value={name}>
                                  {name}
                                </Option>
                              ))}
                            </Select>
                          </div>
                        </Tooltip>
                      </div>

                      {data.cryptoAssest.owner.includes("Other") && (
                        <div className="mt-2">
                          <Tooltip title="Enter Your Other Owner Name ">
                            <div>
                              <FormLabel>Other Owner</FormLabel>
                              <Input
                                className="input_mui_joy"
                                placeholder="Enter Other Owner"
                                id="otherprop"
                                onChange={(e) => handleChanges(e, "otherOwner")}
                                value={data.cryptoAssest.otherOwner}
                              />
                            </div>
                          </Tooltip>
                        </div>
                      )}
                      <div>
                        <Tooltip title="Enter Heading For Your Coin">
                          <div className="mt-2">
                            <FormLabel>BitCoin Heading</FormLabel>
                            <Input
                              className="input_mui_joy"
                              placeholder="Enter bitcoin heading"
                              value={data.cryptoAssest.cryptoCaption}
                              onChange={(e) =>
                                handleChanges(e, "cryptoCaption")
                              }
                            />
                          </div>
                        </Tooltip>
                      </div>

                      <div>
                        <Tooltip title="Select Coin Type">
                          <div className="mt-2">
                            <FormLabel required>Coin Type</FormLabel>
                            <Select
                              value={data.cryptoAssest.coin}
                              onChange={(event, newValue) =>
                                handleChange(event, newValue, "coin")
                              }
                              required
                            >
                              <Option value="">Select Your Type Of coin</Option>
                              <Option value="BitCoin">BitCoin</Option>
                            </Select>
                          </div>
                        </Tooltip>
                      </div>

                      <div>
                        <Tooltip title="Enter name of the exchange">
                          <div className="mt-2">
                            <FormLabel required>Exchange</FormLabel>
                            <Select
                              value={data.cryptoAssest.exchange}
                              onChange={(event, newValue) =>
                                handleChange(event, newValue, "exchange")
                              }
                              required
                            >
                              <Option value="">
                                Select Your Type Of exchange
                              </Option>
                              <Option value="Coinbase">Coinbase</Option>
                              <Option value="Binance.US">Binance.US</Option>
                              <Option value="Kraken">Kraken</Option>
                              <Option value="Gemini">Gemini</Option>
                              <Option value="Bitfinex">Bitfinex</Option>
                              <Option value="Bitstamp">Bitstamp</Option>
                              <Option value="Huobi US">Huobi US</Option>
                              <Option value="Crypto.com">Crypto.com</Option>
                              <Option value="BitFlyer">BitFlyer</Option>
                              <Option value="OKCoin">OKCoin</Option>
                            </Select>
                          </div>
                        </Tooltip>
                      </div>

                      <div>
                        <Tooltip title="Enter name of the wallet">
                          <div className="mt-2">
                            <FormLabel required>Wallet</FormLabel>
                            <Select
                              value={data.cryptoAssest.wallet}
                              onChange={(event, newValue) =>
                                handleChange(event, newValue, "wallet")
                              }
                              required
                            >
                              <Option value="">
                                Select Your Type Of wallet
                              </Option>
                              <Option value="Ledger Nano S">
                                Ledger Nano S
                              </Option>
                              <Option value="Trezor Model T">
                                Trezor Model T
                              </Option>
                              <Option value="Exodus">Exodus</Option>
                              <Option value="Atomic Wallet">
                                Atomic Wallet
                              </Option>
                              <Option value="Trust Wallet">Trust Wallet</Option>
                              <Option value="MyEtherWallet">
                                MyEtherWallet
                              </Option>
                              <Option value="Coinomi">Coinomi</Option>
                              <Option value="MetaMask">MetaMask</Option>
                              <Option value="Freewallet">Freewallet</Option>
                              <Option value="KeepKey">KeepKey</Option>
                            </Select>
                          </div>
                        </Tooltip>
                      </div>
                    </div>

                    <div className="addform_body_right_crypto">
                      <div className="address_input">
                        <div className="mt-2 addform_input_half">
                          <div>
                            <Tooltip title="Enter your coin quantity">
                              <div>
                                <FormLabel>Quantity</FormLabel>
                                <Input
                                  className="input_mui_joy"
                                  placeholder="Enter quantity"
                                  onChange={(e) => handleChanges(e, "quantity")}
                                  value={data.cryptoAssest.quantity}
                                />
                              </div>
                            </Tooltip>
                          </div>
                        </div>

                        <div className="mt-2 addform_input_half">
                          <div>
                            <Tooltip title="Enter Your Estimated Value ">
                              <div>
                                <FormLabel required>Estimated Value</FormLabel>
                                <Input
                                  placeholder="Estimated Investment Value"
                                  required
                                  type="number"
                                  onChange={(e) => {
                                    handleChanges(e, "estValue");
                                  }}
                                  value={data.cryptoAssest.estValue}
                                  startDecorator={<div>$</div>}
                                />
                              </div>
                            </Tooltip>
                          </div>
                        </div>
                      </div>

                      <div>
                        <Tooltip title="Add your banks related file">
                          <div className="mt-2">
                            <FormLabel>Supporting Document</FormLabel>
                            <input
                              style={{
                                border: "solid 1px lightgray",
                                borderLeft: "none",
                                width: "100%",
                                borderRadius: "5px",
                              }}
                              type="file"
                              name="myfile"
                              id="exampleFile"
                              onChange={(e) => handleImageChange(e, 5)}
                              accept=".pdf"
                              multiple
                            />
                          </div>
                        </Tooltip>
                      </div>
                      <div
                        className="bene-select mt-3"
                        onClick={handleShowBeneficiary}
                        style={{ cursor: "pointer" }}
                      >
                        Select Your Beneficiary
                      </div>

                      <div className="address_input">
                        <div style={{ width: "100%" }} className="mt-2">
                          <Tooltip title="Enter notes for your coin">
                            <div>
                              <FormLabel>Notes</FormLabel>
                              <Textarea
                                sx={{ height: "182px", minHeight: "52px" }}
                                placeholder="Notes"
                                id="notes"
                                onChange={(e) => handleChanges(e, "notes")}
                                value={data.cryptoAssest.notes}
                              />
                            </div>
                          </Tooltip>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="text-center"
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <Button outline className="my-estate-add-btn">
                      Update
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      )}

      {benevisible && (
        <div className="popup">
          <div
            className="popup-content"
            style={{
              minWidth: "350px",
              width: "100%",
              maxWidth: "700px",
            }}
          >
            <div className="note_popup">
              <div className="note_popup_heading">
                <div className="share_property_heading">
                  <h2>Share Property </h2>
                </div>
                <div>
                  <button
                    className="note_popup_heading_close_btn"
                    onClick={handleReset}
                  >
                    <FontAwesomeIcon icon={faXmark} />
                  </button>
                </div>
              </div>
              <div className="share_property_est_value">
                <p>
                  Estimated Value:{" "}
                  <code style={{ color: "green", fontWeight: "bold" }}>
                    ${estimatedTotalAmount}
                  </code>
                </p>
                {/* {JSON.stringify(sharedDetails)}  */}
                {/* <p>----------------------------------------------------</p>
                    {JSON.stringify(data.documents)}
                    <p>----------------------------------------------------</p>
                    {JSON.stringify(data.accounts)}
                    <p>----------------------------------------------------</p>
                    {JSON.stringify(data.bank)}
                    <p>----------------------------------------------------</p>
                    {JSON.stringify(data)} */}
              </div>
              <div className="BeneficiarySelect">
                <div className="BeneficiarySelectContainer">
                  <div className="BeneficiarySelectRow">
                    <div className="share_property_Type">
                      <p className="share_property_Type_paragraph">
                        Choose Distribution Type:{" "}
                      </p>
                      <p className="ms-2 text-black fw-normal">
                        {/* {sharedDetails[0].distributedType} */}
                      </p>
                      <select
                        value={sharedDetails[0].distributedType}
                        onChange={(e) => handleDistributionTypeChange(e)}
                        class="share_property_Type_select"
                        disabled={
                          sharedDetails[0].distributedType !== "" ? true : false
                        }
                      >
                        <option value="">Select Type</option>
                        <option value="percentage">Percentage</option>
                        <option value="dollar">Dollar</option>
                      </select>
                    </div>
                    <div className="SelectContainer">
                      <select
                        className="Property-inputfiled"
                        onChange={handleBeneficiarySelection}
                        value=""
                        disabled={!distributedType}
                      >
                        <option value="" disabled hidden>
                          {distributedType
                            ? "Select Your Beneficiary Username"
                            : "Select Type First"}
                        </option>
                        {beneficiary.map((benif) => (
                          <option
                            key={benif.id}
                            value={benif.id}
                            disabled={selectedBeneficiaries.includes(benif.id)}
                          >
                            {/* {benif.username} */}
                            {`${benif.firstName} ${benif.lastName}`}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  {/* <div className="share_beneficiary_main_card">
                        {sharedDetails[0].distributedType !== null && (
                          <>
                            {sharedDetails[0].distributedType === 'dollar' ? (
                              <div>
                                <div className="share_beneficiary_card_close" onClick={() => handleBeneficiaryClose(beneficiary)}>
                                  <FontAwesomeIcon icon={faXmark} />
                                </div> 
                                <p>Beneficiary: </p>
                                <TextField type="number" label="Dollar" />
                              </div>
                            ) : null}

                            {sharedDetails[0].distributedType === 'percentage' ? (
                              <div>
                                <p>Beneficiary: </p>
                                <TextField type="number" label="Percentage" />
                              </div>
                            ) : null}
                          </>
                        )}
                      </div> */}
                  <div className="share_beneficiary_main_card">
                    {selectedBeneficiaries.map((beneficiary) => (
                      <div key={beneficiary} class="share_beneficiary_card">
                        <div>
                          <p className="share_beneficiary_card_para">
                            Beneficiary: {getBenificiaryName({ beneficiary })}
                          </p>
                          {distributedType === "percentage" && (
                            <input
                              type="text"
                              className="share_ben_percentage"
                              placeholder="Percentage"
                              value={
                                beneficiaryDetails[beneficiary]?.percentage ||
                                ""
                              }
                              onChange={(e) =>
                                handleFieldChange(
                                  beneficiary,
                                  "percentage",
                                  e.target.value
                                )
                              }
                            />
                          )}
                          {distributedType === "dollar" && (
                            <input
                              type="text"
                              className="share_ben_percentage"
                              placeholder="Dollar Value"
                              value={
                                beneficiaryDetails[beneficiary]?.dollar || ""
                              }
                              onChange={(e) =>
                                handleFieldChange(
                                  beneficiary,
                                  "dollar",
                                  e.target.value
                                )
                              }
                            />
                          )}

                          {distributedType && (
                            <p className="share_beneficiary_card_para">
                              {/* <p></p> */}
                              Distributed Value: $
                              {distributedType === "percentage"
                                ? calculateDistributedAmount(
                                    "percentage",
                                    estimatedTotalAmount,
                                    beneficiaryDetails[beneficiary]
                                  )
                                : calculateDistributedAmount(
                                    "dollar",
                                    estimatedTotalAmount,
                                    beneficiaryDetails[beneficiary]
                                  )}
                            </p>
                          )}
                        </div>
                        {/* <div
                            className="share_beneficiary_card_close"
                            onClick={() => handleBeneficiaryClose(beneficiary)}
                          >
                            <FontAwesomeIcon icon={faXmark} />
                          </div> */}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            {selectedBeneficiaries.length > 0 && (
              <button onClick={handleSave}>Save</button>
            )}
          </div>
        </div>
      )}
    </>
  );
}
