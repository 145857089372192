import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Autocomplete,
  FormLabel,
  Input,
  Option,
  Select,
  Textarea,
} from "@mui/joy";
import { FormControlLabel, Switch, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Form } from "reactstrap";
import SideBar from "../../../components/sidebar/Sidebar";
import UserBase from "../../../components/user/UserBase";
import "../../../css/formPOPup.css";
import "../../../css/myestate_edit.css";
import {
  getSingleVehicle,
  updateVehicles,
} from "../../../services/VehicleService";
import {
  fetchNetWorth,
  getBeneficiary,
  getOwnerByUserId,
  getSecondaryUser,
  getToken,
  getUser,
} from "../../../services/user-service";
function EditVehical() {
  const { id } = useParams();
  const navigate = useNavigate();
  //set data
  const [data, setData] = useState({
    vehicle: {
      owner: "",
      vehicleCaption: "",
      vehicleType: "",
      otherVehicleType: "",
      year: "",
      vinNumber: "",
      make: "",
      otherMake: "",
      estValue: "",
      model: "",
      otherModel: "",
      miles: "",
      notes: "",
    },
    mortgage: {
      mortgage: "",
      mortgageInstitution: "",
      accountNumber: "",
    },
    sharedDetails: [
      {
        distributedType: "",
        distributedValue: "",
        distributedAmount: "",
        beneficiaryId: "",
      },
    ],
  });

  const [ownerName, setOwnerName] = useState([]);

  const handleChange = (event, newValue, stringValue) => {
    if (stringValue === "vehicleType") {
      setData((prevData) => ({
        ...prevData,
        vehicle: {
          ...prevData.vehicle,
          vehicleType: newValue,
        },
      }));
    }

    if (stringValue === "make") {
      setData((prevData) => ({
        ...prevData,
        vehicle: {
          ...prevData.vehicle,
          make: newValue,
        },
      }));
    }

    if (stringValue === "model") {
      setData((prevData) => ({
        ...prevData,
        vehicle: {
          ...prevData.vehicle,
          model: newValue,
        },
      }));
    }

    if (stringValue === "owner") {
      // Convert ownerName array to a single string
      const comingValue =
        typeof newValue === "string" ? newValue.split(",") : newValue;
      const ownerString = comingValue.join(", ");

      setData((prevData) => ({
        ...prevData,
        vehicle: {
          ...prevData.vehicle,
          owner: ownerString,
        },
      }));

      // Update the ownerName state afterwards
      setOwnerName(newValue);
    }
  };

  // Define an array to store user names
  const [ownerNames, setOwnerNames] = useState([]);

  // Get primaryUser data
  const primaryUserDetails = getUser();
  const primaryUserName =
    primaryUserDetails.firstName + " " + primaryUserDetails.lastName;

  // Get secondaryUser data
  const secondaryUserDetails = getSecondaryUser();
  useEffect(() => {
    const fetchOwnerData = async () => {
      const userId = getUser().commonId;
      const token = "Bearer " + getToken();

      try {
        const resp = await getOwnerByUserId(token, userId);
        // Process data after fetching
        const names = [primaryUserName];

        // Check if secondary user exists
        if (secondaryUserDetails !== undefined) {
          const secondaryUserName = `${secondaryUserDetails.firstName} ${secondaryUserDetails.lastName}`;
          names.push(secondaryUserName);
        }
        resp.forEach((user) => {
          const fullName = `${user.firstName} ${user.lastName}`;
          names.push(fullName);
        });

        // Update ownerNames state
        setOwnerNames(names);
      } catch (error) {
        console.error("Failed to fetch owner data", error);
      }
    };

    fetchOwnerData();
  }, []);

  // use state to set the selected images
  const [selectedImage, setSelectedImage] = useState([]);
  // Handle image
  const handleImageChange = (event) => {
    const selectedFiles = event.target.files;
    const allowedExtensions = ["pdf"];

    if (selectedFiles) {
      const selectedFilesArray = Array.from(selectedFiles);

      // Check each file's extension
      const invalidFiles = selectedFilesArray.filter((file) => {
        const fileNameParts = file.name.split(".");
        const fileExtension =
          fileNameParts[fileNameParts.length - 1].toLowerCase();
        return !allowedExtensions.includes(fileExtension);
      });

      if (invalidFiles.length === 0) {
        const formData = new FormData();

        selectedFilesArray.forEach((file, index) => {
          formData.append(`file${index + 1}`, file);
        });

        // Set the selected image state with the array of files
        setSelectedImage(selectedFilesArray);

        // Now, you can use the formData object to send files to the API
        // Example:
        // apiCall(formData);
      } else {
        // Handle invalid file extensions
        const invalidExtensions = invalidFiles
          .map((file) => file.name.split(".").pop())
          .join(", ");
        toast.error(
          `Invalid file extensions: ${invalidExtensions}. Please select valid document formats.`,
          {
            position: toast.POSITION.BOTTOM_CENTER,
          }
        );
        event.target.value = ""; // Clear the input field
      }
    }
  };
  const currentYear = new Date().getFullYear();
  const handleChanges = (e, field, section) => {
    const newValue = e.target.value;
    if (section === "vehicle") {
      if (field === "year" && newValue > currentYear) {
        if (newValue > currentYear) {
          setData((prevData) => ({
            ...prevData,
            vehicle: {
              ...prevData.vehicle,
              [field]: "",
            },
          }));
          toast.error("You cannot select a future year.", {
            position: toast.POSITION.BOTTOM_CENTER,
          });
        } else if (newValue.length === 4 && newValue < 1900) {
          setData((prevData) => ({
            ...prevData,
            vehicle: {
              ...prevData.vehicle,
              [field]: "",
            },
          }));
          toast.error("You cannot select year before 1900.", {
            position: toast.POSITION.BOTTOM_CENTER,
          });
        } else {
          setData((prevData) => ({
            ...prevData,
            vehicle: {
              ...prevData.vehicle,
              [field]: newValue,
            },
          }));
        }
      } else {
        setData((prevData) => ({
          ...prevData,
          vehicle: {
            ...prevData.vehicle,
            [field]: newValue,
          },
        }));
        setEstimatedTotalAmount(data.vehicle.estValue);
      }
    } else {
      setData((prevData) => ({
        ...prevData,
        mortgage: {
          ...prevData.mortgage,
          [field]: newValue,
        },
      }));
    }
  };

  const [isChecked, setIsChecked] = useState(false);
  const handleSwitchChange = () => {
    setIsChecked(!isChecked);
  };

  // post the form
  const vehiclesForm = (event) => {
    event.preventDefault();
    let token = "Bearer " + getToken();
    const formData = new FormData();
    if (null != selectedImage) {
      for (let i = 0; i < selectedImage.length; i++) {
        formData.append(`files`, selectedImage[i]);
        console.log("this is file indexs", selectedImage[i]);
      }
    }
    formData.append("data", JSON.stringify(data));
    console.log("formData : ", JSON.stringify(data));

    updateVehicles(formData, token)
      .then((resp) => {
        toast.success("Updated Successfully!!", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
        fetchNetWorth(primaryUserDetails.commonId);
        navigate("/user/my-estate/vehicles");
      })
      .catch((error) => {
        console.log(error);
        toast.error("An error occurred. Please try again.", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      });
  };

  // get single data
  // const [showAfterCloseBene, setShowAfterCloseBene] = useState(true);
  useEffect(() => {
    const fetchData = async () => {
      try {
        let token = "Bearer " + getToken();
        const res = await getSingleVehicle(token, id);
        console.log("this is vehicle responce ", res);
        setData({
          ...data,
          vehicle: res.vehicle,
          mortgage: res.mortgage,
          documents: res.documents,
          sharedDetails: res.sharedDetails,
        });
        const ownerNameArray = res.vehicle.owner.split(", ");
        setOwnerName(ownerNameArray);
        setIsChecked(res.mortgage ? true : false);
        const copiedSharedDetails = [...res.sharedDetails];
        console.log("copiedSharedDetails response : ", copiedSharedDetails);
        setEstimatedTotalAmount(res.vehicle.estValue);

        console.log("check ", sharedDetails[0].distributedType);

        console.log("sharedDetails response : ", res.sharedDetails);
        console.log("sharedDetails response : ", sharedDetails);
        if (copiedSharedDetails.length > 0) {
          console.log(res.sharedDetails.length);
          setSharedDetails(res.sharedDetails);
          console.log("check ", sharedDetails[0].distributedType);
          ben(copiedSharedDetails[0].distributedType);
          for (var i = 0; i < copiedSharedDetails.length; i++) {
            handleBeneficiarySelection1(copiedSharedDetails[i].beneficiaryId);
            handleFieldChange1(
              copiedSharedDetails[i].beneficiaryId,
              copiedSharedDetails[i].distributedType,
              copiedSharedDetails[i].distributedValue
            );
            // console.log("sajid " + sharedDetails[0])
          }

          console.log(
            "sharedDetails beneficiaryDetails : ",
            beneficiaryDetails
          );
          console.log(
            "sharedDetails selectedBeneficiaries : ",
            selectedBeneficiaries
          );
          console.log("sharedDetails distributedType : ", distributedType);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
    getBenificiarydata();
  }, []);

  // beneficiary addition in form
  const [beneficiary, setBenificiary] = useState([]);
  const getBenificiarydata = () => {
    let userId = getUser().commonId;

    let token = "Bearer " + getToken();
    getBeneficiary(token, userId)
      .then((res) => {
        setBenificiary(res);
      })
      .catch((err) => console.log(err));
  };

  //

  const [benevisible, setbeneVisible] = useState(false);

  const [selectedBeneficiaries, setSelectedBeneficiaries] = useState([]);
  const [beneficiaryDetails, setBeneficiaryDetails] = useState({});
  const [estimatedTotalAmount, setEstimatedTotalAmount] = useState(0);

  const [distributedType, setDistributedType] = useState("");

  const [sharedDetails, setSharedDetails] = useState([
    {
      distributedType: "",
      distributedValue: "",
      distributedAmount: "",
      beneficiaryId: "",
    },
  ]);

  const ben = (newType) => {
    // const newType = sharedDetails[0].distributedType;
    const resetDetails = {};
    Object.keys(beneficiaryDetails).forEach((beneficiary) => {
      resetDetails[beneficiary] = { percentage: "", value: "" };
    });
    setDistributedType(newType);
    setBeneficiaryDetails(resetDetails);
  };

  const handleShowBeneficiary = () => {
    setbeneVisible(true);

    // setShowAfterCloseBene(true);
    // data.sharedDetails = [];
  };

  const handleReset = () => {
    setbeneVisible(false);
    setSelectedBeneficiaries([]);
    setBeneficiaryDetails({});
    // setShowAfterCloseBene(false);
  };

  const handleDistributionTypeChange = (event) => {
    console.log("handleDistributionTypeChange working : ", event.target.value);
    console.log("handleDistributionTypeChange sharedDetails", sharedDetails);
    var distributedType = event.target.value;
    const sharedDetails1 = sharedDetails.map((detail) => {
      // Change the distributedType value as needed
      detail.distributedType = distributedType;
      return detail;
    });
    console.log("handleDistributionType changed sharedDetails", sharedDetails);
    setSharedDetails(sharedDetails1);
    const newType = event.target.value;
    const resetDetails = {};
    Object.keys(beneficiaryDetails).forEach((beneficiary) => {
      resetDetails[beneficiary] = { percentage: "", dollar: "" };
    });
    setDistributedType(newType);
    setBeneficiaryDetails(resetDetails);
  };

  const handleBeneficiarySelection1 = (selectedBeneficiary) => {
    console.log("handleBeneficiarySelection working : ", selectedBeneficiary);
    if (selectedBeneficiary === null) {
      return null;
    }
    setSelectedBeneficiaries((prevSelectedBeneficiaries) => {
      // Check if the selectedBeneficiary is already included
      if (!prevSelectedBeneficiaries.includes(selectedBeneficiary)) {
        // If not included, update the selected beneficiaries
        const newSelectedBeneficiaries = [
          ...prevSelectedBeneficiaries,
          selectedBeneficiary,
        ];
        console.log(
          "Updated selected beneficiaries:",
          newSelectedBeneficiaries
        );
        return newSelectedBeneficiaries;
      }

      // If already included, return the previous state without any changes
      return prevSelectedBeneficiaries;
    });

    setBeneficiaryDetails((prevBeneficiaryDetails) => {
      const newBeneficiaryDetails = {
        ...prevBeneficiaryDetails,
        [selectedBeneficiary]: { percentage: "", dollar: "" },
      };
      console.log("Updated beneficiary details:", newBeneficiaryDetails);
      return newBeneficiaryDetails;
    });

    // setSelectedBeneficiaries((prevSelectedBeneficiaries) => {
    //   if (!prevSelectedBeneficiaries.includes(selectedBeneficiary)) {
    //     console.log("handleBeneficiarySelection 1 : ", selectedBeneficiary);
    //     setBeneficiaryDetails({
    //       ...beneficiaryDetails,
    //       [selectedBeneficiary]: { percentage: "", dollar: "" },
    //     });
    //     return [...prevSelectedBeneficiaries, selectedBeneficiary];
    //   }
    //   return prevSelectedBeneficiaries;
    // });

    console.log("handleBeneficiarySelection 2 : ", selectedBeneficiaries);
    console.log("check beneficiaryDetails 2 : ", beneficiaryDetails);
  };

  const handleBeneficiarySelection = (event) => {
    const selectedBeneficiary = event.target.value;
    console.log("handleBeneficiarySelection working : ", selectedBeneficiary);
    if (!selectedBeneficiaries.includes(selectedBeneficiary)) {
      setSelectedBeneficiaries([...selectedBeneficiaries, selectedBeneficiary]);
      setBeneficiaryDetails({
        ...beneficiaryDetails,
        [selectedBeneficiary]: { percentage: "", dollar: "" },
      });
    }
  };

  const getBenificiaryName = (id) => {
    // console.log('getBenificiaryName id : ', id);
    var foundBenificiary = null;
    if (id.beneficiary === undefined) {
      // console.log('IF condition');
      foundBenificiary = beneficiary.find((b) => b.id === parseInt(id));
    } else {
      foundBenificiary = beneficiary.find(
        (b) => b.id === parseInt(id.beneficiary)
      );
    }
    // console.log('foundBenificiary details : ', foundBenificiary);
    if (foundBenificiary) {
      return `${foundBenificiary.firstName} ${foundBenificiary.lastName}`;
    } else {
      return "Benificiary not found";
    }
  };

  const handleFieldChange1 = (beneficiary, field, value) => {
    console.log("handleFieldChange1   : ", beneficiaryDetails);

    setBeneficiaryDetails((prevDetails) => {
      // Create a copy of the state
      let updatedDetails = { ...prevDetails };

      // Update the copy
      updatedDetails = {
        ...updatedDetails,
        [beneficiary]: {
          ...updatedDetails[beneficiary],
          [field]: value,
        },
      };
      console.log("handleFieldChange1  2 : ", updatedDetails);

      // Return the updated state
      return updatedDetails;
    });
  };

  const handleFieldChange = (beneficiary, field, value) => {
    if (!estimatedTotalAmount || parseFloat(estimatedTotalAmount) === 0) {
      toast.error(
        "Please provide a valid estimated value before adding percentages!"
      );
      return;
    }
    let updatedDetails = { ...beneficiaryDetails };
    if (/^\d*$/.test(value)) {
      updatedDetails = {
        ...updatedDetails,
        [beneficiary]: {
          ...updatedDetails[beneficiary],
          [field]: value,
        },
      };
    }
    let totalPercentage = 0;
    let totalValue = 0;
    Object.keys(updatedDetails).forEach((key) => {
      const beneficiaryPercentage = parseFloat(updatedDetails[key]?.percentage);
      const beneficiaryValue = parseFloat(updatedDetails[key]?.dollar);
      if (!isNaN(beneficiaryPercentage) && beneficiaryPercentage >= 0) {
        totalPercentage += beneficiaryPercentage;
      }
      if (!isNaN(beneficiaryValue) && beneficiaryValue >= 0) {
        totalValue += beneficiaryValue;
      }
    });
    if (
      totalPercentage > 100 ||
      totalValue > parseFloat(estimatedTotalAmount)
    ) {
      updatedDetails[beneficiary][field] = "";
      toast.error(
        "Total percentage exceeds 100% or total value exceeds estimated value!"
      );
    } else {
      setBeneficiaryDetails(updatedDetails);
      // setSharedDetails(updatedDetails);
      // sharedDetails[index].distributedValue = updatedDetails.value;
      console.log("updatedDetails : ", updatedDetails);
    }
  };

  const calculateDistributedAmount = (
    distributedType,
    balance,
    beneficiaryDetail
  ) => {
    console.log("calculateDistributedAmount : ", beneficiaryDetail);
    console.log("calculateDistributedAmount balance : ", balance);
    // Assuming beneficiaryDetail is an object with a property 'value'
    if (distributedType === "percentage") {
      const calculatedAmount =
        (parseFloat(balance) * parseFloat(beneficiaryDetail.percentage)) / 100;
      console.log("calculateDistributedAmount return: ", calculatedAmount);
      return calculatedAmount.toFixed(2);
    } else if (distributedType === "dollar") {
      const detailValue = parseFloat(beneficiaryDetail.dollar);
      return detailValue.toFixed(2);
    }
    return "0.00";
  };

  const handleSave = () => {
    toast.success("Saved successfully!");
    setbeneVisible(false);
    var i = 0;
    Object.keys(beneficiaryDetails).forEach((key) => {
      const beneficiaryPercentage = parseFloat(
        beneficiaryDetails[key]?.percentage
      );
      const beneficiaryValue = parseFloat(beneficiaryDetails[key]?.dollar);
      if (!isNaN(beneficiaryPercentage) && beneficiaryPercentage >= 0) {
        const distributedAmount = calculateDistributedAmount(
          "percentage",
          estimatedTotalAmount,
          beneficiaryDetails[key]
        );
        handleChanges2(
          "percentage",
          beneficiaryPercentage,
          distributedAmount,
          parseInt(key),
          i
        );
        i += 1;
      }
      if (!isNaN(beneficiaryValue) && beneficiaryValue >= 0) {
        const distributedAmount = calculateDistributedAmount(
          "dollar",
          estimatedTotalAmount,
          beneficiaryDetails[key]
        );
        handleChanges2(
          "dollar",
          beneficiaryValue,
          distributedAmount,
          parseInt(key),
          i
        );
        i += 1;
      }
    });
    console.log("beneficiaryDetails data: ", data);
  };

  const handleChanges2 = (t, v, a, b, i) => {
    const updatedSharedDetails = [...data.sharedDetails];
    const updatedElement = {
      ...updatedSharedDetails[i],
      distributedType: t,
      distributedValue: v,
      distributedAmount: a,
      beneficiaryId: b,
    };
    updatedSharedDetails[i] = updatedElement;
    console.log("updatedSharedDetails[i] : ", updatedSharedDetails[i]);
    setData((prevState) => ({
      ...prevState,
      sharedDetails: updatedSharedDetails,
    }));
    data.sharedDetails[i] = updatedSharedDetails[i];
  };

  return (
    <UserBase>
      <div className="mt-5"></div>
      <SideBar>
        <div className="overlay1" style={{ transition: "500ms", height: "" }}>
          <div className="addform_ichest">
            <div className="addform_main">
              <div className="addform_heading">
                <h3 className="addform_heading_h1">Edit Vehicle Assets</h3>
                <div className="addform_close">
                  <FontAwesomeIcon
                    icon={faXmark}
                    onClick={() => {
                      navigate("/user/my-estate/vehicles");
                    }}
                  />
                </div>
              </div>
              <div className="addform_body">
                <Form onSubmit={vehiclesForm} className="addform_body">
                  <div className="addform_select_body">
                    <div className="addform_body_left">
                      <div>
                        <Tooltip title="Select Owner" className="mt-2">
                          <div>
                            <FormLabel required>Select Owner</FormLabel>
                            <Autocomplete
                              multiple
                              id="tags-default"
                              placeholder="Select Owner"
                              options={ownerNames}
                              value={ownerName}
                              onChange={(event, newValue) =>
                                handleChange(event, newValue, "owner")
                              }
                              required={ownerName.length > 0 ? false : true}
                            />
                          </div>
                        </Tooltip>
                      </div>

                      <div>
                        <Tooltip title="Enter your vehicle heading">
                          <div className="mt-2">
                            <FormLabel>Vehicle Heading</FormLabel>
                            <Input
                              className="input_mui_joy"
                              placeholder="Enter vehicle heading"
                              onChange={(e) =>
                                handleChanges(e, "vehicleCaption", "vehicle")
                              }
                              value={data.vehicle.vehicleCaption}
                            />
                          </div>
                        </Tooltip>
                      </div>

                      <div className="address_input">
                        <div className="mt-2 addform_input_half">
                          <div>
                            <Tooltip title="Enter the type of your vehicle">
                              <div className="mt-2">
                                <FormLabel required>Vehicle Type</FormLabel>
                                <Select
                                  value={data.vehicle.vehicleType}
                                  onChange={(event, newValue) =>
                                    handleChange(event, newValue, "vehicleType")
                                  }
                                  required
                                >
                                  <Option value="">
                                    Select type of vehicle
                                  </Option>
                                  <Option value="Auto">Auto</Option>
                                  <Option value="Two Wheeler">
                                    Two Wheeler
                                  </Option>
                                  <Option value="Boat">Boat</Option>
                                  <Option value="Yacht">Yacht</Option>
                                  <Option value="Other">Other</Option>
                                </Select>
                              </div>
                            </Tooltip>
                          </div>
                        </div>

                        <div className="mt-2 addform_input_half">
                          <div>
                            <Tooltip title="Enter the year of Manufacture of your vehicle">
                              <div className="mt-2">
                                <FormLabel required>
                                  Manufactured Year
                                </FormLabel>
                                <Input
                                  type="number"
                                  onChange={(e) =>
                                    handleChanges(e, "year", "vehicle")
                                  }
                                  value={data.vehicle.year}
                                  inputProps={{
                                    max: currentYear,
                                  }}
                                  placeholder="Manufactured Year"
                                  required
                                />
                              </div>
                            </Tooltip>
                          </div>
                        </div>
                      </div>

                      {data.vehicle.vehicleType === "Other" && (
                        <div>
                          <Tooltip title="Enter your other vehicle type">
                            <div className="mt-2">
                              <FormLabel>Other Vehicle Type</FormLabel>
                              <Input
                                className="input_mui_joy"
                                placeholder="Enter Other Vehicle"
                                onChange={(e) =>
                                  handleChanges(
                                    e,
                                    "otherVehicleType",
                                    "vehicle"
                                  )
                                }
                                value={data.vehicle.otherVehicleType}
                              />
                            </div>
                          </Tooltip>
                        </div>
                      )}

                      <div className="address_input">
                        <div className="mt-2 addform_input_half">
                          <div>
                            <Tooltip title="Enter the vin number">
                              <div>
                                <FormLabel>Vin Number</FormLabel>
                                <Input
                                  placeholder="Enter vin number"
                                  value={data.vehicle.vinNumber}
                                  onChange={(e) =>
                                    handleChanges(e, "vinNumber", "vehicle")
                                  }
                                />
                              </div>
                            </Tooltip>
                          </div>
                        </div>

                        <div className="mt-2 addform_input_half">
                          <div>
                            <Tooltip title="Enter the company of your vehicle">
                              <div>
                                <FormLabel required>Make</FormLabel>

                                <Input
                                  placeholder="Enter make"
                                  value={data.vehicle.make}
                                  onChange={(e) =>
                                    handleChanges(e, "make", "vehicle")
                                  }
                                  required
                                />
                              </div>
                            </Tooltip>
                          </div>
                        </div>
                      </div>

                      <div className="address_input">
                        <div className="mt-2 addform_input_half">
                          <div>
                            <Tooltip title="Enter the model of your vehicle">
                              <div>
                                <FormLabel>Model</FormLabel>
                                <Input
                                  placeholder="Enter model"
                                  value={data.vehicle.model}
                                  onChange={(e) =>
                                    handleChanges(e, "model", "vehicle")
                                  }
                                />
                              </div>
                            </Tooltip>
                          </div>
                        </div>

                        <div className="mt-2 addform_input_half">
                          <div>
                            <Tooltip title="Enter your vehicle mileage">
                              <div>
                                <FormLabel>Miles</FormLabel>
                                <Input
                                  placeholder="Enter miles"
                                  type="number"
                                  onChange={(e) =>
                                    handleChanges(e, "miles", "vehicle")
                                  }
                                  value={data.vehicle.miles}
                                />
                              </div>
                            </Tooltip>
                          </div>
                        </div>
                      </div>

                      <div>
                        <Tooltip title="Enter estimated value">
                          <div className="mt-2">
                            <FormLabel required>Estimated Value</FormLabel>
                            <Input
                              type="number"
                              className="input_mui_joy"
                              placeholder="Enter Estimated Value"
                              onChange={(e) =>
                                handleChanges(e, "estValue", "vehicle")
                              }
                              value={data.vehicle.estValue}
                              startDecorator={<div>$</div>}
                              required
                            />
                          </div>
                        </Tooltip>
                      </div>
                    </div>

                    <div className="addform_body_right_crypto">
                      <div className="mt-2 switch_changes_main">
                        <div className="switch_changes_base">
                          <div>Do you have loan against vehicle?</div>
                          <div>
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={isChecked}
                                  onChange={handleSwitchChange}
                                  color="primary"
                                  size="normal"
                                />
                              }
                              label={isChecked ? "Yes" : "No"}
                              labelPlacement="end"
                            />
                          </div>
                        </div>
                      </div>
                      {isChecked && (
                        <div>
                          <div>
                            <div>
                              <div>
                                <FormLabel required>
                                  {`Institution/Bank`}
                                </FormLabel>

                                <Input
                                  className="input_mui_joy"
                                  placeholder={`Institution/Bank`}
                                  onChange={(e) =>
                                    handleChanges(
                                      e,
                                      "mortgageInstitution",
                                      "mortgage"
                                    )
                                  }
                                  value={data?.mortgage?.mortgageInstitution}
                                  required
                                />
                              </div>
                            </div>
                          </div>
                          <div className="address_input">
                            <div className="mt-2 addform_input_half">
                              <div style={{ width: "100%" }}>
                                <FormLabel required>
                                  {`Account Number`}
                                </FormLabel>
                                <Input
                                  className="input_mui_joy"
                                  type="number"
                                  placeholder={`Account Number`}
                                  onChange={(e) =>
                                    handleChanges(
                                      e,
                                      "accountNumber",
                                      "mortgage"
                                    )
                                  }
                                  value={data?.mortgage?.accountNumber}
                                  required
                                />
                              </div>
                            </div>
                            <div className="mt-2 addform_input_half">
                              <div>
                                <FormLabel required>{`Amount`}</FormLabel>
                                <Input
                                  className="input_mui_joy"
                                  type="number"
                                  placeholder="Amount"
                                  onChange={(e) =>
                                    handleChanges(e, "mortgage", "mortgage")
                                  }
                                  value={data?.mortgage?.mortgage}
                                  startDecorator={<div>$</div>}
                                  required
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                      <div>
                        <Tooltip title="Add your banks related file">
                          <div className="mt-2">
                            <FormLabel>Supporting Document</FormLabel>
                            <input
                              style={{
                                border: "solid 1px lightgray",
                                borderLeft: "none",
                                width: "100%",
                                borderRadius: "5px",
                              }}
                              type="file"
                              name="myfile"
                              id="exampleFile"
                              onChange={(e) => handleImageChange(e, 5)}
                              accept=".pdf"
                              multiple
                            />
                          </div>
                        </Tooltip>
                      </div>
                      <div
                        className="bene-select mt-3"
                        onClick={handleShowBeneficiary}
                        style={{ cursor: "pointer" }}
                      >
                        Select Your Beneficiary
                      </div>

                      <div className="address_input">
                        <div style={{ width: "100%" }} className="mt-2">
                          <Tooltip title="Enter notes for your vehicle">
                            <div>
                              <FormLabel>Notes</FormLabel>
                              <Textarea
                                sx={{ height: "182px", minHeight: "52px" }}
                                placeholder="Notes"
                                id="notes"
                                onChange={(e) =>
                                  handleChanges(e, "notes", "vehicle")
                                }
                                value={data.vehicle.notes}
                              />
                            </div>
                          </Tooltip>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="text-center"
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <Button outline type="" className="my-estate-add-btn">
                      Update
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
        {benevisible && (
          <div className="popup">
            <div
              className="popup-content"
              style={{
                minWidth: "350px",
                width: "100%",
                maxWidth: "700px",
              }}
            >
              <div className="note_popup">
                <div className="note_popup_heading">
                  <div className="share_property_heading">
                    <h2>Share Property </h2>
                  </div>
                  <div>
                    <button
                      className="note_popup_heading_close_btn"
                      onClick={handleReset}
                    >
                      <FontAwesomeIcon icon={faXmark} />
                    </button>
                  </div>
                </div>
                <div className="share_property_est_value">
                  <p>
                    Estimated Value:{" "}
                    <code style={{ color: "green", fontWeight: "bold" }}>
                      ${estimatedTotalAmount}
                    </code>
                  </p>
                </div>
                <div className="BeneficiarySelect">
                  <div className="BeneficiarySelectContainer">
                    <div className="BeneficiarySelectRow">
                      <div className="share_property_Type">
                        <p className="share_property_Type_paragraph">
                          Choose Distribution Type:{" "}
                        </p>
                        <p className="ms-2 text-black fw-normal"></p>
                        <select
                          value={sharedDetails[0].distributedType}
                          onChange={(e) => handleDistributionTypeChange(e)}
                          class="share_property_Type_select"
                          disabled={
                            sharedDetails[0].distributedType !== ""
                              ? true
                              : false
                          }
                        >
                          <option value="">Select Type</option>
                          <option value="percentage">Percentage</option>
                          <option value="dollar">Dollar</option>
                        </select>
                      </div>
                      <div className="SelectContainer">
                        <select
                          className="Property-inputfiled"
                          onChange={handleBeneficiarySelection}
                          value=""
                          disabled={!distributedType}
                        >
                          <option value="" disabled hidden>
                            {distributedType
                              ? "Select Your Beneficiary Username"
                              : "Select Type First"}
                          </option>
                          {beneficiary.map((benif) => (
                            <option
                              key={benif.id}
                              value={benif.id}
                              disabled={selectedBeneficiaries.includes(
                                benif.id
                              )}
                            >
                              {`${benif.firstName} ${benif.lastName}`}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>

                    <div className="share_beneficiary_main_card">
                      {selectedBeneficiaries.map((beneficiary) => (
                        <div key={beneficiary} class="share_beneficiary_card">
                          <div>
                            <p className="share_beneficiary_card_para">
                              Beneficiary: {getBenificiaryName({ beneficiary })}
                            </p>
                            {distributedType === "percentage" && (
                              <input
                                type="text"
                                className="share_ben_percentage"
                                placeholder="Percentage"
                                value={
                                  beneficiaryDetails[beneficiary]?.percentage ||
                                  ""
                                }
                                onChange={(e) =>
                                  handleFieldChange(
                                    beneficiary,
                                    "percentage",
                                    e.target.value
                                  )
                                }
                              />
                            )}
                            {distributedType === "dollar" && (
                              <input
                                type="text"
                                className="share_ben_percentage"
                                placeholder="Dollar Value"
                                value={
                                  beneficiaryDetails[beneficiary]?.dollar || ""
                                }
                                onChange={(e) =>
                                  handleFieldChange(
                                    beneficiary,
                                    "dollar",
                                    e.target.value
                                  )
                                }
                              />
                            )}

                            {distributedType && (
                              <p className="share_beneficiary_card_para">
                                {/* <p></p> */}
                                Distributed Value: $
                                {distributedType === "percentage"
                                  ? calculateDistributedAmount(
                                      "percentage",
                                      estimatedTotalAmount,
                                      beneficiaryDetails[beneficiary]
                                    )
                                  : calculateDistributedAmount(
                                      "dollar",
                                      estimatedTotalAmount,
                                      beneficiaryDetails[beneficiary]
                                    )}
                              </p>
                            )}
                          </div>
                          {/* <div
                            className="share_beneficiary_card_close"
                            onClick={() => handleBeneficiaryClose(beneficiary)}
                          >
                            <FontAwesomeIcon icon={faXmark} />
                          </div> */}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              {selectedBeneficiaries.length > 0 && (
                <button onClick={handleSave}>Save</button>
              )}
            </div>
          </div>
        )}
      </SideBar>
    </UserBase>
  );
}

export default EditVehical;
